import React from 'react';
import {
  Grid,
} from 'semantic-ui-react'

import LoShuGridSVG from './../SVGs/LoShuGridSVG';
import PlanePercentages from './PlanePercentages';

const LoShuGrid = ({
  conductorNumber,
  driverNumber,
  kuaNumber,
  loShuGridData,
  loShuPlanesData,
}) => {
  return(<Grid>
    <Grid.Column
      mobile={16}
      tablet={8}
      computer={4}
      style={{
        minWidth: '450px'
      }}
    >
      <LoShuGridSVG
        borderColor={'#999'}
        borderStrokeWidth={1}
        gridData={loShuGridData}
        gridSize={400}
        textColor={'#999'}
        driver={driverNumber}
        conductor={conductorNumber}
        kua={kuaNumber}
      />
    </Grid.Column>
    <Grid.Column mobile={16} tablet={8} computer={4}>
      {loShuPlanesData && <PlanePercentages
        planes={loShuPlanesData}
      />}
    </Grid.Column>
  </Grid>);
};

export default LoShuGrid;
