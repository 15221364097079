import React, { useContext, useState } from "react";
import { Button, TextArea } from "semantic-ui-react";

import { NakshatraContext } from "../../contexts/nakshatra.context";

import UntenantedPlanets from '../UntenantedPlanets';
import { KPSignificatorsPlanets } from "../KPSignificatorsPlanets";
import KPSignificatorsCuspsView from "../KPSignificatorsCuspsView";
import CuspChart from "../CuspChart";
import PlanetaryPosition from "../PlanetaryPosition";

const Basic = () => {
  const [chartData, setChartData] = useState("");

  const { dispatch} = useContext(NakshatraContext);

  const textAreaChanged = (event, { value }) => setChartData(value);

  const analyze = () => {
    if (chartData !== "" && chartData !== " ") {
      dispatch({
        type: `JHORA_CHART_ANALIZED`,
        payload: {
          jHoraChartData: chartData,
        },
      });
    }
  };

  return (
    <div>
      <TextArea
        placeholder="Paste Jagannatha Hora Main chart, Houses and KP details one after another"
        style={{
          width: "100%",
          minHeight: 100,
        }}
        onChange={textAreaChanged}
      />
      <Button type="submit" className="btn btn-primary" onClick={analyze}>
        Analyze
      </Button>
      <KPSignificatorsPlanets chartType={"jhora"} />
      <CuspChart chartType={"jhora"} />
      <PlanetaryPosition chartType={"jhora"} />
      <KPSignificatorsCuspsView chartType={"jhora"} />
      <UntenantedPlanets chartType={"jhora"} />
    </div>
  );
};

export default Basic;
