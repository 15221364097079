import React, {
  useEffect,
  useState
} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { Card, Divider, Header, Icon, Modal } from 'semantic-ui-react'
import signStarSubTable from './../../data/signStarSubTable'
import { Tagify } from '../engine/helper'

import './table.css';

const ToggleContent = ({ signStarSub }) => {
  const [isShown, setIsShown] = useState(false)

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  return (
    <Modal
      key={signStarSub.id}
      size='fullscreen'
      trigger={<Tr
        key={signStarSub.id}
        onClick={toggler}
      >
        <Td>{signStarSub.id}</Td>
        <Td>{signStarSub.rashi}</Td>
        <Td>{signStarSub.nakshatra}</Td>
        <Td>{`${signStarSub.sign} ${signStarSub.star} ${signStarSub.sub}`}</Td>
      </Tr>}
      open={isShown}
      centered={false}>
      <Card fluid
        key={signStarSub.id}
        className='toggle'
      >
        <Card.Content>
          <Card.Header>{signStarSub.rashi}</Card.Header>
          <Divider>{signStarSub.rashi + ' Body Parts'}</Divider>
          <Card.Description>
            <Tagify
              tags={signStarSub.kpRashiBodyParts}
            />
          </Card.Description>
          <Divider>Epilogue</Divider>
          <Card.Description>
            <Tagify
              tags={signStarSub.epilogue}
            />
          </Card.Description>
          <Divider>{signStarSub.sign + '-' + signStarSub.star + '-' + signStarSub.sub + ' Body Parts'}</Divider>
          <Card.Meta>
            <Tagify
              tags={signStarSub.kpSubBodyParts}
            />
          </Card.Meta>
          <Divider>Pathogenic Effects</Divider>
          <Card.Content extra>
            <Tagify
              tags={signStarSub.kpPathogenicEffects}
            />
          </Card.Content>
          <Header floated='right'>
            <Icon onClick={toggler} name='close' />
          </Header>
        </Card.Content>
      </Card>
    </Modal>
  )
}

const renderHeader = (key) => {
  let element

  switch (key) {
    case 'kpPathogenicEffects':
      element = <Th key={key}>Pathogenic Effects</Th>
      break
    case 'kpRashiBodyParts':
      element = <Th key={key}>Rashi Body Parts</Th>
      break
    case 'kpSubBodyParts':
      element = <Th key={key}>Sub Body Parts</Th>
      break
    case 'id':
      element = <Th key={key}>#</Th>
      break
    case 'rashi':
      element = <Th key={key}>Rashi</Th>
      break
    default:
      element = <Th key={key}>{key}</Th>
      break
  }

  return element
}

const renderTable = () => {
  const minusArray = ['rashiIndex', 'nakshatraIndex', 'profession',
    'character', 'pada', 'startsAt', 'endsAt', 'epilogue', 'kpPathogenicEffects',
    'kpRashiBodyParts', 'kpSubBodyParts', 'sign', 'star', 'sub']
  const keys = Object.keys(signStarSubTable[0]).filter(x => !minusArray.includes(x))
  keys.push('Sign Star Sub')

  return (<Table>
    <Thead>
      <Tr>
        {keys.map((key) => {
          return renderHeader(key)
        })}
      </Tr>
    </Thead>
    <Tbody>
      {signStarSubTable.map((signStarSub, idx) => (keys.map((key) => <ToggleContent key={key} signStarSub={signStarSub} />)))}
    </Tbody>
  </Table>)
}

const VimshottariSubs = () => {

  useEffect(() => {
    document.title = 'VimshottariSubs'
  }, []);

  return (renderTable())
}

export default VimshottariSubs
