import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { reducer, initialState } from "./reducers";
import { useActions } from "./actions";

import { getCookie } from "./../components/Auth/session";

const NakshatraContext = createContext(initialState);

// https://github.com/jaehyun-ii/23-/blob/main/src/contexts/auth/auth0/auth-provider.jsx
const auth0Client = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: window.location.origin + "/auth0_callback",
  },
});

const NakshatraProvider = ({ children }) => {
  const storedInitialState = getCookie("state");
  const [state, dispatch] = useReducer(
    reducer,
    storedInitialState || initialState
  );
  const actions = useActions(state, dispatch);

  const isAuth0Authenticated = async () => await auth0Client.isAuthenticated();

  const initialize = useCallback(async () => {
    try {
      await auth0Client.checkSession();

      const isAuthenticated = isAuth0Authenticated();
      if (isAuthenticated) {
        const user = await auth0Client.getUser();

        dispatch({
          type: "USER",
          payload: {
            ...user,
            ...isAuthenticated,
          },
        });
      } else {
        dispatch({
          type: "LOGOUT",
          payload: {
            user: undefined,
            isAuthenticated,
          },
        });
      }
    } catch (e) {
      console.log("🚀 ~ initialize ~ e:", e);
      dispatch({
        type: "LOGOUT",
        payload: {
          user: undefined,
          isAuthenticated: false,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const loginWithRedirect = useCallback(async (appState) => {
    await auth0Client.loginWithRedirect({
      appState,
    });

    const isAuthenticated = isAuth0Authenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      dispatch({
        type: "USER",
        payload: {
          ...user,
          ...isAuthenticated,
        },
      });
    }
  }, []);

  const loginWithPopup = useCallback(async (appState) => {
    await auth0Client.loginWithPopup({
      appState,
    });

    const isAuthenticated = isAuth0Authenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      dispatch({
        type: "USER",
        payload: {
          ...user,
          ...isAuthenticated,
        },
      });
    }
  }, []);

  const handleRedirectCallback = useCallback(async () => {
    console.log("🚀 ~ handleRedirectCallback ~ handleRedirectCallback:")
    const result = await auth0Client
      .handleRedirectCallback
      // window.location.origin + '/auth0_callback'
      ();
    const user = await auth0Client.getUser();

    // Here you should extract the complete user profile to make it available in your entire app.
    // The auth state only provides basic information.

    dispatch({
      type: "USER",
      payload: {
        ...user,
        ...result.appState,
      },
    });

    return result.appState;
  }, []);

  const logout = useCallback(async () => {
    await auth0Client.logout();
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  return (
    <NakshatraContext.Provider
      value={{
        actions,
        dispatch,
        state,
        loginWithRedirect,
        loginWithPopup,
        handleRedirectCallback,
        logout,
      }}
    >
      {children}
    </NakshatraContext.Provider>
  );
};

const useAuth = () => useContext(NakshatraContext);

export { NakshatraContext, NakshatraProvider, useAuth };
