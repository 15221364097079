import React from 'react'
import styled from 'styled-components';
import VictorMonoExtraLightItalic from '../../../fonts/VictorMono-ExtraLightItalic.ttf';

const GridStyle = styled.svg`
  @font-face {
    font-family: 'VictorMono-ExtraLightItalic';
    src: local('VictorMono-ExtraLightItalic'), local('VictorMono-ExtraLightItalic'),
    url(${VictorMonoExtraLightItalic}) format('ttf'),
    font-weight: 200;
    font-style: normal;
  }
  font-family: 'VictorMono-ExtraLightItalic';
  font-weight: 200;
  font-size: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSvgTSpan = styled.tspan`
  font-size: 80%;
`;

const DCKText = ({
  driver,
  conductor,
  kua,
  loShuNumber,
  x,
  y
}) => {
  return(
    <StyledSvgTSpan
      x={x}
      y={y}
    >
      {(driver && (driver.toString() === loShuNumber.toString())) && `${loShuNumber.toString()}d`}
      {(conductor && (conductor.toString() === loShuNumber.toString())) && `${loShuNumber.toString()}c`}
      {(kua && (kua.toString() === loShuNumber.toString())) && `${loShuNumber.toString()}k`}
    </StyledSvgTSpan>
  )
};

const LoShuGrid = (props) => {
  const {
    borderColor,
    borderStrokeWidth,
    conductor,
    driver,
    gridData,
    gridSize,
    kua,
    textColor,
  } = props;

  const adjustedSize = gridSize <= 400 ? 400 : gridSize;
  const gridHeight = adjustedSize;
  const gridWidth = adjustedSize;

  return (
    <GridStyle
      fill={textColor}
      width={gridWidth || 400}
      height={gridHeight || 400}
      {...props}
    >
      <path
        fill='none'
        stroke={borderColor || '#000'}
        strokeWidth={borderStrokeWidth ? borderStrokeWidth + 2 : 1}
        strokeLinecap='round'
        strokeLinejoin='round'
        d={`M0 0h${gridWidth}v${gridHeight}H0z`}
      />
      <g
        fill='none'
        stroke={borderColor || '#000'}
        strokeWidth={borderStrokeWidth || 1}
      >
        <path
          d={`M0 ${(gridWidth/100)*33.33}h${gridWidth}
              M0 ${(gridWidth/100)*66.66}h${gridWidth}
              M${(gridHeight/100)*33.33} 0v${gridHeight}
              M${(gridHeight/100)*66.66} 0v${gridHeight}`
            }
        />
      </g>
      <g
        fontSize={30}
        fontWeight={400}
        fontFamily='VictorMono-ExtraLightItalic'
      >
        <text>
          <tspan y={'5%'} x={'28%'}>
            {'4'}
          </tspan>
          <tspan y={'18%'} x={(gridData && gridData[4].entries.length >3) ? '1%' : '10%'}>
            {gridData && `${gridData[4].entries}`}
          </tspan>
          {<DCKText
            driver={driver}
            conductor={conductor}
            kua={kua}
            loShuNumber={4}
            y={'25%'}
            x={(gridData && gridData[4].entries.length >3) ? '1%' : '10%'}
          />}
        </text>
        <text>
          <tspan y={'5%'} x={'61%'}>
            {'9'}
          </tspan>
          <tspan y={'18%'} x={(gridData && gridData[9].entries.length >3) ? '35%' : '40%'}>
            {gridData && `${gridData[9].entries}`}
          </tspan>
          {<DCKText
            driver={driver}
            conductor={conductor}
            kua={kua}
            loShuNumber={9}
            y={'25%'}
            x={(gridData && gridData[9].entries.length >3) ? '35%' : '40%'}
            />}
        </text>
        <text>
          <tspan y={'5%'} x={'95%'}>
            {'2'}
          </tspan>
          <tspan y={'18%'} x={(gridData && gridData[2].entries.length >3) ? '70%' : '75%'}>
            {gridData && `${gridData[2].entries}`}
          </tspan>
          {<DCKText
            driver={driver}
            conductor={conductor}
            kua={kua}
            loShuNumber={2}
            y={'25%'}
            x={(gridData && gridData[2].entries.length >3) ? '70%' : '75%'}
            />}
        </text>
        <g>
          <text>
            <tspan  y={'39%'} x={'28%'}>
              {'3'}
            </tspan>
            <tspan y={'53%'} x={(gridData && gridData[3].entries.length >3) ? '1%' : '10%'}>
              {gridData && `${gridData[3].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={3}
              y={'60%'}
              x={(gridData && gridData[3].entries.length >3) ? '1%' : '10%'}
            />}
          </text>
          <text>
            <tspan y={'39%'} x={'61%'}>
              {'5'}
            </tspan>
            <tspan y={'53%'} x={(gridData && gridData[5].entries.length >3) ? '35%' : '40%'}>
              {gridData && `${gridData[5].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={5}
              y={'60%'}
              x={(gridData && gridData[5].entries.length >3) ? '35%' : '40%'}
            />}
          </text>
          <text>
            <tspan y={'39%'} x={'95%'}>
              {'7'}
            </tspan>
            <tspan y={'53%'} x={(gridData && gridData[7].entries.length >3) ? '70%' : '75%'}>
              {gridData && `${gridData[7].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={7}
              y={'60%'}
              x={(gridData && gridData[7].entries.length >3) ? '70%' : '75%'}
            />}
          </text>
        </g>
        <g>
          <text>
            <tspan y={'72.5%'} x={'28%'}>
              {'8'}
            </tspan>
            <tspan y={'85%'} x={(gridData && gridData[8].entries.length >3) ? '1%' : '10%'}>
              {gridData && `${gridData[8].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={8}
              y={'92%'}
              x={(gridData && gridData[8].entries.length >3) ? '1%' : '10%'}
            />}
          </text>
          <text>
            <tspan y={'72.5%'} x={'61%'}>
              {'1'}
            </tspan>
            <tspan y={'85%'} x={(gridData && gridData[1].entries.length >3) ? '35%' : '40%'}>
              {gridData && `${gridData[1].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={1}
              y={'92%'}
              x={(gridData && gridData[1].entries.length >3) ? '35%' : '40%'}
            />}
          </text>
          <text>
            <tspan y={'72.5%'} x={'95%'}>
              {'6'}
            </tspan>
            <tspan y={'85%'} x={(gridData && gridData[6].entries.length >3) ? '70%' : '75%'}>
              {gridData && `${gridData[6].entries}`}
            </tspan>
            {<DCKText
              driver={driver}
              conductor={conductor}
              kua={kua}
              loShuNumber={6}
              y={'92%'}
              x={(gridData && gridData[6].entries.length >3) ? '70%' : '75%'}
            />}
          </text>
        </g>
      </g>
    </GridStyle>
  )
};

export default LoShuGrid;
