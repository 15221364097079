import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
    font-size: 16px;
    font-family: 'Open Sans';

    --background-light: #eef4f7;
    --background-dark: #1E1F1A;

    --background-dark-selected: #54544c;
    --background-light-selected: #dfdfdf;

    --text-color-normal: #0a244d;

    --color-light: #10171d;
    --color-dark: #eef4f7;
    --color-dark-selected: #dfdfdf;

    --nav-bg-color-dark: #1b2938;

    --chart-line-dark: #999;
    --chart-line-normal: #000;

    --table-tr-alternate-dark: #282828;
    --table-tr-alternate-light: #f6f6f6;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
`;
