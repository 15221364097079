import React from 'react';
import { Modal } from 'semantic-ui-react';

import CombinationCard from '../CombinationCard';

import 'semantic-ui-css/semantic.min.css';

const rashis = require('../../../data/rashis');

const ToggleContent = ({
  ghaarIndex,
  selectionIndex,
  triggerElement,
  isShown,
  toggler,
  houseIndex,
  selectedPlanets,
}) => {
  const rashi = rashis[ghaarIndex];

  return (
    <Modal
      key={rashi.id}
      size="fullscreen"
      trigger={triggerElement}
      open={isShown && selectionIndex === rashi.id}
      centered={false}>
      <CombinationCard
        houseIndex={houseIndex}
        rashiIndex={ghaarIndex}
        toggler={toggler}
        selectedPlanets={selectedPlanets}
      />
    </Modal>
  );
};

export default ToggleContent;
