import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Checkbox, Dropdown } from "semantic-ui-react";
import useDarkMode from "use-dark-mode";
import { NakshatraContext } from "../../contexts/nakshatra.context";

import ChartSvg from "./ChartSvg";

import "semantic-ui-css/semantic.min.css";

import useComponentSize from "../../engine/useComponentSize";

const rashis = require("../../../data/rashis");

const NorthChartStyle = styled.div`
  height: ${(p) => p.height}; //100vh;
  width: ${(p) => p.width}; //100vw;
  .item {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? "#54544c" : "#e0e1e2"};
  }
  .selected {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? "#54544c" : "#afaf9f"};
  }
  .planetBtn {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? "#54544c" : "#e0e1e2"};
    color: ${({ darkModeValue }) => (darkModeValue ? "#e0e1e2" : "#54544c")};
    border: none;
    border-radius: 0.1em;
    position: relative;
    overflow: hidden;
    transition: background-color 200ms ease-in-out;
  }
  .planetBtn::before,
  .planetBtn::after {
    content: "";
    width: 0%;
    height: 0.15em;
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? "#e0e1e2" : "#54544c"};
    position: absolute;
    transition: width 200ms ease-in-out;
  }

  .planetBtn::before {
    left: 0;
    top: 0;
  }

  .planetBtn::after {
    right: 0;
    bottom: 0;
  }

  .planetBtn:hover::before,
  .planetBtn:hover::after {
    width: 100%;
  }

  .planetBtn:hover {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? "#54544c" : "#e0e1e2"};
  }
`;

const signOptions = () => {
  return rashis.map((sign, indx) => {
    return {
      key: sign.id,
      value: sign.id,
      text: `${sign.name} (${sign.vedicName})`,
    };
  });
};

const PlanetGroupStyle = styled.div`
  .ui.buttons {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ChartNorth = () => {
  const ref = useRef(null);
  const size = useComponentSize(ref);
  const { width, height } = size;
  const { value: darkModeValue } = useDarkMode();

  const {
    dispatch,
    state: {
      slider: {
        chartAnalysis: { northChart: { currentSignIndex } = {} } = {},
        planets,
        flags: {
          showNakshatraPadas,
          showJHoraMappedPlanets,
          showAspectsWhenClicked,
        } = {},
      } = {},
    },
  } = useContext(NakshatraContext);

  const [currentSelectedPlanet, setCurrentSelectedPlanet] = useState(null);
  const [currentSelectedHouse, setCurrentSelectedHouse] = useState(null);

  const handleDropdownChange = (e, data) => {
    dispatch({
      type: "SLIDER_NORTH_CHART_HOUSE_ROTATION",
      payload: data.value - 1,
    });
  };

  const handlePlanetClick = (planet) => {
    setCurrentSelectedPlanet(planet?.[0]);
  };

  const planetOptions = () => {
    const returnPlanets = () => {
      return Object.entries(planets).map((planet, indx) => {
        const { id, name } = planet[1];
        return (
          <Button
            className="planetBtn"
            key={id}
            onClick={() => handlePlanetClick(planet)}
          >
            {name.slice(0, 2)}
          </Button>
        );
      });
    };
    return (
      <PlanetGroupStyle darkModeValue={darkModeValue}>
        <Button.Group>{returnPlanets()}</Button.Group>
      </PlanetGroupStyle>
    );
  };

  const handleHousePlanetReset = () => {
    dispatch({
      type: "NAK_ASPECTS_HOUSE_PLANET_RESET",
    });
  };

  useEffect(() => {
    if (currentSelectedPlanet === null && currentSelectedHouse !== null) {
      setCurrentSelectedHouse(null);
    }
    if (currentSelectedPlanet !== null && currentSelectedHouse !== null) {
      dispatch({
        type: "NAK_ASPECTS_HOUSE_PLANET_ASSIGNMENT",
        payload: {
          house: currentSelectedHouse + 1,
          planet: currentSelectedPlanet,
        },
      });
      setCurrentSelectedHouse(null);
      setCurrentSelectedPlanet(null);
    }
  }, [currentSelectedPlanet, currentSelectedHouse, dispatch]);

  const handleShowJHoraMappedFlagChange = () => {
    dispatch({
      type: "TOGGLE_SHOW_JHORA_MAPPED_PLANETS_FLAG",
    });
  };

  const handleShowNakshatraFlagChange = () => {
    dispatch({
      type: "TOGGLE_SHOW_NAKSHATRA_PADAS_FLAG",
    });
  };

  return (
    <NorthChartStyle
      ref={ref}
      darkModeValue={darkModeValue}
      height={height}
      width={width}
    >
      <Dropdown
        placeholder="Rotate Asc to"
        inline
        defaultValue={currentSignIndex + 1}
        options={signOptions()}
        onChange={handleDropdownChange}
      />
      <Button className="planetBtn" onClick={handleShowNakshatraFlagChange}>
        {showNakshatraPadas ? "Hide Nakshatras" : "Show Nakshatras"}
      </Button>
      <Button className="planetBtn" onClick={handleShowJHoraMappedFlagChange}>
        {showJHoraMappedPlanets
          ? "Show Selected Planets"
          : "Show JHora Mapped Planets"}
      </Button>
      <Checkbox
        checked={showAspectsWhenClicked}
        onChange={() => dispatch({ type: "TOGGLE_SHOW_ASPECTS_FLAG" })}
        label="Show Aspects with clicked"
      />
      {currentSelectedPlanet}
      {currentSelectedHouse}
      {planetOptions()}
      <Button onClick={handleHousePlanetReset}>Reset</Button>
      <ChartSvg
        setCurrentSelectedHouse={setCurrentSelectedHouse}
      />
    </NorthChartStyle>
  );
};

export default ChartNorth;
