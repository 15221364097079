import React, { useContext } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { NakshatraContext } from "../../contexts/nakshatra.context";

const KPSignificatorsCuspsView = ({
  chartType = 'jhora',
}) => {
  let kpSigABCD = [];
  const {
    state: {
      jHoraChart: {
        jHoraChartData,
      } = {},
      horosoftChart: {
        horosoftChartData,
      } = {},
    },
  } = useContext(NakshatraContext);

  if (chartType === 'jhora') {
    kpSigABCD = jHoraChartData?.kpSigABCD;
  } else if (chartType === 'horosoft') {
    kpSigABCD = horosoftChartData?.kpSigABCD;
  }

  return kpSigABCD ? (
    <Table>
      <Thead>
        <Tr>
          <Th>House</Th>
          <Th>A</Th>
          <Th>B</Th>
          <Th>C</Th>
          <Th>D</Th>
          <Th>Independent</Th>
        </Tr>
      </Thead>
      <Tbody>
        {kpSigABCD.map(({House, A, B, C, D, Independent}, index) => (
          <Tr key={index}>
            <Td>{House}</Td>
            <Td>{A}</Td>
            <Td>{B}</Td>
            <Td>{C}</Td>
            <Td>{D}</Td>
            <Td>{Independent.toString()}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : null;
};

export default KPSignificatorsCuspsView;
