import React, {
  useEffect,
} from 'react';
import {
  Message,
} from 'semantic-ui-react';

const houses = require('./../../../data/houses');

const renderContent = (contentList) => contentList.map((textRow, index) => {
  return <Message.Item
    key={index}
  >
    {textRow}
  </Message.Item>;
});

const Houses = () => {

  useEffect(() => {
    document.title = 'KP Houses'
  }, []);

  return (
    <div>
      {houses.map((house) => {
        return <Message floating key={`house-${house.id}`}>
          <Message.Header>
            {house.numorizedName}
          </Message.Header>
          <Message.Content>
            <Message.List>
              {renderContent(house.bhrigu)}
            </Message.List>
          </Message.Content>
        </Message>
      })}
    </div>
  );
};

export default Houses;
