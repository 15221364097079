import React, {
  useState,
} from 'react';
import {
  Card,
  Modal,
} from 'semantic-ui-react';
import {
  Table,
} from 'semantic-ui-react'

import GrahaCardContent from './GrahaCardContent';

const GrahaToggleCellContent = ({
  grahaKey,
  graha,
}) => {
  const [isShown, setIsShown] = useState(false)

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  return (
    <Modal
      key={grahaKey}
      size='fullscreen'
      trigger={
        <Table.Cell
          key={grahaKey}
          collapsing
          onClick={toggler}
        >
          {graha.graha}
        </Table.Cell>
      }
      open={isShown}
      centered={false}>
      <Card fluid
        key={grahaKey}
        className='toggle'
      >
        <GrahaCardContent
          graha={graha}
          toggler={toggler}
        />
      </Card>
    </Modal>
  )
}

export default GrahaToggleCellContent;
