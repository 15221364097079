module.exports = Object.freeze({
  1: {
    best: [9, 2, 5, 3, 6, 4, 7, 1],
    neutral: [1, 6, 4],
    avoid: [8],
    shortDiscription: `King`,
    planets: [`Sun`],
  },
  2: {
    best: [1, 5, 3, 7, 2],
    neutral: [6, 2],
    avoid: [4, 8, 9, 6],
    shortDiscription: `Queen`,
    planets: [`Moon`],
  },
  3: {
    best: [1, 2, 5, 7, 4, 8, 9, 3],
    neutral: [4, 8, 9],
    avoid: [6],
    shortDiscription: ``,
    planets: [`Jupiter`],
  },
  4: {
    best: [7, 4, 1, 3, 5, 4, 8],
    neutral: [], //none
    avoid: [9, 2, 4, 8],
    shortDiscription: ``,
    planets: [`Rahu`, `Uranus`],
  },
  5: {
    best: [1, 2, 3, 6, 7, 8, 9, 4, 5],
    neutral: [8, 9],
    avoid: [], //none
    shortDiscription: `Airport`,
    planets: [`Mercury`],
  },
  6: {
    best: [1, 5, 7, 4, 8, 6, 2],
    neutral: [2, 8],
    avoid: [3, 9],
    shortDiscription: `Air plane`,
    planets: [`Venus`],
  },
  7: {
    best: [4, 6, 3, 1, 5, 9, 8, 2, 7],
    neutral: [7, 8, 9],
    avoid: [], //none
    shortDiscription: `Over trust, Pure`,
    planets: [`Ketu`, `Neptune`],
  },
  8: {
    best: [5, 6, 3, 7, 4, 8],
    neutral: [], //none
    avoid: [1, 2, 9, 4, 8],
    shortDiscription: ``,
    planets: [`Shani`],
  },
  9: {
    best: [1, 5, 3, 7],
    neutral: [6, 9],
    avoid: [6, 4, 2, 8, 9],
    shortDiscription: ``,
    planets: [`Mars`],
  },
});
