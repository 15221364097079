import { useState } from "react";
import { Input, Label } from "semantic-ui-react";
import DataGrid from "react-data-grid";

import "react-data-grid/lib/styles.css";

import { transliterateToDevanagari } from "./../../engine/name";

const akshar = require("./../../../data/name/akshar");

const aksharRefColomns = [
  {
    key: "planet",
    name: "Planet",
  },
  {
    key: "planetLetters",
    name: "Planet Letters",
  },
  {
    key: "eventYears",
    name: "Event Years",
  },
];

const createAksharRefRows = () => {
  const rows = [];

  Object.keys(akshar).map((ak) => {
    rows.push({
      planet: akshar[ak].planetName,
      planetLetters: akshar[ak].planetLetters.join(", "),
      eventYears: akshar[ak].eventYears,
    });
    return null;
  });

  return rows;
};

const aksharColumns = [
  {
    key: "letter",
    name: "Letter",
  },
  {
    key: "lord",
    name: "Lord",
  },
  {
    key: "repeats",
    name: "Repeats",
  },
  {
    key: "rahuCount",
    name: "Rahu",
  },
];

const createAksharRows = (aksharMap) => {
  const rows = [];
  Object.keys(aksharMap).map((akshar) => {
    rows.push({
      letter: akshar,
      lord: aksharMap[akshar].lord,
      repeats: aksharMap[akshar].count,
      rahuCount: aksharMap[akshar].rahuCount ? "True" : "-",
    });
    return null;
  });
  return rows;
};

const lordColumns = [
  {
    key: "lord",
    name: "Lord",
  },
  {
    key: "letters",
    name: "Letters",
  },
];

const createLordRows = (lordMap) => {
  const rows = [];
  Object.keys(lordMap).map((lord) => {
    rows.push({
      lord: lord,
      letters: lordMap[lord].akshar.join(","),
    });
    return null;
  });
  return rows;
};

const EnglishToDevanagari = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [aksharMap, setAksharMap] = useState([]);
  const [lordMap, setLordMap] = useState([]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    const [translation, aksharMap, lordMap] = transliterateToDevanagari(
      event.target.value
    );
    setOutputText(translation);
    setAksharMap(createAksharRows(aksharMap));
    setLordMap(createLordRows(lordMap));
  };

  const [aksharRefRows] = useState(createAksharRefRows);

  return (
    <div>
      <Input type="text" value={inputText} onChange={handleInputChange}></Input>
      <Label htmlFor="outputText">{outputText}</Label>
      {aksharMap.length > 0 && (
        <DataGrid
          columns={aksharColumns}
          rows={aksharMap}
          style={{
            height: "min-content",
          }}
        />
      )}
      {lordMap.length > 0 && (
        <DataGrid
          columns={lordColumns}
          rows={lordMap}
          style={{
            height: "min-content",
          }}
        />
      )}
      <DataGrid
        columns={aksharRefColomns}
        rows={aksharRefRows}
        style={{
          height: "min-content",
        }}
      />
    </div>
  );
};

export default EnglishToDevanagari;
