module.exports = Object.freeze({
  englishName: 'aries',
  vedicName: 'Mesh (मेष)',
  symbol: 'Ram (मेंढा)',
  timeFrame: {
    bornIn: {
      a: 'Aries as Ascendant',
      b: 'Nakshatras Aswini or Barani of the first quarter of Kirtika',
      c: 'between 14th april and 13th may'
    }
  },
  shortDescription: `
    Aries - Mesh is the first or head sign of the Zodiac.
    fiery and hot by nature,
    termed bestial (savage, brutal, barbaric, cruel) and sterile (वांझ्),
    movable or a cardinal (मुख्य) sign,
    said to be masculine or positive and is of short ascension (वाढ),
    sign denoting the beginning of the Right Ascension as it represents the gate of Gold on the east,
  `,
  bodyParts: ['head', 'face'],
  rulerPlanet: 'mars',
  debilatedPlanets: ['saturn'],
  exaultedPlanets: ['venus'],
  physicalAppearance: [
    'middle stature (माध्यम वर्ण)',
    'lean and muscular body',
    'neither stout (मोटा) not think',
    'complexion will be ruddy (कडक / लाल)',
    'fairly long neck and face.',
    'head will be broad at the temples and narrow at the chin',
    'bushy eyebrows, eyes grey to greyish brown with sharp sight',
    'wiry hair, color varying from dark to sandy',
    'go bald in adult age at the temples',
    'mole or scar on the head when posited malifics'
  ],
  mentalTendencies: [
    'ruler mars, will be ever active and ambitious',
    'bold and impulsive',
    'afflicted ascendant: rash and aggressive',
    'beneficial aspects: confidence and courage',
    'always aim high and ever enterprising'
  ],
  characteristics: [
    'first sign: desire to be at the head of all affairs',
    'do not relish (आवडत) suggestions from others; act only according to their own judgement',
    'do not like subordination but wish to be the leaders in thought and action',
    'receives beneficial aspects: best fitted to guide, control and govern others',
    'positive sign: it gives determination and force of character',
    'act quickly with unbounded self-confidence',
    'have much of executive ability and uncompromising spirit',
    'Being born in a movable sign, they will not hesitate to change whatever they dislike and whenever they want',
    'cannot wait patiently for opportunities to come but will go out and create them to fulfil their desire',
    'firey sign: mind will be pregnant with ideas and their desire will be to execute them at once',
    'best fitted to deal with any situation or emergency',
    'never sacrifice their personal, desires for others',
    ''
  ],
  bhrigu: {
    marsIsFocus: {},
    ariesHouseDisbalanced: {},
    ariesMarsAggressiveInThatHouse: {},
    bodyNetwork: `Mars - मज्जा तन्तु`,
    fever: {
      stories: [
        `sun`,
        `mars`,
        `rahu when sitted with warn/hot planet`,
      ],
      qualities: [
        `sun - wheat (गेहु)`,
        `mars - jaggary (गुड)`,
        `rahu - Sorghum (जवार)`,
        `1kg - donate by the kid with fever mars timing 4-5pm - 3days as mars 3rd hous(parakram) so 3 days`,
      ],
    },
    ariesHouseResponsibilityOfThatHouse: {
      stories: [
        `Army commander 'I will show you how to do it' approach`,
      ],
      qualities: [
        `aries or mars take's Responsibilities that house`,
        `than whines `,
      ],
    },
    logic: {
      stories: [
        `Narasimha avatar`
      ],
      qualities: [
        `solves technical difficulties by logic`,
        `out perform in pressure conditions`,
      ],
    },
    suddenOutburst: {
      stories: [
        `Narasimha avatar`,
        `slower than Mercury and Venus`,
      ],
      qualities: [
        `sudden burst of energy than calm`,
      ],
    },
    veryHot: {
      stories: [
        `Kartikeya avatar born using sun's sperm by swahaa`,
        `Kartikeya was so hot kills tarakasur demon on 7th day of his birth`,
      ],
      qualities: [
        `Mars earth's son no digging on Tuesday`,
      ],
    },
    readyToFight: {
      stories: [
        `Red people - Panjabis, red neck (TX)`,
      ],
      qualities: [
        `ready to fight`,
      ],
    },
    uniqueOrFirst: {
      stories: [
        `Indira Gandhi - first lady PM`,
      ],
      qualities: [
        `something new / different / unique`,
        `unique color of vehicle`,
        `unique vehicle / house`,
        `9th House - new religion / vidya(study)`,
      ],
    },
    armyCommander: {
      stories: [
        `Army commander stories dont care about health/family life`,
      ],
      qualities: [
        `Focus on job/task to finish`,
        `10th Aries - career oriented`,
        `गधा मजदुरी in that house`,
        `11th Aries only focus on my fulfillment of desire - बनीया chart`,
      ],
    },
    color: 'red',
    stories: {
      'Narasimha avatar': {
        description: `Solving technical difficulties by using logic to kill Hiranyakashipu for saving Pralhad`,
        reference: `00:12:40`,
      },
      'Kartikeya avatar': {
        description: `Birth story, after birth 7th day kill Tarakasur using his heatness nature`,
        reference: `00:15:37`,
      }
    },
    qualities: {
      'Mars: logic': {
        description: `Mars use logic to solve technical difficulties`,
        reference: `00:13:55`,
      },
      'Mars: quick burst of energy': {
        description: `Mars speed average slower than Mercury & Venus but not constant speed, quick action, quick burst`,
        reference: `00:15:00`,
      },
      'Mars: No digging on Tuesday': {
        description: `No digging on tuesday as Earth is considered Mars mother and Tuesday is Mars day`,
        reference: `00:18:19`,
      },
      'Mars|Aries: Red Color & Quick Action': {
        description: `Red - Anger, Red - Very high energy (more than expected), More than expected Energy - Quick Action`,
        reference: `00:19:07`,
      },
      'Aries: Things never constant': {
        description: `The house Aries is posited things are never constant always something changing`,
        reference: `00:19:07`,
      },
      'Red People Rough (Mars)': {
        description: `Panjabis, Hariyanvis, Himachalis (Indian), Red Necks (US) and Afgans (आक्रमक)`,
        reference: `00:20:58`,
      },
      'Mars: First Number Aries - Cruel than Saturn': {
        description: `Mars Cruel (नेर्दयी ग्रह) planet than Saturn - शनि एक बार बक्श देता है, पुजा पाठ करे तो`,
        reference: `00:25:35`,
      },
      'Mars: Army commander - Needs to get the job done': {
        description: `Needs to get the job done`,
        reference: `00:27:00`,
      },
      'Aries Asc - 5th Leo (Sun) - Good Digestion': {
        description: `Sun (Fire - अग्नी) Good Digestion`,
        reference: `00:27:18`,
      },
      'Aries - Quality': {
        description: `Thin, Not fat at all, always looks young, to get the job done, more than usual energy, Riding on Red Horse, Red Eyes, Sword in Hand, Ready to fight, friendship won't last long (Army commander)`,
        reference: `00:27:30`,
      },
      'Mars: Army leader - aggressive when slight flactuation': {
        description: `Any slight change in current position gets aggressive and look for change or defends postion`,
        reference: `00:34:19`,
      },
      'Mars: Ego, Focus': {
        description: `Mars is Ego also has Red Eyes (Focus)`,
        reference: `00:39:00`,
      },
      'Mars-Aries: Self Energy - Highly energetic': {
        description: `Self Energy - Highly energetic`,
        reference: `00:41:20`,
      },
      'Mars: Bile - Pitta (Firy) karak': {
        description: `Pitta - Bile (Firy) (पित्त कारक)`,
        reference: `00:41:59`,
      },
      'Mars: Saturn - Conj, aspected - Devine help': {
        description: `Mars is young man with no experience (No father - raised by mother) meets Old man with knowledge and wisdom, if good aspect good energy to move forward, Mars + Saturn or Saturn in Aries or Scorpio, Person won't sit stable (आराम से नही बैठता)`,
        reference: `01:04:26`,
      },
      'Mars: Saturn - Conj, aspected - Rat race (लडने झगडने वाले), Riots in office, union leader': {
        description: `Always eger to move forward, do something new, Rat race, Mars won't let self stop and Saturn gives wisdom and knowledge, Riots in office, Leader, Union leader`,
        reference: `01:05:00`,
      },
      'Mars - Blood (RBCs)': {
        reference: `1:29:15`,
        description: `
          -  less RBCs Mars Deficiency`
      },
      'Mars-Ketu or in trin (5th)': {
        reference: `1:29:27`,
        description: `
          -  Ketu makes Mars half
          -  Blood deficiency (RBC count)`
      },
      'Mars-Electric city': {
        reference: `1:42:10`,
        description: ``
      },
      'Mars + Raha -Theft of electricity in home': {
        reference: `1:42:22`,
        description: `
          -  Depend on where deposited that relative`
      },
      'Bad Mars - Power/electricity line start getting damaged': {
        reference: `1:42:33`,
        description: ``
      },
    },
    techniques: {
      'Energitic and sudden Burst': {
        description: `Aries: Things are not constant`,
        reference: `00:22:12`,
      },
      'Aries: 1st sign - dasha - dasha of planet in Aries - Paribhraman - Sign Active, First time thing in family for that house': {
        description: `That year something new will start on life - New beginning - Desire to do new in that House where Aries is placed`,
        reference: `00:22:56`,
      },
      'Aries: Disbalances chart in that house': {
        description: `Aries disbalances the chart in respective house cause you spends a lot of energy in Aries positioned house to maintain the house`,
        reference: `00:30:26`,
      },
      'Aries: Automatic responsibilities of that house': {
        description: `Aries Automatically takes responsibilities of the house its posited in.`,
        reference: `00:37:00`,
      },
      'Aries: Fast results': {
        description: `Any planet bound to give fast results`,
        reference: `00:44:20`,
      },
      'Aries: Hot': {
        description: `Aries is hot sign / rashi`,
        reference: `00:44:35`,
      },
      'Aries-Mars: Full energy applies to that House': {
        description: `Full body's energy, You apply full energy in the house where Mars or Aries is deposited`,
        reference: `01:01:52`,
      },
      'Aries: 1st House - Burst (descipate energy) start but no planning, objective or known end result': {
        description: `Starts work with quick action(with burst), No planning, No objective, no known end result, possibally leave few things in middle (completes nothing), but have to do it to descipate energy`,
        reference: `01:03:10`,
      },
      "Aries: Planning won't work for that house or quick opportunities comes and go": {
        description: `Planning won't work for that house because you won't plan at all, Burst start work without planning or opportunities come and go very last moment dont't gives time to balance (संभलनेका मौका ही नही मिलता)`,
        reference: `01:03:46`,
      },
      'Malefic (kills living) - Benifics (kills non-living)': {
        description: `Malefic planets Kills (मार देंगे) living things and increases (बढा देंगे) non-living things, Benific planets kills non-living things and increases living things`,
        reference: `01:11:20`,
      },
      'Pisces Asc - Aries 2nd': {
        reference: `1:53:32`,
        description: `Mars in 2nd House or aspects on 2nd
          -  person act/have to assume elderly responsabilities (Bada Bhai/Bahan banta hai)
          -  Habit of becoming a leader`
      },
      'Mars-Assumes responsibilities': {
        reference: `1:55:51`,
        description: `Whatever house you will assumes responsibility of that house
        -  1st house-Own responsibility plus leadership qualities everywhere (Mars powerhouse of the chart)
        -  4th house-mother's responsibility
        -  5th house-kid's responsibility
        -  7th house-Mangalik-assumes or try to take responsibilities in relationship and under estimate other person (army commander in relationship won't work-needs to have both parties)`
      },
      'Mars deities difference in Energy': {
        reference: `2:04:53`,
        description: `
          -  Hanuman Ji
          -  He helped everyone but no one comes to help him
                T - Mangal (Mars) in the chart
          -  or dosha person don't helped by anyone his/her whole life, He is on his own all the time`
      },
      'Mars-Places:': {
        reference: `01:16:07`,
        description: `Places with fire
          - Restaurants
          - Crimaton Ground alternate)
          - Brick factory
          - BBQ Places
          - Heat generating places
              4th Mars & Saturn
          - (industry) factory
              12th Mars
          - Birth Place Home -Has Hospital, dispensary, medical store, will have medicine knowledge (Lal-Kitab)
              11th Mars
          - Functions by erecting tents - celebration places`,
      },
      'Mars - Bitter (कडु) Medicines': {
          reference: `01:18:00`,
          description: `- Army Commander have to complete Job make you better so don't care if you don't like the medicines (kadavi)
          - Neem plant
          - Lal Kitab - Bad Mars 4 Silver coin neem ke ped me gandh lagaiye`
      },
      'Bad Mars: Not getting energy, No motivation, No focus': {
        reference: `1:19:45`,
        description: `
          - Plant Neem tree (big remedy)`
      },
      'House represented by Mars': {
        reference: `1:20:35`,
        description: `
          - 4th House Relation : owner, conj, aspected, etc.
          - Always old house
          - Need to do some work in that house (don't easily backdown)`
      },
      'Mars-sign Bhed-Meda - But needs direction': {
        reference: `1:31:00`,
        description: `कित्नी भी बडी पहाडी भेड चढ जायेगी - Think of achieving something
          (Aries) - Energy works in that house - Have to GIVE GOAL by someone
          -  people achieves it (Energy Burst-living everyone behind)
          -  Leo Asc- Trump-9th aries
          -  Cancer Asc-10th Aries-carrier
                          achievement
          -  Gemini Asc - 11th Aries - achieving money, achieves it`
      },
    },
    combinations: {
      'Cancer Asc 10th Aries - Profession': {
        description: `Profession won't be always same, Sudden ups= than start to slowing down`,
        reference: `00:20:03`,
      },
      'Pisces Asc 2nd Aries - Earnings': {
        description: `Earning capacity won't be linear, sudden earning than stop than sudden earning than stop, Family life won't be same happy everytime`,
        reference: `00:20:20`,
      },
      'Cancer Asc 10th Aries - First lady PM': {
        description: `Indira Gandhi - First Lady Prime minister of India`,
        reference: `00:22:05`,
      },
      'Cancer Asc 10th Aries - first in Prof.': {
        description: `First in Father's family to start something new in Profession`,
        reference: `00:24:12`,
      },
      '5th House Aries - Love Marriage first in family': {
        description: `First in family for love marriage or something new no one has done in the family in love marriage before`,
        reference: `00:24:17`,
      },
      '9th House Aries - New: Religion, Knowledge (विद्या) learnt': {
        description: `New: Religion, Knowledge (विद्या) learnt`,
        reference: `00:22:23`,
      },
      'Cancer Asc 10th Aries - Too much career focus': {
        description: `Too much focus on career - Mars गधा मजदुरी करायेगा काम के लिये, Can't come out of career, health, family doesn't matter`,
        reference: `00:28:34`,
      },
      'Leo Asc 9th Aries - First comes - Religion, new teaching': {
        description: `Religion comes first, teach me new things first, Hunger for learning something new`,
        reference: `00:28:40`,
      },
      'Gemini Asc 11th Aries - Money First': {
        description: `Money first, बनियेकी कुन्डली, desires first, profit first`,
        reference: `00:28:40`,
      },
      'Scorpio(8) Asc 6th Aries - works a lot': {
        description: `6th रूग, रिण, शत्रु, working capacity, debt taking capacity: Works a lot, lots of different assignment at work, always front runner at work, taking challenging roles at work`,
        reference: `00:30:26`,
      },
      'Gemini Asc 11th Aries - Very agressive Money': {
        description: `will be very agressive about money`,
        reference: `00:34:19`,
      },
      'Scorpio Asc 6th Aries - Terrorist type - ready to fight': {
        description: `Slightly provked to mars ready to fight (6th house -  fighting)`,
        reference: `00:34:19`,
      },
      'Cancer Asc 10th Aries - Work load (I will show you how its done)': {
        description: `Work load (I will show you how its done)(सिना चौडा करके खुद - ये काम मै कर के दिखाता हू)`,
        reference: `00:37:05`,
      },
      'Gemini Asc 11th Aries - Show you how to make money': {
        description: `will Show you how to make money`,
        reference: `00:37:10`,
      },
      'Scorpio Asc 6th Aries - Challenges': {
        description: `I can do it its my speciality`,
        reference: `00:37:30`,
      },
      'Leo Asc 9th Aries - New learning responsibilities': {
        description: `I will show you new religion, learning`,
        reference: `00:37:45`,
      },
      'Aries: Venus - Bad character - Quick action for new': {
        description: `Venus (relationship) + Aries (Fast Change), tries to change relationship faster (for something new). Stable Venus makes Mars energy stable, Combine dasha me give changes in stable relationship`,
        reference: `00:44:48`,
      },
      'Aries: Mercury - Comedians - Political Satire (व्यङ्ग)': {
        description: `Gives Comedians (Humor) (भिगोके जुते मारने की कला), Political Satire (व्यङ्ग)`,
        reference: `00:46:30`,
      },
      'Mars: Mercury - Diplomatic': {
        description: `Mercury (Communication) + Aries (Logic)`,
        reference: `00:46:37`,
      },
      'Aries: Jupiter - Quick brains - Mug up': {
        description: `Jupiter (Knowledge) (ड्य्न करक) gets pushed in Aries, गुरु की बात नही मानी तो सीधे श्राप, Quick brain capacities - mugups fast, wants to do PhD in a day`,
        reference: `00:47:44`,
      },
      'Aries: Sun - Exaulted - King on its throne': {
        description: `Sun become exaulted in Aries as Sun (king) is sitting on its throne`,
        reference: `00:50:00`,
      },
      'Aries: Rahu - Super quick energy': {
        description: `Head (Aries) bigger (Rahu) than usual (00:53:23)`,
        reference: `00:50:35`,
      },
      'Aries: Rahu - Bigger head': {
        description: `Head (Aries) bigger (Rahu) than usual`,
        reference: `00:50:45`,
      },
      'Aries: Rahu - two commanders together Accident yog': {
        description: `Rahu (Amplifying mirror) (Mirror based remedies) `,
        reference: `00:50:50`,
      },
      'Aries: Rahu + 3rd House - Hand Shakes or Hands has special skills': {
        description: `Rahu (Amplifying mirror) (Mirror based remedies) `,
        reference: `00:50:50`,
      },
      'Mars: Rahu - Tumor, Cancer': {
        description: `Extra ordinary energy generation +ve or -ve`,
        reference: `00:52:05`,
      },
      'Aries: Saturn - skillfull perfection (Painter, Musician)': {
        description: `Saturn (Old man) (lower level worker) (signifies Knowledge), slow mover but skillfull perfection (Painter, Musician)`,
        reference: `00:52:57`,
      },
      'Aries: 5th Saturn(R)(D) - Quick decision - Big shot stock market': {
        description: `5th house (speculation) + Saturn (Doer - करम) - Big shot in stock market - Quick decision maker`,
        reference: `00:55:49`,
      },
      "Aries: 9th Saturn(R)(D) - Can't do regular 9-5 jobs": {
        description: `Risk taking capabilities needed or bored of normal job, Want to start something new can't do regular peace 9-5 job`,
        reference: `00:56:58`,
      },
      'Aries: Good Saturn - Good Judge': {
        description: `Judge (good) - Hears out everything than takes time to judge`,
        reference: `00:57:37`,
      },
      'Aries: Moon - Non-stable thoughts': {
        description: `Moon (Mind - मन) like water governs thoughts + Aries (high energy) - mind like water takes high energy of Aries, quick changing mind (thoughts) - 10s of thoughts at one time`,
        reference: `01:00:14`,
      },
      'Mars: Rahi + 8th House (Inovation house) - inovation - mother cancer': {
        description: `Living: Mother stomach cancer (5th of 4th), Non-Living: Super inovative`,
        reference: `01:08:50`,
      },
      'Malefics - Benifics': {
        description: `Malefics in 2nd increases money and ruins eyes (आखे खाराब करेंगा), 10th house Jupiter (benific) - very big father's family but father's finance will be finished`,
        reference: `01:11:20`,
      },
      '4th House - modify according to self- may demolish & make as per own wish': {
        reference: `1:21:44`,
        description: ``
      },
      '4th House Aries + Saturn': {
        reference: `1:24:48`,
        description: `
          -  Quick purchase of House (quick decision)`
      },
      '4th House Mars or association (Construction)': {
        reference: `1:25:02`,
        description: `
          -  Property purchase in hurry and with some issue (Kharabi)
          -  Some vastu dosha which won't be solved`
      },
      '4th Mars or connected (Vehicles)': {
        reference: `1:25:52`,
        description: `
          -  Dent
          -  5th House Aries or Mars-
          -  Kid cries to avoid going school (don't like bowing down to learn) (pit ke school leke jana padata hai)`
      },
      '12th Mars': {
        reference: `1:28:44`,
        description: `
          -  Donate or calm - Mars-Cold Sweetwater to friends`
      },
      'Aries Asc- Aries 1st': {
        reference: `1:33:41`,
        description: `Highly energetic
          -  Try to do many thing at a time
          -  may not get end result for any/most-no planning
          -  when get up want to do something
          -  signup for all courses with all things needed`
      },
      'Taurus Ase - Aries 12th': {
        reference: `1:34:40`,
        description: `
          -  Very beautiful
          -  Spend more than usual on face
          -  1st Hs is personality and 12th Hs is expenses
          -  All bedroom related stuff happen sudden`
      },
      'Gemini Asc - Aries 11th': {
        reference: `1:37:13`,
        description: `
          -  Baniye ka sign
          -  Very aggressive in terms of making money
          -  Pet (stomach) ke upper ki soch nahi sakhta
          -  works super-sensitively
          -  sudden results related to 11th house matter`
      },
      'Cancer Asc - Aries 10th': {
        reference: `1:39:36`,
        description: `
          -  work more than needed for profession
          -  take more than needed responsibilities regarding profession
          -  When something change in prof. same time wife's home/mother (4th from 7th) some things happen (10-15 day during event timing)
          -  Father's wealth/family (2nd from 9th)`
      },
      'Leo Asc -Aries 9th': {
        reference: `1:44:53`,
        description: `
          -  Quick (Jaldi) to learn something new
          -  Quick to make and leave Guru
          -  Wife's communication house (3rd to 7th) wife's phone (may break & fight with Guru)
          -  Wife's sibling + Guru events both simultenously`
      },
      'Virgo Asc -Aries 8th': {
        reference: `1:46:19`,
        description: `
          -  Servant's (Sevak) sign (natural 6th House)
          -  Service sign
          -  8th house inheritance- try harder than needed to aquire inheritance (sabar nahi hai)
          -  Most court cases related to inheritance`
      },
      'Libra Asc - Aries 7th': {
        reference: `1:47:45`,
        description: `
          -  Think before starting
          -  Relationships
          -  Business partnership`
      },
      'Scorpio Asc - Aries 6th': {
        reference: `1:48:29`,
        description: `
          -  Scorpio sign
          -  destructive
          -  Big transformation sign
          -  6th House Aries (like suicide Bomber)
          -  challenges (Ladna- zhagadana)
          -  brings something that no one in your circle can do
          -  Be careful while taking up challenges
          -  don't loose your ground reality
          -  Take more than need task & they destroys themself
          -  Service house (be very careful before taking up challenge)
          -  don't think before commiting for service related matters`
      },
      'Sagittarius Asc - Aries 5th': {
        reference: `1:49:58`,
        description: `
          -  5th House
          -  love, Romance, Education
          -  think before starting new relationships, starts in a sec and ends in 10 mins like tinder (Jyada din relationship chalata nahi hai)
          -  Very quick in learning something
          -  Won' t wait to learning something new`
      },
      'Capricorn Asc -Aries 4th': {
        reference: `1:51:00`,
        description: `
          -  4th house
          -  property, car, mother, peace
          -  Capricorn - workaholic sign (high energy)
          -  Relaxing & enjoying vacation is punishment
          -  Brings home work (aaram Karne Ki adat nahi)`
      },
      'Aquarius Asc - Aries 3rd': {
        reference: `1:52:13`,
        description: `
          -  3rd house
          -  Communication, meeting new people, initiatives
          -  won' t think before
          -  sending new friend's request
          -  calling someone
          -  talking to some
          -  communication to someone
          -  not afraid of initiatives
          -  lots of friends on Facebook
          -  don't get bothered intruding privacy(Karna hai to Karna hai)`
      },
      'Mars-Saturn opposition - external conflicts': {
        reference: `2:01:45`,
        description: `
          -  Kranti Kari-freedom fighter
          -  Saturn - Voice of garib, low people etc`
      },
      'Mars-Moon somewhere affected': {
        reference: `2:03:57`,
        description: `
          -  High or low Blood pressure`
      },
      'Mars (logic) - Aries (Quick action) different or not': {
        reference: `2:07:32`,
        description: `
          -  Mars + Logic - one sec decision
          -  Tennis-Yes
          -  Chase-No
          -  Mars + Aries + other planets support
          -  Better than usual results`
      },
      'Mars + 8th house + Taurus Ase': {
        reference: `2:10:41`,
        description: `
          -  Mars - takes responsibility
          -  8th house- inheritance, wife's family
          -  wife's family's responsibilities
          -  taking care of inheritane responsibility`
      },
      'Sun (ego) & Mars (Power)': {
        reference: `2:12:31`,
        description: `
          -  Mars: Army Commander`
      },
      'Mars 10th House for Taurus Asc': {
        reference: `2:15:22`,
        description: `
          -  10th house-Aqu-Saturn's House
          -  Mars have Saturn's energy in profession
          -  works on making profession better
          -  delays in profession`
      },
    },
    crossReferences: {
      'Saturn + Venus - Ruins Grace': {
        description: `Saturn + Venus - Ruins (Saturn) Grace (Venus), grace finished`,
        reference: `00:26:19`,
      },
      '1st Rahu - Helps father whole life': {
        description: `God Rahu helped father to defets Gods`,
        reference: `00:52:52`,
      },
      'Cruel & benefici planets': {
        description: `Cruel: Mars, Saturn, Rahu, Ketu and Sun, Benific: Jupiter, Venus, Mercury (like kid - conditional conj, nor male or female), Moon (except when within +/- 3 house)`,
        reference: `01:10:15`,
      },
      'Mars + Saturn - direction': {
        reference: `1:33:19`,
        description: `
          -  Mars (Power but no direction)
          -  Satun (steady direction)`
      },
    },
    remedies: {
      'Fever in kids - specially': {
        description: `Mars, Sun and Rahu are mainly responsible for fever, Donate (दान) jaggary (गुड) , Wheat (गेहू), Sorghum (ज्ॉआर) (ज्ॉअरी) 1 Kg each Between 4pm to 5pm (lal kitab Mars time) 3 consecutive days (Mars karaka for 3rd House - Parakram (पराक्रम) House)`,
        reference: `00:42:25`,
      },
      'Lal-Kitab -Tuesday': {
        reference: `1:26:58`,
        description: `
          -  Don't buy new cloths - they burn (end result)
          -  Mars signifies burnt cloths`
      },
      'Mars represent Summer - Cold sweet water donation': {
        reference: `1:27:40`,
        description: `
              (Remedy) (chabil/Bade mangal)
          -  Sun in
          -  Aries - 15 April to 15 may
          -  Taurus - 15 May to 15 Jun -Taur.us`
      },
      'Cold sweet water to friends - for money in-flow': {
        reference: `1:28:36`,
        description: ``
      },
    },
    books: {
      'H. M. Katwe': {
        description: `Charts of freedom fighters, Mars Saturn Combination, दबते नही है किसीसे, अख्ख दीमाग`,
        reference: `01:07:19`,
      }
    }
  },
})
