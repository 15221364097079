import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import AriesMars from "./Bhrigu/AriesMars";
import BCP from "./Bhrigu/BCP";

import './table.css';

const panes = [
  {
    menuItem: "AriesMars",
    render: () => (
      <Tab.Pane>
        <AriesMars />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "BCP",
    render: () => (
      <Tab.Pane>
        <BCP />
      </Tab.Pane>
    ),
  },
];
const Bhrigu = () => {
  useEffect(() => {
    document.title = "Bhrigu";
  }, []);

  return (
    <div>
      <h2>Bhrigu Notes</h2>
      <Tab panes={panes} />
    </div>
  );
};

export default Bhrigu;
