import React from 'react';

import ToggleSVG from './SVGs/ToggleSVG';
import useDarkMode from 'use-dark-mode';

const DarkModeToggle = ({themeSelected}) => {
  const darkMode = useDarkMode(false);

  return (
    <div className='dark-mode-toggle'>
      <ToggleSVG
        checked={darkMode.value}
        onChange={darkMode.toggle}
        themeSelected={themeSelected}
      />
    </div>
  )
};

export default DarkModeToggle;
