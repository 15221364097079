import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import useDarkMode from "use-dark-mode";

import ToggleContent from "./ToggleContent";
import { placeNakshatras } from "./helper";
import { NakshatraContext } from "../../contexts/nakshatra.context";

import houseCoordinates from "../../engine/houseCoordinates";

const nakshatra = require("../../../data/nakshatraPai");
const rashis = require("../../../data/rashis");

const ChartNorthSvgPolyline = styled.polygon`
  color: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  stroke: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  fill: ${({ darkModeValue }) => (darkModeValue ? "#1E1F1A" : "#fff")};
  fill-opacity: 1;
  stroke-width: 1;
  pointer-events: auto;
`;

const GharTextStyle = styled.text`
  fill: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  font-size: 0.8rem;
`;


const mappedRashiNNakshatra = () => {
  return rashis.map((rashi) => {
    const rashiShortName = rashi.name.slice(0, 3);
    return nakshatra.filter((nak) => {
      if (nak.rasi === rashiShortName) {
        return nak["nk-pada"];
      }
      return null;
    });
  });
};

const createContextFromEvent = (e) => {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,

      height: 0,
      width: 0,
    }),
  };
};

const DrawHouse = ({
  house,
  height,
  width,
  renderIndex,
  setCurrentSelectedHouse,
  setCurrentRef,
  setIndexToSet,
  setOpen,
}) => {
  const { value: darkModeValue } = useDarkMode();
  const { houseIndex, houseName, key, nakshatraCoordinates } = house;

  const twelveHouses = houseCoordinates(
    width,
    height,
    (renderIndex + 1).toString()
  );
  const houseNameTextCoordinates =
    twelveHouses[`${houseName}NameTextCoordinates`];

  const [isShown, setIsShown] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState(-1);

  const { state } = useContext(NakshatraContext);
  const {
    slider: {
      houses,
      flags: { showNakshatraPadas } = {},
    },
  } = state || {};

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }

    if (e) {
      setSelectionIndex(parseInt(e.target.innerHTML));
    }
  };
  const nakshatraArray = mappedRashiNNakshatra();
  let selectedPlanets = houses[renderIndex + 1]?.planets;

  const handleContextMenu = useCallback(
    (e) => {
      setOpen(false);
      e.preventDefault();
      setCurrentRef(createContextFromEvent(e));
      setIndexToSet(renderIndex);
      setOpen(true);
    },
    [setCurrentRef, setOpen, setIndexToSet, renderIndex]
  );

  const handleClick = (e) => {
    setOpen(false);
    setCurrentSelectedHouse(renderIndex);
  };

  return (
    <React.Fragment key={renderIndex}>
      <ChartNorthSvgPolyline
        key={houseName}
        darkModeValue={darkModeValue}
        points={twelveHouses[houseName]}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      />
      <GharTextStyle key={`${renderIndex}-'${key}'`} darkModeValue={darkModeValue}>
        {placeNakshatras(
          nakshatraArray[renderIndex],
          {
            x: nakshatraCoordinates.x,
            y: nakshatraCoordinates.y,
          },
          key,
          showNakshatraPadas,
          selectedPlanets
        )}
        <ToggleContent
          selectionIndex={selectionIndex}
          ghaarIndex={renderIndex}
          isShown={isShown}
          setIsShown={setIsShown}
          toggler={toggler}
          houseIndex={houseIndex}
          selectedPlanets={selectedPlanets}
          triggerElement={
            <tspan
              x={`${houseNameTextCoordinates.x}`}
              y={`${houseNameTextCoordinates.y}`}
              onClick={toggler}
            >
              {renderIndex + 1}
            </tspan>
          }
        />
      </GharTextStyle>
    </React.Fragment>
  );
};

export default DrawHouse;
