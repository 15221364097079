import React, { useEffect, useState } from "react";
import { Button, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";

const getYear = (date) => {
  return date.getFullYear();
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonthName = (monthNumber) => {
  return months[monthNumber - 1];
};

const range = (start, end, step = 1) => {
  let array = [];

  if (!step) {
    step = 1;
  }

  if (step > 0) {
    for (let i = start; i <= end; i += step) {
      array.push(i);
    }
  } else {
    for (let i = start; i >= end; i += step) {
      array.push(i);
    }
  }
  return array;
};

const formatDate = (date) =>
  `${getMonthName(date.getMonth() + 1)} ${getYear(date)}`;

const YearMonth = ({
  label = "Month & Year",
  onMonthChange,
  onYearChange,
  yearRange = {
    start: 1900,
    end: getYear(new Date()) + 10
  },
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);

  const years = range(yearRange.start, yearRange.end, 1);

  useEffect(() => {
    onMonthChange(startDate.getMonth() + 1);
    onYearChange(startDate.getFullYear());
  }, [onMonthChange, onYearChange, startDate]);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  return (
    <>
      <Label>{label}</Label>
      <Button onClick={handleClick}>{formatDate(startDate)}</Button>
      {isOpen && (
        <DatePicker
          inline
          selected={startDate}
          dateFormat="MMM yyyy"
          showMonthYearPicker
          onYearChange={(date) => {
            onYearChange(date.getFullYear());
            onMonthChange(date.getMonth() + 1);
          }}
          onChange={handleChange}
          renderCustomHeader={({
            date,
            changeYear,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => changeYear(getYear(date) - 1)}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={() => changeYear(getYear(date) + 1)}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
        />
      )}
    </>
  );
};

export default YearMonth;
