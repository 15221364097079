import React, {
  useMemo,
  useState,
} from 'react';
import DataGrid from 'react-data-grid';

import 'react-data-grid/lib/styles.css';

const nakshatraPai = require('../../../data/nakshatraPai')

const createRows = () => {
  const rows = [];

  nakshatraPai.forEach((pai, i) => {
    rows.push({
      'id': pai['id'],
      'rasi': pai['rasi'],
      'degrees': pai['degrees'],
      'nk-pada': pai['nk-pada'],
      'marathiName': pai['marathiName'],
      'deity': pai['deity'],
      'rasi-lord': pai['rasi-lord'],
      'nk-lord': pai['nk-lord'],
      'nav-lord': pai['nav-lord'],
    });
    return null;
  });

  return rows;
}

const BasicNakshatras = () => {
  const [rows] = useState(createRows);
  const columns = useMemo(() => {
    return [
    {
      key: 'id',
      name: 'ID',
    },
    {
      key: 'rasi',
      name: 'Rasi',
    },
    {
      key: 'degrees',
      name: 'Degrees',
    },
    {
      key: 'nk-pada',
      name: 'Nakshatra',
      renderHeaderCell: (props) => {
        return (
          <div>
            <span>{props.column.name}</span>
            <span> Pada</span>
          </div>
        )

      },
    },
    {
      key: 'marathiName',
      name: 'Vedic Name',
    },
    {
      key: 'deity',
      name: 'Nakshatra Deity',
    },
    {
      key: 'rasi-lord',
      name: 'Rasi Lord',
    },
    {
      key: 'nk-lord',
      name: 'Nakshatra Lord',
    },
    {
      key: 'nav-lord',
      name: 'Navmansh Lord',
    },
  ]}, []);

  return (
    <DataGrid columns={columns} rows={rows} />
  )
}

export default BasicNakshatras