import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Label,
  List,
  Table,
  TextArea,
} from 'semantic-ui-react';

import { NakshatraContext } from '../../contexts/nakshatra.context';

const { yoginis } = require('../../../data/yoginiDasha');

const grahas = require('../../../data/grahas');

const rashiMap = {
  Ar: 0,
  Ta: 1,
  Ge: 2,
  Cn: 3,
  Le: 4,
  Vi: 5,
  Li: 6,
  Sc: 7,
  Sg: 8,
  Cp: 9,
  Aq: 10,
  Pi: 11,
};

const acceptedPlanets = [
  'Mars',
  'Venus',
  'Mercury',
  'Moon',
  'Sun',
  'Jupiter',
  'Saturn',
  'Rahu',
];

const yoginiNames = [
  'Mars',
  'Venus',
  'Mercury',
  'Moon',
  'Sun',
  'Saturn',
  'Jupiter',
  'Rahu',
  'Ketu',
];

const JustHouses = ({ houseDetails }) =>
  Object.values(houseDetails).length ? (
    <>
      <Label size="small">Yogini Maha Dasha</Label>
      <List>
        {Object.values(houseDetails).map(({ name, yoginiDasha }, indx1) => (
          <List.Item key={`${name}-${indx1}`}>
            <List.Content>
              <List.Header>
                <Label size="tiny">{name} House</Label>
              </List.Header>
              <List.Description>
                <List.List>
                  {Object.values(yoginiDasha).map(
                    ({ factor, newLord }, indx2) => (
                      <List.Item key={`${name}-${indx2}`}>
                        <List.Header>
                          <Label size="tiny">{newLord} Dasha</Label>
                        </List.Header>
                        <List.Description>
                          <Label size="tiny">{factor}</Label>
                        </List.Description>
                      </List.Item>
                    )
                  )}
                </List.List>
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </>
  ) : (
    ''
  );

const GrahaNHouses = ({ basic, houseDetails }) => {
  const renderItem = (planet, factor) => {
    const { rashi: { degree, minutes } = {} } = planet;
    const planetDegree = parseFloat(`${degree}.${minutes}`);
    const dashaFactor = planetDegree * factor;

    let monthsFloat = dashaFactor * 12;

    let years = 0;
    if (monthsFloat > 12) {
      const yearFloat = monthsFloat / 12;
      years = Math.floor(yearFloat);
      monthsFloat = (yearFloat - years) * 30;
    }

    const months = Math.floor(monthsFloat);
    const daysFloat = (monthsFloat - months) * 30;
    const days = Math.floor(daysFloat);
    const hoursFloat = (daysFloat - days) * 24;
    const hours = Math.floor(hoursFloat);
    const minutesFloat = (hoursFloat - hours) * 60;
    const mins = Math.floor(minutesFloat);

    return (
      <Label size="tiny">{`${years}y, ${months}m, ${days}d, ${hours}h, ${mins}m`}</Label>
    );
  };

  return (
    <>
      <Label size="small">Yogini Maha Dasha</Label>
      {acceptedPlanets.map((planet, indx1) => (
        <Table definition key={`${planet}-${indx1}`}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Label>{planet}</Label>
              </Table.HeaderCell>
              {yoginiNames.map((yoginiName, indx2) => (
                <Table.HeaderCell key={`${yoginiName}-${indx2}`}>
                  <Label size="tiny">{yoginiName}</Label>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.values(houseDetails).map(({ name, yoginiDasha }, indx1) => (
              <Table.Row key={`${name}-${indx1}`}>
                <Table.Cell>
                  <Label size="tiny">{name} House</Label>
                </Table.Cell>
                {Object.values(yoginiDasha).map(
                  ({ factor, newLord }, indx2) => (
                    <Table.Cell key={`${name}-${indx2}`}>
                      {renderItem(basic[planet], factor)}
                    </Table.Cell>
                  )
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ))}
    </>
  );
};

const Houses = () => {
  const [chartData, setChartData] = useState('');

  const {
    dispatch,
    state: {
      jHoraChart: { basic: basicIn, houseDetails: houseDetailsIn } = {},
    },
  } = useContext(NakshatraContext);

  const [houseDetails, setHouseDetails] = useState(houseDetailsIn || []);
  const [basic] = useState(basicIn || {});

  const textAreaChanged = (event, { value }) => setChartData(value);

  const analyze = () => {
    if (chartData !== '' && chartData !== ' ') {
      const entitities = {};
      const rows = chartData.split('\n');

      for (const row in rows) {
        const [
          house,
          startDeg,
          startSign,
          startMin,
          ,
          cuspDeg,
          cuspSign,
          cuspMin,
          ,
          endDeg,
          endSign,
          endMin,
        ] = rows[row].split(/[ ,]+/);

        if (house !== 'House' && house !== '') {
          const houseNumber = house.split(/[s|n|r|t ,]+/)[0];
          let totalSpan =
            rashiMap[endSign] * 30 +
            parseInt(endDeg) +
            parseInt(endMin) / 60 -
            (rashiMap[startSign] * 30 +
              parseInt(startDeg) +
              parseInt(startMin) / 60);

          if (totalSpan < 0) {
            totalSpan = 360 + totalSpan;
          }

          const yoginiDasha = {};

          for (const yoginiName in yoginis) {
            if (Object.hasOwnProperty.call(yoginis, yoginiName)) {
              const { name, newLord, dashaPeriodInYears } = yoginis[yoginiName];
              const factr = dashaPeriodInYears / totalSpan;
              const factor =
                yoginiName === 'rahu' || yoginiName === 'ketu'
                  ? factr / 2
                  : factr;

              yoginiDasha[yoginiName] = {
                factor,
                name,
                newLord,
                dashaPeriodInYears,
              };
            }
          }

          const vimshottariDasha = {};
          grahas.map(({ name, vimshottariDashaYears }) => {
            const factor = vimshottariDashaYears / totalSpan;

            vimshottariDasha[name] = {
              vimshottariDashaYears,
              factor,
            };

            return null;
          });

          entitities[houseNumber] = {
            name: house,
            start: {
              sign: startSign,
              deg: parseInt(startDeg),
              min: parseInt(startMin.split("'")[0]),
            },
            cusp: {
              sign: cuspSign,
              deg: parseInt(cuspDeg),
              min: parseInt(cuspMin.split("'")[0]),
            },
            end: {
              sign: endSign,
              deg: parseInt(endDeg),
              min: parseInt(endMin.split("'")[0]),
            },
            totalSpan,
            yoginiDasha,
            vimshottariDasha,
          };
        }
      }

      setHouseDetails(entitities);
    }
  };

  useEffect(() => {
    dispatch({
      type: `JHORA_HOUSES_ANALIZED`,
      payload: {
        houseDetails,
      },
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [houseDetails]);

  return (
    <div>
      <TextArea
        placeholder="Paste Jagannatha Hora Houses details"
        style={{
          width: '100%',
          minHeight: 100,
        }}
        onChange={textAreaChanged}
      />
      <Button type="submit" className="btn btn-primary" onClick={analyze}>
        Analyze
      </Button>
      <Divider />
      {Object.entries(basic).length ? (
        <GrahaNHouses basic={basic} houseDetails={houseDetails} />
      ) : (
        <JustHouses houseDetails={houseDetails} />
      )}
    </div>
  );
};

export default Houses;
