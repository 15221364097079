module.exports = Object.freeze({
  1: {
    avoid: `8, iron work, hardware work`,
    suitable: `Person born on 1 10 19 28 of any month - Career or Occupations are; fire related work (restaurant, furness, luxuries, bakery, glamour, top management). Electricity related work, Communication, leadership work, Management, executive, inventor, lawyer, writer, designer, engineer, business owner, medical doctor, teacher, lecturer, composer, artist, musician, military officer, movie director or producer, sales manager, TV or radio station manager, foreman, department head, editor, principal, production manager, program director, buyer, religious leader or any unique area requiring originality or innovation`,
  },
  2: {
    avoid: `8, 4, 9`,
    suitable: `Person born on 2 11 20 29 of any month - Career or Occupations are; Water related work, Sweet shop, Milk related work lassi or tea snop - Navy or merchant navy`,
  },
  3: {
    avoid: `6`,
    suitable: `Person born on 3 12 21 30 of any month - Career or Occupations are; Healing profession, Pharmaceuticals Chemist / doctor, Astrology, vastu, reiki, Education. school and student's related, Counseling Media, entertainment, Imports, Wood related work`,
  },
  4: {
    avoid: `2, 9`,
    suitable: `Person born on 4 13 22 31 of any month - Career or Occupations are; Dabang wale kaam, IT sector (Handwork), Any type of work with hands (3-4 surgen), Pollceman, Army, Law sector, Wood related work`,
  },
  5: {
    avoid: `-`,
    suitable: `Person born on 5 14 23 of any month - Career or Occupations are; Any type of work of his choice (banking, financial, property, teaching, entertainment, fire related, hotel, communication, guide)`,
  },
  6: {
    avoid: `3, 9`,
    suitable: `Person born on 6 15 24 of any month - Career or Occupations are; media (hotel industry, restaurant, jewellery designing, fashion industry, aeronautics, entertainment, pilot, airhostess, dance, beauty parlour, lady garments, share market, cosmetics, speculation, bar, tour and travel, foreign related, women accessories related business), event management`,
  },
  7: {
    avoid: `No partnership rest do whatever you like`,
    suitable: `Person born on 7 16 25 of any month - Career or Occupations are; research/occult science (astrologer, reiki, occult), Scientist, Doctor / Chemist, School related, teaching, preacher, LIC, police, investigation, CID.`,
  },
  8: {
    avoid: `1, 2, 9`,
    suitable: `Person born on 8 17 26 of any month - Career or Occupations are;  iron related machinery, mechanical, medical, printing press, mining coal, chemicals, law, finance related, CA, CS, banking, leather, shoes, agriculture, army, police, liquor, hardware, sales and marketing, insurance, computers, Dabang wale kaam, IT sector (Handwork), Any type of work with hands (3-4 surgen), Pollceman, Army, Law sector, Wood related work`,
  },
  9: {
    avoid: `2, 4, 8`,
    suitable: `Person born on 9 18 27 of any month - Career or Occupations are; humanitarian, police, army, NGO, teaching, gun dealer, doctor, chemist, fire related work (restaurant, furness, luxuries, bakery, glamour, top management). Electricity related work, Communication, leadership work, Management, executive, inventor, lawyer, writer, designer, engineer, business owner, medical doctor, teacher, lecturer, composer, artist, musician, military officer, movie director or producer, sales manager, TV or radio station manager, foreman, department head, editor, principal, production manager, program director, buyer, religious leader or any unique area requiring originality or innovation`,
  },
});
