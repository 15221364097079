module.exports = Object.freeze({
  1: `just one 1 in your chart find it hard to express your innermost feelings, they may be good communicators in other ways but find it hard explain your inner voice. You often find it hard to see the others person point of view`,
  11: `good communicator, good assassin of situation impartially`,
  111: `generally talks to much, chatter box, happy & outgoing, good entertainer`,
  1111: `if there are four 1's in your chart you find it extremely easy to express themselves. You are caring people but frequently misunderstood. You also find it very hard to relax and unwind`,
  11111: `these type of people experience enormous difficulties in expressing themselves verbally. You are frequently misunderstood and often direct your expressive skills into an area they find less painful i.e. writing, painting or dancing. Some people with this combination overindulge in food, drugs or alcohol`,
  111111: `these type of people experience enormous difficulties in expressing themselves verbally. You are frequently misunderstood and often direct your expressive skills into an area they find less painful i.e. writing, painting or dancing. Some people with this combination overindulge in food, drugs or alcohol`,
  2: `if there is one 2 in your chart then u sensitive & intuitive. Unfortunately, you are easily hurt. You are able to sum up other people at a glance, and have a uncanny ability at detecting insincerity.(overlook your possibilities)`,
  22: `highly intelligent, sensitive & intuitive. Unlike people with single 2 you are able to make good use of your intuition. You can easily detect the motivations of others & be able to assess others at a glance`,
  222: `this chart says you have experience an overabundance of this sensitive, intuitive energy and can easily be hurt. You may give impression of being aloof, because you live in a world of your own feelings, & often prefer being on your own instead of spending time with others who might hurt your feelings`,
  2222: `these people are very impatient & inclined to overreact to small problems. You are extremely sensitive & frequently prefer time by themselves, rather than risk being hurt by others`,
  22222: `very rare to and very Unfortunate too , as you find life extremely difficult. You are overly sensitive: constantly suffer from self doubt & lack of confidence and trust in others`,
  222222: `very rare to and very Unfortunate too , as you find life extremely difficult. You are overly sensitive: constantly suffer from self doubt & lack of confidence and trust in others`,
  3: `very good/creative brain and an excellent memory. Always have a positive approach to reach your goals. You are able to inspire others with your optimism and honesty`,
  33: `you posses very good imagination power, mentally alert and usually creative. Sank type approach always. Many people with this combo become writers as you have ability to channel your creative imagination and express your ideas in words`,
  333: `having 333 in your chart means live inside your rich imaginations. You often find it hard to relate well with others and can appear self absorbed and remote. You possess excellent mental ability, but frequently spend your lives in a world of dreams. you find it hard to others and can be argumentative and petty at times`,
  3333: `very impractical, overly imaginative, and fearful. All of these things made it hard for them to function well in everyday life`,
  33333: `very impractical, overly imaginative, and fearful. All of these things made it hard for them to function well in everyday life`,
  333333: `very impractical, overly imaginative, and fearful. All of these things made it hard for them to function well in everyday life`,
  4: `very practical in life and usually good with your hands. You enjoy "hand on" type occupations and get impatient with imaginative ideas & theories. You have the ability to organize others and carry out plans to perfection. You also relates to music and handicrafts in SOME Cases`,
  44: `you are overly involved in physical and materialistic activities at the expense of other activities. You have excellent organizational skills and live starting a task at the beginning and carrying it through to the end. You have considerable creative ability in your hands and enjoy making beautiful objects. You people like sar katwa toh sakte hai but zukha nahin sakte.`,
  444: `these are involved almost exclusively with physical activities and find it hard to pay attention to other areas of your lives. Very well organized, self disciplined and hard working people. Your abilities are obvious to others, but people with 3 fours frequently find it difficult to accept your natural talents and waste years while working in the wrong fields`,
  4444: `totally immersed in physical activities and find it difficult to understand other people who are inter tested in intellectual or spiritual matters. You posses enormous ability at anything involving your hands. People with four are susceptible to damage to your lower limbs`,
  44444: `totally immersed in physical activities and find it difficult to understand other people who are inter tested in intellectual or spiritual matters. You posses enormous ability at anything involving your hands. People with four are susceptible to damage to your lower limbs`,
  444444: `totally immersed in physical activities and find it difficult to understand other people who are inter tested in intellectual or spiritual matters. You posses enormous ability at anything involving your hands. People with four are susceptible to damage to your lower limbs`,
  5: `one 5 in your charts are emotionally well balanced. You are compassionate, understanding and caring. You are able to motivate and inspire others to achieve much more than would have otherwise been possible`,
  55: `if there are two 5's in your chart then you are intense & determined. You have great deal of drive & enthusiasm. You have problems harnessing your emotions, which can lead to that are latter regretted. This often creates difficulties in home and family life`,
  555: `if there are 555 in your chart are prone to speaking without thinking and can hurt others unwittingly. You enjoy change adventure, excitement and often take unnecessa risks. You have great deal of drive & energy but these qualities need to be carefully channeled`,
  5555: `beware as it causes unnatural death very dangerous`,
  55555: `beware as it causes unnatural death very dangerous`,
  555555: `beware as it causes unnatural death very dangerous`,
  6: `true lover of home and loved ones. You enjoy domestic responsibilities and possess considerable creative potential. You make good parents and ultimately become the person everyone comes to in the family when things are not going well. You are often insecure and worry about being widowed and left on your own`,
  66: `you are inclined to worries unnecessarily about your home and family. You nervous strain this creates means that they need more rest than most people. You enjoy creative activities and are happiest surrounded by beautiful things. You are usually over protective and find it hard to let your children stand on your own two feet`,
  666: `these type of people are inclined to look more on the negative side of lite than the positive and always need constant encouragement. People with 666 in your charts are to be overly protective and possessive of your loved ones. You also nave potential, which provides a useful release for your emotional tension. considerable creative`,
  6666: `highly creative but find it hard to channel this energy in the early part of your inte, Everything affects them emotionally, which makes it hard for them to fit into everyday life. Rare to see combination in coming times`,
  66666: `highly creative but find it hard to channel this energy in the early part of your inte, Everything affects them emotionally, which makes it hard for them to fit into everyday life. Rare to see combination in coming times`,
  666666: `highly creative but find it hard to channel this energy in the early part of your inte, Everything affects them emotionally, which makes it hard for them to fit into everyday life. Rare to see combination in coming times`,
  7: `this type of people are likely to learn through losses of love, possessions or health. As they learn from these experiences they usually become more & more inter tested in metaphysical or spiritual pursuits`,
  77: `two 7's in your chart grow in knowledge and wisdom by losing either love, health or money. Ultimately this likely to lead to an interest in the physic or occult worlds. You have analytical brain which makes them good at solving intricate technical problems`,
  777: `three 7's in your chart often lead sad lives caused by major disappointments and setbacks in the area of love, health, & money. Frequently these people develop tremendous reserves of inner strength as a result of these difficulties`,
  7777: `four 7's in your chart have to learn through major lessons involving loss of love, health, and finance`,
  77777: `five 7's in your chart have to learn through major lessons involving loss of love, health, and finance`,
  777777: `six 7's in your chart have to learn through major lessons involving loss of love, health, and finance`,
  8: `one 8 in your chart are methodical, conscientious and good with details. However surprisingly, they usually find it hard to finish the task they begins. You have restless, active minds and need constant mental challenges. Dukhi aatma khurapati dimaag. Lakeer k fakeer`,
  88: `people having 88 in your chart are extremely preceptive & conscientious. You prefer to exp. Things for themselves, rather than too much on trust. You have fixed views and Opinions and find it hard to change your minds after decisions have been made. Rigid stubborn aidiyal tatoo`,
  888: `people who are having 888 in your chart are more conscientious rigid frequently. You develop more purpose in life about the time they reach age of forty and that make rapid progress. You can be overly materialistic & need to learn possessions cannot bring lasting happiness`,
  8888: `they are restless people and have a strong need for change and variety in your lives. Once they find something they really want to pursue your progress can be a joy to behold. Until then though they are likely to lead rather aimless pointless lives`,
  88888: `they are restless people and have a strong need for change and variety in your lives. Once they find something they really want to pursue your progress can be a joy to behold. Until then though they are likely to lead rather aimless pointless lives`,
  888888: `they are restless people and have a strong need for change and variety in your lives. Once they find something they really want to pursue your progress can be a joy to behold. Until then though they are likely to lead rather aimless pointless lives`,
  9: `one 9 in your chart are ambitious and have a string desire to improve themselves. The number 9 is on both the mental and will plane. There is one reason humankind has achieved such a great deal in the 20 century`,
  99: `99 in your chart are idealistic, intelligent & inclined to be critical of others. Because they are highly intelligent they sometimes tent to look down on others who are not similarly blessed. You need to learn to mix with people from all levels of society, upkar karke kuch din baad uski yaad bhi dilate hai`,
  999: `999 in your chart are idealistic caring and intelligent. You are inclined to exaggerate and "make mountains out of hole". You learn to handle this better as they mature. When they are making good use of your mental capabilities, they are happy and positive. However they quickly become frustrated and despondent when they feel they are caught in a Tut`,
  9999: `people having 9999 are highly intelligent, but they find hard to live in the everyday world. You frequently retreat into a world of your own imagination. If they can learn to harness the enormous amount of power and energy at your disposal they can become a real force for good in the world`,
  99999: `people having 9999 are highly intelligent, but they find hard to live in the everyday world. You frequently retreat into a world of your own imagination. If they can learn to harness the enormous amount of power and energy at your disposal they can become a real force for good in the world`,
  999999: `people having 9999 are highly intelligent, but they find hard to live in the everyday world. You frequently retreat into a world of your own imagination. If they can learn to harness the enormous amount of power and energy at your disposal they can become a real force for good in the world`,
});
