import React, {
  useEffect,
  useContext
} from 'react';
import { Accordion, Container, Grid, Label, Segment } from 'semantic-ui-react'
import SignPicker from './SignPicker'
import { NakshatraContext } from './../contexts/nakshatra.context'

import 'semantic-ui-css/semantic.min.css'

const Nakshatrified = () => {
  const { state } = useContext(NakshatraContext)

  useEffect(() => {
    document.title = 'Nakshatrified'
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Segment raised>
                <Label as='a' color='orange' ribbon>One</Label>
                <span>Planets</span>
                {Object.entries(state.grahas.filter(gra => typeof (gra.id) !== 'undefined')).map((graha, index) =>
                  <Accordion fluid styled key={index}>
                    <SignPicker for={`${graha[0]} ${graha[1].graha}`} />
                  </Accordion>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment raised>
                <Label as='a' color='orange' ribbon>Two</Label>
                <span>Houses</span>
                {Object.entries(state.houses).map((house, index) =>
                  <Accordion fluid styled key={index + 1} >
                    <SignPicker for={`${house[0]} ${house[1].name}`} />
                  </Accordion>
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Nakshatrified
