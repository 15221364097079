import React, { useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tab } from "semantic-ui-react";

import { NakshatraContext, useAuth } from "../../contexts/nakshatra.context";
import { RestrictedArea } from "../Auth";

import "semantic-ui-css/semantic.min.css";

import ChartNorth from "./ChartNorth";
import YoginiDasha from "./YoginiDasha";
import Basic from "../Jhora/Basic";
import Houses from "../Jhora/Houses";
import YoginiRemain from "./YoginiRemain";
import Horosoft from "../Horosoft";

const panes = [
  {
    menuItem: "Chart Analysis",
    render: () => (
      <Tab.Pane>
        <ChartNorth />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "JHora Basic",
    render: () => (
      <Tab.Pane>
        <Basic />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "JHora Houses",
    render: () => (
      <Tab.Pane>
        <Houses />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "JHora Yogini Change",
    render: () => (
      <Tab.Pane>
        <YoginiDasha />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Yogini Dasha remaining",
    render: () => (
      <Tab.Pane>
        <YoginiRemain />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Horosoft",
    render: () => (
      <Tab.Pane>
        <Horosoft />
      </Tab.Pane>
    ),
  },
];

const Slider = () => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth();
  const { state } = useContext(NakshatraContext);
  const { user } = state || {};

  const initialize = useCallback(async () => {
    await loginWithRedirect({
      redirectUri: window.location.origin + location.pathname,
    });
  }, [loginWithRedirect, location]);

  useEffect(() => {
    if (!user) {
      initialize();
    }
  }, [user, initialize]);

  useEffect(() => {
    document.title = "Slider";
  }, []);

  return user?.email ? (
    <>
      <h2>Chart</h2>
      <Tab panes={panes} />
    </>
  ) : (
    <RestrictedArea />
  );
};

export default Slider;
