export const setCookie = (name, data) => {
  console.info("setCookie--> ", name, data);
  sessionStorage.removeItem(name);
  sessionStorage.setItem(name, JSON.stringify(data), { expires: 14 });
};

export const getCookie = (name) => {
  const cookie = sessionStorage.getItem(name);
  if (cookie === undefined) {
    return {};
  } else {
    return JSON.parse(cookie);
  }
};
