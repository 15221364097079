import React, {
  useCallback,
  // useContext,
  useEffect,
  useState,
} from 'react';
import { Popup, Table } from 'semantic-ui-react';
// import { NakshatraContext } from './../contexts/nakshatra.context';
import useDarkMode from 'use-dark-mode';

import 'semantic-ui-css/semantic.min.css';

import YearMonth from './YearMonth';

const nakshatraImages = require.context(
  './../../../public/images/nakshatras',
  true
);

const nakshatrasData = require('./../../data/nakshatras');
const taraList = require('./../../data/taraList');

let currentNakshatras = nakshatrasData;
let startOrder = 1;

const COLOR = {
  medium: '#eeeeee',
  best: '#81ee7e',
  bad: '#fb4b07',
  black: '#000',
};

const cellClicked = (index, selectedYear, selectedMonth) => {
  let lastIndex = index <= 27 ? (index - 1 === 0 ? 27 : index - 1) : 1;

  startOrder = index;

  let start = true;
  let workingIndex = index;
  let orderValue = 0;
  let year = parseInt(selectedYear);

  while (start) {
    if (workingIndex > 27) {
      workingIndex = 1;
    }

    if (orderValue < 27 && currentNakshatras[workingIndex - 1]['order']) {
      currentNakshatras[workingIndex - 1]['order'] = [];
    }

    if (orderValue < 27 && currentNakshatras[workingIndex - 1]['year']) {
      currentNakshatras[workingIndex - 1]['year'] = [];
    }

    if (typeof currentNakshatras[workingIndex - 1]['order'] === 'undefined') {
      currentNakshatras[workingIndex - 1]['order'] = [];
    }

    if (typeof currentNakshatras[workingIndex - 1]['year'] === 'undefined') {
      currentNakshatras[workingIndex - 1]['year'] = [];
    }

    if (year) {
      currentNakshatras[workingIndex - 1]['order'].push(orderValue + 1);
      currentNakshatras[workingIndex - 1]['year'].push(year);
    }

    workingIndex++;
    orderValue++;
    year++;

    if (workingIndex === lastIndex + 1 && orderValue > 100) {
      start = false;
    }
  }

  return currentNakshatras;
};

const assignNumber1Index = (nakshatras, iidx) => {
  let number1Index = -1;

  [iidx, iidx + 9, iidx + 18].map((ii, idx) => {
    if (nakshatras[ii].order.includes(1)) {
      number1Index = iidx;
    }

    nakshatras[ii]['backgroundColor'] = COLOR.neutral;

    [
      [1, COLOR.medium],
      [9, COLOR.medium],
      [2, COLOR.best],
      [4, COLOR.best],
      [6, COLOR.best],
      [8, COLOR.best],
      [3, COLOR.bad],
      [5, COLOR.bad],
      [7, COLOR.bad],
    ].map(([val, color]) => {
      if (nakshatras[ii].order.includes(val)) {
        nakshatras[ii]['backgroundColor'] = color;
      }
      return nakshatras[ii];
    });

    return number1Index;
  });

  return number1Index;
};

const rowWisedArray = (nakshatras) => {
  let rowArray = [];
  let number1Index = -1;

  for (var i = 0, len = 9; i < len; i++) {
    let returnAssignNumber1Index = assignNumber1Index(nakshatras, i);

    if (returnAssignNumber1Index > -1) {
      number1Index = returnAssignNumber1Index;
    }
    rowArray.push([nakshatras[i], nakshatras[i + 9], nakshatras[i + 18]]);
  }

  if (number1Index > -1) {
    let workingIndex = number1Index;
    let taraIndex = 0;
    let start = true;

    while (start) {
      if (workingIndex > 8) {
        workingIndex = 0;
      }

      nakshatras[workingIndex]['navatara'] = taraList[taraIndex].navatara;
      nakshatras[workingIndex]['meaning'] = taraList[taraIndex].meaning;

      workingIndex++;
      taraIndex++;

      if (taraIndex > 8) {
        start = false;
      }
    }
  }

  return rowArray;
};

const NakshatraChakra = () => {
  const [selectedYear, setSelectedYear] = useState('Select year');
  const [selectedMonth, setSelectedMonth] = useState('Select month');
  const [rowArray, setRowArray] = useState(
    rowWisedArray(cellClicked(startOrder, selectedYear, selectedMonth))
  );
  const darkMode = useDarkMode();

  useEffect(() => {
    document.title = 'NakshatraChakra';
  }, []);

  const rowArrayCallback = useCallback(
    (id) => {
      setRowArray(rowWisedArray(cellClicked(id, selectedYear, selectedMonth)));
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    },
    [selectedYear, selectedMonth]
  );

  return (
    <React.Fragment>
      <YearMonth
        label='Birth Month & Year'
        onYearChange={setSelectedYear}
        onMonthChange={setSelectedMonth}
      />
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan="1">Navatara</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">No.</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Nakshatra</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Year Order</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">No.</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Nakshatra</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Year Order</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">No.</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Nakshatra</Table.HeaderCell>
            <Table.HeaderCell rowSpan="1">Year Order</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">Lord</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rowArray.map((row, index) => (
            <Table.Row key={rowArray[index][0].id}>
              <Popup
                trigger={<Table.Cell>{rowArray[index][0].navatara}</Table.Cell>}
                on="click"
                position="bottom right"
                inverted>
                {rowArray[index][0].meaning}
              </Popup>
              <Popup
                trigger={<Table.Cell>{rowArray[index][0].id}</Table.Cell>}
                on="click"
                position="bottom left"
                inverted>
                <img
                  alt=""
                  src={nakshatraImages('./' + rowArray[index][0].imageFileName)}
                />
              </Popup>
              <Table.Cell
                style={{
                  background: rowArray[index][0].backgroundColor,
                  color: rowArray[index][0].color,
                  WebkitTextFillColor: rowArray[index][0].color,
                  WebkitTextStrokeColor: darkMode.value
                    ? '#555'
                    : rowArray[index][0].color,
                  WebkitTextStrokeWidth: '0.5px',
                }}
                onClick={() => rowArrayCallback(rowArray[index][0].id)}>
                {rowArray[index][0].nakshtraName}
              </Table.Cell>
              <Table.Cell>
                {`${rowArray[index][0].order.join(', ')} ${rowArray[
                  index
                ][0].year.join(', ')}`}
              </Table.Cell>
              <Popup
                trigger={
                  <Table.Cell>
                    {rowArray[index][1] ? rowArray[index][1].id : ''}
                  </Table.Cell>
                }
                on="click"
                position="bottom left"
                inverted>
                <img
                  alt=""
                  src={nakshatraImages('./' + rowArray[index][1].imageFileName)}
                />
              </Popup>
              <Table.Cell
                style={{
                  background: rowArray[index][1].backgroundColor,
                  color: rowArray[index][1].color,
                  WebkitTextFillColor: rowArray[index][1].color,
                  WebkitTextStrokeColor: darkMode.value
                    ? '#555'
                    : rowArray[index][1].color,
                  WebkitTextStrokeWidth: '0.5px',
                }}
                onClick={() => rowArrayCallback(rowArray[index][1].id)}>
                {rowArray[index][1] ? rowArray[index][1].nakshtraName : ''}
              </Table.Cell>
              <Table.Cell>
                {`${rowArray[index][1].order.join(', ')} ${rowArray[
                  index
                ][1].year.join(', ')}`}
              </Table.Cell>
              <Popup
                trigger={
                  <Table.Cell>
                    {rowArray[index][2] ? rowArray[index][2].id : ''}
                  </Table.Cell>
                }
                on="click"
                position="bottom right"
                inverted>
                <img
                  alt=""
                  src={nakshatraImages('./' + rowArray[index][2].imageFileName)}
                />
              </Popup>
              <Table.Cell
                style={{
                  background: rowArray[index][2].backgroundColor,
                  color: rowArray[index][2].color,
                  WebkitTextFillColor: rowArray[index][2].color,
                  WebkitTextStrokeColor: darkMode.value
                    ? '#555'
                    : rowArray[index][2].color,
                  WebkitTextStrokeWidth: '0.5px',
                }}
                onClick={() => rowArrayCallback(rowArray[index][2].id)}>
                {rowArray[index][2] ? rowArray[index][2].nakshtraName : ''}
              </Table.Cell>
              <Table.Cell>
                {`${rowArray[index][2].order.join(', ')} ${rowArray[
                  index
                ][2].year.join(', ')}`}
              </Table.Cell>
              <Table.Cell>{rowArray[index][0].lord}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Table celled structured>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ background: COLOR.medium }}></Table.Cell>
            <Table.Cell>Medium</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ background: COLOR.best }}></Table.Cell>
            <Table.Cell>Best</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ background: COLOR.bad }}></Table.Cell>
            <Table.Cell>Bad</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default NakshatraChakra;
