import React, {
  useEffect,
} from 'react';
import {
  Tab,
} from 'semantic-ui-react'

import EnglishToDevanagari from "./Name/EnglishToDevanagari";

import './table.css';

const panes = [
  {
    menuItem: 'Name Analysis',
    render: () => <Tab.Pane>
      <EnglishToDevanagari />
    </Tab.Pane>
  },
];
const Name = () => {

  useEffect(() => {
    document.title = 'Name'
  }, []);

  return (
    <div>
      <Tab panes={panes} />
    </div>
  )
};

export default Name;
