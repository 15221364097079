import React, { useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th } from 'react-super-responsive-table';
import GrahaToggleRowContent from './Graha/GrahaToggleRowContent';

import './table.css';

const grahas = require('./../../data/grahas');

const Graha = () => {
  useEffect(() => {
    document.title = 'Graha';
  }, []);

  return (
    <React.Fragment>
      <h2>Graha</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Exaltation (उच)</Th>
            <Th>Debilitation (नीच)</Th>
            <Th>Swa-Graha</Th>
            <Th>Color</Th>
            <Th>Direction</Th>
            <Th>Aspects</Th>
            <Th>Friends</Th>
            <Th>Enemies</Th>
            <Th>Neutral</Th>
            <Th>Age</Th>
            <Th>Potentancy</Th>
            <Th>Swar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {grahas.map((graha) => (
            <GrahaToggleRowContent key={graha.id} graha={graha} />
          ))}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export default Graha;
