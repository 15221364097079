import React, {
  // useState
} from 'react';
import {
  Message,
} from 'semantic-ui-react'

const BTR = () => {
  return (
    <div>
      <h2>Birth Time Rectification</h2>
      <Message>
        <Message.Header>
          Direct Linking
        </Message.Header>
        <Message.Content>
          <Message.Item>
            1st CNL can becomes 9th CSL
          </Message.Item>
          <Message.Item>
            1st CSL can becomes 9th CNL
          </Message.Item>
        </Message.Content>

        <Message.Header>
          Indirect Linking
        </Message.Header>
        <Message.Content>
          <Message.Item>
            1st CNL and 9th CSL have common planet as their Nl or SL
          </Message.Item>
          <Message.Item>
            1st CSL and 9th CNL have common planet as their Nl or SL
          </Message.Item>
        </Message.Content>
      </Message>
    </div>
  )
};

export default BTR;
