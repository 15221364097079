import React, {
  useState
} from 'react';
import {
  Accordion,
  Divider,
  Icon,
} from 'semantic-ui-react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from 'react-super-responsive-table'

const planetCapacityPanel = (activeIndex) => {
  return (
    <Accordion.Content active={activeIndex === 0}>
      Even (सम) Rashi
      <Table>
        <Thead>
          <Tr>
            <Th>Degree Range</Th><Th>Condition (अवस्था)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr><Td>00º - 06º</Td><Td>Dead (म्रुत)</Td></Tr>
          <Tr><Td>07º - 12º</Td><Td>Old (व्रुध)</Td></Tr>
          <Tr><Td>13º - 18º</Td><Td>Young (युवा)</Td></Tr>
          <Tr><Td>19º - 24º</Td><Td>Child (कुमार)</Td></Tr>
          <Tr><Td>25º - 30º</Td><Td>Infant (बाल)</Td></Tr>
        </Tbody>
      </Table>
      Odd (विषम) Rashi
      <Table>
        <Thead>
          <Tr>
            <Th>Degree Range</Th><Th>Condition (अवस्था)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr><Td>00º - 06º</Td><Td>Infant (बाल)</Td></Tr>
          <Tr><Td>07º - 12º</Td><Td>Child (कुमार)</Td></Tr>
          <Tr><Td>13º - 18º</Td><Td>Young (युवा)</Td></Tr>
          <Tr><Td>19º - 24º</Td><Td>Old (व्रुध)</Td></Tr>
          <Tr><Td>25º - 30º</Td><Td>Dead (म्रुत)</Td></Tr>
        </Tbody>
      </Table>
      <div>
        Condition (अवस्था) Explained
        <Table>
          <Thead>
            <Tr>
              <Th>Infant (बाल) -> 25%</Th>
              <Th>Child (कुमार) -> 50%</Th>
              <Th>Young (युवा) -> 100%</Th>
              <Th>Old (व्रुध) -> 12%</Th>
              <Th>Dead (म्रुत) -> 0%</Th>
            </Tr>
          </Thead>
        </Table>
      </div>
    </Accordion.Content>
  );
};


const PlanetCapacity = () => {
  const [activeIndex, setActiveIndex] = useState(false)

  const handleClick = (e, titleProps) => {
    console.warn('handleClick e', e, titleProps);
    const { index } = titleProps

    setActiveIndex(activeIndex === index ? -1 : index)
  }

  return (
    <Accordion panels={[
        {
          key: 'planet-capacity',
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <Divider horizontal><Icon name='dropdown' />Planet Capacity (ग्रह क्षमता)</Divider>
            </Accordion.Title>
          ),
          content: planetCapacityPanel(activeIndex),
          active: true
        },
      ]}
    />
  )
};

export default PlanetCapacity;
