module.exports = Object.freeze([
  {
    id: 1,
    navatara: 'Janma',
    meaning: 'Initiation, beginning, spark of ideation. Janma means birth and refers to the self, well being, life and everything that the mind must experience at a personal level or intelligence must act on'
  },
  {
    id: 2,
    navatara: 'Sampat',
    meaning: 'Success, perfection, wealth, concord, enjoyment, glory, beauty, becoming, existence. The capacity to be receptive, with an open mind and therefore enjoy'
  },
  {
    id: 3,
    navatara: 'Vipat',
    meaning: 'Prevent, hinder, fail, die. Misfortune and calamity. All kind of troubles, obstacles and hindrances that lead to sorrow and suffering at the material level.Things may not turn out as expected resulting in potential frustration'
  },
  {
    id: 4,
    navatara: 'Kshema',
    meaning: 'Habitable, security, prosperity. Residing, restoring and being at ease, comfortable, shows an overall prosperous disposition, free from disease (it is opposite of being at ease). It brings in spiritual bliss and final emancipation (moksa). It refers to the perfume of sandal. Something/ someone very secure and safe and being protected by Durga.Recognition of practicality and potential stability offered'
  },
  {
    id: 5,
    navatara: 'Pratyari',
    meaning: 'Facing, coming, averted, moving in an opposite direction, westward, inner, past, gone. Derived from ‘ari’ meaning enemy and pratyari refers to the well matched enemy equal to the native in all respects and capable of inflicting terrible pain and suffering. Fulfilment will only be to a certain degree, so there is a need to search for a greater level of fulfilment'
  },
  {
    id: 6,
    navatara: 'Sadhak',
    meaning: 'Sadhaka. Effective, productive, efficient, finishing, perfecting, fulfilling, useful. Consideration leads to heightened awareness, leading to proper decisions that bring fulfilment. Harmony of inner and outer resources'
  },
  {
    id: 7,
    navatara: 'Vadh',
    meaning: 'Slayer, destroyer, death, destruction, prevention. Also known as Naidana — Perishable, deadly. Consciousness of separation and loss develops leading to inability to find happiness'
  },
  {
    id: 8,
    navatara: 'Mitra',
    meaning: 'Friendly, benevolent, kind, affectionate. Acceptance and cooperation brings fulfilment'
  },
  {
    id: 9,
    navatara: 'Atimitra',
    meaning: 'Extreme, highest, best Maitra. Atimitra shows a superlative form of ‘mitra’ where a spiritual bonding is expressed. It goes beyond normal human ties and is super human in its expression of love indicating something divine. For example if mitra brings glory then atimitra is crowning glory. Unity bringing greatest fulfilment'
  }
])
