import React, {
  useEffect,
} from 'react';
import {
  Accordion,
  Message,
} from 'semantic-ui-react'

const grahas = require('./../../../data/grahas');

const renderContent = (contentList) => contentList.map((textRow, index) => {
  return <Message.Item
    key={index}
  >
    {textRow}
  </Message.Item>;
});

const PlanetBusiness = (activeIndex) => {

  useEffect(() => {
    document.title = 'KP Planets Business'
  }, []);

  return (
    <Accordion.Content active>
      {grahas.map((graha) => {
        return <Message floating key={`graha-${graha.id}`}>
          <Message.Header>
            {graha.graha}
          </Message.Header>
          <Message.Content>
            <Message.List>
              {renderContent(graha.bhrigu.business)}
            </Message.List>
          </Message.Content>
        </Message>
      })}
    </Accordion.Content>
  );
};

export default PlanetBusiness;
