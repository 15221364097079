import React, { useContext, useRef, useState } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import useDarkMode from "use-dark-mode";

import { NakshatraContext } from "../../contexts/nakshatra.context";

import DrawHouse from './DrawHouse';
import PlanetAspect from "./PlanetAspect";

import 'semantic-ui-css/semantic.min.css';

import houseCoordinates from "../../engine/houseCoordinates";

const chartCoordinates = require('../../../data/chartCoordinates');

const DrawHouses = ({
  width,
  height,
  setCurrentSelectedHouse,
}) => {
  const { value: darkModeValue } = useDarkMode();
  const {
    dispatch,
    state: {
      slider: {
        chartAnalysis: {
          northChart: {
            currentSignIndex,
          } = {},
        } = {},
        houses: stateHouses,
        flags: {
          showAspectsWhenClicked,
        } = {},
      } = {},
    } = {},
  } = useContext(NakshatraContext);

  const { houses } = chartCoordinates;

  const contextRef = useRef();
  const [open, setOpen] = useState(false);
  const [indexToSet, setIndexToSet] = useState(false);
  let signIndexNow = currentSignIndex || 0;

  const setCurrentRef = (context) => {
    contextRef.current = context;
  };

  const handleItemClick = (e) => {
    setOpen(false);
    dispatch({
      type: 'SLIDER_NORTH_CHART_HOUSE_ROTATION',
      payload: indexToSet,
    });
  };

  return (
    <>
      {houses.map((house, houseIndex) => {
        if (signIndexNow >= 12) {
          signIndexNow = 0;
        }

        let renderIndex = signIndexNow++;

        return (
          <React.Fragment key={houseIndex}>
            <DrawHouse
              key={houseIndex}
              house={house}
              width={width}
              height={height}
              renderIndex={renderIndex}
              setCurrentSelectedHouse={setCurrentSelectedHouse}
              setIndexToSet={setIndexToSet}
              setOpen={setOpen}
              setCurrentRef={setCurrentRef}
            />
          </React.Fragment>
        );
      })}
      <Popup open={open} context={contextRef} onClose={() => setOpen(false)}>
        <Menu
          items={[
            {
              key: 'rotate',
              content: 'Rotate to this Rashi',
              icon: 'move',
            },
          ]}
          onItemClick={handleItemClick}
          secondary
          vertical
        />
      </Popup>
      {showAspectsWhenClicked && houses.map((house, houseIndex) => {
        if (signIndexNow >= 12) {
          signIndexNow = 0;
        }

        let renderIndex = signIndexNow++;

        const selectedPlanets = stateHouses[renderIndex + 1]?.planets;
        const twelveHouses = houseCoordinates(
          width,
          height,
          (renderIndex + 1).toString()
        );
        return (
          <PlanetAspect
            key={`planet-aspect-${house.houseName}-${houseIndex + 1}'`}
            grahaKey={`ghaar-${houseIndex+1}`}
            renderIndex={renderIndex}
            darkModeValue={darkModeValue}
            selectedPlanets={selectedPlanets}
            twelveHouses={twelveHouses}
          />
        );
      })};
    </>
  );
};

export default DrawHouses;
