const houseNames = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const lords = [
  `As`,
  `Su`,
  `Mo`,
  `Ma`,
  `Me`,
  `Ju`,
  `Ve`,
  `Sa`,
  `Ra`,
  `Ke`,
  `Ur`,
  `Ne`,
  `Pl`,
  `1st`,
  `2nd`,
  `3rd`,
  `4th`,
  `5th`,
  `6th`,
  `7th`,
  `8th`,
  `9th`,
  `10th`,
  `11th`,
  `12th`,
  `1`,
  `2`,
  `3`,
  `4`,
  `5`,
  `6`,
  `7`,
  `8`,
  `9`,
  `10`,
  `11`,
  `12`,
];

const bodyFullNames = [
  "Lagna",
  "Sun",
  "Moon",
  "Mars",
  "Mercury",
  "Jupiter",
  "Venus",
  "Saturn",
  "Rahu",
  "Ketu",
];

const planetShortNames = ["Ke", "Ve", "Su", "Mo", "Ma", "Ra", "Ju", "Sa", "Me"];

const bodyNamesToExclude = [
  `Maandi`,
  `Gulika`,
  `Bhava`,
  `Hora`,
  `Ghati`,
  `Vighati`,
  `Varnada`,
  `Sree`,
  `Pranapada`,
  `Indu`,
  `Bhrigu`,
  `Dhooma`,
  `Vyatipata`,
  `Parivesha`,
  `Indra`,
  `Upaketu`,
  `Kaala`,
  `Mrityu`,
  `Artha`,
  `Yama`,
  `Prana`,
  `Deha`,
  `Mrityu`,
  `Sookshma`,
  `Tithi`,
  `Yoga`,
  `Kshetra`,
  `Beeja`,
  `TriSphuta`,
  `ChatusSphuta`,
  `PanchaSphuta`,
  `V2`,
  `V3`,
  `V4`,
  `V5`,
  `V6`,
  `V7`,
  `V8`,
  `V9`,
  `V10`,
  `V11`,
  `V12`,
  `Kunda`,
  `Yoga`,
  `Avayoga`,
];

const isNumeric = (num) => {
  return !isNaN(parseInt(num));
};

const rashis = {
  Ar: {
    name: `Aries`,
    aspect: [5, 8, 11],
    aspectNames: ["Le", "Sc", "Aq"],
    ownerShortName: "Ma",
    ownerName: "Mars",
    prevNext: ["Pi", "Ta"],
  },
  Ta: {
    name: `Taurus`,
    aspect: [4, 7, 10],
    aspectNames: ["Cn", "Li", "Cp"],
    ownerShortName: "Ve",
    ownerName: "Venus",
    prevNext: ["Ar", "Ge"],
  },
  Ge: {
    name: `Gemini`,
    aspect: [6, 9, 12],
    aspectNames: ["Vi", "Sg", "Pi"],
    ownerShortName: "Me",
    ownerName: "Mercury",
    prevNext: ["Ta", "Cn"],
  },
  Cn: {
    name: `Cancer`,
    aspect: [2, 8, 11],
    aspectNames: ["Ta", "Sc", "Aq"],
    ownerShortName: "Mo",
    ownerName: "Moon",
    prevNext: ["Ge", "Le"],
  },
  Le: {
    name: `Leo`,
    aspect: [1, 7, 10],
    aspectNames: ["Ar", "Li", "Cp"],
    ownerShortName: "Su",
    ownerName: "Sun",
    prevNext: ["Cn", "Vi"],
  },
  Vi: {
    name: `Virgo`,
    aspect: [3, 9, 12],
    aspectNames: ["Le", "Sg", "Pi"],
    ownerShortName: "Me",
    ownerName: "Mercury",
    prevNext: ["Le", "Li"],
  },
  Li: {
    name: `Libra`,
    aspect: [2, 5, 11],
    aspectNames: ["Ta", "Le", "Aq"],
    ownerShortName: "Ve",
    ownerName: "Venus",
    prevNext: ["Vi", "Sc"],
  },
  Sc: {
    name: `Scorpio`,
    aspect: [1, 4, 10],
    aspectNames: ["Ar", "Cn", "Cp"],
    ownerShortName: "Ma",
    ownerName: "Mars",
    prevNext: ["Li", "Sg"],
  },
  Sg: {
    name: `Sagittarius`,
    aspect: [3, 6, 12],
    aspectNames: ["Ge", "Vi", "Pi"],
    ownerShortName: "Ju",
    ownerName: "Jupiter",
    prevNext: ["Sc", "Cp"],
  },
  Cp: {
    name: `Capricorn`,
    aspect: [2, 5, 8],
    aspectNames: ["Ta", "Le", "Sc"],
    ownerShortName: "Sa",
    ownerName: "Saturn",
    prevNext: ["Sg", "Aq"],
  },
  Aq: {
    name: `Aquarius`,
    aspect: [1, 4, 7],
    aspectNames: ["Ar", "Cn", "Li"],
    ownerShortName: "Sa",
    ownerName: "Saturn",
    prevNext: ["Cp", "Pi"],
  },
  Pi: {
    name: `Pisces`,
    aspect: [3, 6, 9],
    aspectNames: ["Ge", "Vi", "Sg"],
    ownerShortName: "Ju",
    ownerName: "Jupiter",
    prevNext: ["Aq", "Ar"],
  },
};

const signShortNames = [
  "Ar",
  "Ta",
  "Ge",
  "Cn",
  "Le",
  "Vi",
  "Li",
  "Sc",
  "Sg",
  "Cp",
  "Aq",
  "Pi",
];

const arrRotateLeft = (a, n) => {
  while (n > 0) {
    a.push(a.shift());
    n--;
  }
  return a;
};

const isHeaderRow = (row) => {
  return row === "Body" || row === "" || row === "Body/Cusp" || row === "House";
};

const round2Fixed = (num, decimals = 2) =>
  (Math.round(num * 100) / 100).toFixed(decimals);

const parseDegree = (currentSignOrder, sign, deg, min, sec) => {
  if (!signShortNames.includes(sign)) return -1;

  const signIndex = currentSignOrder.indexOf(sign);

  const degree = parseInt(deg);
  const minute = parseInt(min);
  const second = parseFloat(sec);

  const parsedDegree = signIndex * 30 + degree + minute / 60 + second / 3600;

  const roundedParsedDegree = round2Fixed(parsedDegree);

  return roundedParsedDegree;
};

const numberPartOfString = (numString) =>
  numString.replace(/'/g, "").replace(/st|th|rd|nd/g, "");

const parseChartData = (data) => {
  const rows = data.split("\n");
  const parsedChartData = {};
  let firstHouseSign = "";
  let indexOfFirstHouse = 0;
  let currentSignOrder = [];
  rows.map((row) => {
    const columns = row.split(/[ ,\t:'']+/);
    if (isHeaderRow(columns[0])) {
      return null;
    }
    const rowEntry = {};
    if (columns[0] === "Lagna") {
      firstHouseSign = columns[2];
      indexOfFirstHouse = signShortNames.indexOf(firstHouseSign);
      currentSignOrder = arrRotateLeft(signShortNames, indexOfFirstHouse);
      parsedChartData.currentSignOrder = currentSignOrder;
    }
    if (isNumeric(columns[0])) {
      // its a house row
      if (columns[1] === "house") {
        rowEntry["nl"] = columns[2];
        rowEntry["sl"] = columns[3];
        rowEntry["ssl"] = columns[4];
        rowEntry["planetsOccurredInLagnaChart"] = [];
        rowEntry["planetsOccurredInBhavChart"] = [];
      } else {
        rowEntry["start"] = {
          name: columns[2],
          degrees: columns[1],
          minutes: columns[3],
        };
        rowEntry["cusp"] = {
          name: columns[6],
          degrees: columns[5],
          minutes: columns[7],
        };
        rowEntry["end"] = {
          name: columns[10],
          degrees: columns[9],
          minutes: columns[11],
        };
        rowEntry["planetsInIt"] = columns.slice(13);
        rowEntry["sign"] = columns[2];
        rowEntry["rl"] = rashis[columns[2]]?.ownerShortName;
        // rowEntry["degree"] = {
        rowEntry["degrees"] = columns[1];
        rowEntry["minutes"] = columns[3];
        // };
        rowEntry["parsedDegrees"] = parseDegree(
          currentSignOrder,
          columns[2],
          columns[1],
          columns[3],
          0
        );
      }
    } else {
      if (lords.includes(columns[0])) {
        // its house row in Body/Cusp
        if (columns[1] === "house") {
          rowEntry["nl"] = columns[2];
          rowEntry["sl"] = columns[3];
          rowEntry["ssl"] = columns[4];
        } else {
          if (isNumeric(columns[1])) {
            rowEntry["start"] = {
              name: columns[2],
              degrees: columns[1],
              minutes: columns[3],
            };
            rowEntry["cusp"] = {
              name: columns[6],
              degrees: columns[5],
              minutes: columns[7],
            };
            rowEntry["end"] = {
              name: columns[10],
              degrees: columns[9],
              minutes: columns[11],
            };
            rowEntry["planetsInIt"] = columns.slice(13);
          } else {
            rowEntry["nl"] = columns[1];
            rowEntry["sl"] = columns[2];
            rowEntry["ssl"] = columns[3];
          }
        }
      }

      if (bodyFullNames.includes(columns[0]) && !columns.includes("Sphuta")) {
        let nextPos = 1;
        if (columns[1] === "(R)") {
          rowEntry["retrograde"] = true;
          rowEntry["karak"] = columns[3];
          nextPos = 4;
        } else {
          rowEntry["retrograde"] = false;
        }

        if (columns[1] === "-") {
          rowEntry["karak"] = columns[2];
          nextPos = 3;
        }
        rowEntry["sign"] = columns[nextPos + 1];
        rowEntry["rl"] = rashis[columns[nextPos + 1]]?.ownerShortName;
        rowEntry["degrees"] = columns[nextPos];
        rowEntry["minutes"] = columns[nextPos + 2];
        rowEntry["nakshatra"] = {
          name: columns[nextPos + 4],
          pada: columns[nextPos + 5],
        };

        rowEntry["rashi"] = columns[nextPos + 6];
        rowEntry["navamsa"] = columns[nextPos + 7];
        rowEntry["parsedDegrees"] = parseDegree(
          currentSignOrder,
          columns[nextPos + 1],
          columns[nextPos],
          columns[nextPos + 2],
          0
        );
      }

      if (planetShortNames.includes(columns[0])) {
        rowEntry["inBhav"] = [];
        rowEntry["inHouse"] = [];
        rowEntry["conjunctsWith"] = [];
        rowEntry["aspectedInBhavaBy"] = [];
        rowEntry["aspectedInLagnaBy"] = [];
        columns[0] === "Ra" && rowEntry["aspectedInLagnaBy"].push("Ke");
        columns[0] === "Ke" && rowEntry["aspectedInLagnaBy"].push("Ra");
      }
    }

    if (!bodyNamesToExclude.includes(columns[0])) {
      const columnZero =
        columns[0] === "As" || columns[0] === "Lagna"
          ? "1"
          : numberPartOfString(columns[0]);
      if (lords.includes(columnZero.slice(0, 2))) {
        // console.log("🚀 ~ item", columnZero, `is`, columnZero.slice(0, 2));
        parsedChartData[columnZero.slice(0, 2)] = {
          ...parsedChartData[columnZero.slice(0, 2)],
          ...rowEntry,
          ownerOf: [],
          nakshatraLordOf: [],
          kpSignificatorOf: [],
          kpSignificatorOfSorted: [],
          planetsInBhavChart: [],
          planetsInItLagnaChart: [],
        };
      } else {
        // console.log("🚀 ~ item", columnZero, `not found`);
        parsedChartData[columnZero] = {
          ...parsedChartData[columnZero],
          ...rowEntry,
          ownerOf: [],
          nakshatraLordOf: [],
          kpSignificatorOf: [],
          kpSignificatorOfSorted: [],
          planetsInBhavChart: [],
          planetsInItLagnaChart: [],
        };
      }
    }

    return null;
  });
  return parsedChartData;
};

const nLordOfAllPlanets = (chartData) => [
  ...new Set(planetShortNames.map((planet) => chartData[planet].nl)),
];

const untenantedPlanets = (chartData) => {
  // Untenanted planets
  // - Planet not being Nakshatra Lord of any planet
  // - Planet in its own Nakshatra
  // - Planet has exchange of nakshatra with other planet
  return planetShortNames.map((planet) => {
    const notNLofAnyPlanets = !chartData.nLofAllPlanets.includes(planet);
    const inItsOwnNakshatra = planet === chartData[planet].nl;
    const hasExchangeOfNakshatra =
      chartData[chartData[planet].nl]?.nl === planet;
    chartData[planet].untenanted =
      notNLofAnyPlanets || inItsOwnNakshatra || hasExchangeOfNakshatra;
    return {
      planet,
      notNLofAnyPlanets,
      inItsOwnNakshatra,
      hasExchangeOfNakshatra,
    };
  });
};

const planetaryPosition = (chartData) =>
  planetShortNames.map((planet) => ({
    planet: `${planet} ${chartData[planet].retrograde ? "(R)" : ""}`,
    sign: chartData[planet].sign,
    degree: `${chartData[planet]?.degrees}° ${chartData[planet]?.minutes}'`,
    rl: chartData[planet].rl,
    nl: chartData[planet].nl,
    sl: chartData[planet].sl,
    ssl: chartData[planet].ssl,
  }));

const nirayanaCusps = (chartData) =>
  houseNames.map((house) => {
    return {
      bhavNo: house,
      sign: chartData[house].sign,
      degree: `${chartData[house].degrees}° ${chartData[house].minutes}'`,
      rl: chartData[house].rl,
      nl: chartData[house].nl,
      sl: chartData[house].sl,
      ssl: chartData[house].ssl,
    };
  });

const assignPlanetNLOf = (chartData) =>
  planetShortNames.map((nLPlanet) => {
    planetShortNames.map((planet) => {
      if (chartData[planet].nl === nLPlanet) {
        chartData[nLPlanet].nakshatraLordOf.push(planet);
      }
      return null;
    });
    return null;
  });

const assignPlanetOwnerOf = (chartData) =>
  houseNames.map((house, index) => {
    const entity = chartData[house];
    const houseLord = rashis[entity.sign]?.ownerShortName;
    chartData[houseLord].ownerOf.push(house);
    return null;
  });

const assignPlanetToHouse = (chartData) =>
  planetShortNames.map((planet) => {
    const planetData = chartData[planet];
    houseNames.map((house, index) => {
      const houseData = chartData[house];
      const nextHouse = houseNames[index + 1] || houseNames[0];
      const nextHouseData = chartData[nextHouse];
      if (
        parseFloat(planetData.parsedDegrees) >
        parseFloat(nextHouseData.parsedDegrees)
      ) {
        return null;
      }

      if (
        parseFloat(planetData.parsedDegrees) >=
          parseFloat(houseData.parsedDegrees) &&
        parseFloat(planetData.parsedDegrees) <
          parseFloat(nextHouseData.parsedDegrees)
      ) {
        planetData.inBhav.push(house);
        houseData.planetsOccurredInBhavChart.push(planet);
      }
      if (
        nextHouse === "1" &&
        parseFloat(planetData.parsedDegrees) <
          parseFloat(nextHouseData.parsedDegrees)
      ) {
        planetData.inBhav.push("12");
        chartData["12"].planetsOccurredInBhavChart.push(planet);
      }
      if (planetData.sign === houseData.sign) {
        planetData.inHouse.push(house);
        houseData.planetsOccurredInLagnaChart.push(planet);
      }
      return null;
    });

    if (
      planetData.inBhav.length === 0 &&
      planetData.parsedDegrees > chartData["12"].parsedDegrees
    ) {
      // planet degrees are greater than 12th house degreess that means the
      // planet is in 12th house
      planetData.inBhav.push("12");
      chartData["12"].planetsOccurredInLagnaChart.push(planet);
    }

    if (planetData.inHouse.length === 0) {
      planetData.inHouse = planetData.inBhav;
    }
    return null;
  });

const flatFilterPlanet = (arr) =>
  arr.flat().filter((planet) => planet.length > 0);

const kpPlanetSignificators = (planet, chartData) => {
  const inBhav = chartData[planet].inBhav.flat();
  const ownerOf = chartData[planet].ownerOf.flat();

  return [...flatFilterPlanet(inBhav), ...flatFilterPlanet(ownerOf)];
};

const assignKPSignificatorOf = (chartData) =>
  planetShortNames.map((planet) => {
    const planetData = chartData[planet];
    planetData.kpSignificatorOf.push(kpPlanetSignificators(planet, chartData));

    if (
      planet.toLocaleLowerCase() === "ra" ||
      planet.toLocaleLowerCase() === "ke"
    ) {
      // 1) RL (Lagna Chart)
      planetData.kpSignificatorOf.push(
        kpPlanetSignificators(planetData.rl, chartData)
      );

      // 2) Con. Planet (Lagna Chart)
      planetData.kpSignificatorOf.push(
        flatFilterPlanet(
          planetData.conjunctsWith.map((planet) =>
            kpPlanetSignificators(planet, chartData)
          )
        )
      );

      // 3) Planet Aspecting on Rahu (Lagna Chart)
      planetData.kpSignificatorOf.push(
        flatFilterPlanet(
          planetData.aspectedInLagnaBy.map((planet) =>
            kpPlanetSignificators(planet, chartData)
          )
        )
      );

      // 4) House itself sitting (Bhav Chart)
      planetData.kpSignificatorOf.push(planetData.inBhav);
    }

    chartData[planet].kpSignificatorOf = [
      ...new Set(
        planetData.kpSignificatorOf.flat().filter((planet) => planet.length > 0)
      ),
    ];

    return null;
  });

const assignConjunctsWith = (chartData) =>
  planetShortNames.map((planet, index) => {
    planetShortNames.map((nextPlanet, nextIndex) => {
      if (index === nextIndex) {
        return null;
      }
      const planetDegree = parseFloat(chartData[planet].parsedDegrees);
      const nextPlanetDegree = parseFloat(chartData[nextPlanet].parsedDegrees);
      if (
        planetDegree > nextPlanetDegree - 7 &&
        planetDegree < nextPlanetDegree + 7
      ) {
        chartData[planet].conjunctsWith.push(nextPlanet);
      }
      return null;
    });
    return null;
  });

const assignAspectedInLagnaBy = (chartData) =>
  planetShortNames.map((planet, index) => {
    const planetData = chartData[planet];
    const planetInHouse = parseInt(planetData.inHouse[0]);

    planetShortNames.map((nextPlanet, nextIndex) => {
      if (index === nextIndex) {
        return null;
      }
      if (["Ura", "Nep", "Plu"].includes(nextPlanet)) {
        return null;
      }

      const nextPlanetData = chartData[nextPlanet];

      // 7th aspect in Lagna Chart
      const nextPlanetInhouse = parseInt(nextPlanetData.inHouse[0]);
      const planetDegree7thAspected = (planetInHouse + 6) % 12;
      if (nextPlanetInhouse === planetDegree7thAspected) {
        planetData.aspectedInLagnaBy.push(nextPlanet);
      }

      switch (nextPlanet) {
        case "Ma":
          // 4, 8
          const marfourthAspect = (nextPlanetInhouse + 3) % 12;
          const marEighthAspect = (nextPlanetInhouse + 7) % 12;

          if (marfourthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (marEighthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        case "Ju":
        case "Ra":
        case "Ke":
          // 5, 9
          const jupfifthAspect = (nextPlanetInhouse + 4) % 12;
          const jupNinethAspect = (nextPlanetInhouse + 8) % 12;

          if (jupfifthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (jupNinethAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        case "Sa":
          // 3, 10
          const satThirdAspect = (nextPlanetInhouse + 2) % 12;
          const satTenthAspect = (nextPlanetInhouse + 9) % 12;

          if (satThirdAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (satTenthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        default:
          break;
      }
      return null;
    });
    return null;
  });

const isIndependentHouse = (chartData, untenantedPlanet, house) => {
  let returnVal = false;
  const owns = chartData[untenantedPlanet].ownerOf;
  if (
    owns.includes(house) &&
    chartData[house].planetsOccurredInBhavChart.length === 0
  ) {
    returnVal = true;
  }

  return returnVal;
};

const planetHouseScript = (
  chartData,
  untenantedPlanet,
  kpSignificatorHouses
) => {
  return kpSignificatorHouses.map((house) =>
    isIndependentHouse(chartData, untenantedPlanet, house)
      ? `(${house})`
      : house
  );
};

const planetCSLOf = (chartData, planet) =>
  houseNames
    .map((house) => (chartData[house].sl === planet ? house : null))
    .filter((house) => house?.length > 0);

const kpSignificatorsOfPlanets = (chartData) => {
  const returnObj = {};
  planetShortNames.map((planet) => {
    const { kpSignificatorOf, nl, sl, untenanted } = chartData[planet];
    const subLord = {
      name: sl,
      ...chartData[sl],
    };
    const nlOfSl = {
      name: chartData[sl].nl,
      ...chartData[chartData[sl].nl],
    };

    const planetKpSignificatorOf = flatFilterPlanet(
      kpSignificatorOf.map((house) => house)
    );
    const nlKpSignificatorOf = flatFilterPlanet(
      chartData[nl].kpSignificatorOf.map((house) => house)
    );
    const slKpSignificatorOf = flatFilterPlanet(
      subLord.kpSignificatorOf.map((house) => house)
    );
    const nlOfSlKpSignificatorOf = flatFilterPlanet(
      nlOfSl.kpSignificatorOf.map((house) => house)
    );

    returnObj[planet] = {
      planet: {
        name: planet,
        untenanted: untenanted,
        basicSignificators: untenanted
          ? planetHouseScript(chartData, planet, [
              ...new Set(planetKpSignificatorOf),
            ])
          : [...new Set(planetKpSignificatorOf)],
        utCSLOf: untenanted
          ? planetCSLOf(chartData, planet).length > 0
            ? " {" + planetCSLOf(chartData, planet) + "}"
            : ""
          : planetCSLOf(chartData, nl).length > 0
          ? " <" + planetCSLOf(chartData, nl) + ">"
          : "",
      },
      nl: {
        name: nl,
        basicSignificators: nlKpSignificatorOf,
      },
      sl: {
        name: subLord.name,
        basicSignificators: subLord.untenanted
        ? planetHouseScript(chartData, subLord.name, [
            ...new Set(slKpSignificatorOf),
          ])
        : [...new Set(slKpSignificatorOf)],
      },
      nlOfSl: {
        name: nlOfSl.name,
        basicSignificators: nlOfSlKpSignificatorOf,
      },
    };
    return null;
  });
  return returnObj;
};

const analyzeJHoraChartData = (chartData) => {
  if (chartData) {
    const parsedChartData = parseChartData(chartData);

    parsedChartData.nLofAllPlanets = nLordOfAllPlanets(parsedChartData);
    parsedChartData.untenantedPlanets = untenantedPlanets(parsedChartData);
    parsedChartData.nirayanaCusps = nirayanaCusps(parsedChartData);
    parsedChartData.planetaryPositions = planetaryPosition(parsedChartData);

    const kpSignificatorsHouses = {};
    const kpSignificatorsHousesTable = {};
    assignPlanetOwnerOf(parsedChartData);
    assignPlanetNLOf(parsedChartData);
    assignPlanetToHouse(parsedChartData);
    assignConjunctsWith(parsedChartData);
    assignAspectedInLagnaBy(parsedChartData);
    assignKPSignificatorOf(parsedChartData);

    houseNames.map((house) => {
      const entity = parsedChartData[house];
      const houseLord = rashis[entity.sign]?.ownerShortName;
      const planetsInBhavChart = entity.planetsInBhavChart;

      const occupiedPlanetsAsNLofPlanets = parsedChartData[
        house
      ].planetsOccurredInBhavChart
        .map((planet) => parsedChartData[planet].nakshatraLordOf)
        .flat()
        .filter((planet) => planet.length > 0);

      kpSignificatorsHouses[house] = {
        d: {
          name: "Owner",
          planet: parsedChartData[house].rl,
        },
        c: {
          name: "NL Planets",
          planets: parsedChartData[parsedChartData[house].rl].nakshatraLordOf, //houseLordAsNLofPlanets,
        },
        b: {
          name: "Planets In House",
          planets: parsedChartData[house].planetsOccurredInBhavChart,
        },
        a: {
          name: "Planets In House As NL of Planets",
          planets: occupiedPlanetsAsNLofPlanets,
        },
        i: {
          name: "Independent House",
          isIndependent:
            parsedChartData[houseLord].untenanted &&
            planetsInBhavChart.length === 0
              ? true
              : "-",
        },
      };
      kpSignificatorsHousesTable[house] = [
        occupiedPlanetsAsNLofPlanets.join(", "),
        parsedChartData[house].planetsOccurredInBhavChart.join(", "),
        parsedChartData[parsedChartData[house].rl].nakshatraLordOf.join(", "),
        parsedChartData[house].rl,
      ];
      return null;
    });

    const planetKpSignificators = kpSignificatorsOfPlanets(parsedChartData);

    const kpSigABCD = houseNames.map((house) => ({
      House: house,
      A: kpSignificatorsHouses[house]?.a?.planets.length
        ? kpSignificatorsHouses[house]?.a?.planets.join(", ")
        : "-",
      B: kpSignificatorsHouses[house]?.b?.planets.length
        ? kpSignificatorsHouses[house]?.b?.planets.join(", ")
        : "-",
      C: kpSignificatorsHouses[house]?.c?.planets.length
        ? kpSignificatorsHouses[house]?.c?.planets.join(", ")
        : "-",
      D: kpSignificatorsHouses[house]?.d?.planet,
      Independent: kpSignificatorsHouses[house]?.i?.isIndependent,
    }));

    return {
      chartData: parsedChartData,
      kpSigABCD,
      kpSignificatorsHouses,
      kpSignificatorsHousesTable,
      kpSignificatorsPlanets: planetKpSignificators,
      untenantedPlanets: parsedChartData.untenantedPlanets,
    };
  }
};

export { analyzeJHoraChartData };
