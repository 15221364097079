import React, { useContext } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { NakshatraContext } from "../../contexts/nakshatra.context";

const UntenantedPlanets = ({chartType = "jhora"}) => {
  const {
    state: {
      jHoraChart: { jHoraChartData } = {},
      horosoftChart: { horosoftChartData } = {},
    },
  } = useContext(NakshatraContext);

  let untenantedPlanets = null;
  if (chartType === "jhora") {
    untenantedPlanets = jHoraChartData?.untenantedPlanets;
  } else if (chartType === "horosoft") {
    untenantedPlanets = horosoftChartData?.untenantedPlanets;
  }

  return untenantedPlanets ? (
    <Table>
      <Thead>
        <Tr>
          <Th>Planet</Th>
          <Th>Untenented</Th>
        </Tr>
      </Thead>
      <Tbody>
        {untenantedPlanets?.map(
          (
            {
              hasExchangeOfNakshatra,
              inItsOwnNakshatra,
              notNLofAnyPlanets,
              planet,
            } = {
              hasExchangeOfNakshatra: false,
              inItsOwnNakshatra: false,
              notNLofAnyPlanets: false,
              planet: "",
            }
          ) => {
            return (
              planet && (
                <Tr key={planet}>
                  <Td>{planet.toString()}</Td>
                  <Td>
                    {(
                      hasExchangeOfNakshatra ||
                      inItsOwnNakshatra ||
                      notNLofAnyPlanets
                    ).toString()}
                  </Td>
                </Tr>
              )
            );
          }
        )}
      </Tbody>
    </Table>
  ) : null;
}

export default UntenantedPlanets
