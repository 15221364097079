import React, { useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { NakshatraContext } from "../../contexts/nakshatra.context";

const planetShortNames = [
  "Ket",
  "Ven",
  "Sun",
  "Mon",
  "Mar",
  "Rah",
  "Jup",
  "Sat",
  "Mer",
  "Ke",
  "Ve",
  "Su",
  "Mo",
  "Ma",
  "Ra",
  "Ju",
  "Sa",
  "Me",
];

const PlanetaryPosition = ({ chartType = "jhora" }) => {
  let chartData = null;
  const {
    state: {
      jHoraChart: { jHoraChartData } = {},
      horosoftChart: { horosoftChartData } = {},
    },
  } = useContext(NakshatraContext);

  if (chartType === "jhora") {
    chartData = jHoraChartData?.chartData;
  } else if (chartType === "horosoft") {
    chartData = horosoftChartData?.chartData;
  }

  return (
    chartData && (
      <div>
        Planetary Position
        <Table>
          <Thead>
            <Tr>
              <Th>Planet</Th>
              <Th>Sign</Th>
              <Th>Degrees</Th>
              <Th>RL</Th>
              <Th>NL</Th>
              <Th>SL</Th>
              <Th>SSL</Th>
            </Tr>
          </Thead>
          <Tbody>
            {planetShortNames.map(
              (planet) =>
                chartData[planet] && (
                  <Tr key={planet}>
                    <Td>
                      {planet} {chartData[planet].retrograde ? "(R)" : ""}
                    </Td>
                    <Td>{chartData[planet].sign}</Td>
                    <Td>{`${
                      chartData[planet].degree?.degrees ||
                      chartData[planet].degrees
                    }:${
                      chartData[planet].degree?.minutes ||
                      chartData[planet].minutes
                    }'`}</Td>
                    <Td>{chartData[planet].rl}</Td>
                    <Td>{chartData[planet].nl}</Td>
                    <Td>{chartData[planet].sl}</Td>
                    <Td>{chartData[planet].ssl}</Td>
                  </Tr>
                )
            )}
          </Tbody>
        </Table>
      </div>
    )
  );
};

export default PlanetaryPosition;
