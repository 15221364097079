import React from "react";

import { capitalize } from "../../engine/helper";

const nakshatraName = (nakshatra) => {
  const firstThree = nakshatra.slice(0, 3);
  let returnString;

  switch (firstThree) {
    case "Pur":
      returnString = `${nakshatra.slice(0, 1)}-${nakshatra.slice(
        5,
        9
      )} ${nakshatra.slice(-1)}`;
      break;
    case "Utt":
      returnString = `${nakshatra.slice(0, 1)}-${nakshatra.slice(
        6,
        10
      )} ${nakshatra.slice(-1)}`;
      break;
    default:
      returnString = `${nakshatra.slice(0, 4)} ${nakshatra.slice(-1)}`;
      break;
  }

  return returnString;
};

const drawNakshatraWithPadasNPlanets = ({
  mNak,
  indx,
  selectedNakCoordinates,
  showNakshatraPadas,
}) => {
  return (
    <tspan
      key={mNak.id}
      x={selectedNakCoordinates.x + "%"}
      y={selectedNakCoordinates.y + indx * 2 + "%"}
    >
      {showNakshatraPadas ? nakshatraName(mNak["nk-pada"]) : ""}
    </tspan>
  );
};

const DrawSelectedHousePlanets = ({
  planet,
  direction,
  index,
  selectedPlanetCoordinates,
}) => {
  return (
    <tspan
      key={`${direction}-${index}`}
      x={selectedPlanetCoordinates.x + "%"}
      y={selectedPlanetCoordinates.y + index * 4 + "%"}
    >
      {capitalize(planet.slice(0, 2))}
    </tspan>
  );
};

export const selectedGrahaCoordinates = (grahs) => {
  let grahaCoordinates;

  switch (grahs) {
    case "ghaar-1":
      grahaCoordinates = { x: 46, y: 10 };
      break;
    case "ghaar-2":
      grahaCoordinates = { x: 20, y: 5 };
      break;
    case "ghaar-3":
      grahaCoordinates = { x: 2, y: 15 };
      break;
    case "ghaar-4":
      grahaCoordinates = { x: 20, y: 40 };
      break;
    case "ghaar-5":
      grahaCoordinates = { x: 2, y: 60 };
      break;
    case "ghaar-6":
      grahaCoordinates = { x: 20, y: 82 };
      break;
    case "ghaar-7":
      grahaCoordinates = { x: 46, y: 62 };
      break;
    case "ghaar-8":
      grahaCoordinates = { x: 71, y: 82 };
      break;
    case "ghaar-9":
      grahaCoordinates = { x: 87, y: 68 };
      break;
    case "ghaar-10":
      grahaCoordinates = { x: 70, y: 40 };
      break;
    case "ghaar-11":
      grahaCoordinates = { x: 87, y: 17 };
      break;
    case "ghaar-12":
      grahaCoordinates = { x: 71, y: 3 };
      break;
    default:
      // statements_def
      break;
  }
  return grahaCoordinates;
};

export const placeNakshatras = (
  nakshatraArray,
  coordinates,
  direction,
  showNakshatraPadas,
  selectedHousePlanets
) =>
  nakshatraArray.map((mNak, indx) => {
    return (
      <React.Fragment key={`${direction}-${indx}`}>
        {drawNakshatraWithPadasNPlanets({
          direction,
          mNak,
          indx,
          coordinates,
          selectedNakCoordinates: selectedGrahaCoordinates(direction),
          showNakshatraPadas,
        })}
        {selectedHousePlanets.map((planet, indx) => (
          <DrawSelectedHousePlanets
            key={`${direction}-${planet}-${indx}`}
            planet={planet}
            direction={direction}
            index={indx}
            selectedPlanetCoordinates={selectedGrahaCoordinates(direction)}
          />
        ))}
      </React.Fragment>
    );
  });
