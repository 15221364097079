import React from "react";
import styled from "styled-components";

import { selectedGrahaCoordinates } from "./helper";

const grahas = require("../../../data/grahas");

const grahaMapping = {
  ke: 0,
  ve: 1,
  su: 2,
  mo: 3,
  ma: 4,
  ra: 5,
  ju: 6,
  sa: 7,
  me: 8,
};

const houseNumberNameMapping = {
  1: "firstHouse",
  2: "secondHouse",
  3: "thirdHouse",
  4: "fourthHouse",
  5: "fifthHouse",
  6: "sixthHouse",
  7: "seventhHouse",
  8: "eighthHouse",
  9: "ninethHouse",
  10: "tenthHouse",
  11: "eleventhHouse",
  12: "twelvethHouse",
};

const ChartNorthSvgLine = styled.line`
  color: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  stroke: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  fill: ${({ darkModeValue }) => (darkModeValue ? "#1E1F1A" : "#fff")};
  fill-opacity: 1;
  stroke-width: 1;
  pointer-events: auto;
`;

const PlanetAspect = ({
  grahaKey,
  darkModeValue,
  selectedPlanets,
  renderIndex,
  twelveHouses,
}) => {
  if (!selectedPlanets || selectedPlanets.length === 0) {
    return null;
  }

  if (
    !selectedPlanets
      .flatMap((planet) => ["uranus", "neptune"].includes(planet) && null)
      .join("")
  )
    return null;

  const selectedGraha = selectedGrahaCoordinates(grahaKey);

  const planetAspects = selectedPlanets.flatMap((planet) => {
    return grahas[grahaMapping[planet.slice(0, 2).toLowerCase()]].aspects
      .split(",")
      .flatMap((aspect) => {
        const indexInitialCalculation = renderIndex + parseInt(aspect);
        const mappingIndex =
          indexInitialCalculation > 12
            ? indexInitialCalculation % 12
            : indexInitialCalculation;
        return twelveHouses[
          `${houseNumberNameMapping[mappingIndex]}MiddleTextCoordinates`
        ];
      });
  });
  return (
    planetAspects.length > 0 &&
    planetAspects.map((planetAspect, idz) => (
      <>
        <ChartNorthSvgLine
          key={`${grahaKey}-${idz}`}
          darkModeValue={darkModeValue}
          x1={selectedGraha.x + "%"}
          y1={selectedGraha.y + "%"}
          x2={planetAspect.x}
          y2={planetAspect.y}
          markerEnd="url(#head)"
          strokeWidth="2"
        />
        <defs>
          <marker
            id="head"
            orient="auto"
            markerWidth="2"
            markerHeight="4"
            refX="0.1"
            refY="2"
          >
            <path d="M0,0 V4 L4,4 Z" fill={darkModeValue ? "#999" : "#000"} />
          </marker>
        </defs>
      </>
    ))
  );
};

export default PlanetAspect;
