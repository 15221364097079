module.exports = Object.freeze({
  4: {
    position: `1x1`,
    arrayPosition: 1,
    baseNumber: 4,
    planes: [`mental`, `thought`, `luck`],
    elements: [`Wood`],
    entries: [],
    backgroundColor: '#090a0c',
    color: '#FFF1CC',
  },
  9: {
    position: `1x2`,
    arrayPosition: 2,
    baseNumber: 9,
    planes: [`mental`, `will`],
    elements: [`Fire`],
    entries: [],
    backgroundColor: '#ffa500',
    color: '#7A2C00',
  },
  2: {
    position: `1x3`,
    arrayPosition: 3,
    baseNumber: 2,
    planes: [`mental`, `action`, `property`],
    elements: [`Earth`],
    entries: [],
    backgroundColor: '#1dcecf',
    color: '#7A2C00',
  },
  3: {
    position: `2x1`,
    arrayPosition: 4,
    baseNumber: 3,
    planes: [`emotional`, `thought`],
    elements: [`Wood`],
    entries: [],
    backgroundColor: '#ffd700',
    color: '#7A2C00',
  },
  5: {
    position: `2x2`,
    arrayPosition: 5,
    baseNumber: 5,
    planes: [`emotional`, `will`, `luck`, `property`],
    elements: [`Earth`],
    entries: [],
    backgroundColor: '#005500',
    color: '#FFF1CC',
  },
  7: {
    position: `2x3`,
    arrayPosition: 6,
    baseNumber: 7,
    planes: [`emotional`, `action`],
    elements: [`Metal`, `Silver`],
    entries: [],
    backgroundColor: '#C0C0C0',
    color: '#7A2C00',
  },
  8: {
    position: `3x1`,
    arrayPosition: 7,
    baseNumber: 8,
    planes: [`practical`, `thought`, `property`],
    elements: [`Earth`],
    entries: [],
    backgroundColor: '#87ceff',
    color: '#7A2C00',
  },
  1: {
    position: `3x2`,
    arrayPosition: 8,
    baseNumber: 1,
    planes: [`practical`, `will`],
    elements: [`Water`],
    entries: [],
    backgroundColor: '#ff0000',
    color: '#FFF1CC',
  },
  6: {
    position: `3x3`,
    arrayPosition: 9,
    baseNumber: 6,
    planes: [`practical`, `action`, `luck`],
    elements: [`Metal`, `Gold`],
    entries: [],
    backgroundColor: '#ffffff',
    color: '#7A2C00',
  },
});
