import React, { useContext, useState } from "react";
import { Button, TextArea } from "semantic-ui-react";

import { NakshatraContext } from "../../contexts/nakshatra.context";

import UntenantedPlanets from '../UntenantedPlanets';
import { KPSignificatorsPlanets } from "../KPSignificatorsPlanets";
import KPSignificatorsCuspsView from "../KPSignificatorsCuspsView";
import CuspChart from "../CuspChart";
import PlanetaryPosition from "../PlanetaryPosition";

const Horosoft = () => {
  const [chartData, setChartData] = useState("");
  const textAreaChanged = (event, { value }) => setChartData(value);
  const {
    dispatch,
  } = useContext(NakshatraContext);


  const analyze = () => {
    if (chartData !== "" && chartData !== " ") {
      dispatch({
        type: `ANALYZE_HOROSOFT_CHART`,
        payload: {
          chartData,
        },
      });
    }
  };

  return (
    <div>
      Horosoft Calculations
      <TextArea
        placeholder="Paste Horosoft Nirayana Cusps and Planetary Position chart data"
        style={{
          width: "100%",
          minHeight: 100,
        }}
        onChange={textAreaChanged}
      />
      <Button type="submit" className="btn btn-primary" onClick={analyze}>
        Analyze
      </Button>
      <KPSignificatorsPlanets chartType={"horosoft"} />
      <KPSignificatorsCuspsView chartType={"horosoft"} />
      <UntenantedPlanets chartType={"horosoft"} />
      <CuspChart chartType={"horosoft"} />
      <PlanetaryPosition chartType={"horosoft"} />
    </div>
  );
};

export default Horosoft;
