import React, { useContext, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { Dropdown, Input, Label } from 'semantic-ui-react'
import NakshatraRow from './NakshatraRow'
import { NakshatraContext } from './../contexts/nakshatra.context'
import { types } from './../contexts/reducers'

import rashis from './../../data/rashis'

const signOptions = () => {
  return rashis.map((sign, indx) => {
    return {
      key: sign.id,
      value: sign.id,
      text: `${sign.name} (${sign.vedicName})`
    }
  })
}

const SignPicker = (props) => {
  const [signDegrees, setSignDegrees] = useState('');
  const [signIndex, setSignIndex] = useState(-1);
  const [selectedSign, setSelectedSign] = useState('Click here to Select Sign');
  const [renderNakshatraRow, setRenderNakshatraRow] = useState(false);
  const { dispatch } = useContext(NakshatraContext);
  const forProp = props.for.split(' ');

  useEffect(() => {
    dispatch({
      type: types.GRAHAS,
      payload: {
        key: forProp[0],
        value: {
          name: forProp[1],
          vedicName: forProp[2]
        }
      }
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(
    () => {
      const degreesArray = signDegrees.replace(/_/g, '').replace(/°/, '').replace(/'/, '').replace(/"/, '').split(' ')
      if (degreesArray.length > 1) {
        if (degreesArray.filter(degree => parseInt(degree)).length > 2 && signIndex > -1) {
          setRenderNakshatraRow(true)
        }
      }
    },
    [signDegrees, signIndex]
  )

  const handleDegreeChange = (event) => {
    setSignDegrees(event.target.value)
  }

  const handleDropdownChange = (e, data) => {
    setSignIndex(data.value - 1)
    setSelectedSign(`${rashis[data.value - 1].name} (${rashis[data.value - 1].vedicName})`)
  }

  return (
    <React.Fragment>
      <Label as='a' color='brown' tag>{props.for || 'Sign'}</Label>
      <InputMask {...props} mask="99° 99' 99" maskChar={'_'} value={signDegrees} onChange={handleDegreeChange} >
        {() =>
          <Input
            size={'mini'}
            action={
              <Dropdown
                fluid
                search
                labeled
                text={selectedSign}
                options={signOptions()}
                onChange={handleDropdownChange}
              />
            }
          />
        }
      </InputMask>
      {renderNakshatraRow && <NakshatraRow for={`${props.for}`} signDegrees={signDegrees} signIndex={signIndex} />}
    </React.Fragment>
  )
}

export default SignPicker
