export const lightTheme = {
  body: '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
  background: '#eef4f7',
  backgroundSelected: '#dfdfdf',
  color: '#10171d',
  chartLine: '#000',
  tableTrAlternate: '#FFFFFF',
};

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#1E1F1A',
  backgroundSelected: '#54544c',
  color: '#eef4f7',
  colorSelected: '#dfdfdf',
  navBgColor: '#1b2938',
  chartLine: '#999',
  tableTrAlternate: '#232323',
};
