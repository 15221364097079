import React, {
  useEffect,
} from 'react';
import ChartDetails from './ChartDetails'
import PropTypes from 'prop-types'

const Chart = () => {
  useEffect(() => {
    document.title = 'Chart'
  }, []);

  return (
    <React.Fragment>
      <ChartDetails />
    </React.Fragment>
  )
};

Chart.propTypes = {
  day: PropTypes.number,
  month: PropTypes.number,
  year: PropTypes.number,
  hour24: PropTypes.number,
  minute: PropTypes.number,
  timeZoneHour: PropTypes.number,
  timeZoneMinute: PropTypes.number
}

export default Chart
