import React from "react";
import { Container, Header, Message, Segment } from "semantic-ui-react";

const KPRulesFramework = () => {
  return (
    <Container style={{ margin: 20 }}>
      <Header as="h3">Framework for successful profession</Header>
      <Segment>
        <Message floating>
          <Message.Header>Very important 1 & 2</Message.Header>
          <ol>
            <li>Which profession? (MANDATORY)</li>
            <ol type="a">
              <li>Which house lord is sitting in 10th House?</li>
              <li>10th Lord is sitting in which House?</li>
              <li>10th Lord is Conjuction with which House lord?</li>
            </ol>
            <li>Where Money comes from? (MANDATORY)</li>
            <ol type="a">
              <li>Which house lord is sitting in 2nd House?</li>
              <li>2nd Lord is sitting in which House?</li>
              <li>2nd Lord is Conjuction with which House lord?</li>
            </ol>
          </ol>
          <ul>
            <li>Which house give Gain / Labh / Wish fullfilment?</li>
              <ul>
                <li>Which house lord is sitting in 11th House?</li>
                <li>11th Lord is sitting in which House?</li>
                <li>11th Lord is Conjuction with which House lord?</li>
              </ul>
          </ul>
          <ul>
            <li>Indicators</li>
              <ul>
                <li>What service will be provided by the native?</li>
                <li>Job in which field?</li>
                <ul>
                  <li>House Lord sitting in 6th House?</li>
                  <li>6th Lord is sitting in which House?</li>
                  <li>6th Lord is Conjuction with which House lord?</li>
                </ul>
                <li>Business of what?</li>
                <li>How will get Clients and where will clients come from?</li>
                <ul>
                  <li>House Lord sitting in 7th House?</li>
                  <li>7th Lord is sitting in which House?</li>
                  <li>7th Lord is Conjuction with which House lord?</li>
                </ul>
              </ul>
          </ul>
          <ul>
            <li>10th CSL Script (VERY IMPORTANT)</li>
            <ul>
              <li>Planet - :</li>
              <li>NL - :</li>
              <li>10th House Connections with houses:</li>
              <li>2nd House Connections with houses:</li>
            </ul>
          </ul>
        </Message>
      </Segment>
    </Container>
  );
};

export default KPRulesFramework;
