module.exports = Object.freeze({
  jHoraSeq: [`Chan`, `Sury`, `Guru`, `Mang`, `Budh`, `Sani`, `Sukr`, `Rah`],
  jHoraSeqEng: [`Moon`, `Sun`, `Jup`, `Mars`, `Merc`, `Sat`, `Ven`, `Rah`],
  oldDashaSeq: [
    `moon`,
    `sun`,
    `jupiter`,
    `mars`,
    `mercury`,
    `saturn`,
    `venus`,
    `rahu`,
    `ketu`,
  ],
  newDashaSeq: [
    `mars`,
    `venus`,
    `mercury`,
    `moon`,
    `sun`,
    `saturn`,
    `jupiter`,
    `rahu`,
    `ketu`,
  ],
  yoginis: {
    mars: {
      name: `mangla`,
      oldLord: `moon`,
      newLord: `mars`,
      dashaPeriodInYears: 1,
      energyInHouseItsPlacedFrom: 1,
      events: [`Blood bath`, `Blood and sweat`],
      shortDescription: [`New start`, `new beginning`, `red`, `sos`, `blood`],
    },
    venus: {
      name: `pingla`,
      oldLord: `sun`,
      newLord: `venus`,
      dashaPeriodInYears: 2,
      energyInHouseItsPlacedFrom: 12,
      events: [
        `12th from its position`,
        `Leave things in 12 house from its placed by will or it will be snatched (helplessness)`,
      ],
      shortDescription: [`Resources`],
    },
    mercury: {
      name: `dhanya`,
      oldLord: `jupiter`,
      newLord: `mercury`,
      dashaPeriodInYears: 3,
      energyInHouseItsPlacedFrom: 3,
      events: [
        `3rd from its position`,
        `takes skillset to next level / forward`,
        `child's / kid's energy`,
        `if bad Mercury depends on other (3rd hs thing) and Frustrated when not helped`,
        `good mercury gives help like helping kid`,
      ],
      shortDescription: [`Dhanya`, `kids`, `Prosperity`, `favors`],
    },
    moon: {
      name: `bhramari`,
      oldLord: `mars`,
      newLord: `moon`,
      dashaPeriodInYears: 4,
      energyInHouseItsPlacedFrom: 4,
      events: [
        `Peace`,
        `सकन`,
        `Change of place for peace`,
        `mood swings`,
        `leave home`,
      ],
      shortDescription: [
        `Bhraman`,
        `keep moving`,
        `chalate rahana`,
        `Travels`,
        `displacement Abroad`,
      ],
    },
    sun: {
      name: `bhadrika`,
      oldLord: `mercury`,
      newLord: `sun`,
      dashaPeriodInYears: 5,
      energyInHouseItsPlacedFrom: 5,
      events: [`Govt.`, `I should get fame`, `Fame`, `Position`],
      shortDescription: [`Learnings`],
    },
    saturn: {
      name: `ulka`,
      oldLord: `saturn`,
      newLord: `saturn`,
      dashaPeriodInYears: 6,
      energyInHouseItsPlacedFrom: 6,
      events: [
        `Challenges to grow`,
        `Obstacles`,
        `Magical - may have to loan as per 6th place house`,
      ],
      shortDescription: [`Challenges`, `Disputes`, `high efforts`, `mehanat`],
    },
    jupiter: {
      name: `siddha`,
      oldLord: `venus`,
      newLord: `jupiter`,
      dashaPeriodInYears: 7,
      energyInHouseItsPlacedFrom: 7,
      events: [`Completion (सकन)`],
      shortDescription: [`Sadhak (in Journey)`, `Siddha (realized)`, `Guru`],
    },
    rahu: {
      name: `sankata`,
      oldLord: `rahu`,
      newLord: `rahu`,
      dashaPeriodInYears: 8,
      energyInHouseItsPlacedFrom: 8,
      events: [
        'fear because of big change(s) in life',
        `we dont like because as we age we dont like changes`,
      ],
      shortDescription: [`Rahu (first 4 years)`, 'Ketu (last 4 years)'],
    },
    ketu: {
      name: `sankata`,
      oldLord: `ketu`,
      newLord: `ketu`,
      dashaPeriodInYears: 8,
      energyInHouseItsPlacedFrom: 8,
      events: [],
      shortDescription: [`Rahu (first 4 years)`, 'Ketu (last 4 years)'],
    },
  },
  divisionalCharts: {
    dashamsha: {
      1: [
        `[swami's / posited planets] [dasha / anter] - major kar  m time, freewill`,
        `02.class - 1:00:00`,
      ],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [
        `[swami's / posited planets] [dasha / anter] - challenges / ragadegi / heavy work / mehanat`,
        `02.class - 1:00:50`,
      ],
      7: [],
      8: [
        `[swami's / posited planets] [dasha / anter] - end of profession / karm - new type of job / technology / department`,
        `02.class - 1:00:50`,
      ],
      9: [],
      10: [],
      11: [],
      12: [
        `[swami's / posited planets] [dasha / anter] / suspension in profession`,
        `02.class - 1:00:30`,
      ],
    },
  },
});
