const DEGS = Math.PI / 180;
const RADS = 180 / Math.PI;

const zn = [
  "Mesha",
  "Vrushabha",
  "Mithuna",
  "Karkataka",
  "Simha",
  "Kanya",
  "Tula",
  "Vrushchika",
  "Dhanu",
  "Makara",
  "Kumbha",
  "Meena",
];
const wd = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const range = [1, 31, 0, 0, -3000, 4000, 0, 23, 0, 59, -12, 12, 0, 59];
const naks = [
  "Ashwini",
  "Bharani",
  "Kruthika",
  "Rohini",
  "Mrugasira",
  "Aarudra",
  "Punarwasu",
  "Pushyami",
  "Aslesha",
  "Makha",
  "Pubha",
  "Uttara",
  "Hasta",
  "Chitta",
  "Swati",
  "Visakha",
  "Anuradha",
  "Jyesta",
  "Mula",
  "Purva-Shada",
  "Uttara-Shaada",
  "Sravanam",
  "Dhanishta",
  "Satabhisham",
  "Purva-Bhadra",
  "Uttara-Bhadra",
  "Revathi",
];
const tith = [
  "Pratipada",
  "Dwitiya",
  "Tritiya",
  "Chaturthi",
  "Panchami",
  "Shashthi",
  "Saptami",
  "Ashtami",
  "Navami",
  "Dashami",
  "Ekadashi",
  "Dwadashi",
  "Trayodashi",
  "Chaturdashi",
  "Purnima",
  "Pratipada",
  "Dwitiya",
  "Tritiya",
  "Chaturthi",
  "Panchami",
  "Shashthi",
  "Saptami",
  "Ashtami",
  "Navami",
  "Dashami",
  "Ekadashi",
  "Dwadashi",
  "Trayodashi",
  "Chaturdashi",
  "Amavasya",
];
const kar = [
  "Bawa",
  "Balava",
  "Kaulava",
  "Taitula",
  "Garaja",
  "Vanija",
  "Vishti",
  "Sakuna",
  "Chatushpada",
  "Nagava",
  "Kimstughana",
];
const yog = [
  "Vishkumbha",
  "Preeti",
  "Ayushman",
  "Saubhagya",
  "Shobhana",
  "Atiganda",
  "Sukarman",
  "Dhrithi",
  "Soola",
  "Ganda",
  "Vridhi",
  "Dhruva",
  "Vyaghata",
  "Harshana",
  "Vajra",
  "Siddhi",
  "Vyatipata",
  "Variyan",
  "Parigha",
  "Siva",
  "Siddha",
  "Sadhya",
  "Subha",
  "Sukla",
  "Bramha",
  "Indra",
  "Vaidhruthi",
];
const tipnaks = [
  2, 5, 6, 0, 1, 4, 3, 2, 4, 5, 5, 0, 2, 1, 3, 6, 1, 4, 4, 5, 0, 3, 3, 3, 5, 0,
  1,
];

const rashi = [
  "Mesha",
  "Vrishabha",
  "Mithuna",
  "Karka",
  "Simha",
  "Kanya",
  "Tula",
  "Vrischika",
  "Dhanu",
  "Makara",
  "Kumbha",
  "Meena",
];

const REV = (x) => x - Math.floor(x / 360.0) * 360.0;

module.exports = {
  DEGS,
  kar,
  naks,
  RADS,
  range,
  rashi,
  REV,
  tipnaks,
  tith,
  wd,
  yog,
  zn,
};
