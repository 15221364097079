module.exports = Object.freeze([
  {
    id: 1,
    graha: "Ketu (केतू)",
    name: `ketu`,
    vimshottariDashaYears: 7,
    uchhaRas: "Gemini (मिथुन)",
    nicchaRas: "Sagittarius (धनु)",
    swaGraha: "-",
    color: `Earth colors, brown (विचित्र), black & white, gray, multicolor`,
    direction: `Up, to Sky`,
    directionalStrengthIn: `South (दक्षिण)`,
    directionRoom: ``,
    aspects: "5,7,9",
    characteristics: `Turning Away from Enjoyment (Virakti) and Pleasures, Delays, Sleep, Destruction, Dreams, Knowledge, Liveliness, Salvation, Maternal Grandmother, Paternal Grandmother, Renunciation, Blind Devotion, Wearing Beaded Garlands, Deceitful Behaviour, Frightening Others, Pilgrimages, Lust, Quarrels, Separation, Untidiness, Lunacy, Knowledge of Omens, Salvation.`,
    karakatva: `Worship of the lord of Chandi, Ganesha and others, Medical practitioner, Dogs, Cocks, Vultures, Final salvation, All sorts of prosperity, Consumption, Painful fevers, Bath in the Ganges, Great penance, Wind complaints, Friendship with hunters, Acquiring prosperity, Stones, Wounds, Mantra Shastra, Instability of mind, Knowledge of Brahmn, Diseases of the stomach and eye, Stupidity, Thorn, Knowledge of the animals, zoology, Observing silence religiously, Vedanta, All kinds of luxury, Fortune, Suffering from foes, Sparing in eating, Renunciation, Father's father, Hunger, Great pain from peptic or duodenal Ulcer, Small pox, or bolls and such other diseases, Horned animals, A servant of Shiva, Getting the order of imprisonment revoked, and Conversation or association with Shudras.`,
    kpBodyParts: `Belly, Feet`,
    kpPathogenicEffects: `Intestine worms, Epidemics, erruptive fever, low blood pressure, deafness, defective speech, lungs troubles, fever eye pain, stomach pains, boils, pains in the body and disease from unknown causes and brain diseases etc`,
    kpHoraSignifier: ``,
    friends: `Saturn, Venus, Rahu, Mercury`,
    enemies: `Sun`,
    neutral: `Mars, Moon, Jupiter`,
    varna: ``,
    nature: `Malefic`,
    linga: `Male`,
    oneRashiTime: `1.5years`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: ``,
    elements: `Firy`,
    trees: `Bushes`,
    ages: [`48-50`],
    lunarAstro: {
      cabinetPlace: `Solider - King maker`,
      basic: [
        `Intention, Occultism (Tantra & Mantra), spirituality`,
        `detachment, isolation, liberation or moksha`,
        `Ketu is South node of Moon and is an imaginary point on orbit of Moon.`,
        `Ketu is highly malefic in nature and always create sudden results.`,
        `will give results according to planet they are with but with problems included.`,
        `create tension and misery in life.`,
        `Ketu is a detachment and takes away wealth suddenly.`,
        `Ketu is significator of final bliss`,
        `give problems to make you a better person.`,
      ],
      business: [],
      predictive: [`4am`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [`Helps you - do your own work`],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [`Wake up at 4am (ketu's time) to consum Fresh Air (Jupiter)`],
    },
    bhrigu: {
      basic: [
        `Intention, Occultism (Tantra & Mantra), spirituality`,
        `detachment, isolation, liberation or moksha`,
      ],
      business: [],
    },
    sthanBala: ``,
    digBalaIn: ``,
    kalaBala: ``,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: ``, number: 1 }, degree: 3 },
    debilitation: { inSign: { name: `aries`, number: 1 }, degree: 10 },
    moolatrikona: {
      inSignName: ``,
      inSignNumber: 1,
      startDegree: 0,
      endDegree: 20,
    },
    alphabetsSanskrit: [],
    alphabetsEnglish: [],
    places: [],
  },
  {
    id: 2,
    graha: "Venus (शुक्र)",
    name: `venus`,
    vimshottariDashaYears: 20,
    uchhaRas: "Pisces (मीन)",
    nicchaRas: "Virgo (कन्या)",
    swaGraha: "Taurus, Libra (वृषभ, तुला)",
    color: `pink, pale (श्वेत), Variegated (tulip with a red base and yellow streaks), Cream, Off white`,
    direction: "South-East (आग्नेय)",
    directionalStrengthIn: `North (उत्तर)`,
    directionRoom: `Kitchen`,
    aspects: "7",
    characteristics: `Beauty, Wife, Art, Artists, Enjoyment, Love, Mantra Study, Passion and Happiness, Education, Humour, Dancing, Sweet Conversation, Handwork, Astrology, Ornaments, Conveyances, Precious Stones, Coral, Pearls, Ministership, Fame, Respect in Assemblies, Pearls, Happiness through Women and from Family and Married Wife, Silver, White Clothes, Gentlemanly Looks, Creation, Good Feelings towards All, Poetical Learning, Happy Meals, Happy Cot/ Seat, Honour, Play/ Songs, Sex, Good Smell of Flowers, Enjoyment in General.`,
    karakatva: `White umbrella which is a royal insignia, Good chowrie, Good clothes, Marriage, Income, Bipeds, Woman, Dvijas, Gentle or benefic, White colour, Wile or husband, Happiness from sex-life, Short stature, Acid taste, Flowers, Commands, Fame, Youth, Pride, Conveyance, Silver, Fond of the south-east, Saltish taste, Cross-wise vision, Cough, Fortnight, Rajasik nature, Firmness, Pearls, Yajur Veda, Vaisyas, Beauty, Buying and selling—or buying and selling the articles that contribute to beauty, Pleasant talk arising from mutual love, Watery place, Elephants, Horses, Pecullar or strange poetry, Dancing, Middle age, Music, Enjoyment, Happiness from wife or husband, Precious stones, Love of humour, Swimming, Servants, Fortune, Strange lustre, Tenderness, Kingdom, Fragrant garland, Taking pleasure in violin and flute, Pleasant movement or walking, Eight Kinds of prosperity, Well-proportioned limbs, Frugal in taking food, Season of spring, Ornaments, Possessing many women, Facing the east, Eyes, Speaking truth, Skilled in the fine arts, Semen, Sporting in water with love, Profundity, Excess or eminence, Pleasant musical instruments, Decoration for the dramatic shows, Indulgence in amorous sports, Declining physical form, One who attaches great importance to sex, Receiving good respect or honour, Fondness for having white clothes, Expert in Bharata's Natya Sastra, Having the seal of authority or of the government, Ruler, Fond of worshipping Gauri and Lakshmi, Emaciated from the pleasures of sex, Playing the role of a mother for those born during the day, Skilled in composing Kavyas, minor epics and the like, Blue-black hair, Benefic, Genitals, Urine or urinary tract, Moving in the world of the snakes, Strong in the afternoon, An awareness of the genital organs and the secrets connected with them.`,
    kpBodyParts: `Face, Eye sight, Genital Organs, Semen, Urine Lustre of body, Throat, Water in body and Glands Overies, Gullet, CHIn, Cheeks, Navel, Left Bar, Productive Organs`,
    kpPathogenicEffects: `Eye troubles, venereal diseases, Disease in face, urinal disease, fading away of lustre of body, indigestion, throat troubles, diabetes, dropsy fever and disease concering glands, carbuncles, stricture urethra, stone in bladder and kidneys, kidney troubles, cataract and weakness of sexual organs, tonsillitis, disease of overies, mucous disease, skin disease, gannorrhea and syphillis, goiture, gout and anaemia and brights disease cysts etc`,
    kpHoraSignifier: `Jewelry, Calm, Level Headed, Eversmilling, Pleasing, Never Irriating, Sweet, Cheerful, Dance, Music, Silk, Linen, Paint jewel, Embroidery women, Perfumery, Engraving, bed, dance, banqueting hall`,
    friends: `Mercury, Saturn, Rahu, Ketu, Anukul-12(uchhicha)-2-7-3-6-11-8`,
    enemies: `Sun, Pratikul-1-9-4-5-6`,
    neutral: `Moon, Mars, Jupiter`,
    varna: `Brown (सावळा - स्त्रीया मोहक)`,
    nature: `Benefic`,
    linga: `Female`,
    oneRashiTime: `1months`,
    shubhAshub: `नैसर्गिक शुभ`,
    deities: `Shachi or Indrani`,
    elements: ``,
    trees: `Creepers (लता), Milky, blossoming`,
    ages: [`25-27`],
    lunarAstro: {
      cabinetPlace: `Minister - Planning, Luxury`,
      basic: [
        `Resourceful, Emune system, Sperm, refined. kam, ushna, beej nadi, kavya, choices, wisdom, taste`,
        `show-off, serving food, journey imp, faith, burden carrier, responsibilities, re-generation, healing, aaram`,
        `spiral(curly), branded cloths`,
        `Beauty, Love, Passion, Wife, Clothes, Marital Happiness`,
        `Vehicles, Harmony, Nature, Pets and animals`,
        `Venus is feminine in nature and has beautiful body, watery and airy in constitution, represented by light grey colour.`,
        `Venus is natural benefic planet and unless afflicted badly does not do bad.`,
        `Venus represents love and beauty, She represents art, poetry, music and dance.`,
        `Venus represents all the luxuries of modern world and bodily pleasure. All the sensual comforts of body is given by Venus only.`,
        `Venus represents quality of married life in a chart.`,
        `Venus shows refinement so also represents semen in human body.`,
        `While Mars is male sexual energy, Venus is female sexual energy and combination of these two in any chart boost sexual drive in person.`,
        `Strong Venus will give all the ways to enjoy on this earth, While weak Venus give problems.`,
        `A strong Venus let you appreciate good quality in everyone but a afflicted Venus makes you pass sarcastic comments on everyone.`,
      ],
      business: [
        `Teacher, Musician, attist, sculptor, seller of rutistic materials`,
      ],
      predictive: [
        `Recovery`,
        `Regeneration capabilities`,
        `Taste`,
        `Pride / Self respect`,
        `Devotion / Love`,
        `Pride & Honor`,
        `Fights back only when his pride is hurt`,
        `Wants equality, being Rakshasa-Guru`,
        `ParashuRam Avatar is not a PariPoorna Avatar. So, Venus is not a complete (Poorna) Planet`,
        `Self worth`,
        `Devotion`,
        `Represent DNA`,
        `Rules Water`,
        `Wherever Venus is placed, Practice devotion in that house`,
        `Will be pleased on surrendering oneself`,
        `Spring season (Vasant Ritu)`,
        `Resources`,
        `One of your girl child’s marriage can be a failure in case of Sukra Vanshi`,
        `If Jupiter aspects Venus in Sukra Vanshi, the malefic effect is reduced`,
        `Rules poetry. People with good Venus can bring out meanings for what might look like a non-meaningful thing`,
        `Venus based astrologers die early for trying Tantra, Mantra, Yantras. Imp: No Mercy for mistakes like cheating etc.(Parashuram Avatar)`,
        `During Venus Dasa / Antar – person can’t do hard work (Venus-comfort)`,
        `Debilitated Venus placed in 6,8,12 during his dasa – likely to have diseases like diabetes etc`,
        `Venus rules immune system and sperm. To better Venus, controlled sex is advised`,
        `Bad Venus – complains often on food’s taste`,
        `In the Upachaya houses from Lagna (3,6,10,11) – Malefic placements are good. Venus in such houses is not good for winning battles of life`,
        `Venus is also called Beeja Nidhi (treasure of seeds). All seeds are controlled by Venus – Venus gives growth`,
        `Bad Venus – low Sperm count`,
        `Good Venus – beautiful eyes, attractive body / shiny face`,
        `Wherever Venus is placed, native tries to show-off there. For example, Venus in Ninth-house, shows off his knowledge`,
        `Flower(s)`,
        `Medicine`,
        `Perfume - Firy/Energetic Sign ~ Very High note (Citrus) perfume`,
        `Perfume - Dull Saturn houses ~ Low note perfume`,
        `Perfume - Mercury houses ~ Mutiple note (Woody mix with Citrus low note) perfume`,
        `Yogurt`,
      ],
      inHouse: {
        1: [
          `Shukra Vanshi Rule: Venus in Lagna. Check degrees of Lagna & Venus (should not be too far)`,
          `Shukra Vanshi Rule: Venus and Saturn conjunct within 3 degrees`,
          `Shukra Vanshi Rule: Saturn in Venus's Nakshatra; Venus in Saturn's Nakshatra`,
          `Shukra Vanshi Rule: Venus and Saturn mutual 7th aspect over each other within 2 degress`,
          `Shukra Vanshi Rule: Exchange of houses between Venus and Saturn (House Parivartan)`,
          `1st indication of Shukra Vanshi - Eldest in family have legs issue (Vericose Veins / Fractures)`,
        ],
        2: [`Start  appreciating and find something good in everyone`],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [`in Capri - Major change in profession(Capri) due to women(venus)`],
        9: [`Shows off his knowledge`],
        10: [
          `Donate anti-aging medicine (Ve-Medicine) to someone in office (10th house house of saturn (Old people))`,
          `Bring anti-aging medicine (Ve-Medicine) in office (10th house house of saturn (Old people))`,
        ],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [
        `Plant 2 Amla Plants (2 plants for 2 legs) as a Remedy for Sukra Vanshi`,
        `For anybody of Venus in 6th house, get married to ugly looking spouse, as a remedy`,
        `To Improve Venus – increase spending on cosmetics / haircut, grooming etc`,
        `Eat food while sitting on mud/normal floor (kacchi jamin)`,
      ],
    },
    bhrigu: {
      basic: [
        `Resourceful, Emune system, Sperm, refined. kam, ushna, beej nadi, kavya, choices, wisdom, taste`,
        `show-off, serving food, journey imp, faith, burden carrier, responsibilities, re-generation, healing, aaram`,
        `spiral(curly), branded cloths`,
        `Beauty, Love, Passion, Wife, Clothes, Marital Happiness`,
        `Vehicles, Harmony, Nature, Pets and animals`,
      ],
      business: [
        `Teacher, Musician, attist, sculptor, seller of rutistic materials`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `4`,
    kalaBala: `stronger during the day`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `pisces`, number: 12 }, degree: 27 },
    debilitation: { inSign: { name: `virgo`, number: 6 }, degree: 27 },
    moolatrikona: {
      inSignName: `libra`,
      inSignNumber: 7,
      startDegree: 0,
      endDegree: 15,
    },
    alphabetsSanskrit: [`च`, `द्द`, `ज`, `झ`, `ञ`],
    alphabetsEnglish: [],
    places: [`bed`, `hospital`, `massage`, `clubs`],
  },
  {
    id: 3,
    graha: "Sun (रवी)",
    name: `sun`,
    vimshottariDashaYears: 6,
    uchhaRas: "Aries (मेष)",
    nicchaRas: "Libra (तुला)",
    swaGraha: "Leo (सिह)",
    color: `golden, blood red, yellow (लाल श्याम), orange`,
    direction: "East (पूर्व)",
    directionalStrengthIn: `South (दक्षिण)`,
    directionRoom: `Living Room, Bathroom (no toilet)`,
    aspects: "7",
    characteristics: `Father, Elder Brother after Father, Position in Society, Ruling Party, King, Promotion, Showing-off, Soul, Enemy, Incentive, Push, Forests, Peaks, Mountains, Happiness from Father, Authority Wielded by The Native, Religious Sacrifice/ Inclinations, Gold, Horse, Topaz, Status/ Position Occupied by the Native, Blood, White Umbrella, Denoting Honour, Copper, Grass, Medicine, Red Leprosy, Valour, Eyes, Medical Treatment.`,
    karakatva: `Soul, Power or strength, Greatest severity, Fortress, Good strength, Heat, Influence, Fires, Worship of Shiva, Courage, Trees having thorns, favour of the king or ruler, Bitter taste,. Old age, Cattle, Wickedness, Land, Father, Taste, Awakening of knowledge or enlightenment, Looking up toward the sky-very great ambition, One born to a timid person or one whose actions are born out olf fear, World of mortals, Square or harmonious, bongs, Valour, Grass, Stomach, Enthusiasm, Forest, Half a year, Eye, Wandering in or over the hills, Quadruped, King, Travelling, Dealings with affairs personal or social, Bile, Feverish or inilammatory complaints, Circular forms, Diseases of the eye, Body, Timber, Mental purity, Sovereignty over the entire country or over all countries, Freedom from disease, Rulership over Saurashtra — southern Gujarat, Ornaments, Diseases of the head, Pearls, Ruler of the sky-control over airspace, Short or dwartish stature, Rulership over the eastern direction, Copper, Blood or blood-red gems like ruby, Kingdom, Blood-red cloth, stones, Undisguised or open conduct, Shores of a river, Red coral, Strong at noon, Fast, Face, Long-standing anger or strong indignation, Capturing the foe, Sattvik or benevolent and good temper, Red sandal paste or saffron, Otherness or opposition or hostility, and Thick or coarse yarn.`,
    kpBodyParts: `Stomach, Bone, Btood, Heart, Skin, Belly, Eyesight (right eye of the male and left eye of the female). Head, constitution of the body`,
    kpPathogenicEffects: `Trouble in right eye, high fever, jaundice, burning sensation, heart disease, stomach and skin disease, fracture of bones, internal fever, brain troubles, diseases of head, palpitation, baldness, typhoid, polypus, Epilepsy, Bile Complications, Sun Stroke, Eruption of face, Loss of speech as a result of Cerebral affections`,
    kpHoraSignifier: `Produces Libral, Noble and Sympathethic people, Reliable, Proud and Generous, Fond of Dress, Jewels, Internal Decorations, also outside, works in Government in any capacity, Magistrates, Stewards (प्रबंधक/कारभारी), Goldsmiths, Copper-Smiths, Mint, Municipalities, Corporation, etc`,
    friends: `Moon, Mars, Jupiter`,
    enemies: `Saturn, Rahu`,
    neutral: `Mercury, Ketu`,
    varna: ``,
    nature: `Malefic`,
    linga: `Male`,
    oneRashiTime: `1months`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: `Fire (अग्नि)`,
    elements: `Fire`,
    trees: `Tall, Strong inside`,
    ages: [`22-23`],
    lunarAstro: {
      cabinetPlace: `The King - Soul`,
      basic: [
        `Soul (Atma Karaka), Govt., Father, Authority and leadership, Charisma, Vitality, Health`,
        `Ego and soul purpose`,
        `Father - Shows relationship with father, Status and life of father as well.`,
        `Ego - A good Sun will make person outshine but bad Sun will give ego issues.`,
        `Self -  Sun represents soul and self confidence, will power to strive in life.`,
        `Individuality - A good Sun will make you outshine among others.`,
        `Rational mind - Sun helps people take rational decisions.`,
        `Honour - All the honour, respect is due to Sun.`,
        `Government - Sun also represents government authorities, People with bad Sun will have problem following rules and regulations.`,
        `Discipline - Sun is a very disciplined planet as it always comes on time and never take off.`,
        `Sun is the only deity which is visible to us  and we should offer water to sun to increase our intuition as an Astrologer.`,
        `Sun is considered as strict planet in Vedic astrology.`,
      ],
      business: [`Paper merchant, Bookseller, Farmer, Saint, Devotee`],
      predictive: [`Boss`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [`Nich bhang rajyog`],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [`Maran karan sthana`],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [
        `Soul (Atma Karaka), Govt., Father, Authority and leadership, Charisma, Vitality, Health`,
        `Ego and soul purpose`,
      ],
      business: [`Paper merchant, Bookseller, Farmer, Saint, Devotee`],
    },
    sthanBala: ``,
    digBalaIn: `10`,
    kalaBala: `stronger during the day`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `aries`, number: 1 }, degree: 10 },
    debilitation: { inSign: { name: `libra`, number: 7 }, degree: 10 },
    moolatrikona: {
      inSignName: `leo`,
      inSignNumber: 5,
      startDegree: 0,
      endDegree: 20,
    },
    alphabetsSanskrit: [`अ`, `आ`, `इ`, `ई`, `उ`, `ऊ`],
    alphabetsEnglish: [],
    places: [`temple`, `church`, `Śiva liñga`],
  },
  {
    id: 4,
    graha: "Moon (चंद्र)",
    name: `moon`,
    vimshottariDashaYears: 10,
    uchhaRas: "Taurus (वृषभ)",
    nicchaRas: "Scorpio (वृश्चिक)",
    swaGraha: "Cancer (कर्क)",
    color: `light blue, silver, tawny (light brown to brownish-orange), white (पांढरा)`,
    direction: "North-West (वायव्य)",
    directionalStrengthIn: `North (उत्तर)`,
    directionRoom: `Guest room, Bathroom, Grain Storage`,
    aspects: "7",
    characteristics: `Emotions, Mother, Water Related, Mind, Flower, Dwijatwam (Thread Ceremony), Happiness from Mother, Clothes, Agriculture, Money, Cows, Milk and Allied Products, Cereals and Grains, Precious Stones and Shells Obtained from The Sea-Bed Or River, Pearls, Royal/ Kingly Life, White Umbrella Denoting Honour, Dignified Paraphernalia, Nobility, Liquids, Fruits, Land, Lustre, Contentment, Perfume.`,
    karakatva: `Intelligence, Flowers, Perfumes, Going to a fortress, Disease, Brahmana or Dvija, Laziness, Phlegmatic constitution, Epilepsy, Hidden or ulcerous troubles in the stomach, Nature of the mind, Heart, Woman, Benefic and malefic nature, Acidity, Sleep, Happiness, Liquids, sliver, Stout sugarcane, Malarial fever, Pilgrimage, Wells, Tank, Mother, Impartial outlook, Noon, Pearls, Consumption, Whiteness, Waist-band, Bell-metal, Salt, Short stature, Mind, Abilities, Ponds, Diamond, Sarad or autumn, Munurta or 48 minute duration, Facial lustre, white colour, Stomach, Worship of Gauri, the consort of Shiva, Honey, Grace or favour, Sense of fun or humour, Nourishment, Wheat, Pleasures, Splendour, Face, Swiftness of mind or agility of mind, Fondness for curd, One who does tapas or penance, Fame, Beauty, Strong at night, Facing west, Lover, Saline, Acquiring a post, Love of the west, Middle world, Nine gems, Middle age, Self, Eating, Going to distant places, Ailments of the shoulders, Umbrella and other royal inslgnia, Good fruits, Fine blood circulation or vital energy, Fish and other aquatic beings, Serpents and the like, Clothes, Fine blossoming, Lustre, Clean crystals, Refined clothing.`,
    kpBodyParts: `Breast, Intestines, Lymph, Eye sight (Left of the male and right of the female), Throats, Nervous debility, chest, Mind, Kidney, Alimentary canal, water in body`,
    kpPathogenicEffects: `Diseases of heart and Lungs, diseases of Uterus dropsy, skin disease, Pleuricy of T.B. menstrural disorders, mental aberration, aneamias, serious effusions, nervousness, disease in left eye, over sleepness, diarrhoea, poisoning of blood, disease from water, vomitting gastric troubles, dropsy, appendicities, disease of breasts, and mammary glands, Hysteria, Beri-Beri, Cold, cough, colic pains. Tumours, throat troubles, Asthma, Esnophillia, Branchitis, cancer, dysentry, hydrocele, dyspepsia, gastric catarrh, typhoid etc`,
    kpHoraSignifier: `Ever changing habits, unsteady, thinks of the present alone, timid, tries to lead a peaceful life, tries to avoid anxiety, worry, etc. traveller, fish, breweries, milk, mes. sengers, marine, navy, those who deal in liquids. (They do not take Rahu or Kethu.)`,
    friends: `Sun, Mercury, Mars, Jupiter`,
    enemies: `None`,
    neutral: `Venus, Rahu, Ketu, Saturn`,
    varna: ``,
    nature: `Benefic`,
    linga: `Female`,
    oneRashiTime: `2.25days`,
    shubhAshub: `नैसर्गिक शुभ`,
    deities: `Water (वरुण)`,
    elements: `Water`,
    trees: `Creepers (लता), Milky, blossoming, lord of herbs`,
    ages: [`24`],
    lunarAstro: {
      cabinetPlace: `The Queen - Mind`,
      basic: [
        `Thoughts, Feelings, Emotions, Mental attitude, Mother, Mind, Memory`,
        `Sleep, Imagination, General happiness and Peace of mind`,
        `Moon is the Queen among planets and represents mind, White in colour and represented by water.`,
        `Her body is mainly constituted of water and also called as watery planet in astrology`,
        `She has sweet speech and very easily change her mind.`,
        `She is not stable in her decisions.`,
        `Moon represents mother in chart, condition of mother, relationship with mother.`,
        `Moon also represents mental peace.`,
        `Moon is mind and represents phycological and emotional plane.`,
        `Any affliction to Moon will cause emotional turbulence.`,
        `Moon gives beauty and attractiveness.`,
        `Moon is our social nature and how we interact with society.`,
      ],
      business: [
        `Actor, Actress, dancer, Poet, drawing artist, traveler, Sculptor`,
        `jeweler, sailor, Photographer, Vendor of perfumes or glass articles`,
      ],
      predictive: [`Nourishment`, `Resources`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [
        `Thoughts, Feelings, Emotions, Mental attitude, Mother, Mind, Memory`,
        `Sleep, Imagination, General happiness and Peace of mind`,
      ],
      business: [
        `Actor, Actress, dancer, Poet, drawing artist, traveler, Sculptor`,
        `jeweler, sailor, Photographer, Vendor of perfumes or glass articles`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `4`,
    kalaBala: `stronger at night`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `tarus`, number: 2 }, degree: 3 },
    debilitation: { inSign: { name: `scorpio`, number: 8 }, degree: 3 },
    moolatrikona: {
      inSignName: `tarus`,
      inSignNumber: 2,
      startDegree: 4,
      endDegree: 30,
    },
    alphabetsSanskrit: [`य`, `र`, `ल`, `व`, `श`, `स`, `ह`],
    alphabetsEnglish: [],
    places: [`water`, `resorts`, `beach`],
  },
  {
    id: 5,
    graha: "Mars (मंगळ)",
    name: `mars`,
    vimshottariDashaYears: 7,
    uchhaRas: "Capricorn (मकर)",
    nicchaRas: "Cancer (कर्क)",
    swaGraha: "Aries, Scorpio (मेष, वृश्चिक)",
    color: "Blood red (लाल)",
    direction: "South (दक्षिण)",
    directionalStrengthIn: `South (दक्षिण)`,
    directionRoom: `Kitchen, storeroom`,
    aspects: "4,7,8",
    characteristics: `Army, Siblings, Land, Property, House, Surgery, Husband, Truth, Land And Fort, Diseases, Wounds/Ulcer, Valour, Fighting Equipment’s Or Arms, Fire, Dignity, Higher Knowledge, Brothers, Happiness Through Brothers, Positions Like 2nd in-Command Or Heir Apparent, Bile, Heat, Strength Of Native’s Hands, Courage, Strength Of Native, Quarrels, Arguments, Success In Encounters/ War, Goats, Red Leprosy, Sacrificing Live Animals, Getting Lost In Deserts, Having To Live in Hill Stations Or Mountains, Women, Copper, Gold, Coral, Ruby, Stamp Of Authority, Fetters or Imprisonment, Punishment, Nature, Friends or Followers, Fort, Implements or Armour, Poisonous Creatures, Bamboo, Catechu, Sulphur, Lions, Tigers, Valour.`,
    karakatva: `Valour, Land, Strength, Carrying weapons, Ruling over people — administrator, Loss of virility, Thief, Battle, Opposition, Foes, Charitable nature, Love of deep-red objects, Ruler over sacred places—archaeologist, or religious endowments, Sound of a trumpet, Fondness, Quadrupeds, King, Obdstinate fool, Anger, Going fo a foreign country, Steadfastness, Supporter, Fire, Controversy or arguments, Bile, (26) Heat, Wounds, Service under the ruler, Day, Sky, Sight, Shortness, Ailment, Fame, Tin, Sword, Spear, Minister, Firmness of limbs, Jewels, Worship of subrahmanya, Youth, Pungent taste, Hall of the ruler, Karthenware, Obstacles, Lating nonvegetarlan food, Abusing or bitterly criticising others, Conquering foes, Bitterness of taste, Strong towards the end of the night, Goid, Summer season, Valour, Strength of the enemy, Profundity, Courageous behaviour, Man, Cnaracter, Brahma, Axe, Moving in the forest—forest officer, Chief ruler or officer of the village, Favour of the king, Painful urination, Square, Goldsmith, Wickcd one, Burnt place, Good food, Leanness, Skill in archery, Blood, Copper, Variegated or peculiar clothes, Face turned toward the south, Fond of the southern direction, Desire, Anger, Spreading scandals, House, Commander of the Army, A fire arm that kills a hundred—something like a machine-gun, Tree, Sama Veda, Brothers, Hatchet, Controlling fierce or wild animals, Independence, Persistance, Land, One who awards punishment, Snake, World, Speech, Fickleness or imbalance of the mind, Getting on a vehicle, eeeing blood and Drying or coagulating of blood, and many other such things are attributed to Kuja by the wise.`,
    kpBodyParts: `Blood, Marrow, Energy, Neck, Gepitals, Red colouring matter in blood, Rectum, Head, Veins, Female Organs, Nose, Fore-head, Digestive section of sines, vitality`,
    kpPathogenicEffects: `Blood diseases, tissue breakages, fevers, burns mental aberrations, epilepsy, tumours, sore eye, itches, cuts and wounds, Blood pressure, loss of energy, diseases in female organs, fracture of bones, urinal diseases, boils, cancers, piles, ulcer, disease in  rectum, plague, small pox, chicken pox, messels, mumps, fis tu la , brain fever, haemmorrhage, typhoid, puerperal fever, hernia, malaria, abortions, bleedings, muscular rheumatism, septic poisoning, tetanus etc`,
    kpHoraSignifier: `Force, Brave, violent, unyielding, taking chances, pleasure in fighting, over liberal, extravagant. It shows captains, soldiers, surgeons, chemists, barbers, butchers, thieves, smiths, tailors, carpenters, masons, etc. It also denotes those who work in fire, furnaces, ovens, or deal in blood and so on.`,
    friends: `Sun, Moon, Jupiter`,
    enemies: `Rahu, Mercury`,
    neutral: `Venus, Saturn, Ketu`,
    varna: ``,
    nature: `Malefic`,
    linga: `Male`,
    oneRashiTime: `1.5months`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: `Kartikeya`,
    elements: `Fire`,
    trees: `Thorny`,
    ages: [`28-33`],
    lunarAstro: {
      cabinetPlace: `Army Chief - Energy`,
      basic: [
        `Army General (Securer), action, target, end result imp`,
        `Energy, Strength, Anger, Conflict, younger sibling, weapons`,
        `Accidents, Litigation, debts, Landed property`,
        `Mars is the army chief of planets so has all the qualities of Army Guy.`,
        `Mars is cruel, has blood red eyes, short height, thin waist and body and bilious in nature.`,
        `Mars represented by red colour, is fast  in nature and has lot of energy.`,
        `Mars represents our passion, skill and logical ability.`,
        `Mars when get combined with Venus increases sexual drive.`,
        `Mars represents younger siblings, friends and anyone with common interest or goal working together for example Army, Police and even thieves.`,
        `Strength and courage comes from Mars only, People with strong Mars mostly work where courage is required like Army.`,
        `Mars is malefic is nature and known for accidents, Premature death, Violence, injury and accidents.`,
        `Loss of life partner can be seen in chart due to Mars.`,
      ],
      business: [
        `Doctor, Lawyer, Engineer, captain, Commander, Soldier, Historian`,
        `magician, director, scientist, minister, philosopher, foreign traveler`,
      ],
      predictive: [
        `Logic`,
        `Logic: If Mars is well placed, your logic is good`,
        `Ready to Fight`,
        `Red Eyed - Focus`,
        `Bad Mars – Bad Focus`,
        `Karak for Pitta`,
        `Looks Upward`,
        `Never Dig Earth on a Tuesday – Reliance Ltd never starts oil drilling on a Tuesday!`,
        `People with Reddish Complexion when compared to others in the same country are more quarrelsome due to Martian Nature!`,
        `3rd house is for Parakram and Karaka is Mars`,
        `Mars Represents Grishma Ritu (Sun in Aries/Taurus)`,
        `Represents Bitter medicines / plants. Neem Tree is governed by Mars. If Mars is bad, focus is problem, plant a Neem tree.`,
        `Mars is karaka for electricity`,
      ],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [
          `Does not want to learn`,
          `Difficulty in sending him/her to school`,
        ],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [
          `Function Halls (Marriage Halls etc), Tent House etc. close by to the home`,
        ],
        12: [`Has knowledge of medicines / chemicals (Lal Kitab)`],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [`Mars & Rahu are conjunct, there is a theft of electricity`],
    },
    bhrigu: {
      basic: [
        `Army General (Securer), action, target, end result imp`,
        `Energy, Strength, Anger, Conflict, younger sibling, weapons`,
        `Accidents, Litigation, debts, Landed property`,
      ],
      business: [
        `Doctor, Lawyer, Engineer, captain, Commander, Soldier, Historian`,
        `magician, director, scientist, minister, philosopher, foreign traveler`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `10`,
    kalaBala: `stronger at night`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `capricorn`, number: 10 }, degree: 28 },
    debilitation: { inSign: { name: `aries`, number: 4 }, degree: 28 },
    moolatrikona: {
      inSignName: `aries`,
      inSignNumber: 1,
      startDegree: 0,
      endDegree: 12,
    },
    alphabetsSanskrit: [`क`, `ख`, `ग`, `घ`, `ड़`],
    alphabetsEnglish: [],
    places: [`near fire`, `kitchen`, `furnace`],
  },
  {
    id: 6,
    graha: "Rahu (राहू)",
    name: `rahu`,
    vimshottariDashaYears: 18,
    uchhaRas: "Sagittarius (धनु)",
    nicchaRas: "Gemini (मिथुन)",
    swaGraha: "-",
    color: `earth colors, brown (निळा), blue, dark honey`,
    direction: `South-West (नैऋत्य)`,
    directionalStrengthIn: `West (पश्चिम)`,
    directionRoom: `Master bedroom, heavy storage`,
    aspects: "5,7,9",
    characteristics: `Success, Enjoyment (Upabhoga), Mohammedans, Hidden Meaning, Valour, Paternal Grandfather, Maternal Grandmother, Strength and Losses, Condemnation, Punishment, Highly Poisonous Snakes, Untouchables, Courage, Pride, Burial Grounds, Theft, Connections with Widows, Breaking Stones, Professions such as Digging Of Wells or Boring Pits or Ant's Nest, Driving Oil Expellers, Wood-Cutting, Major Diseases, Accidents, Fever, Small Pox, Fainting, Having to Quit Native Place, Ideas of Renouncing Family Ties, Getting Cast Away from Religion, Life in Forests, Serious Encounters/ Battle.`,
    karakatva: `Umbrella, Chowrie, Acquiring a kingdom, Faulty logic, Harsh speech, One belonging to a caste outside the four main castes, A sinful woman, A conveyance covered on all four sides, An irreligious person or a Shudra, Gambling, Strong at sunset, Having sex with a wicked woman, Going to a different country, Unclean, Bones, Hidden abdominal ulcer, Falsehood, Looking downwards, Perplexity, Emerald, Facing south, Depending on miechchhas, low castes and the like, Malignant tumor, Great forest, Wandering in difficult places, Suffering from mounitains, Staying outside, South-western direction, Complaints of wind and phlegm, Serpents, Southern breeze, Severe, Long, Reptiles, Interpretation of dreams, Travels, One muhurta, Old age, Conveyance, Worid of the snakes, Maternal grand father, Air, Acute duodenal pain, Catarrh, Breathing, Great valour, worship of Vana-Durga, Wickedness, Assciation with animals, Writing Urdu or Persian, and Harsh speech.`,
    kpBodyParts: `Feet, Breathing, Neck`,
    kpPathogenicEffects: `Lungs troubles, diseases of feet, pain of walking, Leprosy, difficulties in breathing, enlargement of spleen, cataract and hydrocele, highcough, slowness of action, clumsiness, intestinal disease, insanity, ulcers, general debility, boils, eclampsia, varicose veins, disease of spieen and adrenals, pleurisy, T.B., pain in feet and legs, pulmonary mal-function etc`,
    kpHoraSignifier: ``,
    friends: `Saturn, Venus, Ketu, Mercury`,
    enemies: `Sun, Mars`,
    neutral: `Moon, Jupiter`,
    varna: ``,
    nature: `Malefic`,
    linga: `Male`,
    oneRashiTime: `1.5years`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: ``,
    elements: `Airy or Windy`,
    trees: `Bushes, Sala`,
    ages: [`42-47`],
    lunarAstro: {
      cabinetPlace: `Solider - King maker`,
      basic: [
        `Unorthodoxy, rebellion, confusion, attention, propaganda`,
        `new technology, grandparents, sudden changes, ups and downs`,
        `Rahu is North node of Moon and is an imaginary point on orbit of Moon.`,
        `Rahu is highly malefic in nature and always create sudden results.`,
        `will give results according to planet they are with but with problems included.`,
        `create tension and misery in life.`,
        `Rahu gives material wealth if good placed and give sudden gains such as lottery winning.`,
        `Rahu is illusion`,
        `give problems to make you a better person.`,
      ],
      business: [],
      predictive: [
        `Rahu is a mirror / amplifies the house many times`,
        `Army Chiefs`,
        `Tobacco`,
        `Toilet`,
        `Modern cooking - Microwave/Airfryer`,
        `Cancer disease`,
      ],
      inHouse: {
        1: [],
        2: [`Good astrology - to see what is not written (Power of Rahu)`],
        3: [`Leave ur job do something of ur hobbies (3rd house)`],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [
          `keep a opened perfume (Venus) bottle by bed side when sleeping and throw the bottle content in toilet (Rahu) in the morning`,
        ],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [`Mars & Rahu are conjunct, there is a theft of electricity`],
      remedies: [
        `Stop using pressure cooker to cook food`,
        `Stop using aluminium utensils for cooking - use copper/pital/Brass (Sun will get better with time)`,
        `Eat food in the kitchen`,
        `Vaad /Baad Tree`,
      ],
    },
    bhrigu: {
      basic: [
        `Unorthodoxy, rebellion, confusion, attention, propaganda`,
        `new technology, grandparents, sudden changes, ups and downs`,
      ],
      business: [],
    },
    sthanBala: ``,
    digBalaIn: ``,
    kalaBala: ``,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: ``, number: 1 }, degree: 3 },
    debilitation: { inSign: { name: `aries`, number: 1 }, degree: 10 },
    moolatrikona: {
      inSignName: ``,
      inSignNumber: 1,
      startDegree: 0,
      endDegree: 20,
    },
    alphabetsSanskrit: [],
    alphabetsEnglish: [],
    places: [],
  },
  {
    id: 7,
    graha: "Jupiter (गुरू/बृहस्पति)",
    name: `jupiter`,
    vimshottariDashaYears: 16,
    uchhaRas: "Cancer (कर्क)",
    nicchaRas: "Capricorn (मकर)",
    swaGraha: "Sagittarius, Pisces (धनु, मीन)",
    color: `light blue (गौर पिट), tawny (light brown to brownish-orange), lemon yellow, golden`,
    direction: `North-East (ईशान्य)`,
    directionalStrengthIn: `East (पूर्व)`,
    directionRoom: `Worship room, Living room`,
    aspects: "5,7,9",
    characteristics: `Children, Rich People in Society, Education, Religion, Sacrifices, Reputation, Household Property, Gold, Sons, Wearing Apparels, Conveyances or Horses, Ministership, Proficiency in Planning, Philosophy, Knowledge Of Soul/ God, Devotion To Learned People/ Brahmins, Interest, Deep Penetrating Intellect, Purifying Oneself through Repetition of OM, Conducting on Right Lines, Devotion to Lord Vishnu, Elephants, Reputation, Education or Learning, Respect, Success, Proficiency in Teaching, Charities`,
    karakatva: `Brahmanas, One's own teachers, Religious, social and other duties, Chariot, Cows, Infantry, Deposits, Mimansa or enquiry into jurisprudence ana the nature of dharma, Treasure, Horses. Bulfaloes, Large or stout body, Valour, Fame, Logic, Astronomy and astrology, Sons, Grandsons, Complaints of the digestive system, Wealth of the elephants, Vedanta system of, philosophy, Elders like the great-grand-father, Mansion, Gomedha or hassonite, Elder prother, Grand-father, Indra. Second half of winter, Acts arising from great indignation, Precious stones, Tradesman, Physical heaith, Strange or peculiar palace, honour from the King, Great gods, Penance, Charity, Dharma, Doing good to others, [mpartial outiook, Facing towards the north, Circular forms, Yellow colour, Wandering in the villages, North, Dear friends, Swinging on a plank or on an elephant, Great eloquence, Brain, Cloth of a medium count, Happiness in a newly built house, Old age Or Old persons, Mantra, Dvijas, Holy water or place of pilgrimage, Knee, Moving towards heaven, House that offers happiness, Intellect, Great proficiency, Great long poems, Towers, Delighting or impressing an audience, Throne, Installation of the idol of Brahma, Cat’s eye or lahsunia, The great results of Agnishtoma sacrifice, Sweet juices, Sattvic nature, Happiness and unhappiness, Long, Gentle or benefic, Understanding the minds or thoughts of others, Gold, Decorations, Tantra or technical subjects and the lke, Wind complaints Phlegm, Good yellow topaz, Sound Knowledge of the sacred texts and scriptures, Soft and pleasant stones, Worship of Shiva, Performing religious and moral acts strictly, Travelling in a carriage covered on the four sides.`,
    kpBodyParts: `Thighs, Fat, Brain, Lungs, Liver, Kidney, RightEar, Momory, Tongue, Spleen, Semen, Pleura`,
    kpPathogenicEffects: `Liver, kidney and Lung diseases. Ear troubles, diabeties, Lack of memory, disease of tongue, soleen, dropsy & disease of thighs, jaundic, vertigo, laziness, disease of gall bladder, sleeping sickness, anaemia, dropsy, neurological melody, fatty degeneration of the muscles, tumours, cancer, morbid growths, enlargement of organs, waste of sugars, albumin in the urine, blood poisoning hyperaemia, a poplexy, dyspepsia, abscess, hernia and skin troubles, cerebral congestion catarrh and carbuncles etc`,
    kpHoraSignifier: `Produces Magnanimous, Nobel Honorabl, True, Reliable people, with Ambition and Hopeful Disposition, Fair Dealings, Religious and God-fearing, try to Stick on to Traditions, Lead a very happy family life with their children, Charitable and Sympathethic,
    Judges, Advocates, Professors, Priests, Ministers, Administrators, Chancellors, Civilians, Bankers, Revenue depts, Works in Palace, Gardens, Law, Courts, Deals in honey, Oil, Silk, Clothing, Horse, Domestic Fowls (पक्षी)`,
    friends: `Sun, Moon, Mars`,
    enemies: `Mercury, Rahu`,
    neutral: `Saturn, Venus, Ketu`,
    varna: ``,
    nature: `Benefic`,
    linga: `Male`,
    oneRashiTime: `13months`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: `Indra`,
    elements: `Sky or Ether`,
    trees: `Fruit bearing`,
    ages: [`9-12`, `16-21`],
    lunarAstro: {
      cabinetPlace: `Minister - Wisdom`,
      basic: [
        `Wealth, fortune, dharma, religion, knowledge, higher wisdom, ethics`,
        `morals, long distance travel, teaching, Guru, Father, traditions`,
        `Jupiter is biggest benefic amongst all planets and tend to do good wherever he goes, Phlegmatic in nature and governs fat in body, represented by yellow colour.`,
        `Jupiter is teacher of Gods and does all the religious work.`,
        `Jupiter represents husband in female’s chart and also significator of children.`,
        `Jupiter gives wisdom and applied knowledge for the betterment of self.`,
        `All the religious people or people who are healers, who work for betterment of other people and show everyone right path are under influence of Jupiter.`,
        `Jupiter represents peace and prosperity in real terms.`,
        `When afflicted it creates hurdles in education, Progeny and gives disease related to fat.`,
      ],
      business: [
        `Teacher, Professor, Social worker, Doctor, Clerk, Publisher`,
        `Exporter, Commission Agent (if weak would be a servant in these areas)`,
      ],
      predictive: [`Fresh Air`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [`Banana tree planting`, `Banana tree puja on Thursdays`],
    },
    bhrigu: {
      basic: [
        `Wealth, fortune, dharma, religion, knowledge, higher wisdom, ethics`,
        `morals, long distance travel, teaching, Guru, Father, traditions`,
      ],
      business: [
        `Teacher, Professor, Social worker, Doctor, Clerk, Publisher`,
        `Exporter, Commission Agent (if weak would be a servant in these areas)`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `1`,
    kalaBala: `stronger during the day`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `cancer`, number: 4 }, degree: 5 },
    debilitation: { inSign: { name: `capricorn`, number: 10 }, degree: 5 },
    moolatrikona: {
      inSignName: `sagittarius`,
      inSignNumber: 9,
      startDegree: 0,
      endDegree: 10,
    },
    alphabetsSanskrit: [`त`, `थ`, `द`, `ध`, `न`],
    alphabetsEnglish: [],
    places: [`treasury`, `bank`, `finance`, `business`],
  },
  {
    id: 8,
    graha: "Saturn (शनी)",
    name: `saturn`,
    vimshottariDashaYears: 19,
    uchhaRas: "Libra (तुला)",
    nicchaRas: "Aries (मेष)",
    swaGraha: "Capricorn, Aquarius (मकर, कुंभ)",
    color: `dark violet, dark blue (काळा), Dark, black`,
    direction: `West (पश्चिम)`,
    directionalStrengthIn: `West (पश्चिम)`,
    directionRoom: `Children’s Bedroom, Store room, Study`,
    aspects: "3,7,10",
    characteristics: `Grandparents/ Elders in General, Research, Lower Strata In Society, Lifespan, Sorrow, Grief, Longevity, Weapons, Travels, Fetters, Servitude (With Or Without Discretionary Powers For Native), Imprisonment, Service, Slavery, Rheumatism, Inferior Deeds And Actions, Harbouring Evil Intentions, Friendship with Rogues and Rascals, Obstacles, Savage Tribes, Gambling, Getting Addicted To Drinking, Oil, Base Metals, Black Clothing, Blankets, Cruel Inclinations, Prison, Footwear, Sorrow As a Result of Imprisonment/ Defeat, Inferior Trades as a Slave or Paid-Servant or Debts.`,
    karakatva: `Ill-health and other sufferings, Obstruction, Horses, Elephants, Skin, Income, Standards, Distress, Disease, Enmity, Sorrow, Death, Happiness from a woman, Maid-servant, Asses, Outcastes, Persons with strange or mutilated limbs, Roaring in the woods, Disgust, Charity, Lords, Longevity, Eunuch, One born outside the four castes, Birds, Tretagni, one of the three sacred domestic fires, Servant’s duties, Doing something other than the traditional or accepted duties, One losing his ego, Telling lies, Lasting long, Wind, Old age, Tendon, Strong at the end of the day, The second half of winter, Indignation, Exertion, Born of a low woman, Born An adultcry, A widow's bastard, Dirty cloth, Dirty house, Mind turned to dirty things, Friendship with the wicked, Black colour, Sins, Cruelty, Ashes, Grains.in black colour, Precious stones, lron, Generosity, A year, Shudras, Vital or supportcrs of the lover, Significator of the father for one born in the night, Learning what belongs to another caste, Lameness, Fierce or severe, Blanket, Facing the west, Remedies for restoring life, Looking downwards, Living by agriculture or farming, One who knows where the arms are Kept, manager of an ordnance factory, Cousin, An external position, Fond of the north-east, World of the snakes, Falling, Wandering in the battle fields, Bones, lead, Wrong or wicked valour, Turks, Torn clothes or things, Oil, wood, Brahman having Tamsik quality, Wandering in poisonous places, Roaming in hilly areas, Long standing fear, Hunter, Strange or ugly hair, Whole kingdom, Fear, Goats and the like, Buffaloes and the like, Indulgence in sex, Displaying amorous intentions in dressing, Worship of Yama, Dogs, Thefts, and hara-heartedness.`,
    kpBodyParts: `Osseous system, Joint bones, Teeth, Knees, Phalagm, Ears, Premogastic Nerve, Spleen, Legs, Bones, Muscles, Limbs, Skin, Hair`,
    kpPathogenicEffects: `Paralysis, Insanity, chronocity of a disease, Cancer and other Tumours, Elephantiasis, idosy and glandular diseases, pain or arches in stomach, damage and loss of limbs, disease of teeth, skin and legs, fracture of bones, disease connecting bones, Rheumatic pains, blindness, ugly hair, pain in muscle, hysteria and deafness, colds, fear complex, skin disease, baldness, tumours, cancer, obstruction, atrophy, gall bladder and bones and weakness in the body`,
    kpHoraSignifier: `Imaginative, Cautious action, Silence, Reserved nature, Patience, Preserving, Studious, Sincere, Faithfull, Envious, Jealousy, Untruthful, Lazy, Lair, Discontentment, Dishonest, Works in Woods, Unfrequented Places, Old Churches, Dilapidated (जीर्ण) buildings, Cess pools, Drains, Sewerage`,
    friends: `Rahu, Ketu, Mercury, Venus`,
    enemies: `Sun`,
    neutral: `Moon, Mars, Jupiter`,
    varna: ``,
    nature: `Malefic`,
    linga: `Impotent`,
    oneRashiTime: `2.5years`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: `Brahm`,
    elements: `Air`,
    trees: `Thorny, Sapless (शुष्क), Weak`,
    ages: [`1-6`, `36-41`],
    lunarAstro: {
      cabinetPlace: `Servant - Age`,
      basic: [
        `Focus, Misfortune, grief, separation, longevity, limitation`,
        `responsibility, discomfort, limitation, shame guilt anxiety`,
        `Saturn is an airy planet and represented by servants and highly malefic in nature, represented by black colour.`,
        `Saturn is son of Sun according to vedic mythology.`,
        `Saturn is also significator of work, sorrow, pain, old age, fear, diseases, hard work, labour work, patience`,
        `Saturn is lazy has big teeth and coarse hair.`,
        `Saturn is a natural justice giver and represents weaker section of society.`,
        `If placed well in chart make one work in court as Judge, law makers but in case afflicted it creates criminals.`,
        `Saturn makes people to work hard towards goal and then give results.`,
        `People under effect of Saturn rarely laugh or smile, Mostly they are serious in nature.`,
      ],
      business: [
        `Dealer of hardware or machinery, undertakes huge works`,
        `huge producer, contractor - works at these places`,
      ],
      predictive: [
        `Saturn 10th aspect give correction/surgery`,
        `Salt`,
        `Steel`,
      ],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [`Mental blockage to job/profession`],
        5: [],
        6: [],
        7: [],
        8: [
          `in Sagi - Major change in religion/higher learning(Sagi) due to old person(sat)`,
          `Transit: change in profession - Mrutyu House - Saturn Profession ka karak`,
        ],
        9: [],
        10: [],
        11: [],
        12: [`Vintage (Saturn) item in Bedroom(12)`],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [
        `Focus, Misfortune, grief, separation, longevity, limitation`,
        `responsibility, discomfort, limitation, shame guilt anxiety`,
      ],
      business: [
        `Dealer of hardware or machinery, undertakes huge works`,
        `huge producer, contractor - works at these places`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `7`,
    kalaBala: `stronger at night`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `libra`, number: 7 }, degree: 20 },
    debilitation: { inSign: { name: `aries`, number: 1 }, degree: 20 },
    moolatrikona: {
      inSignName: `aquarius`,
      inSignNumber: 11,
      startDegree: 1,
      endDegree: 20,
    },
    alphabetsSanskrit: [`प`, `फ`, `ब`, `भ`, `म`],
    alphabetsEnglish: [],
    places: [`dirty place`, `bathroom`, `garbage`],
  },
  {
    id: 9,
    graha: "Mercury (बुध)",
    name: `mercury`,
    vimshottariDashaYears: 7,
    uchhaRas: "Virgo (कन्या)",
    nicchaRas: "Pisces (मीन)",
    swaGraha: "Gemini, Virgo (मिथुन, कन्या)",
    color: "Grass green (हिरवा)",
    direction: `North (उत्तर)`,
    directionalStrengthIn: `East (पूर्व)`,
    directionRoom: `Living (sitting) room, Safe`,
    aspects: "7",
    characteristics: `Intelligence, Speech, Business, Math Ability, Maternal Uncle, Mathematics, Hand Work, Literature, Astrology, Education, Maternal Uncles, Intelligence and Intellect, Happiness through Children, Ultimate Knowledge, Deceiving Others, Proficiency In Writing Diplomatically, Handicrafts, Weaving; Sexual Enjoyments, Travels Over Water, Vegetables, Digestive Fire, Fanning, Parrots, Coloured Plays/Shows, Betel Leaves, Cages, Grass, Inciting Quarrels, Prosperity, Ambassadorial Functions.`,
    karakatva: `Education, Horses, Treasury, Mathematics, Knowledge, Speech, Dvijas, Infantry, Script, Writing, New clothes, Construction of palaces, Green colour, Sculpture, Astrology and astronDmy, Pilgrimages, Wise or good conversation or lecturing, Temples, Commerce, Best ornaments, Soft speech, Vedanta system of philosophy, Maternal grand-father, Bad dreams, Eunuch, Face turned to the north, Skin, Moisture, Bell-metal and the like, Renunciation, Seasons, Fine palace, Medical practitioner, Neck, Black magic, Child, Looking cross-wise, Heaven, Humility, Collaterals, Fear, Dancing, Devotion, Tendency to laughter, Strong at sunrise or morning, The season of hemanta or the beginning of winter, Scratching ailments, Self-control, Navel, Flourishing of one’s own dynasty, Mixed or compound substances, Presides over the Telugu language, Worship of Vishnu, Shudras, Birds, Younger coborns or brothers ••• sisters, Witty ianguage, Sticks, Atharvana Veda, Carrying out the karmas or rellgious rites, Towers of temples, Dust, Garden, Sex organs, Evenness, Beneficial nature, Wandering in the villages, Balanced nature and outlook, Fond of the north-western direction, Wellversed in the Puranas or minor epics, Grammiar, One skilled in researching and testing the precious stones—Ratna-Parakhi, Scholar, Maternal uncle, Mantras or sacred spells, Yantras or symbolic religious diagrams, Very powerful tantrik and the like.`,
    kpBodyParts: `Veins, Lungs, Tounges, Arms, Mouth, Hair Nervous system. Chest, Nerves, Skin, Naval Nose, Spinal systems, gall bladder`,
    kpPathogenicEffects: `Disease of chest and neryes and nose, disease from poison itches, mental disease of all kinds, madness, disease of gall bladder, paralysis, fits, ulcers, indigestion, cholera, disease of mouth and skin disease, neuromas, leucoderma, imposence, vertigo, deafness and skin diseases`,
    kpHoraSignifier: `Shape Mind, Intelligent, logician, studious, industry research minded, witty, learning without an instructor, travelled good knowledge in occult sciences, astrologers, mathematician/ travelling agents, poets, advocates, dress, printers, publish^/ orators, ambassadors, clerks, messengers, schools, assemblies, libraries`,
    friends: `Venus, Saturn, Rahu`,
    enemies: `Moon, Mars, Jupiter`,
    neutral: `Sun, Ketu`,
    varna: ``,
    nature: `Influenced`,
    linga: `Impotent`,
    oneRashiTime: `1months`,
    shubhAshub: `नैसर्गिक शुभ`,
    deities: `Vishnu`,
    elements: ``,
    trees: `Fruitless`,
    ages: [`34-35`],
    lunarAstro: {
      cabinetPlace: `Prince - Intelligence`,
      basic: [
        `Intellect, Intelligence, Speech, Networking, Communication`,
        `Writing, Acting and short distance travels`,
        `Mercury has the best appearance of all planets and considered as Prince.`,
        `Mercury governs intelligence, speech, writing, education, calculation and shows our intellectual powers and is considered as messenger of gods.`,
        `Mercury is planet of commerce and represents traders and businessman.`,
        `Mercury easily takes the nature of planet it gets associated with for good or bad. `,
        `Mercury is someone who pays attention to even minor details.`,
        `A afflicted Mercury gives speech defects, nervous problems, breathing problems and even skin ailments.`,
        `A good Mercury makes a wonderful businessman, who does multiple business at same time.`,
        `Mercury is knowledge but not wisdom so if someone has good Mercury with bad planet, What will be the result ?`,
      ],
      business: [
        `Chartered Accountant, Banker, Economist, Translator, Comedian, orator (वक्ता)`,
        `supervisor of rest house or Hostel (if weak would serve at such places)`,
      ],
      predictive: [
        `Pot (Matka)`,
        `Memories`,
        `Bua (Aunt)`,
        `Massi (Aunt)`,
        `Girl children (Betiya)`,
        `Neighbors`,
        `Speech`,
        `Cloths`,
        `Artifical things ceremic/bone china`,
      ],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [
        `Intellect, Intelligence, Speech, Networking, Communication`,
        `Writing, Acting and short distance travels`,
      ],
      business: [
        `Chartered Accountant, Banker, Economist, Translator, Comedian, orator (वक्ता)`,
        `supervisor of rest house or Hostel (if weak would serve at such places)`,
      ],
    },
    sthanBala: ``,
    digBalaIn: `1`,
    kalaBala: `stronger both during day and night`,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: `virgo`, number: 6 }, degree: 15 },
    debilitation: { inSign: { name: `pisces`, number: 12 }, degree: 15 },
    moolatrikona: {
      inSignName: `virgo`,
      inSignNumber: 6,
      startDegree: 15,
      endDegree: 20,
    },
    alphabetsSanskrit: [`ट`, `ठ`, `ड`, `ढ़`, `ण`],
    alphabetsEnglish: [],
    places: [`playground`, `garden`],
  },
  {
    id: 10,
    graha: "Uranus (हर्षल)",
    name: `uranus`,
    vimshottariDashaYears: 84,
    uchhaRas: "",
    nicchaRas: "",
    swaGraha: "",
    color: "",
    direction: "",
    directionalStrengthIn: ``,
    directionRoom: ``,
    aspects: "7",
    characteristics: ``,
    karakatva: ``,
    kpBodyParts: `Nervous system, Brain, Motor Nerves`,
    kpPathogenicEffects: `
    Aries:- Causes injuries to head, and head ache on any one side,
    Taurus:- Sore throat, swellings, mumps and loss of speech,
    Gemini:- Produces Pneumonia, branchial affection, Asthma and cough,
    Cancer:- Dyspepsia fracture of ribs and ulcers in breasts,
    Leo:- Affects the vertebral column, spine and the heart,
    Virgo:- Cause disorders in the digestive system and electric shock,
    Libra:- Inflammation of the kidneys, diabeties stone In bladder and pains in hips,
    Scorpio:- Hernia, Hydrocele, disease of generative system, piles and fistula,
    Saggittarius:- Tetanus, dog bits, hydrophobia, and fractures of hips and thighs,
    Capricorn:- Causes the disease of Rheumatism, dislocations, fracture etc,
    Aquarius:- Heart failures, death due to poisonous gem,
    Pisces:- Fracture, lameness, amputation, scurry, scabies or eczema,
    The diseases denoted by Uranus are Hysteria, Paralysis, spasmas mental derangement, Premature delivery, abnormal growth, palpitation, spinal menangjtls and venereal eruption etc
    `,
    kpHoraSignifier: `Intelligent or ecentric, Fortune, Violence, Sudden changes, Invention, Novelty, Harmony or Divorce, Astrologers, Teachers, Lecturers, Sculptors, Hypnotists, Mesmerise, Railways, Banks, Gas plants, Asylum, Dispensaries, Research Labs, Aerodrome, etc`,
    friends: ``,
    enemies: ``,
    neutral: ``,
    varna: ``,
    nature: `Malefic`,
    linga: `Male`,
    oneRashiTime: `7years`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: ``,
    elements: ``,
    trees: ``,
    ages: [],
    lunarAstro: {
      cabinetPlace: ``,
      basic: [],
      business: [],
      predictive: [],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [``],
      business: [],
    },
    sthanBala: ``,
    digBalaIn: ``,
    kalaBala: ``,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: ``, number: 1 }, degree: 3 },
    debilitation: { inSign: { name: ``, number: 1 }, degree: 10 },
    moolatrikona: {
      inSignName: ``,
      inSignNumber: 1,
      startDegree: 0,
      endDegree: 20,
    },
    alphabetsSanskrit: [],
    alphabetsEnglish: [],
    places: [],
  },
  {
    id: 11,
    graha: "Neptune",
    name: `neptune`,
    vimshottariDashaYears: 84,
    uchhaRas: "",
    nicchaRas: "",
    swaGraha: "",
    color: "",
    direction: "",
    directionalStrengthIn: ``,
    directionRoom: ``,
    aspects: "7",
    characteristics: ``,
    karakatva: ``,
    kpBodyParts: `Nervous system, Resina, the Optic Nerves, Cerebro-Spinal fluids etc`,
    kpPathogenicEffects: `Eye troubles, derangement of Brain coma, catalepsy, trance, insanity, dropsy, narcotic poisoning, leprosy, allergy and neutrasthenia etc`,
    kpHoraSignifier: `Ideal people with rellgious bent of mind and psychic powers, Lethargic (सुस्त), Lazy, Irresponsible and immoral who will cheat others and be dishonest.
    Poets, Anaesthetists, Chemists, Detectives, Liquids, Oils, who work in oil field, Gas, Fish, Sea, Hospitals, Jails, Caves, Submarine, etc`,
    friends: ``,
    enemies: ``,
    neutral: ``,
    varna: ``,
    nature: `Malefic`,
    linga: `Impotent`,
    oneRashiTime: `14years`,
    shubhAshub: `नैसर्गिक अशुभ`,
    deities: ``,
    elements: ``,
    trees: ``,
    ages: [],
    lunarAstro: {
      cabinetPlace: ``,
      basic: [],
      business: [],
      predictive: [],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      withPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    bhrigu: {
      basic: [``],
      business: [],
    },
    sthanBala: ``,
    digBalaIn: ``,
    kalaBala: ``,
    chestaBala: ``,
    naisargikBala: ``,
    drikBala: ``,
    exaltation: { inSign: { name: ``, number: 1 }, degree: 3 },
    debilitation: { inSign: { name: ``, number: 1 }, degree: 10 },
    moolatrikona: {
      inSignName: ``,
      inSignNumber: 1,
      startDegree: 0,
      endDegree: 20,
    },
    alphabetsSanskrit: [],
    alphabetsEnglish: [],
    places: [],
  },
]);
