import React, {
  useState
} from 'react';
import {
  Accordion,
  Divider,
  Icon,
  Message,
} from 'semantic-ui-react';

const rules = [
  {
    key: 'violent-signs',
    header: `Violent Signs`,
    content: <Message.List>
      <Message.Item>
        Aries, Scopio & Capricorn (1, 8, 10) [These signs ruled by Mars & Saturn tend to be destructive and Taamsik in nature and when afflicted in a horoscope will manifest violent characteristics
      </Message.Item>
    </Message.List>,
  },{
    key: 'barren-signs',
    header: `Barren Signs`,
    content: <Message.List>
      <Message.Item>
        In terms of female fertility (for conception) Aries, Gemini, Leo, Virgo (1, 3, 5, 6) are considered Barren (not be fruitful for conception).
      </Message.Item>
    </Message.List>,
  },{
    key: 'fruitful-signs',
    header: `Fruitful Signs`,
    content: <Message.List>
      <Message.Item>
         For conception are Cancer, Scorpio and Pisces (the water element signs - 4, 8, 12)
         Also are Taurus, Cancer, Libra, Scorpio, Sagit, Caprico, Aquar, Pisces (2, 4, 7, 8, 9, 10, 11, 12)
      </Message.Item>
    </Message.List>,
  },{
    key: 'shirshodaya-signs',
    header: `Shirshodaya Signs`,
    content: <Message.List>
      <Message.Item>
        Rising head first – Gemini, Leo, Virgo, Libra, Scorpio, Aquarius – Planets
      </Message.Item>
      <Message.Item>
        in these signs will tend to give results in the First half of their dashas
      </Message.Item>
    </Message.List>,
  },{
    key: 'prishtodaya-signs',
    header: `Prishtodaya Signse`,
    content: <Message.List>
      <Message.Item>
        Rising with Back – Aries, Taurus, Cancer, Saggitarius, Capricorn – Planets
      </Message.Item>
      <Message.Item>
        in these signs will tend to give results in the Second half of their dashas
      </Message.Item>
    </Message.List>,
  },{
    key: 'ubabodaya-signs',
    header: `Ubabodaya Signs`,
    content: <Message.List>
      <Message.Item>
        Rising both side – Pisces – Planets
      </Message.Item>
      <Message.Item>
        in this sign tend to give results in the Middle half of their dashas
      </Message.Item>
    </Message.List>,
  },
];

const MiscSignifications = () => {
  const [activeIndex, setActiveIndex] = useState(false)

  const handleClick = (e, titleProps) => {
    console.warn('handleClick e', e, titleProps);
    const { index } = titleProps

    setActiveIndex(activeIndex === index ? -1 : index)
  }

  return (
    <Accordion panels={[
        {
          key: 'planet-capacity',
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <Divider horizontal><Icon name='dropdown' />Misc. Significations</Divider>
            </Accordion.Title>
          ),
          content: <Accordion.Content active={activeIndex === 0}>
              {rules.map((rule) => {
                return <Message floating key={rule.key}>
                  <Message.Header>
                    {rule.header}
                  </Message.Header>
                  <Message.Content>
                    {rule.content}
                  </Message.Content>
                </Message>
              })}
            </Accordion.Content>,
          active: true
        },
      ]}
    />
  );
};

export default MiscSignifications;
