import React, { useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { NakshatraContext } from "../../contexts/nakshatra.context";

import "./index.css";

const Planet = ({ kpPlanet }) => {
  const { planet, nakshatraLord, subLord, nl, sl, nlOfSl } = kpPlanet;
  const nlord = nakshatraLord || nl;
  const slord = subLord || sl;

  return (
    <>
      <Table>
        <Tbody>
          <Tr>
            <Td className="kp-planet">{`${planet.untenanted ? "[" + planet.name + "]" : planet.name}`}</Td>
            <Td className="kp-planet-houses">
              {planet?.basicSignificators?.join(", ")}
              {planet?.utCSLOf}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Table>
        <Tbody>
          <Tr>
            <Td className="kp-planet">{nlord?.name}</Td>
            <Td className="kp-planet-houses">
              {nlord?.basicSignificators?.join(", ")}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Table>
        <Tbody>
          <Tr>
            <Td className="kp-planet">{slord?.name}</Td>
            <Td className="kp-planet-houses">
              {slord?.basicSignificators?.join(", ")}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {nlOfSl && (
        <Table>
          <Tbody>
            <Tr>
              <Td className="kp-planet">{nlOfSl?.name}</Td>
              <Td className="kp-planet-houses">
                {nlOfSl?.basicSignificators?.join(", ")}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </>
  );
};

export const KPSignificatorsPlanets = ({ chartType = "jhora" }) => {
  let kpSignificatorsPlanets = [];
  const {
    state: {
      jHoraChart: { jHoraChartData } = {},
      horosoftChart: { horosoftChartData } = {},
    },
  } = useContext(NakshatraContext);

  if (chartType === "jhora") {
    kpSignificatorsPlanets = jHoraChartData?.kpSignificatorsPlanets;
  } else if (chartType === "horosoft") {
    kpSignificatorsPlanets = horosoftChartData?.kpSignificatorsPlanets;
  }

  if (!kpSignificatorsPlanets) {
    return null;
  }

  const {Ke, Mo, Ju, Ve, Ma, Sa, Su, Ra, Me} = kpSignificatorsPlanets;
  const {Ket, Mon, Jup, Ven, Mar, Sat, Sun, Rah, Mer} = kpSignificatorsPlanets;

  return kpSignificatorsPlanets ? (
    <Table>
      <Thead>
        <Tr>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>
            <Planet kpPlanet={Ke || Ket} />
          </Td>
          <Td>
            <Planet kpPlanet={Mo || Mon} />
          </Td>
          <Td>
            <Planet kpPlanet={Ju || Jup} />
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Planet kpPlanet={Ve || Ven} />
          </Td>
          <Td>
            <Planet kpPlanet={Ma || Mar} />
          </Td>
          <Td>
            <Planet kpPlanet={Sa || Sat} />
          </Td>
        </Tr>
        <Tr>
          <Td>
            <Planet kpPlanet={Su || Sun} />
          </Td>
          <Td>
            <Planet kpPlanet={Ra || Rah} />
          </Td>
          <Td>
            <Planet kpPlanet={Me || Mer} />
          </Td>
        </Tr>
        <Tr>
          <Td>[] - Untenanted Planet</Td>
          <Td>() - Independent House</Td>
          <Td>{`{} - Untenanted Planet as CSL`}</Td>
        </Tr>
        <Tr>
          <Td>{`<> - Tenanted Planet's NL as CSL`}</Td>
        </Tr>
      </Tbody>
    </Table>
  ) : null;
};
