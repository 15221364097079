import React, {
  useState,
} from 'react';
import {
  Accordion,
  Divider,
  Icon,
} from 'semantic-ui-react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from 'react-super-responsive-table'

const TrikonYog = () => {
  const [activeIndex, setActiveIndex] = useState(false)

  const handleClick = (e, titleProps) => {
    console.warn('handleClick e', e, titleProps);
    const { index } = titleProps

    setActiveIndex(activeIndex === index ? -1 : index)
  }

  return (
    <Accordion panels={[
        {
          key: 'trilok-yog',
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <Divider horizontal><Icon name='dropdown' />त्रिकोण योग</Divider>
            </Accordion.Title>
          ),
          content: (
            <Accordion.Content active={activeIndex === 0}>
              <p>
                जेव्हा पत्रिकेमध्ये कोणत्याही स्थानात चार किंवा चारपेक्षा जास्त ग्रह येतात
              </p>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Yog</Th><Th>Rashies</Th><Th>Meaning</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr><Td>धर्म त्रिकोण</Td><Td>[1, 5, 9]</Td><Td>चरित्र, विस्वास, परम्परा, देव्, धार्मिक, देवावर नितांत श्रद्घा</Td></Tr>
                  <Tr><Td>अर्थ् त्रिकोण</Td><Td>[2, 6, 10]</Td><Td>पैसा, सम्पत्ति, कोणत्या गोष्टीतून पैसा कसा काढावा?</Td></Tr>
                  <Tr><Td>काम त्रिकोण</Td><Td>[3, 7, 11]</Td><Td>मोह, माया, वैवाहिक आयुष्य अधिक सुखकर, संततीसुख</Td></Tr>
                  <Tr><Td>मोक्ष त्रिकोण</Td><Td>[4, 8, 12]</Td><Td>मोक्ष प्रप्ति, ऊत्त्रधा, भक्ती किंवा आध्यात्मिक मर्ग</Td></Tr>
                </Tbody>
              </Table>
            </Accordion.Content>
          ),
          active: true
        },
      ]}
    />
  )
};

export default TrikonYog;
