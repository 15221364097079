import React, { useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { NakshatraContext } from "../../contexts/nakshatra.context";

const houseNames = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];

const CuspChart = ({ chartType = "jhora" }) => {
  let chartData = null;
  const {
    state: {
      jHoraChart: { jHoraChartData } = {},
      horosoftChart: { horosoftChartData } = {},
    },
  } = useContext(NakshatraContext);

  if (chartType === "jhora") {
    chartData = jHoraChartData?.chartData;
  } else if (chartType === "horosoft") {
    chartData = horosoftChartData?.chartData;
  }

  return chartData ? (
    <div>
      Nirayana Cusps
      <Table>
        <Thead>
          <Tr>
            <Th>House</Th>
            <Th>Sign</Th>
            <Th>Degrees</Th>
            <Th>RL</Th>
            <Th>NL</Th>
            <Th>SL</Th>
            <Th>SSL</Th>
          </Tr>
        </Thead>
        <Tbody>
          {houseNames.map(
            (house) =>
              chartData[house] && (
                <Tr key={house}>
                  <Td>{house}</Td>
                  <Td>{chartData[house].sign}</Td>
                  <Td>{`${
                    chartData[house].degree?.degrees || chartData[house].degrees
                  }:${
                    chartData[house].degree?.minutes || chartData[house].minutes
                  }'`}</Td>
                  <Td>{chartData[house].rl}</Td>
                  <Td>{chartData[house].nl}</Td>
                  <Td>{chartData[house].sl}</Td>
                  <Td>{chartData[house].ssl}</Td>
                </Tr>
              )
          )}
        </Tbody>
      </Table>
    </div>
  ) : null;
};

export default CuspChart;
