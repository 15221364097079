const houseCoordinates = (width, height, label) => {
  const firstHouse = `
    ${parseInt(width / 2)},0
    ${parseInt(width / 4)},${parseInt(height / 4)}
    ${parseInt(width / 2)},${parseInt(height / 2)}
    ${parseInt(width - width / 4)},${parseInt(height / 4)}
    ${parseInt(width / 2)},0
  `;
  const firstHouseNameTextCoordinates = {
    x: width / 2 - (label.length > 1 ? 10 : 4),
    y: height / 2 - 10,
  };

  const firstHouseMiddleTextCoordinates = {
    x: width / 2,
    y: height / 4,
  };

  const secondHouse = `
    0,0
    ${parseInt(width / 2)},0
    ${parseInt(width / 4)},${parseInt(height / 4)}
    0,0
  `;

  const secondHouseNameTextCoordinates = {
    x: width / 4 - (label.length > 1 ? 9 : 4),
    y: height / 4 - 12,
  };

  const secondHouseMiddleTextCoordinates = {
    x: width / 4,
    y: height / 8,
  };

  const thirdHouse = `
    0,0
    ${parseInt(width / 4)},${parseInt(height / 4)}
    0,${parseInt(height / 2)}
    0,0
  `;

  const thirdHouseNameTextCoordinates = {
    x: width / 4 - (label.length > 1 ? 25 : 20),
    y: height / 4 + 5,
  };

  const thirdHouseMiddleTextCoordinates = {
    x: width / 16,
    y: height / 4,
  };

  const fourthHouse = `
    ${parseInt(width / 4)},${parseInt(height / 4)}
    0,${parseInt(height / 2)}
    ${parseInt(width / 4)},${parseInt(height - height / 4)}
    ${parseInt(width / 2)},${parseInt(height / 2)}
    ${parseInt(width / 4)},${parseInt(height / 4)}
  `;

  const fourthHouseNameTextCoordinates = {
    x: width / 2 - (label.length > 1 ? 28 : 22),
    y: height / 2 + 5,
  };

  const fourthHouseMiddleTextCoordinates = {
    x: width / 4,
    y: height / 2,
  };

  const fifthHouse = `
    0,${parseInt(height / 2)}
    ${parseInt(width / 4)},${parseInt(height - height / 4)}
    0,${height}
    0,${parseInt(height / 2)}
  `;

  const fifthHouseNameTextCoordinates = {
    x: width / 4 - (label.length > 1 ? 25 : 20),
    y: height - height / 4 + 5,
  };

  const fifthHouseMiddleTextCoordinates = {
    x: width / 16,
    y: height - height / 4 + 5,
  };

  const sixthHouse = `
    0,${height}
    ${parseInt(width / 4)},${parseInt(height - height / 4)}
    ${parseInt(width / 2)},${height}
    0,${height}
  `;

  const sixthHouseNameTextCoordinates = {
    x: width / 4 - (label.length > 1 ? 9 : 4),
    y: height - height / 4 + 22,
  };

  const sixthHouseMiddleTextCoordinates = {
    x: width / 4,
    y: height - height / 16,
  };

  const seventhHouse = `
    ${parseInt(width / 2)},${parseInt(height / 2)}
    ${parseInt(width / 4)},${parseInt(height - height / 4)}
    ${parseInt(width / 2)},${height}
    ${parseInt(width - width / 4)},${parseInt(height - height / 4)}
    ${parseInt(width / 2)},${parseInt(height / 2)}
  `;

  const seventhHouseNameTextCoordinates = {
    x: width / 2 - (label.length > 1 ? 9 : 4),
    y: height / 2 + 22,
  };

  const seventhHouseMiddleTextCoordinates = {
    x: width / 2,
    y: height - height / 4,
  };

  const eighthHouse = `
    ${parseInt(width - width / 4)},${parseInt(height - height / 4)}
    ${width},${height}
    ${parseInt(width / 2)},${height}
    ${parseInt(width - width / 4)},${parseInt(height - height / 4)}
  `;

  const eighthHouseNameTextCoordinates = {
    x: width - width / 4 - (label.length > 1 ? 10 : 4),
    y: height - height / 4 + 22,
  };

  const eighthHouseMiddleTextCoordinates = {
    x: width - width / 4,
    y: height - height / 16,
  };

  const ninethHouse = `
    ${width},${parseInt(height / 2)}
    ${parseInt(width - width / 4)},${parseInt(height - height / 4)}
    ${width},${height}
    ${width},${parseInt(height / 2)}
  `;

  const ninethHouseNameTextCoordinates = {
    x: width - width / 4 + (label.length > 1 ? 10 : 12),
    y: height - height / 4 + 5,
  };

  const ninethHouseMiddleTextCoordinates = {
    x: width - width / 12,
    y: height - height / 4,
  };

  const tenthHouse = `
    ${parseInt(width - width / 4)},${parseInt(height / 4)}
    ${parseInt(width / 2)},${parseInt(height / 2)}
    ${parseInt(width - width / 4)},${parseInt(height - height / 4)}
    ${width},${parseInt(height / 2)}
    ${parseInt(width - width / 4)},${parseInt(height / 4)}
  `;

  const tenthHouseNameTextCoordinates = {
    x: width / 2 + (label.length > 1 ? 10 : 15),
    y: height / 2 + 5,
  };

  const tenthHouseMiddleTextCoordinates = {
    x: width - width / 4,
    y: height - height / 2,
  };

  const eleventhHouse = `
    ${width},0
    ${parseInt(width - width / 4)},${parseInt(height / 4)}
    ${width},${parseInt(height / 2)}
    ${width},0
  `;

  const eleventhHouseNameTextCoordinates = {
    x: width - width / 4 + (label.length > 1 ? 10 : 10),
    y: height / 4 + 5,
  };

  const eleventhHouseMiddleTextCoordinates = {
    x: width - width / 8,
    y: height / 4,
  };

  const twelvethHouse = `
    ${parseInt(width / 2)},0
    ${parseInt(width - width / 4)},${parseInt(height / 4)}
    ${width},0
    ${parseInt(width / 2)},0
  `;

  const twelvethHouseNameTextCoordinates = {
    x: width - width / 4 - (label.length > 1 ? 9 : 5),
    y: height / 4 - 12,
  };

  const twelvethHouseMiddleTextCoordinates = {
    x: width - width / 4,
    y: height / 8,
  };

  return {
    firstHouse,
    firstHouseNameTextCoordinates,
    firstHouseMiddleTextCoordinates,
    secondHouse,
    secondHouseNameTextCoordinates,
    secondHouseMiddleTextCoordinates,
    thirdHouse,
    thirdHouseNameTextCoordinates,
    thirdHouseMiddleTextCoordinates,
    fourthHouse,
    fourthHouseNameTextCoordinates,
    fourthHouseMiddleTextCoordinates,
    fifthHouse,
    fifthHouseNameTextCoordinates,
    fifthHouseMiddleTextCoordinates,
    sixthHouse,
    sixthHouseNameTextCoordinates,
    sixthHouseMiddleTextCoordinates,
    seventhHouse,
    seventhHouseNameTextCoordinates,
    seventhHouseMiddleTextCoordinates,
    eighthHouse,
    eighthHouseNameTextCoordinates,
    eighthHouseMiddleTextCoordinates,
    ninethHouse,
    ninethHouseNameTextCoordinates,
    ninethHouseMiddleTextCoordinates,
    tenthHouse,
    tenthHouseNameTextCoordinates,
    tenthHouseMiddleTextCoordinates,
    eleventhHouse,
    eleventhHouseNameTextCoordinates,
    eleventhHouseMiddleTextCoordinates,
    twelvethHouse,
    twelvethHouseNameTextCoordinates,
    twelvethHouseMiddleTextCoordinates,
  };
};

export default houseCoordinates;
