module.exports = Object.freeze({
  1: {
    numbers: [19, 1, 10, 28],
    tagline: `Communication (Verbal)`,
    qualities: [
      `Leadership Quality`,
      `Royal`,
      `Authoritative personality`,
      `Pioneers`,
      `Innovators`,
      `Cant' perform under pressure`,
      `Always want freedom`,
      `Dominating`,
    ],
    owners: [`Sun`, `Surya`, `King`, `Fire`],
    shortcharacters: `King like,authoritative,pioneers, royal, trend setters , leadership , ego`,
    characters: `leadership quality, authorative personality, pioneers, innovaters, dominating, royal, always want freedom (band kar reh nahin sakte and dabb kar bhi nahin reh sakte)`,
    representingColors: `All Shades of red`,
    avoidColors: `black, darkblue`,
    dayOfWeek: `Sunday`,
    element: [`Water`],
    remediesFengShui: `Red Mouli Baande on hand (Right for male, Right hand unmarried female, Left hand married female), Red Jasper bracelet`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: `Sun support, Surya ko jal (water) de (5:10 - 5:40 UV rays), Om surya ya namaha, red tika on forehead`,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Drink lots of water. Place picture of waterfall / river on North wall`,
    astroNumerology: ``,
  },
  2: {
    numbers: [11, 2, 20, 29],
    tagline: `Intuition Power, Sensitivity`,
    qualities: [
      `Vacillating Mind`,
      `Fluctuating Mind`,
      `Good in helping others`,
      `Helping Nature`,
      `The best intuition power`,
      `Difficult in taking decisions`,
      `Always required support from others Attracting `,
      `Indecisive `,
      `No Mood ek Saman`,
      `Intuition Power`,
      `Sensitivity`,
    ],
    owners: [`Moon`, `Chandra`, `Queen`, `Water`],
    shortcharacters: `11 = master number. Emotional,gentle,slow, sensitive attractive, intuitive, sensitive, mood swing`,
    characters: `Vasilating mind, very gud in helping others, the best intuition power. AlWays avoid crowd, love to help others first. Jaldi se logo se mix up nabin hote but jab dil mein basa lete hai toh 40 exit from their heart. Pal mein tola pal mein masha, fluctuating mind,no mood ek saman, delicate, nazook, attracting, helping nature, jana tha japan pahuch gaye china, indecessive, mauke par decession nahin le sakte, always support reqd by them`,
    representingColors: `Off white`,
    avoidColors: `black, darkblue`,
    dayOfWeek: `Monday`,
    element: [`Earth`],
    remediesFengShui: `Wear crystal bracelet, Green color aventurine bracelet - Left hand for all`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: `Moon support, Aquarium in north with 9 fishes (1 black, 1 blue, remain ok) check D-C, Running water fountain in north`,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear crystal bracelet or neckless`,
    astroNumerology: ``,
  },
  3: {
    numbers: [12, 21, 3, 30],
    tagline: `Imagination Power`,
    qualities: [
      `Always hungry for knowledge`,
      `Very good counselor`,
      `jahan na pahuche ravi vahan pahuche kavi`,
      `Best Mind Visualization`,
      `Best Teacher`,
      `Preacher in life`,
      `Very good healer`,
      `sparsh se theek kar dete hai`,
      `Good starter but bad finisher of all the work they start`,
    ],
    owners: [`Jupiter`, `Guru`, `Counselor`],
    shortcharacters: `Imaginative, healer, preacher, spiritual,religious, councellor, wise`,
    characters: `Always hungry for knowledge, very good councelar, jahan na pahuche ravi vahan pahuche kavi, mind visulation very best, very good healer, sparsh se theek kar dete hai, good starter but bad finisher of all the work you start , very best teacher, preacher in life`,
    representingColors: `Shades of yellow, gold`,
    avoidColors: `white`,
    dayOfWeek: `Thursday`,
    element: [`Wood`],
    remediesFengShui: `Wear wood element / Rudraksh bracelet / Tulsi mala or bracelet, Machis ki dabbi, wooden pen`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear wooden bracelet or neckless`,
    astroNumerology: ``,
  },
  4: {
    numbers: [22, 4, 31, 13],
    tagline: `Discipline, Organized`,
    qualities: [
      `Always in controversy`,
      `Success mileage but with hurdles`,
      `Believe in themselves only`,
      `Full of ego in life`,
      `Dabang`,
      `Rebellious`,
      `they always want to dictate in life`,
      `Struggling`,
      `Impulsive`,
      `Short tempered`,
      `abusive`,
      `harsh Decision maker`,
      `delar`,
      `ladake`,
      `Very good friend and very good foe (dushman)`,
      `Asscenric (chuparustam)`,
      `Life mein Maut manzoor but Matt manzoor nahi`,
      `Apne cards jaldi se nahi kholte hai`,
    ],
    owners: [`Rahu`, `Gunda`, `Daredevil`],
    shortcharacters: `Disciplined, organized, short tempered, rebellious, abusive , stubborn, logical impulsive. 13 = Karmik no.`,
    characters: `Always in controversy, success milege but with hurdles. Believe in themselves only. Full ot 220 in life. Dabang,reballious,they always want to dictate in life, struggling, implusive, short tempered, abusive, hash decesion maker, delar, ladake, disciplined, logical, bina logic k aage nahin chal sakte, hatyogi, stubborn, pran jaye par vachan na jaye, very good friend and very good foe(dushman), sanki, assentric, gussa bahut jaldi aata hoga, chupa tustam, ok sath snan aur maat dene wale, maut manjoor par maat manjoor nahin, apne cards jaldi open nahin karte.`,
    representingColors: `Shades of black`,
    avoidColors: `black, darkblue`,
    dayOfWeek: `Sunday`,
    element: [`Wood`],
    remediesFengShui: `Wear wood element / Rudraksh bracelet / Tulsi mala or bracelet, Machis ki dabbi, wooden pen`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear wooden bracelet or neckless`,
    astroNumerology: `Sudden events, virus, researcher, research`,
  },
  5: {
    numbers: [14, 23, 5],
    tagline: `Balanced Emotion`,
    qualities: [
      `Most luckiest number among all numbers`,
      `born lucky in this life`,
      `Most adjustable nature`,
      `koi gila shikva nahin life se`,
      `mostly satisfied in life`,
      `Intelligent`,
      `entertaining`,
      `communication very good`,
      `They always bounce back in life`,
      `Sensitive`,
      `Lazy`,
      `Aalsi`,
      `Befikr`,
      `Very slow in life to take decisions`,
    ],
    owners: [`Mercury`, `Budh`, `Prince/Princess`],
    shortcharacters: `Happy go lucky, lazy, dark horse, entertaining, communicative`,
    characters: `Most luckiest number among all numbers, born lucky in this life. Most adjustable nature, koi gila shikva nahin life se, mostly satisfied in life, balanced, Dounce back in life. intelligent, entertaining, communication very good, sensative, lazy, aalsi, befikr.`,
    representingColors: `Shades of green`,
    avoidColors: `No Enemy colors`,
    dayOfWeek: `Wednesday`,
    element: [`Earth`],
    remediesFengShui: `Wear crystal bracelet, Green color aventurine bracelet - Left hand for all`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear crystal bracelet or neckless`,
    astroNumerology: `Mercury, Communication, Change, Business, Internet, Tours and Travel`,
  },
  6: {
    numbers: [15, 6, 24],
    tagline: `By Hook or by Crook, Home & Family`,
    qualities: [
      `Truly family people always ready for family every time`,
      `Opposite sex attraction`,
      `Highly manipulative`,
      `jugadu`,
      `Highly Romantic & Highly diplomatic`,
      `Dil maange more always`,
      `guru ghantaal`,
      `nature they like most`,
      `Always ready for outdoor or outing`,
      `Travel karna they like the most`,
    ],
    owners: [`Venus`, `Shukr`, `Guru Ghantal`],
    shortcharacters: `Romantic, communicator, manipulative, diplomatic, family oriented, shrewd`,
    characters: `Truely family people always ready for family everytime. Oposite sex attraction, Highly manipulative, jugadu, highly romantic, diplomatic(soch samaj kar hi answer deta hai questions ka) sensual, shrewd, very good entertainers, dil maange more always, guru shantaal, nature they like most, always ready for outdoor or outing. Travel karna they like the Most.`,
    representingColors: `Shades of white, all new colors, shades of neon, sometimes skyblue`,
    avoidColors: `yellow`,
    dayOfWeek: `Friday`,
    element: [`Metal`, `Gold`],
    remediesFengShui: `White crystal bracelet, Watch with golden strap (avoid leather strap watch)`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear watch or bracelet of gold chain`,
    astroNumerology: ``,
  },
  7: {
    numbers: [25, 16, 7],
    tagline: `Research & Disappointment`,
    qualities: [
      `Trust jaldi kar lete hai`,
      `Self Doubt`,
      `Life mein hamesha dhookha hi milta hai har stage par`,
      `Scandals/ married life?`,
      `Health Problems?`,
      `Emotional setbacks/Love marriage/financial losses & health sector loss`,
      `Low in confidence`,
      `highly spiritual/religious`,
      `occult`,
      `highly secretive`,
      `confused`,
      `Opposite sex mein attraction always`,
    ],
    owners: [`Ketu`, `Shadow`, `No Existence`],
    shortcharacters: `low in confidence, inquisitive, very good healer occult, spiritual religious, researcher, introvert. 16 = Karmik no`,
    characters: `Trust jaldi kar lete hai, life mein hamesha dhookha hi milta hai har stage par. Scandals, married life??? Emotional setbacks/Love marriage/financial losses& health sector loss. low in confidence, highly spiritual/religious, occult, highly secretive, confused, self doubt, searcher, researchers, opp sex mein attraction,`,
    representingColors: `Shades of gray, silver`,
    avoidColors: `No Enemy colors`,
    dayOfWeek: `Monday`,
    element: [`Metal`, `Silver`],
    remediesFengShui: `Watch Silver strap / 7 Chakra`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear silver bracelet or neckless`,
    astroNumerology: ``,
  },
  8: {
    numbers: [17, 8, 26],
    tagline: `Struggle same as number 4`,
    qualities: [
      `Struggle whole life`,
      `success delayed but not denied`,
      `Want isolation in this life`,
      `Personality just like bulldozers`,
      `'aade teh vaade'`,
      `Fixed ideas`,
      `fixed likings`,
      `fixed dislikings`,
      `jo keh diya woh keh diya jaise pather par lakeer`,
      `Egoistic in life`,
      `believe in himself only`,
      `Gussa har samay naak pe hota hai`,
      `Best money managers/fund managers`,
      `Life mein har kaam mein logic chahtye`,
    ],
    owners: [`Saturn`, `Shani`, `Judge`],
    shortcharacters: `Struggle, laborious, hardwork, logical, argumentative, stubborn, law, finace.`,
    characters: `Struggle whole life, success delayed but not denied. Personality just like bulldozers, “Naade ten vaade". fixed ideas. fixed likings, fixed dislikings, jo keh diya woh keh diya jaise pather par lakeer. Egoistic in life, believe in himself only. Life mein har kaam mein logic chaiye, bina logic ko samjhe aage proceed nahin karte. Gussa har samay naak par hota hat.mBest money managers/ fund managers. Paise karch kaise karte hai and bachana kaise hai they know the best, want isolation in this life.`,
    representingColors: `Shades of darkblue, sometimes skyblue`,
    avoidColors: `red, black, darkblue`,
    dayOfWeek: `Saturday`,
    element: [`Earth`],
    remediesFengShui: `Wear crystal bracelet, Green color aventurine bracelet - Left hand for all`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear crystal bracelet or neckless`,
    astroNumerology: ``,
  },
  9: {
    numbers: [27, 9, 18],
    tagline: `Humitarian`,
    qualities: [
      `Personality just like Daanveer Karna`,
      `Always driven by mood if mood is good toh chaiyee jaan bhi maang lo mana nahin karenge`,
      `Always ready to help others`,
      `Always on good track`,
      `bad habit not acceptable`,
      `Truly Humitarian number`,
      `Saatvik`,
      `hate sex (non sensual)`,
      `True warriors in life rough & tough personality`,
      `Achieved in life through Hard work only`,
      `Bad Habit not acceptable`,
    ],
    owners: [`Mars`, `Mangal`, `Senapati`, `Commander in Chief`],
    shortcharacters: `Unpredictable behaviour, worrior, rough n tough, good humanitarian, egoistic`,
    characters: `Personality just like daanveer karna. Samjhana bahut mushkil hai, Always driven by mood if mood is gud toh chaiyee jaan bat maang lo mana nahin karenge. Always ready to help others whether he/ she required cash kind or both and even help mangane wala raat ko 12 baje kyun na aaye tab bhi dukhi nahin hote hai. Kabhi help ko mana nahin karte just like "daanvir karn". And help karne k baad in return vapis kuch nahin mangate. Truely humitarian number. Maut manjoor but maat manjoor nahin, sir katwa sakte hai but jhooka nahin sakte, (seit esteem) izzat k leye sar katwa bhi sakte hai aur jaan le bhi sakte hai, True warriors in ite rough &tough personality. Always on good track, bad habbit not acceptable, saatvik, hate sex(non sensual).`,
    representingColors: `orange, red, maroon`,
    avoidColors: `white, black, darkblue`,
    dayOfWeek: `Tuesday`,
    element: [`Fire`],
    remediesFengShui: `Red Mouli Baande on hand (Right for male, Right hand unmarried female, Left hand married female), Red Jasper bracelet`,
    remediesLalkitab: `Donate 1x Atta + 1/2x ground Sugar mix it fed it to ants - Ficus tree (पीपल का पेड़), Bramhastra -  108 roties + 5 kg/lb gudh (Jaggary) feed it to cows - 3 times sa-tue-sat or tue-sat-tue`,
    remediesVedic: ``,
    remediesBirthday: `Free 5 green parrots after feeding them, Feed cow chara(fodder) of your weight (Tula daan remedy)`,
    remediesGeneric: `Wear read thread on wrist`,
    astroNumerology: ``,
  },
});
