export default Object.freeze({
  क: "Ma",
  ख: "Ma",
  ग: "Ma",
  घ: "Ma",
  ड़: "Ma",
  च: "Ve",
  द्द: "Ve",
  ज: "Ve",
  झ: "Ve",
  ञ: "Ve",
  ट: "Me",
  ठ: "Me",
  ड: "Me",
  ढ़: "Me",
  ण: "Me",
  त: "Ju",
  थ: "Ju",
  द: "Ju",
  ध: "Ju",
  न: "Ju",
  प: "Sa",
  फ: "Sa",
  ब: "Sa",
  भ: "Sa",
  म: "Sa",
  य: "Mo",
  र: "Mo",
  ल: "Mo",
  व: "Mo",
  श: "Mo",
  स: "Mo",
  ह: "Mo",
  अ: "Su",
  आ: "Su",
  इ: "Su",
  ई: "Su",
  उ: "Su",
  ऊ: "Su",
});
