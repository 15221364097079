const grid = require('../../../data/numerology/loShuGrid')
// const planes = require('../../../data/numerology/loShuPlanes')

const digitized = (number) => {
  if (number) {
    const digits = number.toString().split('');
    return digits.map(Number);
  }
  return;
};

const singledDigits = (number) => {
  if (number) {
    let digitizedNumber = digitized(number);
    let reducedNumber = digitizedNumber.reduce((a, b) => a + b, 0);

    while (digitizedNumber.length > 1) {
      digitizedNumber = digitized(reducedNumber);
      reducedNumber = digitizedNumber.reduce((a, b) => a + b, 0);
    }
    return reducedNumber;
  }
}

const includeNumbers = (inputNumber, numberArray) => {
  const result = {
    use: true,
    forNumbers: [],
  };
  numberArray.map((number) => {
    let digitizedNumber = digitized(inputNumber);
    if (digitizedNumber.includes(number)) {
      // console.log(`number ${number} in ${inputNumber}`);
      result.use = false;
      result.forNumbers.push(number);
    }
    return null;
  })
  return result;
};

const singledNumbers = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30,
];

const actualPlanes = {
  action: {
    numbers: [],
    percentage: 0,
  },
  emotional: {
    numbers: [],
    percentage: 0,
  },
  mental: {
    numbers: [],
    percentage: 0,
  },
  practical: {
    numbers: [],
    percentage: 0,
  },
  thought: {
    numbers: [],
    percentage: 0,
  },
  will: {
    numbers: [],
    percentage: 0,
  },
  luck: {
    numbers: [],
    percentage: 0,
  },
  property: {
    numbers: [],
    percentage: 0,
  },
};

const updatePlanes = (item) => {
  if (item) {
    grid[item].planes.map((plane) => {
      if (actualPlanes[plane].numbers && !actualPlanes[plane].numbers.includes(item)) {
        actualPlanes[plane].numbers.push(item);
      }
      return null;
    })
  }
};

const loShuGrid = (dateOfBirth) => {
  const [
    year,
    month,
    date,
    driver,
    conductor,
    kuaDriver,
  ] = dateOfBirth;
  Object.keys(actualPlanes).map((plane) => {
    return actualPlanes[plane].numbers.length = 0;
  });
  const digitizedYear = digitized(year);
  const digitizedMonth = digitized(month);
  const digitizedDate = digitized(date);

  const theGrid = grid;

  for (let i = 1; i < 10; i += 1) {
    theGrid[i].entries.length = 0;
  }

  digitizedYear.map((item) => {
    item && theGrid[item].entries.push(item);
    updatePlanes(item);
    return null;
  });

  digitizedMonth.map((item) => {
    item && theGrid[item].entries.push(item);
    updatePlanes(item);
    return null;
  });

  if (!singledNumbers.includes(date)) {
    digitizedDate.map((item) => {
      item && theGrid[item].entries.push(item);
      updatePlanes(item);
      return null;
    });
  }

  updatePlanes(driver);
  updatePlanes(conductor);
  updatePlanes(kuaDriver);

  Object.keys(actualPlanes).map(plane => {
    let percentage = 0;
    actualPlanes[plane].numbers.map(number => {
      percentage += 33;
      return null;
    })
    actualPlanes[plane].percentage = percentage;
    return null;
  })

  return [theGrid, actualPlanes];
};

export {
  includeNumbers,
  loShuGrid,
  singledDigits,
};
