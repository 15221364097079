module.exports = Object.freeze({
  ma: {
    planetName: 'Mars',
    planetLetters: ['क', 'ख', 'ग', 'घ', 'ड़'],
    eventYears: '28 - 33',
  },
  ve: {
    planetName: 'Venus',
    planetLetters: ['च', 'द्द', 'ज', 'झ', 'ञ'],
    eventYears: '25 - 27',
  },
  me: {
    planetName: 'Mercury',
    planetLetters: ['ट', 'ठ', 'ड', 'ढ़', 'ण'],
    eventYears: '34 - 35',
  },
  ju: {
    planetName: 'Jupiter',
    planetLetters: ['त', 'थ', 'द', 'ध', 'न'],
    eventYears: '21',
  },
  sa: {
    planetName: 'Saturn',
    planetLetters: ['प', 'फ', 'ब', 'भ', 'म'],
    eventYears: '36',
  },
  mo: {
    planetName: 'Moon',
    planetLetters: ['य', 'र', 'ल', 'व', 'श', 'ष', 'स', 'ह'],
    eventYears: '24',
  },
  su: {
    planetName: 'Sun',
    planetLetters: ['अ', 'आ', 'इ', 'ई', 'उ', 'ऊ', 'ऋ', 'ए', 'ऐ', 'ओ', 'औ'],
    eventYears: '22',
  },
});