import React from 'react';

const LightModeSVG = ({ onChange }) =>{
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      stroke='#eef4f7'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='0.5'
      className='light-mode-svg'
      viewBox='0 0 24 24'
      onClick={onChange}
    >
      <path stroke='none' d='M0 0h24v24H0z'></path>
      <circle cx='12' cy='12' r='4'></circle>
      <path d='M3 12h1m8-9v1m8 8h1m-9 8v1M5.6 5.6l.7.7m12.1-.7l-.7.7m0 11.4l.7.7m-12.1-.7l-.7.7'></path>
    </svg>
  );
};

export default LightModeSVG;
