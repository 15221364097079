import React, {
  useState,
} from 'react';
import DataGrid from 'react-data-grid';

import 'react-data-grid/lib/styles.css';

const nakshatraData = require('../../../data/nakshatras')

const columns = [
  {
    key: 'signColumn1',
    name: 'Sign',
  },
  {
    key: 'nakshatraFormulaColumn1',
    name: 'Nakshatra Formula',
  },
  {
    key: 'nakshatraColumn1',
    name: 'Nakshatra',
  },
  {
    key: 'signColumn2',
    name: 'Sign',
  },
  {
    key: 'nakshatraFormulaColumn2',
    name: 'Nakshatra Formula',
  },
  {
    key: 'nakshatraColumn2',
    name: 'Nakshatra',
  },
  {
    key: 'signColumn3',
    name: 'Sign',
  },
  {
    key: 'nakshatraFormulaColumn3',
    name: 'Nakshatra Formula',
  },
  {
    key: 'nakshatraColumn3',
    name: 'Nakshatra',
  },
  {
    key: 'padas',
    name: 'Padas',
  },
  {
    key: 'tatva',
    name: 'Tatva',
  },
  // {
  //   key: 'degrees',
  //   name: 'Degrees',
  // }
];

const createRows = () => {
  const rows = [];

  rows.push({
    signColumn1: "",
    nakshatraFormulaColumn1: "(2R-1)",
    nakshatraColumn1: "",
    signColumn2: "",
    nakshatraFormulaColumn2: "(2R)",
    nakshatraColumn2: "",
    signColumn3: "",
    nakshatraFormulaColumn3: "(2R+1)",
    nakshatraColumn3: "",
    padas: "",
    tatva: "",
    degrees: "",
  });

  const tatvas = ["Firy", "Earthy", "Airy", "Watery"];
  const padas = [
    {1: "1-4", 2: "1-4", 3: "1"},
    {1: "2-4", 2: "1-4", 3: "1-2"},
    {1: "3-4", 2: "1-4", 3: "1-3"},
    {1: "4", 2: "1-4", 3: "1-4"},
  ];

  [1, 2, 3, 4].forEach((signIndex) => {
    const signIndex1 = signIndex;
    const signIndex2 = signIndex1 + 4;
    const signIndex3 = signIndex2 + 4;
    const nakshatraColumn1 = 2 * signIndex1 - 1;
    const nakshatraColumn2 = 2 * (signIndex2);
    const nakshatraColumn3 = 2 * (signIndex3) + 1;
    const pada = padas[signIndex - 1];

    rows.push({
      signColumn1: signIndex1,
      nakshatraFormulaColumn1: `(2 x ${signIndex1} - 1) = ${nakshatraColumn1}`,
      nakshatraColumn1: `${nakshatraColumn1} - ${nakshatraData[nakshatraColumn1 - 1]['nakshtraName']}`,
      signColumn2: signIndex2,
      nakshatraFormulaColumn2: `(2 x ${signIndex2}) = ${nakshatraColumn2}`,
      nakshatraColumn2: `${nakshatraColumn2} - ${nakshatraData[nakshatraColumn2 - 1]['nakshtraName']}`,
      signColumn3: signIndex3,
      nakshatraFormulaColumn3: `(2 x ${signIndex3} - 1) = ${nakshatraColumn3}`,
      nakshatraColumn3: `${nakshatraColumn3} - ${nakshatraData[nakshatraColumn3 - 1]['nakshtraName']}`,
      padas: pada[1],
      tatva: tatvas[signIndex1 - 1],
      degrees: "",
    });
    rows.push({
      signColumn1: "",
      nakshatraFormulaColumn1: "",
      nakshatraColumn1: `${nakshatraColumn1 + 1} - ${nakshatraData[nakshatraColumn1]['nakshtraName']}`,
      signColumn2: "",
      nakshatraFormulaColumn2: "",
      nakshatraColumn2: `${nakshatraColumn2 + 1} - ${nakshatraData[nakshatraColumn2]['nakshtraName']}`,
      signColumn3: "",
      nakshatraFormulaColumn3: "",
      nakshatraColumn3: `${nakshatraColumn3 + 1} - ${nakshatraData[nakshatraColumn3]['nakshtraName']}`,
      padas: pada[2],
      tatva: "",
      degrees: "",
    });
    rows.push({
      signColumn1: "",
      nakshatraFormulaColumn1: "",
      nakshatraColumn1: `${nakshatraColumn1 + 2} - ${nakshatraData[nakshatraColumn1 + 1]['nakshtraName']}`,
      signColumn2: "",
      nakshatraFormulaColumn2: "",
      nakshatraColumn2: `${nakshatraColumn2 + 2} - ${nakshatraData[nakshatraColumn2 + 1]['nakshtraName']}`,
      signColumn3: "",
      nakshatraFormulaColumn3: "",
      nakshatraColumn3: `${nakshatraColumn3 + 2} - ${nakshatraData[nakshatraColumn3 + 1]['nakshtraName']}`,
      padas: pada[3],
      tatva: "",
      degrees: "",
    });
    rows.push({
      signColumn1: "",
      nakshatraFormulaColumn1: "",
      nakshatraColumn1: "",
      signColumn2: "",
      nakshatraFormulaColumn2: "",
      nakshatraColumn2: "",
      signColumn3: "",
      nakshatraFormulaColumn3: "",
      nakshatraColumn3: "",
      padas: "",
      tatva: "",
      degrees: "",
    });
  });

  return rows;
}
const RememberNakshatras = () => {
  const [rows] = useState(createRows);

  return (
    <DataGrid columns={columns} rows={rows} />
  )
}

export default RememberNakshatras