import React, {
  useState,
} from 'react';
import {
  Divider,
  Grid,
  Icon,
  Input,
  Label,
  Segment,
} from 'semantic-ui-react'
import InputMask from 'react-input-mask';
import {
  singledDigits,
} from './calculations';
import PortalElement from './PortalElement';

const Numbers = ({
  number,
  setNumber,
  setSingledNumber,
}) => {
  const [singledDigitNumber, setSingledDigitNumber] = useState(null);

  const handleNumberChange = (event) => {
    const {
      target: {
        value
      } = {},
    } = event;

    if (typeof(value) === 'string') {
      const trimmedValue = value.trim();
      setNumber(trimmedValue);
      const singledDigitValue = singledDigits(trimmedValue);
      setSingledDigitNumber(singledDigitValue);
      setSingledNumber(singledDigitValue);
    }
  };

  return(
    <div>
      <Divider horizontal><Icon name='phone square' />
        <Label>Phone or Any Number</Label>
        <InputMask
          mask={'99999999999999999999'}
          maskChar=''
          value={number}
          placeholder='Number'
          onChange={handleNumberChange}
        >
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      </Divider>
      {number && <Grid>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Segment padded>
              <Label>Number</Label>
              <Label>{number}</Label>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Segment padded>
              <Label>Total</Label>
              <PortalElement
                number={singledDigitNumber}
                triggerNode={<Label>{`#${singledDigitNumber}`}</Label>}
              />
            </Segment>
          </Grid.Column>
      </Grid>}
    </div>
  );

};

export default Numbers;
