import React from 'react';

import LightModeSVG from './LightModeSVG';
import DarkModeSVG from './DarkModeSVG';

const ToggleSVG = ({ checked, onChange, themeSelected }) => {
  const strokeColor = checked ? '#eef4f7' : '#000';

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      stroke={strokeColor}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='0.5'
      className='toggle-svg'
      viewBox='0 0 24 24'
      checked={checked}
      onChange={onChange}
      onClick={onChange}
    >
      {
        checked
        ? <LightModeSVG />
        : <DarkModeSVG />
      }
    </svg>
  );
}

export default ToggleSVG;
