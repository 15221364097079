module.exports = Object.freeze({
  1: {
    year: `year of new start, new thing will impact your life for long, year full of energy and enthusiasm. Good drivers 9-5-2-1-6-4-3-7, may not a good year for drivers 8. Start shooting Efforts in all directions. More enthusiastic, new start with various activities, etc, coming year new hopes and improvement in some directions, obviously thats for betterment. More egoistic this year. Dont afraid of asking favours from your supriors, more benefits. Increase in self confidence,increase ur social status thru some social activities. Leadership skills impress others. More responsibilities in work place. Your new assosiates will become your lasting friends`,
    month: ``,
    day: `Good for planning new projects or procedures related to self-sufficiency and self-determination. Alone time may be of benefit. The energy resonates with a desire to do things independent of others' approval.`,
  },
  2: {
    year: `slow & gentle year, consolidation year, spare time with loved ones patiently, year of temptation, mental tensions, bad for 4-8-9-6 drivers; may be ok for 1-2-5-7-3, only if conductors are not compatible.  Patient waiting is the keyword of this year. Slow and gentle year, what you expect you get back in manageable proportion,trust your intution,enjoy pleasent time with loved ones, full of romance, which lighten ur burden, you will gain more information rather than early years,which will helpful in coming years. Slowly and steady you win the race. Some connections with living abroad will rise. You will make new friends, gain receptive attitude, prosper in patnership, do minor things rather than big one. You will achieve success. Little uncomfortable with older ones,`,
    month: ``,
    day: `Good to resonates with coexistence. Compatible with being a team member, diplomatically dealing with other's feelings, having a role as peacemaker, and resolving issues with tact. Relationships are likely to be a focus of attention.`,
  },
  3: {
    year: `not a bad year, good year for education sector, will deliver after 6 months of coming, late delivery, good for 1-3-5-7-2 ok for 4-8-9, and worst for 6. Positive year for you, more expensive rather than early years, increase in financial earnings and development in your outlook. Positive, cheerful and optimistic. Year of success. Beware of speech, careful of what you say. You can market your ideas, work hard with more pleasures. Good year to spend time with old friends, make new friends, new hobbies and interest. Some short interest come and go this year. You will be calm and more cultivated in nature. You will begin to look for higher progress.`,
    month: ``,
    day: `Creative personal expression is the primary energy. Social interaction can be an outlet for creative expression. The energy tends to encourage optimism and tolerance. Artistic work by others are likely to be appreciated and supported.`,
  },
  4: {
    year: `year of giving subjected to goal setting and hard work, good for 7-1-6-5-4-3 sudden loss and sudden gains. To set a goal better prepare in personal year 3, so you will achieve in personal year 4 worst for driver 2-8-9. Hardwork and expect the unexpected things, sudden ups and downs but get new friendships and associations as well, what you set for an year will get in the end, your outlook will become more unconventional. Mixed year for you as Rahu represent Hardwork`,
    month: ``,
    day: `It's a pragmatic and methodical energy. Tends to attract a determination that the way to get things done is the way it was successfully done before. It also brings consideration about a secure foundation for the future.`,
  },
  5: {
    year: `change and variety, new things to start change of carrier, home, partner, country, expect the unexpected. Good for 1-5-2-6 ok; 3-7-9-4-8. Change is there in positive sense, traveling for business or pleasure. You will occupied with buying, selling and bargaining. Control on your temper. Good for communication activities like teaching, advertising personal or over the phone. Expect the unexpected. This is the year to change which you don't like. Good and productive year`,
    month: ``,
    day: `Expression of a sense of personal freedom. Tends to inspire interest in various things, one right after another. It also encourages multiple points of view.`,
  },
  6: {
    year: `neither good nor bad, year of gain good for home and family. Best for unmarried people, year of home decoration, material gains, best for 6-1-5-4-7, good 8, ok for 2-9 worst=3. This year for home and family. Perfect year to get married (those who are single). If you are in love life, make it permanent. If you are planning, excellent year to have a baby. You will involved in help others.good year to do alterations in existing home. Your friends and patners can be influenced but slow in giving response. Cheerful outlook. Little difficult to maintain budget at home. More on domestic things this year. Comfortable atmosphere and surroundings will highlight the intellectual and emotional aspects.`,
    month: ``,
    day: `Home and family is the primary resonance of this day's numerology energy. It is an energy that encourages nurturing and supporting others, especially family and visitors to the home. The energy resonates with the formulation of an ideal related to the activities and objectives of society as a whole.`,
  },
  7: {
    year: `personal development, education/study either formal or informal , year of planning and analysing & self development, should not change investment. Meditation towards spiritualism, no material gain. More quieter and gentle year, new learning, do meditation. Education carry in some direction.nit from your formal study, you will gain knowledge and wisdom. Enhancement in mental potential.MaintaIn your correspondence with faraway friends and family. Throu traveling and communication you will embark your career. If any court cases, settlement is there. Mainly Divorce. Great year to make plans. More interested in developing inner knowledge. Possibilities that you will misunderstood on important matters. Difficult to clarify your point. Moody and more imaginative this year.`,
    month: ``,
    day: `Encourages introspection, finding answers within. The energy resonates with solving mysteries and interest in both scientific and spiritual subjects. It is compatible with high intuition and accumulating wisdom.`,
  },
  8: {
    year: `pay off year, but only for hard work. monetary gains, if work done little than little gains, may give health problems.Good = 6-5-7-3-4-8 bad for 1-2-9. This is a Money year .hardwork but financially set. Recap of last year. If you have done good, rewards will be good and vice versa. If u buy or sell something, that will be beneficial. Give time for relaxation. Other wise health problem occurs. Dominance from older people on your decisions. Dont look for quick or fast results. Get extra opportunities. Use your power in an constructive way. You should command any situation. Your greatest reward comes to you for knowing that what you do that is ethical.`,
    month: ``,
    day: `Resonance with financial matters and material accumulation this day. It's a realistic and business-related energy, one that also resonates with efficiency, pragmatism, and building something of long-term value.`,
  },
  9: {
    year: `audit year punishment year, wait and watch year, may be painful, year of looking ahead, figure out where to go, last 3 months of personal year 9 will give you clean picture of what / where you want to go?? where you want to do/go in next 9 years. Not a action year, may be accident, murder, suicide, divorce, court case etc. Do not marry in personal year 9. Good for 1-5-3-9 ok 7 worst 4-6-8-2. Planning for new venture, move on , forget and forgive the past. Slow Starting but things will clear with positive approach.various experiences in your way. Year of buying not selling. Beware of quarrels and be patient in every work.You will become more implusive and ambitious. Control on speech.`,
    month: ``,
    day: `Welfare of humanity. It is likely to inspire related thoughts and imagination, and tends to imbue an urge to do something directly or contribute to groups that benefit humankind. It is an energy of acceptance and tolerance.`,
  },
});
