import { types } from './reducers'

export const useActions = (state, dispatch) => {
  const addTechIfNotInList = (newTech) => {
    const techIndex = state.techList.indexOf(newTech)
    if (techIndex !== -1) {
      alert('Tech is defined in list')
    } else {
      dispatch({ type: types.GRAHAS, payload: newTech })
    }
  };

  const updateUser = (user) => {
    dispatch({ type: types.USER, payload: user })
  };

  return {
    addTechIfNotInList,
    updateUser,
  }
}
