import React, {
  useState
} from 'react'

import {
  Accordion,
  Divider,
  Icon,
  Message,
} from 'semantic-ui-react'
import basicPanel from './Basics';

import 'semantic-ui-css/semantic.min.css'

const eventPromisePanel = (activeIndex) => {
  return (
    <Accordion.Content active={activeIndex === 1}>
      <Message floating>
        <Message.Header>
          Check for Promise of an event.
          <p>
            For promise of an event check Event Cusp (House) Sub-lord (Cuspal Sub-lord a.k.a CSL), its Star (Nakshatra) Lord (NL/Stl).
          </p>
          <p>
          </p>
        </Message.Header>
      </Message>
    </Accordion.Content>
  );
};

const eventTimingPanel = (activeIndex) => {
  return (
    <Accordion.Content active={activeIndex === 2}>
      Check for Promise of an event throught its Cuspal Sub-lord.
    </Accordion.Content>
  );
};

const KPRulesBasic = () => {
  const [activeIndex, setActiveIndex] = useState(false)
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <Accordion
    panels={[
        {
          key: 'basics',
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <Divider><Icon name='dropdown' />Basics</Divider>
            </Accordion.Title>
          ),
          content: basicPanel(activeIndex),
          active: true
        },
        {
          key: 'event-promised',
          title: (
            <Accordion.Title
              index={1}
              onClick={handleClick}
              active={activeIndex === 1}
            >
              <Divider><Icon name='dropdown' />I. Event Promise</Divider>
            </Accordion.Title>
          ),
          content: eventPromisePanel(activeIndex),
          active: true
        },
        {
          key: 'event-timing',
          title: (
            <Accordion.Title
              index={2}
              onClick={handleClick}
              active={activeIndex === 2}
            >
              <Divider><Icon name='dropdown' />II. Event Timing</Divider>
            </Accordion.Title>
          ),
          content: eventTimingPanel(activeIndex),
          active: true
        },
      ]}
    />
  )
}

export default KPRulesBasic