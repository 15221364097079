import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { withRouter } from "./withRouter";

const AuthProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };

  const providerConfig = {
    domain,
    clientId,
    authorizationParams: {
      redirect_uri: window.location.origin + "/auth0_callback",
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    onRedirectCallback,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default withRouter(AuthProviderWithHistory);
