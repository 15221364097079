const houseNames = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const planetShortNames = [
  "Ket",
  "Ven",
  "Sun",
  "Mon",
  "Mar",
  "Rah",
  "Jup",
  "Sat",
  "Mer",
];

const isNumeric = (num) => {
  return !isNaN(parseInt(num));
};

const isHeaderRow = (row) => {
  return row === "Bhav" || row === "" || row === "Planet";
};

const rashis = {
  Aries: {
    name: `Aries`,
    aspect: [5, 8, 11],
    aspectNames: ["Leo", "Scorpio", "Aquarius"],
    ownerShortName: "Mar",
    ownerName: "Mar",
    prevNext: ["Pisces", "Taurus"],
  },
  Taurus: {
    name: `Taurus`,
    aspect: [4, 7, 10],
    aspectNames: ["Cancer", "Libra", "Capricorn"],
    ownerShortName: "Ven",
    ownerName: "Venus",
    prevNext: ["Aries", "Gemini"],
  },
  Gemini: {
    name: `Gemini`,
    aspect: [6, 9, 12],
    aspectNames: ["Virgo", "Sagittarius", "Pisces"],
    ownerShortName: "Mer",
    ownerName: "Mercury",
    prevNext: ["Taurus", "Cancer"],
  },
  Cancer: {
    name: `Cancer`,
    aspect: [2, 8, 11],
    aspectNames: ["Taurus", "Scorpio", "Aquarius"],
    ownerShortName: "Mon",
    ownerName: "Moon",
    prevNext: ["Gemini", "Leo"],
  },
  Leo: {
    name: `Leo`,
    aspect: [1, 7, 10],
    aspectNames: ["Aries", "Libra", "Capricorn"],
    ownerShortName: "Sun",
    ownerName: "Sun",
    prevNext: ["Cancer", "Virgo"],
  },
  Virgo: {
    name: `Virgo`,
    aspect: [3, 9, 12],
    aspectNames: ["Leo", "Sagittarius", "Pisces"],
    ownerShortName: "Mer",
    ownerName: "Mercury",
    prevNext: ["Leo", "Libra"],
  },
  Libra: {
    name: `Libra`,
    aspect: [2, 5, 11],
    aspectNames: ["Taurus", "Leo", "Aquarius"],
    ownerShortName: "Ven",
    ownerName: "Venus",
    prevNext: ["Virgo", "Scorpio"],
  },
  Scorpio: {
    name: `Scorpio`,
    aspect: [1, 4, 10],
    aspectNames: ["Aries", "Cancer", "Capricorn"],
    ownerShortName: "Mar",
    ownerName: "Mar",
    prevNext: ["Libra", "Sagittarius"],
  },
  Sagittarius: {
    name: `Sagittarius`,
    aspect: [3, 6, 12],
    aspectNames: ["Gemini", "Virgo", "Pisces"],
    ownerShortName: "Jup",
    ownerName: "Jupiter",
    prevNext: ["Scorpio", "Capricorn"],
  },
  Capricorn: {
    name: `Capricorn`,
    aspect: [2, 5, 8],
    aspectNames: ["Taurus", "Leo", "Scorpio"],
    ownerShortName: "Sat",
    ownerName: "Saturn",
    prevNext: ["Sagittarius", "Aquarius"],
  },
  Aquarius: {
    name: `Aquarius`,
    aspect: [1, 4, 7],
    aspectNames: ["Aries", "Cancer", "Libra"],
    ownerShortName: "Sat",
    ownerName: "Saturn",
    prevNext: ["Capricorn", "Pisces"],
  },
  Pisces: {
    name: `Pisces`,
    aspect: [3, 6, 9],
    aspectNames: ["Gemini", "Virgo", "Sagittarius"],
    ownerShortName: "Jup",
    ownerName: "Jupiter",
    prevNext: ["Aquarius", "Aries"],
  },
};

const signs = [
  "Aries",
  "Taurus",
  "Gemini",
  "Cancer",
  "Leo",
  "Virgo",
  "Libra",
  "Scorpio",
  "Sagittarius",
  "Capricorn",
  "Aquarius",
  "Pisces",
];

const arrRotateLeft = (a, n) => {
  while (n > 0) {
    a.push(a.shift());
    n--;
  }
  return a;
};

const round2Fixed = (num, decimals = 2) =>
  (Math.round(num * 100) / 100).toFixed(decimals);

const parseDegree = (currentSignOrder, sign, deg, min, sec) => {
  const signIndex = currentSignOrder.indexOf(sign);

  const degree = parseInt(deg);
  const minute = parseInt(min);
  const second = parseFloat(sec);

  const parsedDegree = signIndex * 30 + degree + minute / 60 + second / 3600;
  const roundedParsedDegree = round2Fixed(parsedDegree);

  return roundedParsedDegree;
};

const parseChartData = (data) => {
  const rows = data.split("\n");
  const parsedChartData = {};
  let firstHouseSign = "";
  let indexOfFirstHouse = 0;
  let currentSignOrder = [];
  rows.map((row) => {
    const columns = row.split(/[ ,\t:'']+/);
    if (isHeaderRow(columns[0])) {
      return null;
    }
    const rowEntry = {};
    if (isNumeric(columns[0])) {
      // its a house row
      if (columns[0] === "1") {
        firstHouseSign = columns[1];
        indexOfFirstHouse = signs.indexOf(firstHouseSign);
        currentSignOrder = arrRotateLeft(signs, indexOfFirstHouse);
        parsedChartData.currentSignOrder = currentSignOrder;
      }
      rowEntry["sign"] = columns[1];
      rowEntry["degree"] = {
        degrees: columns[2],
        minutes: columns[3],
        seconds: columns[4],
      };
      rowEntry["parsedDegrees"] = parseDegree(
        currentSignOrder,
        columns[1],
        columns[2],
        columns[3],
        columns[4]
      );
      rowEntry["rl"] = columns[5];
      rowEntry["nl"] = columns[6];
      rowEntry["sl"] = columns[7];
      rowEntry["ssl"] = columns[8];
      rowEntry["planetsOccurredInLagnaChart"] = [];
      rowEntry["planetsOccurredInBhavChart"] = [];
    } else {
      // its a planet row
      let colPos = 1;
      if (columns[colPos] !== "(R)") {
        rowEntry["retrograde"] = false;
      } else {
        rowEntry["retrograde"] = true;
        colPos += 1;
      }
      rowEntry["sign"] = columns[colPos];
      rowEntry["degree"] = {
        degrees: columns[colPos + 1],
        minutes: columns[colPos + 2],
        seconds: columns[colPos + 3],
      };
      rowEntry["parsedDegrees"] = parseDegree(
        currentSignOrder,
        columns[colPos],
        columns[colPos + 1],
        columns[colPos + 2],
        columns[colPos + 3]
      );
      rowEntry["rl"] = columns[colPos + 4];
      rowEntry["nl"] = columns[colPos + 5];
      rowEntry["sl"] = columns[colPos + 6];
      rowEntry["ssl"] = columns[colPos + 7];
      rowEntry["inBhav"] = [];
      rowEntry["inHouse"] = [];
      rowEntry["conjunctsWith"] = [];
      rowEntry["aspectedInBhavaBy"] = [];
      rowEntry["aspectedInLagnaBy"] = [];
      columns[0] === "Rah" && rowEntry["aspectedInLagnaBy"].push("Ket");
      columns[0] === "Ket" && rowEntry["aspectedInLagnaBy"].push("Rah");
    }

    parsedChartData[columns[0]] = {
      ...parsedChartData[columns[0]],
      ...rowEntry,
      ownerOf: [],
      nakshatraLordOf: [],
      kpSignificatorOf: [],
      kpSignificatorOfSorted: [],
      planetsInBhavChart: [],
      planetsInItLagnaChart: [],
    };
    return null;
  });
  return parsedChartData;
};

const nLordOfAllPlanets = (chartData) => [
  ...new Set(planetShortNames.map((planet) => chartData[planet].nl)),
];

const untenantedPlanets = (chartData) => {
  // Untenanted planets
  // - Planet not being Nakshatra Lord of any planet
  // - Planet in its own Nakshatra
  // - Planet has exchange of nakshatra with other planet
  return planetShortNames.map((planet) => {
    const notNLofAnyPlanets = !chartData.nLofAllPlanets.includes(planet);
    const inItsOwnNakshatra = planet === chartData[planet].nl;
    const hasExchangeOfNakshatra =
      chartData[chartData[planet].nl]?.nl === planet;
    chartData[planet].untenanted =
      notNLofAnyPlanets || inItsOwnNakshatra || hasExchangeOfNakshatra;
    return {
      planet,
      notNLofAnyPlanets,
      inItsOwnNakshatra,
      hasExchangeOfNakshatra,
    };
  });
};

const planetaryPosition = (chartData) =>
  planetShortNames.map((planet) => ({
    planet: `${planet} ${chartData[planet].retrograde ? "(R)" : ""}`,
    sign: chartData[planet].sign,
    degree: `${chartData[planet].degree.degrees}° ${chartData[planet].degree.minutes}' ${chartData[planet].degree.seconds}"`,
    rl: chartData[planet].rl,
    nl: chartData[planet].nl,
    sl: chartData[planet].sl,
    ssl: chartData[planet].ssl,
  }));

const nirayanaCusps = (chartData) =>
  houseNames.map((house) => {
    return {
      bhavNo: house,
      sign: chartData[house].sign,
      degree: `${chartData[house].degree.degrees}° ${chartData[house].degree.minutes}' ${chartData[house].degree.seconds}"`,
      rl: chartData[house].rl,
      nl: chartData[house].nl,
      sl: chartData[house].sl,
      ssl: chartData[house].ssl,
    };
  });

const assignPlanetNLOf = (chartData) =>
  planetShortNames.map((nLPlanet) => {
    planetShortNames.map((planet) => {
      if (chartData[planet].nl === nLPlanet) {
        chartData[nLPlanet].nakshatraLordOf.push(planet);
      }
      return null;
    });
    return null;
  });

const assignPlanetOwnerOf = (chartData) =>
  houseNames.map((house, index) => {
    const entity = chartData[house];
    const houseLord = rashis[entity.sign]?.ownerShortName;
    chartData[houseLord].ownerOf.push(house);
    return null;
  });

const assignPlanetToHouse = (chartData) =>
  planetShortNames.map((planet) => {
    const planetData = chartData[planet];
    houseNames.map((house, index) => {
      const houseData = chartData[house];
      const nextHouse = houseNames[index + 1] || houseNames[0];
      const nextHouseData = chartData[nextHouse];
      if (
        parseFloat(planetData.parsedDegrees) >
        parseFloat(nextHouseData.parsedDegrees)
      ) {
        return null;
      }

      if (
        parseFloat(planetData.parsedDegrees) >=
          parseFloat(houseData.parsedDegrees) &&
        parseFloat(planetData.parsedDegrees) <
          parseFloat(nextHouseData.parsedDegrees)
      ) {
        planetData.inBhav.push(house);
        houseData.planetsOccurredInBhavChart.push(planet);
      }
      if (
        nextHouse === "1" &&
        parseFloat(planetData.parsedDegrees) <
          parseFloat(nextHouseData.parsedDegrees)
      ) {
        planetData.inBhav.push("12");
        chartData["12"].planetsOccurredInBhavChart.push(planet);
      }
      if (planetData.sign === houseData.sign) {
        planetData.inHouse.push(house);
        houseData.planetsOccurredInLagnaChart.push(planet);
      }
      return null;
    });

    if (
      planetData.inBhav.length === 0 &&
      planetData.parsedDegrees > chartData["12"].parsedDegrees
    ) {
      // planet degrees are greater than 12th house degreess that means the
      // planet is in 12th house
      planetData.inBhav.push("12");
      chartData["12"].planetsOccurredInLagnaChart.push(planet);
    }

    if (planetData.inHouse.length === 0) {
      planetData.inHouse = planetData.inBhav;
    }
    return null;
  });

const flatFilterPlanet = (arr) =>
  arr.flat().filter((planet) => planet.length > 0);

const kpPlanetSignificators = (planet, chartData) => {
  const inBhav = chartData[planet].inBhav.flat();
  const ownerOf = chartData[planet].ownerOf.flat();

  return [...flatFilterPlanet(inBhav), ...flatFilterPlanet(ownerOf)];
};

const assignKPSignificatorOf = (chartData) =>
  planetShortNames.map((planet) => {
    const planetData = chartData[planet];
    planetData.kpSignificatorOf.push(kpPlanetSignificators(planet, chartData));

    if (planet === "Rah" || planet === "Ket") {
      // 1) RL (Lagna Chart)
      planetData.kpSignificatorOf.push(
        kpPlanetSignificators(planetData.rl, chartData)
      );

      // 2) Con. Planet (Lagna Chart)
      planetData.kpSignificatorOf.push(
        flatFilterPlanet(
          planetData.conjunctsWith.map((planet) =>
            kpPlanetSignificators(planet, chartData)
          )
        )
      );

      // 3) Planet Aspecting on Rahu (Lagna Chart)
      planetData.kpSignificatorOf.push(
        flatFilterPlanet(
          planetData.aspectedInLagnaBy.map((planet) =>
            kpPlanetSignificators(planet, chartData)
          )
        )
      );

      // 4) House itself sitting (Bhav Chart)
      planetData.kpSignificatorOf.push(planetData.inBhav);
    }

    chartData[planet].kpSignificatorOf = [
      ...new Set(
        planetData.kpSignificatorOf.flat().filter((planet) => planet.length > 0)
      ),
    ];

    return null;
  });

const assignConjunctsWith = (chartData) =>
  planetShortNames.map((planet, index) => {
    planetShortNames.map((nextPlanet, nextIndex) => {
      if (index === nextIndex) {
        return null;
      }
      const planetDegree = parseFloat(chartData[planet].parsedDegrees);
      const nextPlanetDegree = parseFloat(chartData[nextPlanet].parsedDegrees);
      if (
        planetDegree > nextPlanetDegree - 7 &&
        planetDegree < nextPlanetDegree + 7
      ) {
        chartData[planet].conjunctsWith.push(nextPlanet);
      }
      return null;
    });
    return null;
  });

const assignAspectedInLagnaBy = (chartData) =>
  planetShortNames.map((planet, index) => {
    const planetData = chartData[planet];
    const planetInHouse = parseInt(planetData.inHouse[0]);

    planetShortNames.map((nextPlanet, nextIndex) => {
      if (index === nextIndex) {
        return null;
      }
      if (["Ura", "Nep", "Plu"].includes(nextPlanet)) {
        return null;
      }

      const nextPlanetData = chartData[nextPlanet];

      // 7th aspect in Lagna Chart
      const nextPlanetInhouse = parseInt(nextPlanetData.inHouse[0]);
      const planetDegree7thAspected = (planetInHouse + 6) % 12;
      if (nextPlanetInhouse === planetDegree7thAspected) {
        planetData.aspectedInLagnaBy.push(nextPlanet);
      }

      switch (nextPlanet) {
        case "Mar":
          // 4, 8
          const marfourthAspect = (nextPlanetInhouse + 3) % 12;
          const marEighthAspect = (nextPlanetInhouse + 7) % 12;

          if (marfourthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (marEighthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        case "Jup":
        case "Rah":
        case "Ket":
          // 5, 9
          const jupfifthAspect = (nextPlanetInhouse + 4) % 12;
          const jupNinethAspect = (nextPlanetInhouse + 8) % 12;

          if (jupfifthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (jupNinethAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        case "Sat":
          // 3, 10
          const satThirdAspect = (nextPlanetInhouse + 2) % 12;
          const satTenthAspect = (nextPlanetInhouse + 9) % 12;

          if (satThirdAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          if (satTenthAspect === planetInHouse) {
            planetData.aspectedInLagnaBy.push(nextPlanet);
          }
          break;
        default:
          break;
      }
      return null;
    });
    return null;
  });

const isIndependentHouse = (chartData, untenantedPlanet, house) => {
  let returnVal = false;
  const owns = chartData[untenantedPlanet].ownerOf;
  if (
    owns.includes(house) &&
    chartData[house].planetsOccurredInBhavChart.length === 0
  ) {
    returnVal = true;
  }

  return returnVal;
};

const planetHouseScript = (
  chartData,
  untenantedPlanet,
  kpSignificatorHouses
) => {
  return kpSignificatorHouses.map((house) =>
    isIndependentHouse(chartData, untenantedPlanet, house)
      ? `(${house})`
      : house
  );
};

const planetCSLOf = (chartData, planet) =>
  houseNames
    .map((house) => (chartData[house].sl === planet ? house : null))
    .filter((house) => house?.length > 0);

const kpSignificatorsOfPlanets = (chartData) => {
  const returnObj = {};
  planetShortNames.map((planet) => {
    const { kpSignificatorOf, nl, sl, untenanted } = chartData[planet];
    const subLord = {
      name: sl,
      ...chartData[sl],
    };
    const nlOfSl = {
      name: chartData[sl].nl,
      ...chartData[chartData[sl].nl],
    };

    const planetKpSignificatorOf = flatFilterPlanet(
      kpSignificatorOf.map((house) => house)
    );
    const nlKpSignificatorOf = flatFilterPlanet(
      chartData[nl].kpSignificatorOf.map((house) => house)
    );
    const slKpSignificatorOf = flatFilterPlanet(
      subLord.kpSignificatorOf.map((house) => house)
    );
    const nlOfSlKpSignificatorOf = flatFilterPlanet(
      nlOfSl.kpSignificatorOf.map((house) => house)
    );

    returnObj[planet] = {
      planet: {
        name: planet,
        untenanted: untenanted,
        basicSignificators: untenanted
          ? planetHouseScript(chartData, planet, [
              ...new Set(planetKpSignificatorOf),
            ])
          : [...new Set(planetKpSignificatorOf)],
        utCSLOf: untenanted
          ? planetCSLOf(chartData, planet).length > 0
            ? " {" + planetCSLOf(chartData, planet) + "}"
            : ""
          : planetCSLOf(chartData, nl).length > 0
          ? " <" + planetCSLOf(chartData, nl) + ">"
          : "",
      },
      nl: {
        name: nl,
        basicSignificators: nlKpSignificatorOf,
      },
      sl: {
        name: subLord.name,
        basicSignificators: subLord.untenanted
        ? planetHouseScript(chartData, subLord.name, [
            ...new Set(slKpSignificatorOf),
          ])
        : [...new Set(slKpSignificatorOf)],
      },
      nlOfSl: {
        name: nlOfSl.name,
        basicSignificators: nlOfSlKpSignificatorOf,
      },
    };
    return null;
  });
  return returnObj;
};

const analyzeHorosoftChartData = (chartData) => {
  if (chartData) {
    const parsedChartData = parseChartData(chartData);
    parsedChartData.nLofAllPlanets = nLordOfAllPlanets(parsedChartData);
    parsedChartData.untenantedPlanets = untenantedPlanets(parsedChartData);
    parsedChartData.planetaryPositions = planetaryPosition(parsedChartData);
    parsedChartData.nirayanaCusps = nirayanaCusps(parsedChartData);

    const kpSignificatorsHouses = {};
    const kpSignificatorsHousesTable = {};
    assignPlanetOwnerOf(parsedChartData);
    assignPlanetNLOf(parsedChartData);
    assignPlanetToHouse(parsedChartData);
    assignConjunctsWith(parsedChartData);
    assignAspectedInLagnaBy(parsedChartData);
    assignKPSignificatorOf(parsedChartData);

    houseNames.map((house) => {
      const entity = parsedChartData[house];
      if (!entity) return null;
      const houseLord = rashis[entity.sign]?.ownerShortName;
      const planetsInBhavChart = entity.planetsInBhavChart;
      const occupiedPlanetsAsNLofPlanets = parsedChartData[
        house
      ].planetsOccurredInBhavChart
        .map((planet) => parsedChartData[planet].nakshatraLordOf)
        .flat()
        .filter((planet) => planet.length > 0);

      kpSignificatorsHouses[house] = {
        d: {
          name: "Owner",
          planet: parsedChartData[house].rl,
        },
        c: {
          name: "NL Planets",
          planets: parsedChartData[parsedChartData[house].rl].nakshatraLordOf, //houseLordAsNLofPlanets,
        },
        b: {
          name: "Planets In House",
          planets: parsedChartData[house].planetsOccurredInBhavChart,
        },
        a: {
          name: "Planets In House As NL of Planets",
          planets: occupiedPlanetsAsNLofPlanets,
        },
        i: {
          name: "Independent House",
          isIndependent:
            parsedChartData[houseLord].untenanted &&
            planetsInBhavChart.length === 0
              ? true
              : "-",
        },
      };
      kpSignificatorsHousesTable[house] = [
        occupiedPlanetsAsNLofPlanets.join(", "),
        parsedChartData[house].planetsOccurredInBhavChart.join(", "),
        parsedChartData[parsedChartData[house].rl].nakshatraLordOf.join(", "),
        parsedChartData[house].rl,
      ];
      return null;
    });

    const planetKpSignificators = kpSignificatorsOfPlanets(parsedChartData);

    const kpSigABCD = houseNames.map((house) => ({
      House: house,
      A: kpSignificatorsHouses[house]?.a?.planets.length
        ? kpSignificatorsHouses[house]?.a?.planets.join(", ")
        : "-",
      B: kpSignificatorsHouses[house]?.b?.planets.length
        ? kpSignificatorsHouses[house]?.b?.planets.join(", ")
        : "-",
      C: kpSignificatorsHouses[house]?.c?.planets.length
        ? kpSignificatorsHouses[house]?.c?.planets.join(", ")
        : "-",
      D: kpSignificatorsHouses[house]?.d?.planet,
      Independent: kpSignificatorsHouses[house]?.i?.isIndependent,
    }));

    return {
      chartData: parsedChartData,
      kpSigABCD,
      kpSignificatorsHouses,
      kpSignificatorsHousesTable,
      kpSignificatorsPlanets: planetKpSignificators,
      untenantedPlanets: parsedChartData.untenantedPlanets,
    };
  }
};

export { analyzeHorosoftChartData };
