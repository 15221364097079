import React, { useRef } from "react";
import styled from "styled-components";
import useDarkMode from "use-dark-mode";

import useComponentSize from "../../engine/useComponentSize";

import DrawHouses from "./DrawHouses";

const ChartContainer = styled.div`
  display: grid;
  justify-content: center;
`;

const ChartNorthSvg = styled.svg`
  min-height: ${p => p.height};
  width: ${p => p.width};
  border: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")} solid 1px;
`;

const ChartNorthSvgRect = styled.rect`
  color: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  stroke: ${({ darkModeValue }) => (darkModeValue ? "#999" : "#000")};
  height: 100%;
  width: 100%;
  x: 0%;
  y: 0%;
  fill: none;
  fill-opacity: 1;
  stroke-width: 3;
  pointer-events: none;
`;

const ChartSvg = ({
  setCurrentSelectedHouse,
}) => {
  const { value: darkModeValue } = useDarkMode();
  const ref = useRef(null);
  const size = useComponentSize(ref);
  const { width, height } = size;

  return (
    <ChartContainer ref={ref}>
      <ChartNorthSvg
        height={Math.abs(height)}
        width={Math.abs(width)}
        darkModeValue={darkModeValue}
      >
        <g>
          <ChartNorthSvgRect darkModeValue={darkModeValue} />
          <DrawHouses
            setCurrentSelectedHouse={setCurrentSelectedHouse}
            width={width}
            height={height}
          />
        </g>
      </ChartNorthSvg>
    </ChartContainer>
  );
};

export default ChartSvg;
