import React from 'react';
import {
  Card,
  Divider,
  Header,
  Icon,
  Label,
} from 'semantic-ui-react';
import { Tagify } from '../../engine/helper';
import useKeyboardEvent from '../../engine/useKeyboardEvent';

const subMinutes = (vimshottariDashaYears) => {
  const minutesFloat = 800 * (parseInt(vimshottariDashaYears) / 120)
  const secs = Math.round(minutesFloat * 60)
  const hours = Math.floor(secs / (60 * 60))
  const divisor4Minutes = secs % (60 * 60)
  const minutes = Math.floor(divisor4Minutes / 60)
  const divisor4Seconds = divisor4Minutes % 60
  const seconds = Math.ceil(divisor4Seconds)
  return [hours + 'h ' + minutes + 'm ' + seconds + 's']
};

const renderColors = (colors) => {
  const colorsArray = colors.split(',');

  return(
    colorsArray.map((colour) => {
      return(
        <Label  color={colour} key={colour}>
          {colour}
        </Label>
      )
    })
  );
};

const GrahaCardContent = ({graha, toggler}) => {
  useKeyboardEvent('Escape', () => toggler());

  return (
    <Card.Content>
      <Card.Header>{`${graha.graha} - ${graha.direction}`}</Card.Header>
      <Card.Description>
        <Tagify tags={graha.alphabetsSanskrit?.join(', ')} />
      </Card.Description>
      <Divider>Hora Signifies</Divider>
      <Card.Description>
        <Tagify tags={graha.kpHoraSignifier} />
      </Card.Description>
      <Divider>Characteristics</Divider>
      <Card.Description>
        <Tagify tags={graha.characteristics} />
      </Card.Description>
      <Divider>Hora Signifier</Divider>
      <Card.Description>
        <Tagify tags={graha.kpHoraSignifier} />
      </Card.Description>
      <Divider>Body Parts</Divider>
      <Card.Meta>
        <Tagify tags={graha.kpBodyParts} />
      </Card.Meta>
      <Divider>Pathogenic Effects</Divider>
      <Card.Content extra>
        <Tagify tags={graha.kpPathogenicEffects} />
      </Card.Content>
      <Divider>Dasha & Sub Period</Divider>
      <Card.Content extra>{graha.vimshottariDashaYears} Years & {subMinutes(graha.vimshottariDashaYears)[0]}</Card.Content>
      <Divider>Color</Divider>
      <Card.Content extra>{renderColors(graha.color)}</Card.Content>
      <Header floated='right'>
        <Icon onClick={toggler} name='close' />
      </Header>
    </Card.Content>
  )
};

export default GrahaCardContent;
