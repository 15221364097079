import React, { useContext, useEffect, useState } from "react";
import { Item, Popup, Table } from "semantic-ui-react";
import { Table as RTable, Tbody, Tr, Td } from "react-super-responsive-table";

import { calculateNakshatra, signStarSub } from "./../engine/helper";

import nakshatras from "./../../data/nakshatras";
import grahas from "./../../data/grahas";
import rashis from "./../../data/rashis";
import GrahaToggleCellContent from "./Graha/GrahaToggleCellContent";

import { NakshatraContext } from "./../contexts/nakshatra.context";

import "semantic-ui-css/semantic.min.css";

const nakshatraImages = require.context(
  "./../../../public/images/nakshatras",
  true
);

const grahaMapping = {
  Ketu: 0,
  Venus: 1,
  Sun: 2,
  Moon: 3,
  Mars: 4,
  Rahu: 5,
  Jupiter: 6,
  Saturn: 7,
  Mercury: 8,
};

const nakshatraImageFileName = (nakshatraIndex) => {
  return nakshatras[nakshatraIndex].imageFileName;
};

const nakshatraTableCell = (nakFor, nIndex, accessor, value) => {
  let element = null;

  switch (accessor) {
    case "rashi":
      element = (
        <Popup
          key={`${accessor}-${nakFor}-${nIndex}`}
          trigger={
            <Table.Cell key={`${accessor}-${nakFor}-${nIndex}`} collapsing>
              {value[accessor]}
            </Table.Cell>
          }
          on="click"
          position="bottom left"
          inverted
          size={"mini"}
          wide
          content={rashis[value.rashiIndex].characteristics}
        />
      );
      break;
    case "nakshatra":
      element = (
        <Popup
          key={`${accessor}-${nakFor}-${nIndex}`}
          trigger={
            <Table.Cell key={`${accessor}-${nakFor}-${nIndex}`} collapsing>
              {value[accessor]}
            </Table.Cell>
          }
          on="click"
          position="bottom left"
          inverted>
          <Item>
            <Item.Content verticalAlign="middle">
              <Item.Header>{`${nakshatras[value.nakshatraIndex].id}. ${
                nakshatras[value.nakshatraIndex].catchPhrase
              }`}</Item.Header>
              <Item.Meta>{`${
                nakshatras[value.nakshatraIndex].keyWords
              }`}</Item.Meta>
              <Item.Description>{`${
                nakshatras[value.nakshatraIndex].shortDiscription
              }`}</Item.Description>
            </Item.Content>
            <Item.Image
              size="large"
              src={nakshatraImages(
                "./" + nakshatraImageFileName(value.nakshatraIndex)
              )}
            />
          </Item>
        </Popup>
      );
      break;
    case "pada":
      element = (
        <Popup
          key={`${accessor}-${nakFor}-${nIndex}`}
          trigger={
            <Table.Cell key={`${accessor}-${nakFor}-${nIndex}`} collapsing>
              {value[accessor]}
            </Table.Cell>
          }
          on="click"
          position="bottom left"
          inverted>
          <img
            alt=""
            src={nakshatraImages(`./padas/NakshatraPada${value[accessor]}.jpg`)}
          />
        </Popup>
      );
      break;
    case "sign":
    case "star":
    case "sub":
      element = (
        <GrahaToggleCellContent
          key={`${accessor}-${nakFor}-${nIndex}`}
          grahaKey={`${accessor}-${nakFor}-${nIndex}`}
          graha={grahas[grahaMapping[value[accessor]]]}
        />
      );
      break;
    case "endsAt":
    case "startsAt":
      element = (
        <Table.Cell key={`${accessor}-${nakFor}-${nIndex}`} collapsing>
          {value[accessor]}
        </Table.Cell>
      );
      break;
    default:
      element = null;
      break;
  }
  return element;
};

const capacity = (rashiId, degree) => {
  const firstDegree = parseInt(degree.split(/[°']/)[0]);

  if (rashiId % 2) {
    if (0 <= firstDegree <= 6) return "Dead (म्रुत) 0%";
    if (7 <= firstDegree <= 12) return "Old (व्रुध) 12%";
    if (13 <= firstDegree <= 18) return "Young (युवा) 100%";
    if (19 <= firstDegree <= 24) return "Child (कुमार) 50%";
    if (25 <= firstDegree <= 30) return "Infant (बाल) 25%";
  } else {
    if (0 <= firstDegree <= 6) return "Infant (बाल) 25%";
    if (7 <= firstDegree <= 12) return "Child (कुमार) 50%";
    if (13 <= firstDegree <= 18) return "Young (युवा) 100%";
    if (19 <= firstDegree <= 24) return "Old (व्रुध) 12%";
    if (25 <= firstDegree <= 30) return "Dead (म्रुत) 0%";
  }
};

const NakshatraRow = ({
  for: forProp,
  navamsa,
  rashiAspects,
  signDegrees,
  signIndex,
}) => {
  let aspectNames, aspectNumbers;

  if (rashiAspects) {
    aspectNames = rashiAspects.aspectNames;
    aspectNumbers = rashiAspects.aspectNumbers;
  }

  const [signStartsSubList, setSignStartsSubList] = useState([]);
  const nakshatraRow = calculateNakshatra(signDegrees, signIndex);

  const { dispatch } = useContext(NakshatraContext);

  const {
    id: radhiDataId,
    vedicName,
    ownerGraha,
    gender,
    nature,
    tenor,
  } = rashis[signStartsSubList?.[0]?.rashiIndex] || {};

  useEffect(() => {
    const props4Index = forProp.split(" ")[0];
    dispatch({
      type: "GRAHAS",
      payload: {
        key: props4Index,
        value: signStartsSubList?.[0],
      },
    });
    setSignStartsSubList(
      signStarSub(
        props4Index,
        dispatch,
        nakshatraRow.sign,
        nakshatraRow.nakshatra
      )
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <React.Fragment>
      {nakshatraRow &&
        signStartsSubList?.map((value, index) => (
          <React.Fragment key={`${forProp}-${index}`}>
            <Table key={`${forProp}-${index}`} size={"small"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Sign</Table.HeaderCell>
                  <Table.HeaderCell>Nakshatra</Table.HeaderCell>
                  <Table.HeaderCell>Nakshatra Pada</Table.HeaderCell>
                  <Table.HeaderCell>Starts At</Table.HeaderCell>
                  <Table.HeaderCell>Ends At</Table.HeaderCell>
                  <Table.HeaderCell>Sign Lord</Table.HeaderCell>
                  <Table.HeaderCell>Star Lord</Table.HeaderCell>
                  <Table.HeaderCell>Sub Lord</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key={`${forProp}-${index}`}>
                  {Object.keys(value).map((keyCol, colIndex) =>
                    nakshatraTableCell(
                      `${forProp}-${index}`,
                      index,
                      keyCol,
                      value
                    )
                  )}
                </Table.Row>
                <Table.Row key={`${forProp}-${index}-rRow`}>
                  <Table.Cell key={`${forProp}-${index}-rCell`} colSpan="8">
                    <RTable key={`${forProp}-${index}-rashi`}>
                      <Tbody>
                        <Tr>
                          <Td>No.</Td>
                          <Td>Vedic Name</Td>
                          <Td>Owner</Td>
                          <Td>Gender</Td>
                          <Td>Nature</Td>
                          <Td>Tenor</Td>
                          <Td>Capacity</Td>
                        </Tr>
                        <Tr>
                          <Td>{radhiDataId}</Td>
                          <Td>{vedicName}</Td>
                          <Td>{ownerGraha}</Td>
                          <Td>{gender}</Td>
                          <Td>{nature}</Td>
                          <Td>{tenor}</Td>
                          <Td>
                            {capacity(
                              signStartsSubList?.[0]?.rashiIndex,
                              signDegrees
                            )}
                          </Td>
                        </Tr>
                      </Tbody>
                    </RTable>
                  </Table.Cell>
                </Table.Row>
                {aspectNames && aspectNumbers && navamsa && (
                  <Table.Row key={`${forProp}-${index}-rAspectRow`}>
                    <Table.Cell
                      key={`${forProp}-${index}-rAspectCell`}
                      colSpan="8">
                      <RTable key={`${forProp}-${index}-rashiAspect`}>
                        <Tbody>
                          <Tr>
                            <Td>Navamsa</Td>
                            <Td>Aspect Names</Td>
                            <Td>Aspect Number</Td>
                          </Tr>
                          <Tr>
                            <Td>{navamsa}</Td>
                            <Td>{aspectNames.join(", ")}</Td>
                            <Td>{aspectNumbers.join(", ")}</Td>
                          </Tr>
                        </Tbody>
                      </RTable>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default NakshatraRow;
