import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Card, Modal } from 'semantic-ui-react';
import SthanaCardContent from './Sthana/SthanaCardContent';

import './table.css';

const ghaars = require('./../../data/houses');

const ToggleContent = ({ ghaar }) => {
  const [isShown, setIsShown] = useState(false);

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  return (
    <Modal
      key={ghaar.id}
      size="fullscreen"
      trigger={
        <Tr key={ghaar.id} onClick={toggler}>
          <Td>{ghaar.name}</Td>
          <Td>{ghaar.romanName}</Td>
          <Td>{ghaar.karakatva}</Td>
          <Td>{ghaar.kpBodyParts}</Td>
          <Td>{ghaar.kpSignifiers}</Td>
          <Td>{ghaar.nkSignifiers}</Td>
          <Td>{ghaar.aSignifies}</Td>
          <Td>{ghaar.karakas}</Td>
          <Td>{ghaar.sthanas}</Td>
          <Td>{ghaar.direction}</Td>
          <Td>{ghaar.sunTiming}</Td>
        </Tr>
      }
      open={isShown}
      centered={false}>
      <Card fluid key={ghaar.id} className="toggle">
        <SthanaCardContent ghaar={ghaar} toggler={toggler} />
      </Card>
    </Modal>
  );
};

const Ghaars = () => {
  useEffect(() => {
    document.title = 'Houses';
  }, []);

  return (
    <React.Fragment>
      <h2>Houses</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>RomanName</Th>
            <Th>Karakatva</Th>
            <Th>KpBodyParts</Th>
            <Th>KpSignifies</Th>
            <Th>NkSignifies</Th>
            <Th>ASignifies</Th>
            <Th>Karakas</Th>
            <Th>Sthanas</Th>
            <Th>Direction</Th>
            <Th>SunTiming</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ghaars.map((ghaar) => (
            <ToggleContent key={ghaar.id} ghaar={ghaar} />
          ))}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export default Ghaars;
