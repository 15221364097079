import React from 'react';
import {
  Card,
  Divider,
  Header,
  Icon,
  Label,
} from 'semantic-ui-react';
import { Tagify } from '../../engine/helper';
import useKeyboardEvent from '../../engine/useKeyboardEvent';

const RashiCardContent = ({rashi, toggler}) => {
  useKeyboardEvent('Escape', () => toggler());

  return (
    <Card fluid
      key={rashi.id}
      className='toggle'
    >
      <Card.Content>
        <Card.Header>{rashi.name}<Label>{rashi.vedicName} ≫ {rashi.gender} ≫ {rashi.nature} ≫ {rashi.tenor}</Label></Card.Header>
        <Divider>Characteristics</Divider>
        <Card.Description>
          <Tagify tags={rashi.characteristics} />
        </Card.Description>
        <Divider>Body Parts</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpBodyParty} />
        </Card.Description>
        <Divider>Hora Places</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHPlaces} />
        </Card.Description>
        <Divider>Hora Countries and Cities</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHCountriesNCities} />
        </Card.Description>
        <Divider>Tenor Properties</Divider>
        <Card.Meta>
          <Tagify tags={rashi.kpTenorProperties} />
        </Card.Meta>
        <Divider>Gender</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHGender} />
        </Card.Description>
        <Divider>Orientations</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHOrientations} />
        </Card.Description>
        <Divider>Element</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHElement} />
        </Card.Description>
        <Divider>Qualities</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHQualities} />
        </Card.Description>
        <Divider>Physical Appearance</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHPhysicalAppearance} />
        </Card.Description>
        <Divider>Heath and Diseases</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHHeathNDiseases} />
        </Card.Description>
        <Divider>Finance and Fortune</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHFinanceNFortune} />
        </Card.Description>
        <Divider>Romance (LOVE AFFAIRS)</Divider>
        <Card.Description>
          <Tagify tags={rashi.kpHRomance} />
        </Card.Description>
        <Card.Content extra>
          <Tagify tags={rashi.kpPathogenicEffects} />
        </Card.Content>
        <Header floated='right'>
          <Icon onClick={toggler} name='close' />
        </Header>
      </Card.Content>
    </Card>
  )
};

export default RashiCardContent;
