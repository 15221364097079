import React from 'react';
import { Card, Divider, Header, Icon } from 'semantic-ui-react';
import { Tagify } from '../../engine/helper';
import useKeyboardEvent from '../../engine/useKeyboardEvent';

const SthanCardContent = ({ ghaar, toggler, darkModeValue }) => {
  useKeyboardEvent('Escape', () => toggler());

  // return (
  //   <Card.Content>
  //     <Card.Header>{`${ghaar.romanName} - ${ghaar.name} - ${ghaar.direction}`}</Card.Header>
  //     <Divider>Karakatva</Divider>
  //     <Card.Description>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.karakatva} />
  //     </Card.Description>
  //     <Divider>KP Signifiers</Divider>
  //     <Card.Description>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.kpSignifiers} />
  //     </Card.Description>
  //     <Divider>Nakshatra Signifiers</Divider>
  //     <Card.Description>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.nkSignifiers} />
  //     </Card.Description>
  //     <Divider>A Signifier</Divider>
  //     <Card.Description>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.aSignifies} />
  //     </Card.Description>
  //     <Divider>KP Body Parts</Divider>
  //     <Card.Meta>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.kpBodyParts} />
  //     </Card.Meta>
  //     <Divider>Karakas</Divider>
  //     <Card.Meta>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.karakas} />
  //     </Card.Meta>
  //     <Divider>Sthanas</Divider>
  //     <Card.Content extra>
  //       <Tagify darkModeValue={darkModeValue} tags={ghaar.sthanas} />
  //     </Card.Content>
  //     <Header floated="right">
  //       <Icon onClick={toggler} name="close" />
  //     </Header>
  //   </Card.Content>
  // );

  return (
    <Card.Content>
      <Card.Header>{`${ghaar.romanName} - ${ghaar.name} - ${ghaar.direction}`}</Card.Header>
      <Divider />
      <Card.Description>
        <Tagify darkModeValue={darkModeValue} tags={ghaar.starGuide} />
      </Card.Description>
      <Header floated="right">
        <Icon onClick={toggler} name="close" />
      </Header>
    </Card.Content>
  );
};

export default SthanCardContent;
