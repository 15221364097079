import React from 'react';
import {
  Tab,
} from 'semantic-ui-react'
import KPRulesBasic from './KPRulesBasic';
import KPRulesFramework from './KPRulesFramework';

import 'semantic-ui-css/semantic.min.css'

const panes = [
  {
    menuItem: 'Basic',
    render: () => <Tab.Pane>
      <KPRulesBasic />
    </Tab.Pane>
  }, {
    menuItem: 'Framework',
    render: () => <Tab.Pane>
      <KPRulesFramework />
    </Tab.Pane>
  },
];

const Rules = () => {
  return <Tab panes={panes} />;
};

export default Rules;
