import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button, Label, Menu } from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";

import { NakshatraContext, useAuth } from "./../../contexts/nakshatra.context";

const MenuStyle = styled.div`
  height: 24px;
  border: none;
`;

const renderSingleMenuRow = (menuItems, index) => {
  return (
    <MenuStyle key={index}>
      <Menu key={index} attached="top" size="mini" fluid widths={10}>
        {menuItems.map((menuItem, index) => {
          return (
            <Menu.Item as={NavLink} key={index} to={menuItem.href}>
              {menuItem.name}
            </Menu.Item>
          );
        })}
      </Menu>
    </MenuStyle>
  );
};

const SubMenuStyle = styled.div`
  .ui.menu.ten.item .item {
    border-radius: 15px 50px;
    min-width: 14ch;
    height: 10px;
  }
`;

const renderMenu = (menuItemsArray) => {
  return (
    <SubMenuStyle>
      {menuItemsArray.map((menuItems, index) => {
        return renderSingleMenuRow(menuItems, index);
      })}
    </SubMenuStyle>
  );
};

const MainMenuStyle = styled.div`
  .ui.attached.menu {
    height: 10px !important;
  }
`;

const MainMenu = ({ location }) => {
  const { state } = useContext(NakshatraContext);

  const menuItems = [
    [
      { id: 0, name: "Chart", href: "/Chart" },
      { id: 1, name: "Nakshatra", href: "/Nakshatra" },
      { id: 2, name: "Graha", href: "/Graha" },
      { id: 3, name: "Rashi", href: "/Rashi" },
    ],
    [
      { id: 4, name: "Nakshatra Chakra", href: "/NakshatraChakra" },
      { id: 5, name: "Nakshatrified", href: "/Nakshatrified" },
      { id: 6, name: "Slider", href: "/Slider" },
      { id: 7, name: "Vimshottari Subs", href: "/VimshottariSubs" },
    ],
    [
      { id: 8, name: "Houses", href: "/Houses" },
      { id: 9, name: "KP", href: "/KP" },
      { id: 9, name: "Bhrigu", href: "/Bhrigu" },
      { id: 10, name: "Numerology", href: "/Numerology" },
    ],
    [{ id: 11, name: "Name", href: "/Name" }],
  ];

  const { user } = state;
  const { logout, loginWithRedirect } = useAuth();

  let shownName = user?.given_name || user?.nickname;

  const handleLogout = async (event) => {
    if (event) event.preventDefault();
    try {
      await logout();
    } catch (error) {
      console.error("handleLogout Error:", error);
    }
  };

  const handleLogin = async (event) => {
    if (event) event.preventDefault();
    try {
      await loginWithRedirect({
        redirectUri: window.location.origin + location.pathname,
      });
    } catch (error) {
      console.error("handleLogin Error:", error);
    }
  };

  return (
    <MainMenuStyle>
      {user?.email ? (
        <Label>
          {`Hello, ${shownName}!`}
          <Button onClick={handleLogout}>Logout</Button>
        </Label>
      ) : (
        <Button onClick={handleLogin}>Log in</Button>
      )}
      {renderMenu(menuItems)}
    </MainMenuStyle>
  );
};

export default MainMenu;
