module.exports = Object.freeze([
  {
    id: 1,
    navatara: 'Janma',
    nakshtraName: 'Ashwini',
    lord: 'Ketu',
    imageFileName: '01.Ashwini.jpg',
    catchPhrase: 'The Beautiful Stallion',
    keyWords: 'Health, medicine, beauty, enjoyment, swiftness, loyalty',
    shortDiscription:
      'Ashwini is the star of health, beauty, and speed. It inspires the science of medicine: surgery and curing of illness (especially blindness). It increases our appetite for enjoyment but also our appreciation of loyalty and good character.',
    color: '#FA0006',
    colorName: 'Blood Red',
    kpBodyParts: 'Head and brain, Cerebral hemispheres',
    bodyPart1: 'Knees/Upper Part of the Feet',
    bodyPart2: 'Both Knees',
    deity: 'Ashwini Kumaras, The Celestial Physicians',
    animal: 'Ashwa (Horse) Male',
    diseases:
      'Injury in the head, congestion in the brains, thrombosis, cerebral anaemia, fainting, epilepsy, violence, spasms, severe headache on any one side, neuralgia, coma, trance, cerebral haemorrhages, paralytic stroke, insomnia, malaria, meningitis, smallpox. Does not contribute to child birth unless Kethu happens to be a benefic to the INDIVIDUAL May induce abortion, surgical aid for fertility but mostly remaining barren. When strong benefits form favourable aspects, the evil results will be warded off',
    mentalQualities: `Avarious, extravagant, worried about landed properties, brother's ailment, dispute with brother, partition, black magic, can pray Lord Ganesh or Subramanya or Narasimha or Anjaneya. (Will be fond of Ornaments, lovely in appearance, liked by all, clever and intelligent)`,
    professions: `Service in factory, police, military, medical, surgery, criminal courts, jail, railways, machinery, iron, steel, copper (Commandants, Physicians, Attendants, horses, horse-riders, horse-dealers, horse-groom, handsome persons as traders)`,
    sunTransitPeriod: '14th Apr - 27th Apr',
    kpPathogenicEffects: `Injury in the head, congestion in the brain, thrombosis, Cereberal anaemia, faintings, epiiopsy, violence, spasms, severe head ache on any one side, neuralgia, coma, trance, cereberal hemorrhages, paralytic stroke, insomnia, malaria, menigitis small pox`,
    asc: `Adventurous spirit, love of travel, Brilliant eyes, magnetic look, Respected, prominent, modest, efficient, Need to control diet, drug problems`,
    ascPeople: `Amelia Erhart, Martin Luther King, Robert E Kennedy, Charles Manson, Liza Minelli`,
    moon: `Intelligent, bright mind, Attractive, beautiful appearance, Happy mood, gives hope to others, Healing gifts, Nurtures others, Fond of music and the fine arts Love of travel, Attracted to spiritual life, Believes strongly in God, Sincere love of family, Marriage usually occurs, between the ages of 26-30, Struggles until 30th year, then progress occurs`,
    moonPeople: `Prince Charles, Jackie Onassis, Jerry Lewis, Alfred Adler, Rick Tamas, Aldous Huxley, Ingrid Bergman, Geena Davis, My wife Laura`,
    sun: `Regal, proud nature, Excellent business skills, Aggressive, militant, Leadership, authority positions, Desire for power, fame`,
    sunPeople: `A1 Pacino, J.P Morgan, Adolf Hitler, Charlie Chaplin, Barbra Streisand`,
    carrerInterests: `Psychologists, therapists Physicians, healers, Mystics, Military personnel, Police, criminal courts, Merchants, salespeople, Musicians, horse trainers, jockeys`,
    healthIssues: `Head injuries, Headaches, Mental Illness, Smallpox, Malaria`,
    qualities: `Laghu or Kshipra (light and swift) to start: a journey (travel), sports activities, doing healing work, administer­ing medicines, opening a business, sales, trade and obtaining or repaying a loan or debt`,
    activitiesOrEvents: `Learning Astrology or Astronomy, Planting and Sowing, Medical Treatment, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 2,
    navatara: 'Sampat',
    nakshtraName: 'Bharani',
    lord: 'Venus',
    imageFileName: '02.Bharani.jpg',
    catchPhrase: 'Labor Pains',
    keyWords: 'Self-sacrifice, labor, effort, child-bearing',
    shortDiscription:
      'Bharaṇī represents difficulties on the path to prosperity. It is the “pain” that leads to “gain.” It empowers humanity with self-control and sobriety so that we can endure trials for the sake of producing something truly great.',
    color: '#FA0006',
    colorName: 'Blood Red',
    kpBodyParts:
      'Some part of Head, Cerebral hemisphere, organs within the head, brain, eyes and eye vision',
    bodyPart1: 'Head, Bottom part of the feet',
    bodyPart2: 'Head',
    deity: 'Yama, The Lord of Dharma and the Ancestors',
    animal: 'Gaja (Elephant) Male',
    diseases: `Injury in the head, mostly in the forehead and just around the eyes, cold venereal distemper, syphilis affecting face and vision, catarrh in the head, moist humours, mucus, reins afflicted, weakness due to gratifying one's tastes and pleasurable emotions in dissipating habits`,
    mentalQualities: `Chain smoker (Mars Venus .fiery sign) (Tobacco cultivator) - mind, always fond of pleasure, enterprises, aspiring, amorous, avaricious,- (cruel men, those that feed on blood and flesh, slayers, low-born persons, cudgellers, husk grain and wanting in character. Will carry out his purpose to the end, will be truthful, free from malady, clever and happy)`,
    professions: `Makes money through pleasure, sports, music or musical instruments, art advertisement show display, exhibition, silver utensils, eversilver, silk, mobile, automobile, fertilizer, industry, railway, factory, wrestler, cinema theatre, studio, mansion for marriage, animal husbandry, veterinary doctor, slaughter house, tea or coffee estates, caterers, hotels, restaurants, criminal lawyer, judge, leather manufacturer, skins and hides, building contractors, engineer, those who assess and pass orders, revenue department, surgeon, maternity, venereal expert, optician, agriculturist. Whenever one runs Mars dasa Venus Bhukthi or Venus dasa Mars Bhukthi, then the matters signified by these two planets will materializes, when either of these planets transit-in this zone or in Mrigasira first half or Chithrai second half or Venus star Mars sub or Mars star Venus sub : Also when the luminaries Sun and Moon transit in these areas, one can enjoy those results`,
    sunTransitPeriod: '27th Apr - 10th May',
    kpPathogenicEffects: `Injury in the head', mostly in the forehead, and just around the eyes, cold venereal distemper, syphtllis, affecting face and vision, catarrh in the head, mucus, moist humours reins afflicted, weakness due to gratifying one’s tastes and pleasyrable emotions in dissipating habits`,
    asc: `Courageous, pioneering spirit, Proud, confident, famous, Good health, vibrant, Help their friends and family, Fond of family, but few biological children`,
    ascPeople: `George Lucas, John Denver, Swarni Muktananda, Mia Farrow, Joe Pesci`,
    moon: `Attractive, charismatic, Leadership, shine well in public life, Healthy, free from disease, Clever in doing their work, dutiful, Investigation into occult studies, Success through writing and publishing, General betterment after 33 years of age`,
    moonPeople: `C.G. Jung, Karl Marx Elton John, Oliver Stone, Edgar Cayce, James Braha, Carlos Castaneda, Tonya Harding, Monica Lewinsky`,
    sun: `Intelligent, tactful, Fame, respected, Wealth, creative nature, Issues with anger and pride, Militant nature`,
    sunPeople: `Sigmund Freud, Jack Nicholson, Orson Welles, W.R Hearst, Saddam Hussein, Rudolph Hess`,
    carrerInterests: `Publishers, writers, Film and music industry, Occultists, psychics, hypnotists, Astrologers, psychologists, Entrepreneurs, business people, Financial consultants, Lawyers, Building Contractors`,
    healthIssues: `Problems with reproductive organs, Venereal disease, Face and eyesight problems, Head injuries`,
    qualities: `Krura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement`,
    activitiesOrEvents: ``,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 3,
    navatara: 'Vipat',
    nakshtraName: 'Krittika',
    lord: 'Sun',
    imageFileName: '03.Krittika.jpg',
    catchPhrase: 'The Sharp Blade',
    keyWords: 'Incision, insight, brilliance, subdivision, critique, sharpness',
    shortDiscription:
      'Kṛttikā is “bright” – brilliant, intellectual, and quick to digest .It is a star of insight, incisiveness, and dissection. It is analytical and critical and therefore not favorable towards things requiring tender care.',
    color: '#E4E4E4',
    colorName: 'White',
    kpBodyParts:
      '(Aries part) Head, eyes, brain, vision, (Taurus part) Face, Neck, larynx, Tonsillitis, lower Jaw, back part of the head, occipital region',
    bodyPart1: 'Hips, Loins, Upper & Back Portions of the Head',
    bodyPart2: 'Waist',
    deity: 'Agni, The Consumer of the Food of the Gods, Defied Fire',
    animal: 'Mesha (Goat/ Ram) yoni (reproductive organ) Female',
    diseases: `(Aries part) Sharp fever, malaria, filaria, plague, smallpox, wounds, cerebral meningitis, brain fever, injury, cuts, accident, explosions, carbuncle, fire accident.
    (Taurus part) Pimples, cuts, reddish eye, eyesore, throat troubles, tumours in the knees, quinsy, swelling above neck polypus of the nose`,
    mentalQualities: `(Aries part) Normally robust health, good strength, great enthusiasm, push-up, go ahead spirit, militant temperament, leading nature, commanding appearance, competitive mind, argumentative ability etc. (will eat much, will be drawn to other peoples' wives, will be ebullient in appearance and famous)
    (Taurus part) Always fond of company, sociability, hospitality, pleasure, comfort, luxury, generous, courteous affectionate, cheerful and impressionable, creative mind, fruitful action, best fitted for business, smooth profession, speculative tendency, popular and prosperous`,
    professions: `(Aries part) Acquisition of lands, buildings or possession of patrimony, bold punter, dauntless, escalator, military, police, industry, medical, surgical, navy (Moon in this position or a planet in this star and Moon Sub) Defence department, travels, resignation, retirement, manufacturing chemicals, bombs, crackers, explosives, match boxes, iron and steel utensils, war material (Saturn in this area makes one listless and rapid. He will never amount to anything. But Mars in this area promises that the person will not hesitate to fight at the drop of his hat, especially, when Mars is in between 28° - 26*40" and 29°-6'-40", i.e. Mars Sing, Sun Star and Mars sub). (Brahmins and particularly those performing daily Agnihotra, reciters of sacred hymns, white flowers, grammarians, barbers, miners, priests, astrologers, potters and those who know the sacrificial rules)
    (Taurus part) Benefit through Government, Victory over enemies, acquires costly jewels and dress, contacts with foreigners, realization of even bad debts, pleasant functions, music, dance, drama, opera, poet, artist, drawing master, draftsman, sculpture, silk, photography, international trade, installation of parks, decoration, industry, medical department, engineer, tax collector, wool-dealer, export of hair, assessment, venereal expert`,
    sunTransitPeriod: `11th May - 13th May, (Taurus part) 13th May - 24th May`,
    kpPathogenicEffects: `(Aries part) Sharp fever, Malaria, Filaria, Plaugue, Small pox. Wounds, cereberal menangitis, brain fever, injury, cuts, accident, explosions, carbuncle, fire accident (Taurus part) Pimples, Cuts, Reddish eye, Eye sore Throat troubles, Tumours in knees, quinsy, Swelling above neck, Polypus of the nose`,
    asc: `Dignified, proud, honorable, Ambitious, skillful, wealthy, Truthful, honest, Strong appetite, Wavering mind at times`,
    ascPeople: `Gregory Peck, Dennis Flaherty, Bepin Behari, James Braha, Lazaris (Jach Pursel)`,
    moon: `Brilliant appearance, well-known, Peaceful nature, good advisor, Strong in purpose, determined, Intelligent, but unstable mind, Gifts in the fine arts, Moves away from birthplace, Good periods: 25-35; 50-56, Attached to mother, trouble with father, Combative nature at times`,
    moonPeople: `Bill Clinton, Bob Dylan, Ammachi, Ronald Reagan, Frida Kahlo, Mick Jagger, Gregory Peck, Peter Sellers, Jimmy Hoffa`,
    sun: `Spiritual warrior, disciplined, Leadership, power over the masses, Issues with authority figures, Need for seclusion, solitude, Gifts in music, dance, and drama, Angry temperament, destructive`,
    sunPeople: `Sri Yukteswar, Pope John Paul II, Swami Kriyananda, Swami Muktananda, Janet Jackson, Cher, Jim Jones`,
    carrerInterests: `Spiritual teachers, advisors, Musicians, dancers, singers, Modelings, fashion designers, Military careers, Building contractors`,
    healthIssues: `Neck ache, Throat soreness, Headaches, Fevers, Malaria`,
    qualities: `Misra (mixed): mundane daily activities, fire ceremonies (Agni)`,
    activitiesOrEvents: ``,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 4,
    navatara: 'Kshema',
    nakshtraName: 'Rohini',
    lord: 'Moon',
    imageFileName: '04.Rohini.jpg',
    catchPhrase: 'The Blushing Bride',
    keyWords:
      'Creativity, fertility, passion, beauty, inspiration, imagination',
    shortDiscription:
      'Rohiṇī is the star of fertility & creativity; two traits always accompanied by passion and beauty. It is a star of motivation and all manner of creative talents. It abounds in imagination & procreation. It has a short-lived but hot temper when frustrated.',
    color: '#E4E4E4',
    colorName: 'White',
    kpBodyParts:
      'Face, mouth, tongue, tonsils, palate, neck, cerebellum, atlas, cervical vertebrate, neck bone',
    bodyPart1: 'Forehead, Ankles, Skin & Calves of the legs',
    bodyPart2: 'Both Legs',
    deity:
      'Prajapati, The Creator and Maker of the Universe and all the Beings',
    animal: 'Sarpa (Serpent) Male',
    diseases: `Sore-throat, cold, Cough, pain in the legs and apoplexy, irregular menses, sweating`,
    mentalQualities: `Pleasant manners, good nature, enjoys nature, taste in music, art, drama, literature, public function, maternal affection, sympathetic, enjoys life in the company of other sex. (Will be truthful, pure, sweet-tongued, with a settled mind and lovely appearance)`,
    professions: `Caters to the need of the public, hotels, restaurants, lodging-houses, bakeries, boarding houses with comforts and conveniences, bar attached, dealers in houses, lands, fruits, automobile, petrol, oils, milk, dairy farm, ice cream, glass, plastic, scents, perfumeries, soap, sandalwood, oil, paints, water, colour, dyes, liquids, acids, marriage broker, dress and pearls dealer, navy, shipping, clearing agent, judge, politician, tannery, yarn merchant, sugar, sugarcane, sugarcoated tablets, etc. (King, Wealthy person, observers of vows, merchandises, cartmen, cows, bulls, aquatic animals, agriculturists, yogis, mountains and men in authority)`,
    sunTransitPeriod: '25th May - 7th Jun',
    kpPathogenicEffects: `Sore-throat, Cold, Cough, Pains In the Legs and Feet, Paining in the breast, goiter, apoplexy, Irregular menses, Swellings`,
    asc: `Brilliant, attractive, magnetic eyes, Charismatic leader, wealthy, Political power, popular, Virile, romantic, artistic, Sex symbol, sensual nature, Indulgent, sexual addiction`,
    ascPeople: `Sri Krishna, Wemer Erhard, Queen Victoria, Mick Jagger, Michelle Pfeiffer, Burt Reynolds, Wilt Chamberlain`,
    moon: `Lovely appearance, balanced mind, Gifts in music, dance and drama, Successful career, affects the public, Success in politics, fame, wealth, Good health, well-developed muscles, Best periods: 30-50 (after Rahu Dasa), Trouble with early marriage, indecisive`,
    moonPeople: `Sri Krishna, Fred Astaire, Sigmund Freud, Queen Victoria, William Levacy, Howard Cosell`,
    sun: `Beautiful, seductive, sensual, Artistic nature, strong emotions, Poetic, musical gifts, Robust nature, well-liked, many friends`,
    sunPeople: `Marilyn Monroe, JFK Isadora Duncan, Bob Dylan, John Wayne, Clint Eastwood`,
    carrerInterests: `Politicians, authority positions, Musicians, artists, actors, dancers, Agriculture, real estate, Restaurant and hotel business, Fashion designers, models`,
    healthIssues: `Colds and cough, Irregular menses, Apoplexy (Sun stroke), Obesity, Poor diet, Sore throat, Venereal disease`,
    qualities: `Sthira (fixed or permanent): emphasis toward permanence and stability and structure: building homes, laying the foundations of communities, plowing the land, planting trees, purchasing agricultural property`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Laying Foundation of Home, Learning Music or Dance, Planting and Sowing, Medical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 5,
    navatara: 'Pratyari',
    nakshtraName: 'Mrigashirsh',
    lord: 'Mars',
    imageFileName: '05.Mrigshira.jpg',
    catchPhrase: 'The Quest',
    keyWords: 'The search for goals and paths; quests',
    shortDiscription:
      'Mrigashirsh is a star of searching for the best goal, and the best path to it. It empowers humanity to seek and quest, driving the human animal to sniff out the paths of life in search of true happiness.',
    color: '#A6AAB7',
    colorName: 'Silver Grey',
    kpBodyParts: `(Taurus part) Face, chin, cheeks, larynx, palate, carotid, arteries jugular, veins, inflamed tonsils
    (Gemini part) Throat, vocal chord, Arms, Shoulders, Ears, Thymus Gland, Upper Ribs`,
    bodyPart1: 'Eyes & Eyebrows',
    bodyPart2: 'The Two Eyes',
    deity: 'Soma, The God of the Nectar of Immortality',
    animal: 'Sarpa (Serpent) Female',
    diseases: `(Taurus part)Pimples, cuts and injuries in those parts, pain in the throat, adenoids, diphtheria, weak loins and king's evil, nose-bleed, goitre, constipation, venereal distemper polypus
    (Gemini part) Corrupted blood, itches, wounds, and fracture of arms, collarbone, fractured femur, sciatica, surfeit, fever, pains in the shoulders near collar bone, arms, disorders in secret parts, inflammation of pericardium.`,
    mentalQualities: `(Taurus part)
    (Gemini part) Gives mental energy, enthusiasms, dexterity, quick-witted, sharp, fluent, alert, quick, magnetic, impulsive, excitable, vitriolic in their wrath, tongues more poisonous than rattlesnake, acme of selfishness. (Will be fickle, sharp-witted, timid, eloquent, industrious, wealthy and indulging in sexual pleasure)`,
    professions: `(Taurus part) Estate owners, land, building, instrumental music, exhibition, display, eversilver, platinum, tailor, dressmaker, mobile structurals, fertilizer, automobile engineering, income tax and sales tax department, skins and hides, tobacco, snuff, confectionery, marriage mandapam owners, veterinary doctors, animal husbandry, lethal chamber, cart-men, rickshaw pullers, taxi drivers, fruit sellers like apple, banana, etc., whereas Rohini shows oranges, grapes and juicy ones, gains through business, textiles, cinema industry, sound engineer, photography, diamonds, rubies, corals, jewelery, vaseline, snow, face powder, sandal powder, tooth paste, brush etc
    (Gemini part) Dealing in machineries, tools, instruments, electrical goods, surgical instruments, telephones, telegraph cables, wires, engineers, surgeons, soldiers, mathematicians, ambassadors, astronomers, building contractors, highways, bridge or dam-site constructors, textile engineers, tape-record, gramophone, radio dealers, press, publication, computers, calculators, salesman, representatives, agents, brokers, jobbers, thieves, etc. (Aquatic products, fragrant things, flowers, fruits, garments, gems, birds, beast, forests, drinkers of Soma juice, letter-bearers, lovers and musicians)`,
    sunTransitPeriod: '8th Jun - 21nd Jun',
    kpPathogenicEffects: `(Taurus part) Pimples, Cuts and injuries In those parts, pain in the throat, dlptheria, nose bleeding, goiter, constipations, venereal distamper, polypus (Gemini part) Corrupted blood, Itches, wounds, and fracture of arms, collarbone, sciatica, fever, pains in the shoulders near collar bone, arms, disorder in secret parts`,
    asc: `Attractive, seductive nature, Mystical, seeker of truth, Face of a deer, Enthusiastic, talkative, good speech, Active mind, mental vacillation, Daring, love of travel`,
    ascPeople: `Bagwan Shree Rajneesh, Orson Welles, Leonard Nimoy, Pamela Lee Anderson, Drew Barrymore, Henry Kissenger, Rick Tamas, Noel Tyl, Michael Caine, Gen. George Patton`,
    moon: `Intelligent, writing skills, Political nature, wealth, Sensual, beautiful, timid, Trouble with business partnerships, Life is generally better after, Rahu dasa (after 21)`,
    moonPeople: `Dr. B.V. Raman, Walter Mondale, Shirley Temple Black, Brooke Shields, Rodin, Doris Day, Niranjan Babu, Goldie Hawn`,
    sun: `Adventurous, courageous, Athletic, competitive, Leadership, authority figure, Poetic, writing skill, Jack of all trades, creative, Indulgent nature, laziness`,
    sunPeople: `Jacques Cousteau, Venus Williams, George Bush, William Butler Yeats, Frank Lloyd Wright, Judy Garland`,
    carrerInterests: `Writers, poets, Astrologers, mystics, Researchers, teachers, Engineers, gemologists, Acting profession, music, Real estate, sales, travel agents`,
    healthIssues: `Vocal chords, sore throats, Constipation, Venereal disease, Sciatica`,
    qualities: `Mridu (soft, mild or tender): learning music-dance-drama, performing auspicious ceremonies like marriage, buying new clothes, wearing new clothes, Love-making, romance, making new friends, enjoyment of pleasures that are healing, revitalizing`,
    activitiesOrEvents: `Buying a Home, Marriage, Installing a Deity or Building a Temple, Laying Foundation of Home, Medical Treatment, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 6,
    navatara: 'Sadhak',
    nakshtraName: 'Ardra',
    lord: 'Rahu',
    imageFileName: '06.Ardra.jpg',
    catchPhrase: 'The Storm',
    keyWords: 'Storms, destruction, frustration, blockade, spirituality',
    shortDiscription:
      'Ardra is the storm that we must weather so that our nature can once again become pure and clean. It represents all the things we must overcome before we can prosper. It allows us to destroy inauspicious things and forget what should be forgotten. Given spiritual direction, Ardra empowers us to destroy the false ego and enter blessed realms.',
    color: '#7DC02A',
    colorName: 'Green',
    kpBodyParts: 'Throat, Arms, Shoulders',
    bodyPart1: 'Eyes, Front & Back of the Head',
    bodyPart2: 'Hair',
    deity: 'Rudra, Howling God of Thunder and Storms',
    animal: 'Shwana (Dog) Female',
    diseases: `Septic throat, mumps, asthma, eosinophilia, dry cough, diphtheria, ear trouble, pus in the ear`,
    mentalQualities: `Active mind, initiative perception, ingenious and critical, unsuccessful in literary pursuits, or a scholar depending on Rahu in the individual's horoscope, good character reader, resourceful mind. (Slayers, adulterers, chasers, thieves, liars, cruel minded people, men versed in the art pertaining to goblins, catchers, rogues, sowers of discord, charmers, sorcerers, and husk-grain. Will be perfidious, haughty, ungrateful, mischievous and sinful)`,
    professions: `Salesman, bookseller, shopkeeper, Post & Telegraphs Department, Communication, transport, atomic energy, aerial, radio, advertising, publicity, writer, author, research department, explorer, dealing in drugs, beverages, canned goods, handwriting expert, finger print expert, public appointments, finance broker. Any how this zone of 13°-20' is barren. (Planets signifying birth of children, in this zone get weakened and mostly become barren). This area is good for statistics, physics, dynamics, gravitation`,
    sunTransitPeriod: '22th Jun - 5th Jul',
    kpPathogenicEffects: `Septic throat, Mumps, Asthma, Eosnophillia, Dry cough, Diptheria, Ear Trouble, Pus in the ear`,
    asc: `Intelligent, brilliant mind, Writing and communication skills, Crafty speech, fickle nature, Athletic, good dexterity (निपुणता), Ungrateful, deceitful (कपटपूर्ण)`,
    ascPeople: `Albert Einstein, Robert Hand, Swami Kriyananda, Hilary Clinton, Ross Perot, Merv Griffin, Babe Ruth, Marie Antoinette`,
    moon: `Thirst for knowledge, curious mind Difficult marital karma, delays in marriage, Great communicator, orator, First pada produces good effects, truthful, Ungrateful, mischievous, anti-social`,
    moonPeople: `Elizabeth Kubler-Ross, Babe Ruth, FD Roosevelt, Humphrey Bogart, Richard Speck`,
    sun: `Social skills, politically minded, Mental instability, fickle, critical, Dishonest, cunning, Fierce activity, restless mind`,
    sunPeople: `Princess Diana, Ross Perot, Clarence Thomas, Stan Grof, David Duke`,
    carrerInterests: `Writers, teachers, Hospice work, social services, Sales, public relations, Politics, humanitarian projects, Athletes, Butchers, Drug dealers`,
    healthIssues: `Nervous system disorders, Skin sensitivity, allergies, Mental disorders, Lung problems`,
    qualities: `Tikshna (sharp or dreadful) Powerful and bold and brash activities: creating separation from friends, filing for a divorce, invoking spirits and other incantations, black magic, casting spells, exorcism, punishment, murder`,
    activitiesOrEvents: `Surgical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 7,
    navatara: 'Vadh',
    nakshtraName: 'Punarvasu',
    lord: 'Jupiter',
    imageFileName: '07.Punarvasu.jpg',
    catchPhrase: 'Repeating Patterns',
    keyWords:
      'Repetition, pattern, cause within effect; good offspring; unity, wholeness',
    shortDiscription:
      'Punarvasu is about things happening again; Things happening within other things, within themselves; in cycles; in repetition. It is a star of good offspring and non-linear cognition. It empowers humanity to work cooperatively, become unified, seek holistic and all-embracing understandings, and to embrace a principle of all-inclusive divine love.',
    color: '#565B5F',
    colorName: 'Lead',
    kpBodyParts: `(Gemini part) Ear, Throat, Shoulders Blades
    (Cancer part) Lungs, Respiratory system, chest, stomach, oesophagus, diaphragm, pancreas, niples, lacteals, upper lobes of liver, thoracic`,
    bodyPart1: 'Fingers & Nose',
    bodyPart2: 'Fingers',
    deity: 'Aditi, Mother of the 12 Adityas (Solar Principles)',
    animal: 'Marjara (Cat) Female',
    diseases: `(Gemini part)Pleurisy, bronchitis, pneumonia, reins disorder, pericardium inflamed, swelling and pain in the ear, goitre due to iodine deficiency, infiltration in the upper lobe the lungs, pulmonary apoplexy
    (Cancer part) Dropsy, beriberi, stomach upset, irregular appetite, corrupt blood, tuberculosis, pneumonia, bronchitis, liver complaint, jaundice, gourmandize, dyspepsia`,
    mentalQualities: `(Gemini part) Broad outlook, sharp intellect, good memory, worldly wisdom, correct intuition, clear in sight, proper judgment, practical adaptability, mental balance, desire to learn more and more. (Pure, truthful, highborn, charitably disposed, handsome, intelligent, renowned and rich men, servants, artisans, merchants and valuable grain. Will be self-controlled, happy, amiable, dull, afflicted with disease, thirsty and easily contented)
    (Cancer part) Fertile imagination, honest, true, sincere, reliable, forgiving temperament, appreciates beauty, correct judgement and effective argument, given good vitality, resourcefull and fertility, just and benevolent, compassionate, sympathetic, born rich, prince, politician`,
    professions: `(Gemini part) Success in journalism, editing, publishing correcting, inspecting, story-writing, advertising, publicity, religion, law, literature, poetry, insurance agency, finance broker, accountants, auditors, civil judge, civil engineer, mayor, counsellor, school master, preceptor, interpreter, secretary, registrar, messenger, Tamil Pandit, astrologer, mathematician, clerk, postman, clothes, woolen merchant, provision, dealer, planets which signify pregnancy in one's chart gains beneficial strength when they are in this zone
    (Cancer part) Banker, physician, religious head, temples, economics, statistics, advocate, judge, professor, principal, merchant, sailor, traveller, manager of public conveyance, dealers in fluids, salesman, nurse, midwife, water supplier, female officials`,
    sunTransitPeriod: '6th Jul - 19th Jul',
    kpPathogenicEffects: `(Gemini part) Pleurisy branchitis, Pneumonia, reins disorder, Pericordium Inflammated, swellings and pains in the ear, goiter due to iodine deficiency (Cancer part) Dropsy, Beri-Berl, Stomach upset, Irregular appetite, T.B., Branchitis, Liver complaint, Jaundice, corrupt blood, gourmandize(eat good food, especially to excess), dyspepsia`,
    asc: `Charitable, thoughtful, intelligent, Poetic, passionate thinker, writer, Fun loving, freedom loving, moves a lot, Several mates, needs a spiritual mate, Acting skill, success in business`,
    ascPeople: `Ram Dass, Madame Blavatsky, Jerry Lewis, Arnold Schwarzenegger, Robert DeNiro, Bill Gates`,
    moon: `Happy, friendly, easily contented (समाधानी), Good speech, self-controlled, Religiously inclined, leads a simple life, Trouble in marriage, better for women, Writing skill, self-publishing, Fickle (चंचल) nature, indecisive`,
    moonPeople: `Bob Hope, Jerry Brown, Ramana Maharshi, Paul Simon, Ira Progoff, Ross Perot`,
    sun: `Great intellectual prowess, Gifts in the communications fields, Emotionally overwhelmed, Good social status`,
    sunPeople: `John Chancellor, Tom Hanks, Harrison Ford, OJ Simpson, Nelson Rockefeller`,
    carrerInterests: `Acting, drama, entertainment, Politics, Writers, publishers, Spiritual teachers, mystics`,
    healthIssues: `Sensitive nervous system, Pain, swelling in the ears, Weak liver, jaundice, Lung problem`,
    qualities: `Chara (movable or ephemeral): buying automobiles and other vehicles, going on a procession (जुलूस), landscaping (gardening), change of residence, change of career, major life changes`,
    activitiesOrEvents: `Buying a Home, Installing a Deity or Building a Temple, Learning Astrology or Astronomy, Medical Treatment, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 8,
    navatara: 'Mitra',
    nakshtraName: 'Pushya',
    lord: 'Saturn',
    imageFileName: '08.Pushya.jpg',
    catchPhrase: 'The Highest Blossom',
    keyWords:
      'Devotion, growth, flourishing, prosperity, morality, prayer, meditation',
    shortDiscription:
      'Puṣya enables us to commune with deeper principles. This strengthens morality and causes prosperity and victory. It encourages faith, and ultimately leads to selfless devotional intimacy with the divine.',
    color: '#600F12',
    colorName: 'Blackish Red',
    kpBodyParts: 'Lungs, Stomach, Ribs',
    bodyPart1: 'Mouth, Face & Facial Expressions',
    bodyPart2: 'Mouth',
    deity: 'Brihaspati, The Lord of Sacred Speech and Prayers',
    animal: 'Mesha (Goat/ Ram) yoni (reproductive organ) Male',
    diseases: `Tuberculosis , ulcerations in the respiratory system, gastric ulcer, gallstones, nausea, belching, obstruction, bruises in the breast, scurvy, cancer, phtisis, jaundice, cough, hiccough, eczema, pyorrhoea (periodontis), dyspepsia`,
    mentalQualities: `Economical, prudent, frugal, conservative, sober, contemplative, careful, attentive, self-reliant, has a systematic and methodical action, patiently works with perseverance, attains, position of trust (Minister, King, fisherman and the like), persons engaged in big and small sacrifices, honest folk, (forests, wheat, sugarcane, rice and barley). Will be composed in mind, liked by all, learned, wealthy and inclined to be virtuous.`,
    professions: `Deals in mine products, kerosene, petrol, petroleum products, coal, lands, produce, wells, excavations, canals, trenches, deals in agricultural lands, holds position of trust, plumber, sexton, jailer, grave-digger, engineer digging wells, tanks, etc. and constructing bridges, darns, tunnels, those who work at, night and underground, dealers in oils, working in submarine`,
    sunTransitPeriod: '20th Jul - 2nd Aug',
    kpPathogenicEffects: `T.B., Gastric Ulcers, Gall stones, belching, Bruises in the breast, scurvy, cancer, phthisis, Jaundice, cough, Eczema, Pyorrhoea, dyspepsia`,
    asc: `Humanitarian, thoughtful nature, Stubborn, bold, eloquent in speech, Religious, intuitive knowledge, Independent, good status, Musical gifts, creative nature`,
    ascPeople: `A1 Gore, Prince Charles, Jack Nicholson, Jeremy Irons, Jeanne Dixon, Madam Curie, Al Pacino, John Travolta`,
    moon: `Learned, composed mind, Virtuous, liked by all people, Wealthy, political interests, Life improves after 33, Problems with family of origin, Strong issues with Mother`,
    moonPeople: `Mikhail Gorbachev Clint Eastwood, Tom Hanks, Dan Quayle, Jimmy Swaggart, Mary Tyler Moore, Jimi Hendrix`,
    sun: `Good speech, authority figure Wealthy, successful, thrifty (उत्साही), Sensitive, artistic nature, Creativity in the arts, Need for security, Arrogant, dictatorial`,
    sunPeople: `Peter Jennings, Arnold Schwarzenegger, Maxfield Parrish, Mick Jagger, Janet Reno, Monica Lewinsky, Benito Mussolini`,
    carrerInterests: `Politics, government positions, Geologists, aquatic biologist, Military, police, Musicians, artists`,
    healthIssues: `Skin problems, eczema (खुजली), Stomach, ulcers, nausea, Breast cancer, Tuberculosis, chest problems`,
    qualities: `Laghu or Kshipra (light and swift): to start: a journey (travel), sports activities, doing healing work, administer­ing medicines, opening a business, sales, trade and obtaining or repaying a loan or debt`,
    activitiesOrEvents: `Installing a Deity or Building a Temple, Learning Astrology or Astronomy, Learning Music or Dance, Planting and Sowing, Medical Treatment, Studying the Vedas or the Shastras, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 9,
    navatara: 'Atimitra',
    nakshtraName: 'Ashlesha',
    lord: 'Mercury',
    imageFileName: '09.Ashlesha.jpg',
    catchPhrase: 'Serpentine Embrace',
    keyWords:
      'Luxury, pleasures, embraces, clinging, loyalty, jealousy, cunning',
    shortDiscription:
      'Ashlesha empowers us embrace strongly, with deep and enduring emotion and desire. It inspires us to bear great responsibilities for the sake of those we love. It endows us with charisma, charms and mystique. It has a weakness towards deception and jealousy, but strength towards deep mysticism and true spirituality.',
    color: '#600F12',
    colorName: 'Blackish Red',
    kpBodyParts:
      'Lungs, Stomach, intestine, food pipe, gall bladder, oesophagus, Diaphram, Pancrease, Liver',
    bodyPart1: 'Joints, Nails & Ears',
    bodyPart2: 'Nails',
    deity:
      'Sarpas and Nagas, The Embracers and the Coiled Ones, Defied Serpents',
    animal: 'Marjara (Cat) Male',
    diseases: `Vitamin 'B' deficiency, cold stomach, windiness, windpressing the diaphragm making it difficult to breathe, distillation of the rheum, pain in knees and legs. Drunkenness, jaundice, nervous, indigestion`,
    mentalQualities: `Quick wit. ingenious, reasonable, versatile, fluent and copious in speech, good writer, acquires other languages easily, can imitate others, mimicry, has the desire to learn art, music and literature, fond of travels, (will not be sincere, inclined to eat everything, sinful, ungrateful and deceitful`,
    professions: `Sale man, agent, representative, business, Commerce, international trade, clerk correspondent, author, writer, manufacturer, water supply engineer, textile engineer, dealer in yarn, paper, pen, ink, traveling agent, guide, hostess, astrologer, mathematician, water diviner, midwife, nurse. (Pulps, roots, fruits, reptile, poison, worms, counterfeits, robbers, huskgrain and all classes of physicians)`,
    sunTransitPeriod: '3rd Aug - 16th Aug',
    kpPathogenicEffects: `Vitamin B deficiency, cold, stomach, wfndlness breathing troubles, Pins In knees and legs. Drunken ness, Phlegm, nephritis, Hysteria, hybochondria, dropsy, jaundice, nervous indigestion`,
    asc: `Sensual, seductive nature, Cruel, ungrateful, need to develop tact, Few children, needs alone time, Capable of much exertion, service oriented, Issues with deception, intrigue`,
    ascPeople: `Marilyn Monroe, Sting, Aleister Crowley, Sri Aurobindo, Buddha, Muhammad Ali, Lyndon Johnson James Earl Ray`,
    moon: `Leadership, political success, Mystical nature, entertainer, Astrological gifts, intuitive, Writing and speaking gifts, Poetic nature, learned, Research and organizational skills, Need to watch diet, Trouble in early marriage, Insincere, ungrateful, sinful`,
    moonPeople: `Mahatma Gandhi, Paul McCartney, Bepin Behari, Jim Kelleher, Swami Kriyananda, Robert Bly, Dennis Harness, Marc Edmund Jones, Barbra Streisand, Larry Flynt, Oscar Wilde`,
    sun: `Business oriented, greedy, Stubborn nature, opinionated, Mystic, intuitive, Good communication skills, Erratic career, unpredictable, Deceptive, evasive (कपटपूर्ण)`,
    sunPeople: `Whitney Houston, Queen Mother Elizabeth, B.V. Raman, H.P. Blavatsky, Dustin Hoffman, Madonna, Lucille Ball, Jerry Falwell, Mata Hari`,
    carrerInterests: `Politicians, lawyers, Writers, teachers, Astrologers, mystics, Snake charmers, zoo keepers, Prostitutes, pimps`,
    healthIssues: `Food poisoning, Obesity, poor diet, Venereal disease, Arthritis, Nervous disorders`,
    qualities: `Tikshna (sharp or dreadful) Powerful and bold and brash activities: creating separation from friends, filing for a divorce, invoking spirits and other incantations, black magic, casting spells, exorcism, punishment, murder`,
    activitiesOrEvents: `Buying a Home, Surgical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 10,
    navatara: 'Janma',
    nakshtraName: 'Magha',
    lord: 'Ketu',
    imageFileName: '10.Magha.jpg',
    catchPhrase: 'Inherited Power',
    keyWords:
      'Power, gifts, wealth, DNA, inherited traits, ancestors, past lives, afterlife, justice',
    shortDiscription:
      'Magha bestows gifts “from our ancestors.” This literally indicates inheritance of power, career, fortune, and qualities: ``, (“DNA”). Such inheritance really comes as a result of efforts made in our previous births, so Magha also indicates powers, qualities: ``, and abilities carried over from past lives.',
    color: '#EAE3BC',
    colorName: 'Ivory / Cream',
    kpBodyParts:
      'Heart, Back, Spinal Chord, Spleen, Dorsal region of Spine, Aorta',
    bodyPart1: 'Nose, Lips & Chin',
    bodyPart2: 'Nose',
    deity: 'Pitris, The Collective Group of Ancestors',
    animal: 'Mushaka (Rat) Male',
    diseases: `Heart affected by sudden shock, grief or poison, pains in the back, cholera, humorous, gravel in kidneys, palpitation, regurgitation, faintings, signal meningitis`,
    mentalQualities: `Outspoken , assertive, combative, defensive, forceful, audacious, sensual, impulsive, proud short-tempered, hasty, fiery temper, enthusiastic and energetic, eminently fitted to occupy a position of trust both in public and private concern, good sportsman, contact with people in very high position, good appointment, undertake lofty plan, (Will be wealthy and have many servants, will enjoy life, worship the Gods and the Manes and be very industrious), (People rich in money and corn, men who are devoted to their elders and manes, granaries mountaineers, heroes, women haters, carnivorous beings)`,
    professions: `Contracts big factories, chemical drug manufacturer, criminal lawyers, defence department, surgeons, medical department, Government service or security in service, manufacturer of imitation jewellery, cheap ornaments, electroplating, manufacturers of nickel and chromium plated surgical instruments, weapons (depending on the planet in this position or the sub in which it is or where the meridian falls select that department in Government service. Just like Sun's rays includes "VIBGYOR', so also government service includes from menial to minister, peon to president`,
    sunTransitPeriod: '17th Aug - 29th Aug',
    kpPathogenicEffects: `Heart affected by sudden shock, grief or poison, pains in the back, cholera, humours gravel In kidneys, palpitation, faintings and spinal menangitis`,
    asc: `Devoted to God and forefathers Wealthy, has servants, Receives praise, respected, Susceptible to allurements of sex, Deep-rooted dislike for certain people`,
    ascPeople: `Paramahansa Yogananda, Elton John, Woody Allen, Alan Leo, Dennis Harness, Ted Bundy, O.J. Simpson`,
    moon: `Honored and recognized by learned people, Worship of gods and ancestors, Involved in some important mystical work, Enterprising, enjoys life, charitable, Physically strong, hot tempered`,
    moonPeople: `Maharishi Mahesh Yogi, Winston Churchill, Paramahansa Yogananda, Dennis Flaherty, Noel Tyl, Walt Whitman, Jodie Foster, Joseph Stalin`,
    sun: `Leadership, authority positions, Entertainer, musical, seeks attention, Kingly, proud nature, Adventurous spirit, love of travel, Connected to a spiritual lineage`,
    sunPeople: `Bill Clinton, Lyndon Johnson, Michael Jackson, Gene Roddenberry, Louis XIV, the Sun King, Deng Xiao Ping, Alexander the Great, Sri Aurobindo, Mother Theresa`,
    carrerInterests: `Politicians, lawyers Heads of corporations, Actors, musicians, Archaeologists, historians, Hypertension, Self-employed, managers`,
    healthIssues: `Heart problems, Stomach, ulcers, Skin problems around mouth`,
    qualities: `Krura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement`,
    activitiesOrEvents: `Buying a Home, Planting and Sowing`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 11,
    navatara: 'Sampat',
    nakshtraName: 'PurvaPhalguni',
    lord: 'Venus',
    imageFileName: '11.PurvaPhalguni.jpg',
    catchPhrase: 'Romantic Enjoyment',
    keyWords: 'Romance, love, enjoyment, expertise, romantic divinity',
    shortDiscription:
      'PurvaPhalguni grants expertise and beauty, helping us enjoy a fulfilling and satisfying sensual and romantic life. It especially dissolves the distinction between material and spiritual, uniting the two through divine love.',
    color: '#BA7C4E',
    colorName: 'Light Brown',
    kpBodyParts: 'Heart, Spinal Cord',
    bodyPart1: 'Lips, Sex Organs, Left Hand',
    bodyPart2: 'Private Parts',
    deity:
      'Aryaman, The Solar God (aditya) of Hospitality, Nobility and Patronage',
    animal: 'Mushaka (Rat) Female',
    diseases: `Due to disappointment in love, heart affected, Loss of children affecting hearts, all due to shocks and irreparable loss, Curvature of the spine, anaemia, hydremia, pain in legs, swelling of ankle, blood pressure, aneurism, swelling of heart, valves affected`,
    mentalQualities: `Competence in music, art, Kavi-poet, Generous, warmhearted, affectionate, fond of pleasure, luxury and comfort, temptation to speculate, true, honest, cautious, able to fulfill his desires, self-centered, desire to at least attend dramas or dance, taste in drawing, sculpture and paintings, acquires fancy and costly jewels, dress, photography as hobby (artists, actors, young damsels, musicians, amiable persons, merchandise, cotton, salt, honey, oil mandy boys. Will speak sweetly, will be liberal in gifts, bright in appearance, fond of wandering, and loyal to his sovereign)`,
    professions: `Government service, Transport, Radio, Music, renovation of ancient paintings, preservation of monuments, museums, sports, mobile, automobile, disinfectant for crops, cinema theatre, studio, film section, photography, animal husbandry, slaughter house, lethal chamber, veterinary surgeon, leather, skin, hides, hotels, canteens, revenue department, house- building, maternity surgeon, venereal expert, educationalist, Vice- Chancellor, service in women's colleges or jails, optician, glass dealers, cigarette manufacturers`,
    sunTransitPeriod: '30th Aug - 12 Sep',
    kpPathogenicEffects: `Heart affections, Curvature of the spine anamefa, hydraemic, pains in legs, swelling of ankle, blood pressures, aneurism swelling of heart, valves affected`,
    asc: `Gifts in music, dance, drama, Attractive, sensual nature, charismatic, Creative intelligence, learned, Good health, vital nature, Leadership, government positions`,
    ascPeople: `Madonna, Mozart, John F Kennedy, Jr. Chakrapani Ullal, Jean Houston, Richard Nixon, Henry VIII, George Bush, FDR`,
    moon: `Creative intelligence, love of drama, Leadership skills, self-employed, Love of travel, a wanderer, Sweet speech, generosity, beauty, Mystical nature, teaching skill, Disturbed mind, fond of sex`,
    moonPeople: `Dustin Hoffman, Ted Kennedy, JFK, John Travolta, Robert Redford, Shirley McClaine, Rudolf Steiner, Marquis de Sade, Madonna`,
    sun: `Gifted in the performing arts, vanity, Sense of humor, playfulness, Regal quality, self-confidence, Recognition thru connection with women, A showman, gifts in teaching and sales, Athletic ability, pitta nature`,
    sunPeople: `Richard Gere, Lily Tomlin, Raquel Welch, Liz Greene, Charles Kuralt, Michael Keaton, Werner Erhard, Jimmy Connors, Roger Maris`,
    carrerInterests: `Actors, musicians, models, Business managers, retail sales, Radio, television, photography Cosmetics, jewelry, wedding planners, Government service, politicians, Teachers, professors, Sex and marital therapists`,
    healthIssues: `Skin problems, lips, Addiction issues, Heart trouble, Blood circulation, Venereal disease, Accidents with fire`,
    qualities: `Krura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement`,
    activitiesOrEvents: `Buying a Home, Learning Music or Dance`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 12,
    navatara:
      'VipatKrura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement',
    nakshtraName: 'UttaraPhalguni',
    lord: 'Sun',
    imageFileName: '12.UttaraPhalguni.jpg',
    catchPhrase: 'Vows of Friendship',
    keyWords: 'Friendship, matchmaking, agreements, vows',
    shortDiscription:
      'Uttara Phālgunī grants successful and enjoyable romance and love. It produces matchmakers and expert councilors in the arts of love and romance. It allows for successful resolution of arguments, and finding agreements, friendship and harmony.',
    color: '#3685D5',
    colorName: 'Bright Blue',
    kpBodyParts: `(Leo part) Spinal Cord
    (Virgo part) Intestines, Bowels, liver`,
    bodyPart1: 'Lips, Sex Organs, Right Hand',
    bodyPart2: 'Private Parts',
    deity: 'Bhaga, The Solar God (aditya) of Conjugal Bliss and Inheritance',
    animal: 'Gau (Cow) Male',
    diseases: `(Leo part) Pains in the back and the head, spotted fever, plague, hyperemia, fainting, blood pressure, becomes mad temporarily due to clotting of blood in the capillaries in the brain, palpitation, backache
    (Virgo part) Tumours in the bowels, obstructions, stomach disorder, sore throat and swelling in neck`,
    mentalQualities: `(Leo part) May or may not be violent. It depends on the planet in this area. Very violent if mars is in between 28°-26'-40", and 29°-3'-20" in Leo. Ambitious, authoritative, boastful and domineering, energetic and enthusiastic, gaudy and generous, haughty and hopeful, illustrious, joyful, kingly, loyal, magnanimous, optimistic, proud, royal, stately and zealous (Charitable and learned persons, mild, modest, pure, heretical, highly wealthy men, those intend on their duties and kings, fine corn. Will be loved by all, earn money by his own learning, will be voluptuous and happy)
    (Virgo part) Good reasoning, intelligent, tactful, business minded, studious, industrious, good in mathematics or engineering, astronomy, astrology, book keeping, clever and critical, dexterous and diplomatic, eloquent and educated (hygiene and sanitary engineering), ingenious and intellectual, accounts, business, acumen, advertising`,
    professions: `(Leo part) Any service under government. Medical, defence, shipping, navy, industry, commerce, stock exchange-share business, maternity home, heart specialist
    (Virgo part) Amalgamating a few companies, astronomer, astrologer, communication department, education department, handwriting expert, governor, tourist department, engineering, press, lecturer, correspondent, publication, public relation officer, megaphone, loudspeaker, manufacturer, telephone, mine engineering, State Trading Corporation, contractors, agents, industry, chemical engineering, physician, public health department, hospitals, Registrar of Newspaper, Registrar of birth and death, ambassadors, postal department`,
    sunTransitPeriod: '13th Sep - 26th Sep',
    kpPathogenicEffects: `(Leo part) Pains in the back and in the head. Spotted fever, Plague, faintings, Blood pressure, palpitations (Virgo part) Tumours in the bowels, obstructions, stomach disorder, sore throat and swelling In neck`,
    asc: `Attractive, wealthy, several mates, Generous, proud, Sensual nature, beautiful, Intelligent, talent in business, Skilled with hands, humanitarian, Mystical powers, intuitive`,
    ascPeople: `Warren Beatty, Hugh Hefner, Shirley McClaine, Brooke Shields, Harrison Ford, Ira Progoff, Hank Aaron, Tiger Woods`,
    moon: `Well-liked, successful, stable Respected, much comfort and luxury, Good intelligence, inventive mind, Happy, friendly nature, Good for marriage, best period 38-62, Tactful, independent, clean-hearted`,
    moonPeople: `Sean Connery, Jack Nicholson, Alexander Graham Bell, Jonathan Winters, Mel Gibson, Agatha Christie`,
    sun: `Humanitarian concern, leadership, Fond of reading and writing, Confident, strong pride, arrogance, Successful retirement and end of life, Love of the creative arts`,
    sunPeople: `Oliver Stone, Ken Kesey Michael Douglas, Jeremy Irons, H.G. Wells, Ray Charles, Sophia Loren`,
    carrerInterests: `Social worker, philanthropists, Writers, actors, media personalities, Healing arts, state health employees, Astrologers, astronomers, mathematicians, Skin sensitivities Business minded people, sales`,
    healthIssues: `Lips and mouth, Sexual organs, Hands and arms, Skin sensitivities, Digestive trouble`,
    qualities: `Sthira (fixed or permanent): emphasis toward permanence and stability and structure: building homes, laying the foundations of communities, plowing the land, planting trees, purchasing agricultural property`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Laying Foundation of Home, Planting and Sowing`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 13,
    navatara: 'Kshema',
    nakshtraName: 'Hasta',
    lord: 'Moon',
    imageFileName: '13.Hasta.jpg',
    catchPhrase: 'Dexterity',
    keyWords: 'Dexterity, manipulation, hands; awareness, awakening, sunrise',
    shortDiscription:
      'Hasta grants heightened awareness and dexterity, empowering us to manipulate tools and be physically agile. It makes us more observant and difficult to deceive and more capable of deceiving others.',
    color: '#3C600D',
    colorName: 'Deep Green',
    kpBodyParts:
      'Bowels, Enzymes, Secreting glands, bleeding glands, intestine',
    bodyPart1: 'Hands & Fingers',
    bodyPart2: 'The Two Hands',
    deity:
      'Savitur, The Solar God (aditya) that inspires (inspires us through Gayatri Mantra)',
    animal: 'Mahisha (Buffalo) Female',
    diseases: `Vitamin B deficiency, gas formation, flatulence, loose bowels, pain and disorder of the bowels, obstructions, arms and shoulders weak, short breath, worms mucus, cholera, diarrhoea, typhoid, amoebic, and bacillary dysentery, neuralgia (imagination), fear complex, hysteria`,
    mentalQualities: `Man versed in the Vedas, merchandises, traders and energetic men, Will be industrious, impudent, fond of drinking, merciless and thievish, charioteers, elephant drivers, robbers, elephants, artisans, husked-grain`,
    professions: `Salesman, commerce, overseas, communication, mail order business, shipping, clearing agent, textile, yarn, engineer, bridges, damsites, canals, tunnels, ink, press, ink manufacturer, advocate, sanitary engineer, export, import, artist, painter, politician, ambassador, embassy, messenger`,
    sunTransitPeriod: '27th Sep - 9th Oct',
    kpPathogenicEffects: `Vitamin B deficiency, Gas formation, loose bowels, Pain and disorders in bowels, Obstructions, short breath, worms, mucus, cholera, diarrohea, typhoid, amoebic, dysentry, fear complex, hysteria and mental diseases`,
    asc: `Likeable, sociable, entertaining Attractive, sensitive, artistic, Respected, Eloquent in speech, Fond of travel, Thievish mind, Humanitarian, serves the public`,
    ascPeople: `Bill Clinton, John Lennon, Cat Stevens, Denzel Washington, Richard Chamberlain, Andy Griffith, Sonny Bono, Jacques Cousteau`,
    moon: `Creative, industrious in work, practical Sweet smile, attractive, wealth late in life Make quick friendships, remain unattached, Inclined toward intoxication, cunning, Many ups and downs in life, Moody nature`,
    moonPeople: `Madame Blavatsky, Rudolph Valentino, Jimmy Carter, Burt Reynolds, Bob Newhart, Richard Burton, Nicole Simpson, Robert Duvall`,
    sun: `Love of knowledge and writing, Skilled with hands, attention to detail, Excellent memory and analytical ability, Interest in astrology and the occult, Gifts in music, dance, drama`,
    sunPeople: `Anne Rice, Chevy Chase, John Lennon, Sting, Jimmy Carter, Marc Edmund Jones, Julie Andrews, Susan Sarandon`,
    carrerInterests: `Painters, artists, craftsmen, Scholars,teachers, writers, Sales, communications, public relations, Hospital and volunteer work, healers, Travel industry, conference planners, Astrologers, palm readers`,
    healthIssues: `Sensitive nervous system, Bowels, colon problems, Dysentery, Colds and allergies, Skin irritations, Hands`,
    qualities: `Laghu or Kshipra (light and swift): to start: a journey (travel), sports activities, doing healing work, administer­ing medicines, opening a business, sales, trade and obtaining or repaying a loan or debt`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Laying Foundation of Home, Learning Astrology or Astronomy, Learning Music or Dance, Planting and Sowing, Medical Treatment, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 14,
    navatara: 'Pratyari',
    nakshtraName: 'Chitra',
    lord: 'Mars',
    imageFileName: '14.Chitra.jpg',
    catchPhrase: 'Multifaceted Brilliance',
    keyWords: 'Intellect, ingenuity, creativity; technology, detail',
    shortDiscription:
      'Chitra empowers humanity to comprehend multi-faceted designs, lay complex blueprints, and generate masterpieces and technological marvels. It is the star of architects, designers and engineers; and allows us to comprehend and give shape to many angles of a subject.',
    color: '#0E0E0E',
    colorName: 'Black',
    kpBodyParts: `(Virgo part) Belly, foetus, womb, Lower part
    (Libra part) Kidney, loins, hernia, Appendicitis, lumbar region of the spine, Vasomotor system`,
    bodyPart1: 'Forehead & Neck',
    bodyPart2: 'Forehead',
    deity:
      'Tvastar/Visvakarma, The Solar God (aditya) of Form, Creativity and Craftsmanship',
    animal: 'Vyaghra (Tiger) Female',
    diseases: `(Virgo part) Ulcers, sharp and acute pains, choleric humours, worms, irritation and itching, legs paining, dry-gripping pain, wounds from insects, reptiles and animals
    (Libra part) Polyuria, inflammation of ureter connecting kidney and bladder, Bright's diseases, lumbago, kidney trouble, excess of urine, hemorrhage of the kidneys, renal stones, sunstroke, brain fever, pains in head`,
    mentalQualities: `(Virgo part) Humorous, dexterous, practical, business like, enthusiastic, bold, courageous, energetic, enterprising, active or quick tempered, sarcastic, argumentative, impatient, resentful, overworking and irritable, (will wear beautiful) garments and flowers and will have beautiful eyes and limbs
    (Libra part) Ambitious, aspiring, adventurous, amorous, faculties of perception and observation, clear vision, refined tastes, idealistic temperament, intuitive mind, loves, science and refined occupations`,
    professions: `(Virgo part) Mechanical Engineer, Press, Publication, Building contractor or broker, Supplier to jail, criminal lawyer, tax department, Sales Tax, Income-tax, revenue, finance department, factory, industry, electricity, mine, tunnel, trenches, finger print expert. Police of Law and order and traffic, defence department, military accounts, cost accountant. Persons skilled in the art of ornamenting, painting, jewellery, singing, writing, perfumery, as well as mathematicians, weavers, physicians)
    (Libra part) Lawyer, Surgeon, Philosopher, scientist, religious, endowment board, business, military, defence department, industry, partnership in business, building contractor, litho press, artistic advertisement, decorators, scents, perfumeries, powders, marriage broker, registrar of marriages, sports materials, music, telescope, microscope, radio, television, tape-record dealers, lady doctors, surgeons, tailors, cigarette and snuff merchants, petroleum products, automobile spare parts, dress, jewels`,
    sunTransitPeriod: '10th Oct - 23rd Oct',
    kpPathogenicEffects: `(Virgo part) Ulcers, Sharpand acute pains, worms, Irritation and itching, Legs pain, dry gripping pain, wounds frominsects (Libra part) Polyuria, Inflammation of urether connecting kidneys and bladder, brights diseases, lumbago, kidney troubles, excess of urine, haemorrhage of the kidneys, venal stones, sunstroke, brain fever, pains in head`,
    asc: `Strong ego, engaged in a variety of pursuits, Wealthy, lives away from birthplace, Wears colorful clothes, jewelry. Expressive nature, graceful, Desire for spiritual liberation, honest`,
    ascPeople: `Adolf Hitler, JFK, Frank Sinatra, Nelson Rockefeller, Ellen Burstyn,Cary Grant Christian Dior, Ramana Maharshi`,
    moon: `Beautiful body, attractive eyes, artistic, Strong sexual appetite, great lovers, Recognized even in a large crowd, Trouble with father, benefits thru mother, Difficulties in marriage for women, Good for astrologers, intuitive, prophetic`,
    moonPeople: `Richard Chamberlain, Wilt Chamberlain, George Bush, Nicholas Cage, Dwight Eisenhower, Marie Antoinette, K.N. Rao, Jim Lewis, Sidney Poitier`,
    sun: `Warrior nature, militant Cunning, harsh at times, Interest in art, photography, music, Strong need for recognition`,
    sunPeople: `Evander Holyfield, Margaret Thatcher, Barbara Walters, Tim Robbins, Deepak Chopra, Eleanor Roosevelt`,
    carrerInterests: `Interior design, architects, jewelers, Lawyers, judges, priests, Religious people, learned in the Vedas Publications, radio, TV, film, Armed forces, police detective`,
    healthIssues: `Ulcers, stomach, Kidney problems, Sunstroke, forehead, Wounds from insects, Skin allergies`,
    qualities: `Mridu (soft, mild or tender): learning music-dance-drama, performing auspicious ceremonies like marriage, buying new clothes, wearing new clothes, Love-making, romance, making new friends, enjoyment of pleasures that are healing, revitalizing`,
    activitiesOrEvents: `Laying Foundation of Home, Planting and Sowing, Medical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 15,
    navatara: 'Sadhak',
    nakshtraName: 'Swati',
    lord: 'Rahu',
    imageFileName: '15.Swati.jpg',
    catchPhrase: 'Individuality',
    keyWords: 'Individuality, distinction, maverick, separatism',
    shortDiscription:
      'Swati empowers us to be individuals capable of standing on our own and going against the norm if need be. It helps us clearly and confidently define "I" and individualize ourselves. It can inspire equally to self-realization or self-absorption.',
    color: '#0E0E0E',
    colorName: 'Black',
    kpBodyParts:
      'Skin, kidneys, ureter, appendicitis, hernia, bladder, urinary tract',
    bodyPart1: 'Intestines & Chest',
    bodyPart2: 'Teeth',
    deity:
      'Vayu, King of the Celestial Musicians and Lord of the Wind and Life-Breath',
    animal: 'Mahisha (Buffalo) Male',
    diseases: `Polyuria, urinary trouble, ureters ulcerated, pus formation, Bright's disease, eczema, skin trouble, leprosy`,
    mentalQualities: `Compassionate, honest, courteous, just, clear, intellect, initiative, perceptive, ability, powers of comparison, upright, sympathetic, flexible, sensitive, slow to lose temper, anger soon pacified, sweet nature, humane, fond of company, partnership, slow to grasp but remembers well. (Traders, feeble characters, ascetics and connoisseurs of wars, fickle-minded friends). (Will be modest, clever in trade, will be compassionate, sweet in speech and virtuous)`,
    professions: `All imitations, mobile, automobile, transport, tourist, music, opera, drama, art, paint, decoration, exhibition, tube light, fan, fridge, hot water equipments, hot air blower, X-ray instrument manufacturer, scientists, service in clinics, judge, poet, toilet set manufacturer, confectionery, bakery, women's apparel, fancy goods, perfumeries, readymade dress, maid servant, plastic, mica, ground glass scents, pasteurized milk, leather goods, Nadaswaram, bugles, cooks, butters, silk-mercers, embroiderers, gloves, bonnets`,
    sunTransitPeriod: '24th Oct - 5th Nov',
    kpPathogenicEffects: `Polyuria, Urinary troubles, ureters ulcerated, Pus formation, brights disease, eczema, skin trouble, Leprosy`,
    asc: `Kind, happy, humorous, Dresses simply, religious, Interest in psychology, medicine, Political nature, Lives away from birthplace`,
    ascPeople: `Johnny Carson, Charlie Chaplin, Groucho Marx, Mahatma Ghandi Sigmund Freud, Alfred Adler Gloria Steinem Oliver Stone, William Levacy`,
    moon: `Righteous, compassionate, truthful, Fame, clever speech, generous nature, Adamant, hot-tempered, independent, Trouble with marriage, traveller, Wealth, greater advancement after 30, Humanitarian concern, charitable`,
    moonPeople: `Sri Meher Baba, Charlie Chaplin, Harry Truman, George Harrison, James Dean, Hank Aaron, Nelson Rockefeller, Edith Hathaway, Whoopi Goldberg`,
    sun: `Self-employed, business skill, logical, Good social status, authority figure, Troubles with government, fall from grace, Always looking for future success, Difficulties with father`,
    sunPeople: `Bill Gates, Johnny Carson, Hilary Clinton, Larry Flynt, Robert Duval, Kevin Kline, Roseanne, Richard Dreyfus`,
    carrerInterests: `Business skill, sales, Yoga teachers, priests, Legal profession, judges Transportation, travel industry Stock brokers, Traders in drugs and alcohol`,
    healthIssues: `Hernia, Eczema, skin problems, Urinary, bladder, Kidneys, Flatulence, Intestinal problems`,
    qualities: `Chara (movable or ephemeral): buying automobiles and other vehicles, going on a procession (जुलूस), landscaping (gardening), change of residence, change of career, major life changes`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Learning Astrology or Astronomy, Planting and Sowing, Medical Treatment, Studying the Vedas or the Shastras`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 16,
    navatara: 'Vadh',
    nakshtraName: 'Vishakha',
    lord: 'Jupiter',
    imageFileName: '16.Vishakha.jpg',
    catchPhrase: 'Obsession',
    keyWords: 'Decisive, dedicated, fixated, goal-oriented, undistracted',
    shortDiscription:
      'Vishakha grants the ability to stay on course and to focus sharply on attaining goals. It creates strong dedication to clear objectives and fosters the ability to achieve those goals by any means necessary.',
    color: '#A77F2E',
    colorName: 'Golden',
    kpBodyParts: `(Libra part) Lower abdomen, part near bladder, and pancreatic gland, Lower part of the belly, urinary bladder, kidneys
    (Scorpio part) Bladder, urethra, genital organs, rectum, descending colon, prostate gland`,
    bodyPart1: 'Arms & Breast',
    bodyPart2: 'Both Upper Limbs',
    deity: 'Indra-Agni, The Divine pair of the Leadership and Purification',
    animal: 'Vyaghra (Tiger) Male',
    diseases: `(Libra part) Deficiency of Adrenal secretion, renal abscess, skin eruption, diabetes, kidney sluggish, insulin deficient, congestion of brain, coma, vertigo
    (Scorpio part) Diseases of the womb, fibroid tumour, prostate gland enlargement, urinary trouble, frequent micturition, abnormal bleeding during menses, rupture, renal stones and gravel, uterine tumour, urethral abscess, dropsy, hydraemia, urates in excess, strangury, apoplexy, nosebleed`,
    mentalQualities: `(Libra part) Pleasant manners, attractive appearance, polite, faith in God, conventional, universal love, brotherhood, generous, magnanimous, true, just, equanimous, broad- minded, talented, cultured. (Will be envious, covetous, of bright appearance, clever in talking and quarrelsome)
    (Scorpio part) (Fr. Montesque has said "It is by adventure that great actions are performed and not by the sovereigns of great empires") Somewhat conservative, dignified, enthusiastic, influential, noble, sincere, honest, straightforward, free will, independent, candid, convincing, reasoning, extravagant, generous, over liberal, an extremist`,
    professions: `(Libra part) Business with partners, traveling agent, tourist officer, shipping, air travel, dealings with foreigners, pawn - broker, banker's speculator, race goer, works in building construction, fruit gardens, Tax and revenue departments, foreign trade, floats big companies, judge, auditor, professor, principal, physician, manufacturers, coloured papers, cinema publicity, advertisement through slides, actor, costly dress, lace, jewelry, perfumeries, minting, etc. (Green gram, sesamum, black gram, Bengal gram, tree with red blossoms and fruits, cotton and men devoted to Indra or Agni)
    (Scorpio part) Bank Judge, Criminal Lawyer, Insurance Company, chemical and drug manufacturer, land, estate owner, agriculturist, share market operations, more by jobbing than by investing, racing, cards, betting, dice, customs, industrial units, labour, port trust, defence department, defence Minister , village Munsiff, Ayurveda medicine`,
    sunTransitPeriod: '6th Nov - 18th Nov',
    kpPathogenicEffects: `(Libra part) Deficiency of Adrenal secretion. Renal abscess, skin eruption, diabetes, kidney sluggish, insulin deficiency, congestion of brain, coma, vertigo (Scorpio part) Diseases of womb, fibroid tumour, prostate glands enlargements urinary troubles, abnormal bleeding during menses, rupture, renal stones and gravel, uterine tumours urethral abscess, dropsy, hydraemia, nose bleed`,
    asc: `Aggressive, impatient, easily angered, Wise, devoted to forms of worship, Wealthy, politically inclined, Astrological knowledge, mystical`,
    ascPeople: `Napoleon Bonaparte, David Frawley, Clint Eastwood, Margaret Thatcher, Jeffrey Green, Nick Campion`,
    moon: `Intelligence of the highest order, Speaks convincingly, writer, Bright appearance, attracts a crowd, Leadership, lives away from family, Treats all religions as one, truthful, Politically inclined, humanitarian`,
    moonPeople: `Swami Muktananda, Buddha, Robert Hand, Jimmy Carter, Warren Beatty, Alexander the Great, Uri Geller, Mario Cuomo`,
    sun: `Self-centered nature, ambitious, Introverted, secretive nature, Troubles with authority figures, father, Research oriented, scientific, Restless nature, alcohol problems`,
    sunPeople: `Demi Moore, Tonya Harding, Prince Charles, Joni Mitchell, Whoopi Goldberg, Carl Sagan, Michel Gauquelin, Richard Burton, Charles Manson`,
    carrerInterests: `Researchers, scientists, Military leaders, Writers, public speakers, Politicians, lawyers`,
    healthIssues: `Breasts, Arms, Reproductive problems, Stomach`,
    qualities: `Misra (mixed): mundane daily activities`,
    activitiesOrEvents: `Buying a Home`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 17,
    navatara: 'Mitra',
    nakshtraName: 'Anuradha',
    lord: 'Saturn',
    imageFileName: '17.Anuradha.jpg',
    catchPhrase: 'Devotion',
    keyWords: 'Devotion, friendship, love as law, faithfulness',
    shortDiscription:
      'Anuradha grants deep devotion to friends and lovers. It makes our motivations more loving and selfless. It grants the ability to achieve objectives through good “people skills,” likeability and trustworthy dedication.',
    color: '#611B19',
    colorName: 'Reddish Brown',
    kpBodyParts:
      'Bladder, genital organs, rectum, nasal bones, bones near genital organs, Urinary bladder, foetus, anus and hipbone',
    bodyPart1: 'Breasts, Stomach, Womb & Bowels',
    bodyPart2: 'Heart',
    deity: 'Mitra, The Solar God (aditya) of Friendship and Partnership',
    animal: 'Mriga (Deer) Female',
    diseases: `Suppression of menses poor bleeding and severe pain, stricture, sterility, constipation, piles, painful and not bleeding, nasal catarrh, phlegm, sore throat, fracture, hipbone`,
    mentalQualities: `Determined, energetic, dominant, forceful or selfish, violent, harsh, cruel, vindictive, untruthful, dishonest, unscrupulous, enthusiastic and practical. (Men of prowess, those dealing in assemblies, heads of Corporations, travellers, hides, woolen articles, water diviner, kerosene merchants, oiling in foreign countries, will be unable to endure hunger`,
    professions: `Mines engineer, criminal lawyer, medicine, surgeon, instrumental music, industries, mechanical engineer, lead type foundry, actor, homeopathy, brothel, leather trade, skins and hides, woolen articles, water diviner, kerosene merchant, oil engine, oil crusher, amputating surgeon, Dentist, service in jail, judge, watchman, undertaker, plumber, laborious work, arduous nature, coal, mine ores dealers, linseed groundnut, mustard, cotton seed`,
    sunTransitPeriod: '19th Nov - 1st Dec',
    kpPathogenicEffects: `Suppression of menses, poor bleeding and severe structure, sterility, constipation piles, painful and not bleeding, nasal catarrh, phlegm, sore throat, fracture, hip bones`,
    asc: `Attractive, passionate, a wanderer, Psychic, devoted to spiritual pursuits, Secretive nature, moody, Need to watch diet, promiscuous, Fond of family life, groups, organizations`,
    ascPeople: `James Dean, Jean Dixon, Bruce Lee, Nietzsche, Kevin Kline, Marlon Brando, Shirley Temple Black`,
    moon: `Wise, truthful, kind-hearted Charismatic, hard working, brave, Wealthy, handle difficult situations, Problems with maternal relationships, Come what may, they march forward`,
    moonPeople: `Ram Dass, Alex Haley, Chakrapani Ullal, Kevin Costner, Steve Allen, Saddam Hussein, Gerard Depardieu`,
    sun: `Leadership ability, interest in politics, Important group affiliations, successful, Wealthy, respected, responsible, Athletic, physical strength, determined, Gifts in the fine arts, creative`,
    sunPeople: `Indira Gandhi, Sai Baba, Mickey Mouse, Jodie fosters, Joe Dimaggio, Boris Becker, Woody Allen, Calvin Klein, Jimi Hendrix, Tina Turner`,
    carrerInterests: `Business management, organizational skills, Travel industry, conference planners, Dentists, plumbers, Criminal lawyers, Actors, musician, Mining engineers`,
    healthIssues: `Stomach, bowels, Constipation, piles, Irregular menses, womb, Sore throat, colds, Breast problems`,
    qualities: `Mridu (soft, mild or tender): learning music-dance-drama, performing auspicious ceremonies like marriage, buying new clothes, wearing new clothes, Love-making, romance, making new friends, enjoyment of pleasures that are healing, revitalizing`,
    activitiesOrEvents: `Marriage, Learning Music or Dance, Planting and Sowing, Medical Treatment, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 18,
    navatara: 'Atimitra',
    nakshtraName: 'Jyeshtha',
    lord: 'Mercury',
    imageFileName: '18.Jyeshtha.jpg',
    catchPhrase: 'Eminence',
    keyWords: 'Eminent, jealous of rivals, protected by destiny, desirous',
    shortDiscription:
      'Jyeshtha empowers humans to become better than their peers, yet inclines us to be jealous of rivals. It grants us the ability to protect ourselves and ensure our victories, often by supernatural means. It inclines us to enjoy our power and eminence.',
    color: '#F5D8BF',
    colorName: 'Cream',
    kpBodyParts: 'Colon, Anus, Genital Organs, Ovaries, Womb',
    bodyPart1: 'Neck, Right Side of the Trunk',
    bodyPart2: 'Tongue',
    deity: 'Indra, The Solar God (aditya) of Protection and Dominance',
    animal: 'Mriga (Deer) Male',
    diseases: ``,
    mentalQualities: ``,
    professions: ``,
    sunTransitPeriod: '2nd Dec - 15th Dec',
    kpPathogenicEffects: `Leucorrhoea, bleeding piles, fistula, tumours, distemper in secret parts, affliction of bowels, pains in arms and shoulders`,
    asc: `Honored, intent on their dharma, Writing skill, smooth and respected, Loose morals and much passion, Many friends, well liked, Love of children, charitable`,
    ascPeople: `Bob Dylan, Stan Grof, Herman Hesse, Elvis Presley, Jodie Foster, Princess Diana`,
    moon: `Virtuous, but irritable at times, Musical gifts, obstinate nature, Good stamina, many job changes, Trouble early in life, tormented, Great wealth or poverty`,
    moonPeople: `Albert Einstein, Nietzsche Mozart, Beethoven, Al Pacino, Werner Erhard, Vincent van Gogh, Ted Bundy, Howard Hughes, Tiger Woods`,
    sun: `Attains fame, but desires seclusion, Ambitious nature, hard-working, High social status, good executive ability, Family obligations and responsibilities, Imaginative and innovative mind`,
    sunPeople: `Frank Sinatra, Walt Disney, Robert Hand, Winston Churchill, Sinead O’Conner, William Blake, Gianni, Versace, Jim Morrison`,
    carrerInterests: `Self-employed, management, Military leaders, Musicians, dancers, modeling, Police detectives, engineers, Intellectuals, philosophers`,
    healthIssues: `Genital organs, ovaries, Muscular problems, Neck pain, earaches, colds, Stomach trouble, Breast cancer`,
    qualities: `Tikshna (sharp or dreadful) Powerful and bold and brash activities: creating separation from friends, filing for a divorce, invoking spirits and other incantations, black magic, casting spells, exorcism, punishment, murder`,
    activitiesOrEvents: `Laying Foundation of Home, Learning Music or Dance, Surgical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 19,
    navatara: 'Janma',
    nakshtraName: 'Mula',
    lord: 'Ketu',
    imageFileName: '19.Mula.jpg',
    catchPhrase: 'Uprooting',
    keyWords: 'Roots, origins, lawlessness, destruction, spirituality',
    shortDiscription:
      'Mula inspires humanity away from what is normal and accepted, towards what is hidden, deeper, more original and true. It is a star of lies and hidden things; and has the power to destroy them both to reveal the deepest truths. Mula empowers destruction and allows us to destroy all that is superficial and illusory on our quest for true origins, true roots.',
    color: '#F8B309',
    colorName: 'Bright Yellow',
    kpBodyParts:
      'Hips, thighs, buttocks, femur, ilium, bone marrow and sciatic nerves',
    bodyPart1: 'Feet, Right Side of the Trunk',
    bodyPart2: 'Both Feet',
    deity: 'Nirruti, God/Goddess of Dissolution, Calamity and Destruction',
    animal: 'Shwana (Dog) Male',
    diseases: `Locomotor ataxia, rheumatism, lumbago, hip diseases, pulmonary troubles`,
    mentalQualities: `Generous, honest, respectful, commanding others esteem, cordial, genial, jovial, law-abiding, superstitious, forgiving, philanthropic, benevolent, charitable, God-fearing, religious, hopeful, buoyant, humane, always contemplative, social, (Will be proud, wealthy, happy, firm minded but luxurious in living)`,
    professions: `Religious endowment, advocate, judge, teacher purohit, puranas, ambassador, Congressman, literate, cabinet official, physician, ayurveda, social worker, councillor, provision dealers, horse-racing, gain through big ventures, International trade. Assembly Speaker, exchange, imports, (Medicines, Physician, Deacon of Corporations, very rich man, persons dealing in flowers, roots and fruits, seeds, and those that feed on fruits and roots)`,
    sunTransitPeriod: '16th Dec - 28th Dec',
    kpPathogenicEffects: `Locomotar ataxia, Rheumatism, Lumbago, Hip diseases, Pulmonary troubles`,
    asc: `Ambitious and independent, Attractive, learned, philosophical, Skilled and clever, suspicious, Marital turmoil, issues with anger, Health complaints, overcome illness`,
    ascPeople: `Jimi Hendrix, Sophia Loren, Priscilla Presley, Jimmy Swaggart, Jim Jones, Danny DeVito, Joe Frazier`,
    moon: `Proud, attractive, fixed mind, Peace loving, gives spiritual advice, Good oratory skills, success in foreign lands, Wealthy, luxurious habits, spends money on friends, Soft disposition, charitable nature`,
    moonPeople: `Al Gore, Dalai Lama, Billy Graham, Arnold Schwarzenegger, Judy Garland, Sri Aurobindo`,
    sun: `Fame, success, wealth, Powerful, strong, dictatorial nature, Psychic, mystical interests, Athletic ability, courage, Humanitarian concern, noble, Spiritual leadership, gives good advice`,
    sunPeople: `Howard Hughes, Brad Pitt, Jane Fonda, Uri Geller, Henry Miller, Alberto Tomba, Denzel Washington, Steven Spielberg, Arthur Clarke, Swami Satchidananda`,
    carrerInterests: `Public speakers, writers, Philosophers, spiritual teachers, Spiritual teachers, Lawyers, politicians, Doctors, pharmacists, Business and sales`,
    healthIssues: `Hip and thigh problems, Sciatic nerve troubles, Foot problems, Obesity, liver issues, Mental vacillation`,
    qualities: `Tikshna (sharp or dreadful) Powerful and bold and brash activities: creating separation from friends, filing for a divorce, invoking spirits and other incantations, black magic, casting spells, exorcism, punishment, murder`,
    activitiesOrEvents: `Buying a Home, Learning Astrology or Astronomy, Planting and Sowing, Surgical Treatment (Tue and Sat) Mars should be strong and the 8th House unoccupied - Waxing Moon but not a Full Moon, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 20,
    navatara: 'Sampat',
    nakshtraName: 'PurvaAshadha',
    lord: 'Venus',
    imageFileName: '20.PurvaAshada.jpg',
    catchPhrase: 'The Invincible',
    keyWords: 'Power, victory, appeal, popularity, strength, fertility, purity',
    shortDiscription:
      'PurvaAshadha is a star of strength through purity. It confers strength and power to mankind, but in a pleasant manner, like the invincible yet appealing power of water. Being both appealing and powerful, PurvaAshadha creates leaders with significant popularity and mass appeal.',
    color: '#0E0E0E',
    colorName: 'Black',
    kpBodyParts:
      'Thighs, hips, the concygeal and sacral regions of the spine, the filiac arteries and Veins',
    bodyPart1: 'Thighs, Back Portion of the Trunk',
    bodyPart2: 'Both the Thighs',
    deity: 'Apas/Apah, God of the Cosmic Waters',
    animal: 'Vanara (Monkey) Male',
    diseases: `Sciatica, diabetes, rheumatism, respiratory diseases, cancer in lungs, hipgout, surfeits cold, moist, human putrefaction of blood, swellings above the knees`,
    mentalQualities: `Magnanimous, over-liberal, broad-minded, educated, pleasing manners, polite, honest, loves others, just compassionate, moderate, optimistic, tolerant of others views, loves expensive and ultra-comfortable things, refined mind, fond of drama and art. (Tender-hearted men, fishermen, navigators, aquatic animals, etc. those that live by water, those who are devoted to truth, purity and wealth, constructors of bridges and aquatic, fruits and flowers. Will have an amiable (सुशील) wife, will be proud and firm in friendship)`,
    professions: `Judge, advocate, banker cashier, accountant, director, revenue and finance department, food, sugar, silk, cotton rubber, railways, road, building, air travel, Goddess temple, social studies welfare officer, animal husbandry, garden, nursery, transport, music, film show, foreign trade, foreign exchange, stock exchange, restaurants, hostels, business partner, ayurveda medicine, physician, women and children hospital, Health centre`,
    sunTransitPeriod: '29th Dec - 10th Jan',
    kpPathogenicEffects: `Sciatica, Diabetes, Rheumatism, Respiratory diseases, cancer in lungs, hip gout, surfeits, cold, moist, humours putrefaction of blood, swellings above the knees`,
    asc: `Proud nature, positions of high respect, Faithful to their mate, good marriage, Humble, many friends and children, Educational handicaps can occur, Strong interest in law and politics`,
    ascPeople: `James Earl Jones, Paul Newman, Dustin Hoffman, Dennis Rodman, J. Edgar Hoover, Earl Warren`,
    moon: `Attractive, charismatic leader, Obstinate, convincing power, dictatorial, Intelligent, good communicator, Highly philosophical nature, writing skill, Strong attachment to certain friends`,
    moonPeople: `Bhagwan Shree Rajneesh, Adolf Hitler, Ernest Hemingway, Johnny Carson, William James, Eddie Albert, Donna Summer`,
    sun: `Leadership skills, philosophical, Political interests, good speaking skills, Slow - but steady recognition, fame, Humanitarian concern, charitable, Interest in sports, competitive, Strange personality challenges, zealous`,
    sunPeople: `Paramahansa Yogananda, Richard Nixon, J. Edgar Hoover, Earl Warren, Anthony Hopkins, Mel Gibson, Kahlil Gibran, Mary Tyler Moore, Tiger Woods, Jimmy Bakker, Joseph Stalin`,
    carrerInterests: `Writers, teachers, debaters, Shipping industry, boating Politicians, lawyers, Travel industry, foreign traders, Actors, film, public speaking`,
    healthIssues: `Bladder, kidney problems, Thighs and hips, Sexual diseases, Colds and lung problems, Sciatica, rheumatism`,
    qualities: ``,
    activitiesOrEvents: ``,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 21,
    navatara:
      'VipatKrura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement',
    nakshtraName: 'UttaraAshadha',
    lord: 'Sun',
    imageFileName: '21.UttaraAshada.jpg',
    catchPhrase: 'Complete Victory',
    keyWords: 'Unchallengeable, undefeatable, intense and all-consuming',
    shortDiscription:
      'UttaraAshadha empowers human beings to give “110%” to their tasks, drawing all available power and pouring it into each of their endeavors. It is a star that helps create people who are undefeatable, even unchallengeable in their determination, strength, and intensity; and who can pull together many divergent resources and make them work for a common purpose.',
    color: '#D38A4A',
    colorName: 'Copper',
    kpBodyParts: `(Sagi part) Thighs, temur, marrow, dermis, knee cap, arteries
    (Capr part) Skins , Knees, Patella`,
    bodyPart1: 'Thighs & Waist',
    bodyPart2: 'Both the Thighs',
    deity: 'Visvedevas, Universal Gods called as Ganadevatas',
    animal: 'Nakula (Mongoose) Male',
    diseases: `(Sagi part) Sciatica, paralysis of limbs, pulmonary diseases, eye affected
    (Capr part) Eczema, skin diseases, leprosy, crysipeas, digestive trouble, uneasiness due to gas in the stomach, dull pain, rheumatism, palpitation of heart, cardiac thrombosis`,
    mentalQualities: `(Sagi part) Lofty ideals, noble aspiring disposition, benevolent, philanthropic, success in religion, law-abiding, expansive mind disposition, funny and jovial, cheerful, hopeful, no mind to have "tainted money". Pillars of good society, optimistic outlook, cheerful, proficiency in some branch of learning. (Mahouts, Wrestlers, horses, elephants, immovables, warriors, devotees of Gods, persons enjoying pleasure and militant persons. Will be well-behaved, righteous, will have many friends, will be grateful and amiable)
    (Capr part) Methodical, organising with foresight, diplomatic during execution, carrying out any work with moral stamina, sincere, reliable, economical, prudent, indomitable will, calculative, best fitted to reduce expenses and hold a position in retrenching department, or such departments to say "No" to lengthen correspondence, to delay as much as possible and at last sanction to the dissatisfaction of the applicant`,
    professions: `(Sagi part) Arbitrator, judge, politician, bank, finance department, shipping, education, religion, international trade, Embassy, Export promotion, Free Trade, Physician, Ayurveda Medicine, port trust, Customs, Jail department. Refugee Camp, Hospitals, Charitable Institutions
    (Capr part) Position of trust, estate, mine, coal, income-tax officer attending to wealth duty, estate duty, scientific research, finance department, curtailing expenses, control department, archaeology, monuments, antiquities, wool, Homeopathy, Jail, Engineer, ancient language, skins, hides`,
    sunTransitPeriod: '11th Jan - 23rd Jan',
    kpPathogenicEffects: `(Sagi part) Sciatica, Paralysis of limbs, Pulmonary diseases, eyes affected (Capr part) Eczema, skin diseases, leprosy, digestive troubles, gastric troubles rheumatism, palpitation of heart, cardiac thrombosis`,
    asc: `Sincere and kind nature, honest, Sharp intellect, reads intensely, Fond of fun, wanderer with many mates, Good public servant and counselor, May become famous later in life`,
    ascPeople: `Rodin, Henry Winkler, Stephen Sondheim, Tennessee Williams, Jane Fonda, Kirk Douglas, Billie Holiday`,
    moon: `Virtuous, intelligent, charitable, Well-liked, many friends, Charming, graceful, refined, Pure-hearted, grateful, Leadership, military prowess, More success after 35, Trouble with early marriage`,
    moonPeople: `Abraham Lincoln, Cary Grant, Robert Kennedy, Deepak Chopra, Cat Stevens, Billy Holliday, Napoleon, Augustus Caesar, Stan Grof, John Lennon`,
    sun: `Humanitarian, spiritual nature, Desire to change societal values, Research oriented, learned, Philosophical nature, deep thinker, Well-known, controversial, Fighter for a cause, strong speech`,
    sunPeople: `Maharishi Mahesh Yogi, Howard Stem, Ellen De Generes, Albert Schweitzer, G.I. Gurdjieff, David Bowie, Kevin Costner, Muhammad Ali, George Foreman`,
    carrerInterests: `Pioneers, researchers, scientists, Military work, hunters, Boxers or fighters for a cause, Government jobs, social work`,
    healthIssues: `Stomach problems, waist, Thighs and hips, Eczema, skin dryness, Arthritis, bone problems`,
    qualities: `Sthira (fixed or permanent): emphasis toward permanence and stability and structure: building homes, laying the foundations of communities, plowing the land, planting trees, purchasing agricultural property`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Laying Foundation of Home, Learning Music or Dance, Planting and Sowing, Medical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 22,
    navatara: 'Kshema',
    nakshtraName: 'Shravana',
    lord: 'Moon',
    imageFileName: '22.Shravana.jpg',
    catchPhrase: 'Listening Carefully',
    keyWords: 'Sound, listening, learning',
    shortDiscription:
      'Shravana is an excellent star for intelligence and education. Through it we become good listeners, speakers, and audio-smiths. Shravana inspires us to learn, ask intelligent questions, listen carefully to answers, and seek qualified teachers. It is one of the few stars that pleasantly turns us towards spiritual enlightenment.',
    color: '#43B6FE',
    colorName: 'Light Blue',
    kpBodyParts: 'Lymphatic Vessels, Knees, Skin',
    bodyPart1: 'Ears & Sex Organs',
    bodyPart2: 'The Two Ears',
    deity: 'Vishnu, The Solar God (aditya) preserver of the Universe',
    animal: 'Vanara (Monkey) Female',
    diseases: `Filaria, eczema, skin diseases, leprosy, boils, pus formation, rheumatism, tuberculosis, pleurisy and poor digestion`,
    mentalQualities: `Sober, conservative, pessimistic, cautious, economical, prudent, contemplative, careful, a little funky, lacks courage, true, sincere, faithful, patient worker, perseverance, attentive (Jugglers, ever-active, able, devotees of Vishnu , energetic and righteous men and truthful persons. Will be learned, possess a generously disposed wife, will be wealthy and famous)`,
    professions: `Mine, mine products, liquids, oil, kerosene, petrol, coal, wet lands, wells, trenches, excavation, tunnels, fisherman, minister, king, agriculturist, plumber, mine, engineer, ice cream, frigidaire, air cooler, those who serve at night and work underground, driver, pearls, submarine`,
    sunTransitPeriod: '24th Jan - 5th Feb',
    kpPathogenicEffects: `Filaria, eczema, skin disease, leprosy, boils, pus formation, rheumatism, T.B. pleurisy and poor digestion`,
    asc: `Religious nature, scholarly work, Excellent character, well-known, May live away from birthplace, Charitable, kind nature, Few children`,
    ascPeople: `C. G. Jung, Edward, Duke of Windsor, Evel Knievel, Bob Fosse, Roseanne`,
    moon: `Intelligent, good speech, fame Wealthy, creative genius, art interests, Charitable, a good host, principled, Political interests, overly zealous, Generous marital partner, religious, Perfectionist, eats good food`,
    moonPeople: `David Frawley, Henry Ford, George Lucas, Muhammad Ali, David Duke, Charles Manson, Bruce Willis, Jessica Lange`,
    sun: `Practical, pragmatic nature, successful, Difficulties with superiors, rebellious, Gifts in the communications field, Strong constitution, good health`,
    sunPeople: `Paul Newman, Alan Alda, James Dean, Mozart, Oprah Winfrey, Oral Roberts, Wayne Gretzky`,
    carrerInterests: `Teachers, speech therapists, linguists, Astrologers, religious scholars, Politicians, business skill, Geologists, researchers, travel`,
    healthIssues: `Hearing problems, ears, Skin sensitivities, Reproductive organs, Knees, rheumatism`,
    qualities: `Chara (movable or ephemeral): buying automobiles and other vehicles, going on a procession (जुलूस), landscaping (gardening), change of residence, change of career, major life changes`,
    activitiesOrEvents: `Laying Foundation of Home, Medical Treatment, Studying the Vedas or the Shastras, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 23,
    navatara: 'Pratyari',
    nakshtraName: 'Dhanishtha',
    lord: 'Mars',
    imageFileName: '23.Dhanishtha.jpg',
    catchPhrase: 'Getting Practical',
    keyWords:
      'Practicality, ideas given shape, plans set in motion, substance given form',
    shortDiscription:
      'Dhanishtha is a practical star, setting theories into practice and seeking tangible returns on ideas. It is too practical for things that require a lot of romance, sympathy, empathy and emotion.',
    color: '#A6AAB7',
    colorName: 'Silver Grey',
    kpBodyParts: `(Capr part) Knee caps, knee bones, calf muscles and hands
    (Aqu part) Ankles, limbs, portion between knees and ankles`,
    bodyPart1: 'Back & Anus',
    bodyPart2: 'Back',
    deity: 'Ashta Vasus, The Collective Elemental Gods',
    animal: 'Simha (Lion) Female',
    diseases: `(Capr part) Injury in the leg, boils, Eosinophilia (too much white blood cells), dry cough, hiccough, flying gout, lameness, amputation
    (Aqu part) Fracture of the leg, Varicose Veins, blood poisoning, heart failure, cardiac thrombosis, high blood pressure, overheated blood, palpitation, fainting`,
    mentalQualities: `(Capr part) Firm will, decisive, active, alert, careful and forceful, cautious and courageous, selfish, vindictive and violent, extravagant, occasionally ambitious, greedy, liberal, in gifts, fond of music, men without arrogance or pride, eunuchs, rich people, trying to maintain peace and avoiding disputes
    (Aqu part) Quarrelsome , cantankerous, short tempered or quick witted, scientific minded, research worker, sincere in religion, faithful in affections, fond of society, will try to start many clubs and organise, fickle friends, charitable, becomes rich`,
    professions: `(Capr part) Mine and ground engineer, death duty, estate duty, insurance, philosopher, Homeopathy, Ayurvedic, Jail department, gallows, murderer, slaughter house, surgeon dealing with fractures, amputation, labourer, labour department, welfare department, refugee camp, rehabilitation, industry, tools, spare parts, etc., distilleries, zinc, cement, metallurgy, lead, quarry, jute, barley, potato
    (Aqu part) Agriculture, tea, coal, steel plants, explorer, mine, lead, ferruginous, control and ration office, reclamation of waste lands, Retrenchment office, engineer, leather, skin, hides, postmortem, slaughter house, police, military, communication, press, foundry, rehabilitation of sufferers from floods, earthquake and war or riots and revolts, monuments, archaeology, building contractor, television, telegram, telephone, electricity, atomic energy, all research stations, silk, hessain (Jute)`,
    sunTransitPeriod: '6th Feb - 18th Feb',
    kpPathogenicEffects: `(Capr part) injury in leg, boils, Esonophillia , dry cough, flying gout, lameness, amputation (Aqu part) Fracture in legs, varicose veins, blood poisoning, heart failures, cardia thrombosis, high blood pressure, over heated blood, palpitation, fainting`,
    asc: `Regal and heroic nature, humanitarian, Idealistic, charitable, virtuous, Ambitious, optimistic, wealthy, Philosophical, rash, arrogant, Inquisitive mind, liberal in thought`,
    ascPeople: `Abraham Lincoln, Sri Ramakrishna, J.P. Morgan, Whoopi Goldberg, Karl Marx, Jimmy Hoffa, Elizabeth Kubler-Ross`,
    moon: `Wealthy, liberal in gift-giving, charitable, Daring and rash in action, controversial, Fond of music and poetry, mystical, Difficult to convince of anything, Troubles or delays in marriage. several mates, obstinate`,
    moonPeople: `Princess Diana, Timothy Leary, R. Maria Rilke, Orson Welles, Marilyn Monroe, Woody Allen`,
    sun: `Courageous, strong, issues with anger, Curious mind, research oriented, Athletic, hard working, Philosophical nature, social interests, Wealthy, successful, cynical, Stressful lifestyle, irrational at times`,
    sunPeople: `John McEnroe, John Grisham, Michael Jordan, Abraham Lincoln, Alfred Adler, Burt Reynolds, Ronald Reagan, Mia Farrow`,
    carrerInterests: `Musicians, poets, Doctors and surgeons, Real estate, property management, Engineering, mining, Scientists, research work, Charitable organizations`,
    healthIssues: `Arthritis, Back problems, Knees and ankles, Hypertension, heart trouble, Piles, hemorrhoids`,
    qualities: `Chara (movable or ephemeral): buying automobiles and other vehicles, going on a procession (जुलूस), landscaping (gardening), change of residence, change of career, major life changes`,
    activitiesOrEvents: `Learning Music or Dance, Medical Treatment, Studying Medicine, Travel or Beginning a Journey`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 24,
    navatara: 'Sadhak',
    nakshtraName: 'Shatabhisha',
    lord: 'Rahu',
    imageFileName: '24.Shatabhisha.jpg',
    catchPhrase: 'The Underworld',
    keyWords: 'The sky, west, underground, night, depths, equality, medicine',
    shortDiscription:
      'Shatabhisha is the star of aeronautics, technology and western civilization. It is a profound and mysterious star promoting counter-culturalism and underground movements. It seeks liberal equality, and fair administration of justice, and is thus often a cause of revolutionary tendencies. It is also a star with special relevance to health and medicine.',
    color: '#2AC8C8',
    colorName: 'Blue Green',
    kpBodyParts:
      'Portion between knees and ankles, Calf muscles, muscular tissues',
    bodyPart1: 'Jaw, Right Thigh',
    bodyPart2: 'Both Sides of Chin',
    deity: 'Varuna, The Solar God (aditya) of the Oceans, Rains and the Sky',
    animal: 'Ashwa (Horse) Female',
    diseases: `Rheumatism, Rheumatic heart, eczema, leprosy, palpitation, high blood pressure, fracture, amputation, lame, guinea worm`,
    mentalQualities: `Sincere, strong, forceful, independent, original, patient, persevering, lethargic, lazy, fond of leisure and holidays or fond of working separately`,
    professions: `Scientist, occult subject, gravitation, dynamics, electricity, atomic theory, air travel, astronomer, astrologer, amputator, stretcher-carrier, renovation of the ancient science, history, Ration office, Census, Censor, Statistics, Stock exchange, Technician, Jail department, translation, and transliterations of old manuscripts, laboratories, factories, Tanneries, counterfeit`,
    sunTransitPeriod: '19th Feb - 3rd Mar',
    kpPathogenicEffects: `Rheumatism, Rheumatic heart, eczema, Leprosy, Palpitation, High cough, high blood pressure, fracture, amputation, lame, guinea worm`,
    asc: `Interest in mysticism and astrology, Service oriented, quiet, honest, Philosophical nature, political interests, Travel for educational purposes, intelligent, Trouble with alcohol, deception`,
    ascPeople: `B.V. Raman, Jason Alexander, Walter Mondale, Donald Bradley, Vincent Price, Whitney Houston`,
    moon: `Truthful, principled, charitable, Writing skill, excellent memory, Interest in astrology, psychology, Daring, adamant, bold nature, Defeats enemies, opinionated, Independent, artistic nature`,
    moonPeople: `Paul Newman, Michelangelo, Goethe, Ronnie Gale Dreyer, Robin Williams, Elvis Presley, J. Edgar Hoover, Paul Klee`,
    sun: `Good intelligence, creative genius, Hard working, humanitarian concern, Writing ability, philosophical nature, Needs external encouragement, sickly`,
    sunPeople: `Rudolph Steiner, Sidney Poitier, Mikhail Gorbachev, George Harrison, Ted Kennedy`,
    carrerInterests: `Astrologers, astronomers, Physicians, healers, Writers, research work, Clerical work, secretaries, Engineers, electricians, Organizational development`,
    healthIssues: `Arthritis, rheumatism, Heart trouble, hypertension, Calves and ankles, Jaw problems, TMJ, Bone fractures`,
    qualities: `Chara (movable or ephemeral): buying automobiles and other vehicles, going on a procession (जुलूस), landscaping (gardening), change of residence, change of career, major life changes`,
    activitiesOrEvents: `Learning Music or Dance, Medical Treatment, Studying Medicine`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 25,
    navatara: 'Vadh',
    nakshtraName: 'PurvaBhadrapada',
    lord: 'Jupiter',
    imageFileName: '25.PurvaBhadrapada.jpg',
    catchPhrase: 'Destruction',
    keyWords:
      'Transformation, death and the afterlife, (the need for) detachment, hoarding / holding on, pessimism, destruction, storms',
    shortDiscription:
      'PurvaBhadrapada is a serious star concerned with significant personal transformation. Inclining us towards personal transformation, it makes us more critical, pessimistic and destructive towards the here and now, and the wealth we are simultaneously inclined to accumulate in it.',
    color: '#A6AAB7',
    colorName: 'Silver Grey',
    kpBodyParts: `(Aqu part) Ankles, legs and thumb
    (Pisc part) Feet, toes`,
    bodyPart1:
      'Sides of the Body, Including the Ribs, Sides of the Legs, Left Thigh & Soles of the Feet',
    bodyPart2: 'The Two Sides of the Body',
    deity: 'Aja Ekapada, One Legged unborn Mountain Goat, also the Fire Dragon',
    animal: 'Simha (Lion) Male',
    diseases: `(Aqu part) Apoplexy, irregularity of heart, dropsy, milk-leg, swollen ankles, palpitation, dilated heart, low blood pressure
    (Pisc part) Swelling, perspiring feet, enlarged liver, abdominal tumour, intestines affected, hernia, Jaundice, sprue, corns`,
    mentalQualities: `(Aqu part) Humane, hopeful, philosophic, optimistic, fond of friends, truth , honest, sincere, reliable, interest in science, philosophy, astronomy, astrology, literature, criticism, systematic action, yet lethargic, capable of extracting work from others, liberal, helpful, unselfish, if a malefic is in this zone, in the ascendant: robber, murderer, devoid of virtue, irreligious, ever fighting, courtbird, hen pecked, miser, clever
    (Pisc part) Magnanimous, liberal minded, charitable, veritable, angels of mercy, fond of music, art, philosophy, literature, law-abiding, true, a little vacillating, pleasing disposition, polite behaviour`,
    professions: `(Aqu part) Teacher-Dynamics, Statistics, Astronomy, Astrology, medicine, aeroplane, municipality, corporation, public limited concerns, stock exchange, share broker, air travel, research worker, planning commission, international trade, overseas bank, foreign exchange, education,/ finance, revenue department, insurance, temple, trustee, executive officer, mint, currency, mining, Anti-corruption department, intelligence department (C.I.D.)
    (Pisc part) Politician, profession, minister, councillor, president, legal education, religious and finance department, judge, criminal lawyer, arbitrator, trustee, visitor of jails, hospitals, famine and refugee camps, planning commission, decontrol, tourist, explorer, physician and surgeon, bank, foreign exchange, prisoners of war, risky investments, associations, public companies`,
    sunTransitPeriod: '4th Mar - 17th Mar',
    kpPathogenicEffects: `(Aqu part) Apoplexy, Irregularity of Heart, Dropsy, Swollen ankles, Palpitation, Dilated heart, low blood pressure (Pisc part) Rheumatic pains, Drop Foot, Indigestion, constipation, hernia, Flatulence fracture in the foot, cold foot, T.B., dropsy`,
    asc: `Philosophical nature, good speaker, Strong sexual attractions, high strung, Changes residences often, fond of travel, Money through government, intent on their work, Good longevity`,
    ascPeople: `Thomas Merton, Ringo Starr, Dorothy Hamill, Norman Mailer, Mary Tyler Moore, Deepak Chopra, Dean Martin`,
    moon: `Spiritual depth, teaching skill, Occult knowledge, intuitive gifts, Wealthy, clever in executing work, Intelligent, scholarly, writer, Cynical, witty nature`,
    moonPeople: `Martin Luther King, Sri Ramakrishna, Aleister Crowley, Jeffrey Green, Hugh Hefner, Robert DeNiro, Goethe, Madam Curie, Groucho Marx, OJ Simpson`,
    sun: `Creative intelligence, detailed work, Dislike routine, need variety, Writing skill, moody nature, Independent, need seclusion`,
    sunPeople: `Albert Einstein, Billy Crystal, Jerry Lewis James Taylor, Michael Caine`,
    carrerInterests: `Astrologers, priests, ascetics, Research skills, statisticians, Occultists, black magicians, Administrative planners, business skills`,
    healthIssues: `Swollen ankles and feet, Heart problems. blood circulation, Enlarged liver, Rib troubles, Sides of the legs, Ulcers`,
    qualities: `Krura or Ugra (fierce or severe): destructive deeds, setting fires, poisoning, deceitful acts, Imprisonment and other forms of confinement`,
    activitiesOrEvents: ``,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 26,
    navatara: 'Mitra',
    nakshtraName: 'UttaraBhadrapada',
    lord: 'Saturn',
    imageFileName: '26.UttaraBhadrapada.jpg',
    catchPhrase: 'Depth',
    keyWords:
      'Transformation, afterlife, various types of wealth, generosity, optimism, depth, intuition, emancipation',
    shortDiscription: `Uttara Bhādrapadā enables us to open our arms to transformation with an optimistic view of what we will become. It creates a love for water and imparts the traits of water, fertility, beauty, quietude, calm, introspection, depth and even a loneliness or sense of being separate and private from the world. Anger, aggression and envy are intense but short lived and manageable.
    Highly knowledgeable people - like Vasuki the serpent guards the earth's treasures.
    Generous in sharing this knowledge.
    May have a slow gait(चाल)`,
    color: '#4D2AA4',
    colorName: 'Purple',
    people: `Bill Gates' moon`,
    kpBodyParts: 'Feet, Legs',
    bodyPart1:
      'Sides of the Body, Including the Ribs, Sides of the Legs, The Shins & Soles of the Feet',
    bodyPart2: 'The Two Sides of the Body',
    deity:
      'Ahir Budhyana, Lord of Fertility and Kundalini, also the Water Dragon',
    animal: 'Gau (Cow) Female',
    diseases: `Rheumatic pains, drop foot, indigestion, constipation, hernia, flatulence, fracture in the foot, cold foot, tuberculosis, dropsy`,
    mentalQualities: `Strong character, benevolent disposition, philosophical mind-likes seclusion-hates disturbance, found of society, desire to help the poor and invalid, Visitor of jails, Sanatoriums, independent, original (fraud if afflicted)`,
    professions: `Home department, prisons, Asylum, Sanatorium, Isolated hospitals, prisoner of war, civil jail, political jail, mines, excavator, Engineer, export, import, harbour storekeeper- inheritance, traditional business, gains through societies, clubs, companies, public appointment, hospitals, religious and charitable institutions, submarine, shipping, foundries, legal, insurance, intelligence department, C.I.D., education, tourist home, manufacturer of Rain coats, umbrella, sponger, boats, life boats etc. oils, fishers, breweries, river and canal constructor, tunnel construction, trench excavators`,
    sunTransitPeriod: '18th Mar - 30th Mar',
    kpPathogenicEffects: `Rheumatic pains, Drop Foot, Indigestion, constipation, hernia, Flatulence fracture in the foot, cold foot, T.B., dropsy`,
    asc: `Eloquent in speech, benevolent, Occultist, humanitarian nature, Happiness from children, love of family, Likes unique treasures, many travels, Writing skills, poetic, Permanent enemies. Fickle nature`,
    ascPeople: `Robert Redford, Walt Whitman, Joan Baez, Billy Graham, Robert Duvall, Barbra Streisand, Rabindrath Tagore, Bruce Willis`,
    moon: `Attractive, innocent looking, Virtuous, good hearted, service oriented, Clever in speech, happy and wise, Overcomes enemies, controls anger, Enjoy children, happy marriage`,
    moonPeople: `Bill Gates, Indira Gandhi, Robert Kennedy, Ken Johnson, Abraham Lincoln, Hilary Clinton, Cary Grant, Christina Collins Hill`,
    sun: `Intelligent, quick-witted, creative work, Peaceful nature, generous and charitable, Mystical mind, good writing skill, Diplomatic, cunning, secretive, Hardworking, speak softly, spiritual`,
    sunPeople: `Chakrapani Ullal, Elton John, Viktor Frankl, Dane Rudhyar, Timothy Dalton, Bruce Willis, Edgar Cayce`,
    carrerInterests: `Charitable work, non-profit organizations, Import and exports, travel industry, Religious work, priests, saints, Astrologers, mystical work, Writers, philosophers, teachers`,
    healthIssues: `Foot problems, cold feet, Indigestion, constipation, Sides of the body and legs, Hypertension, stress disorders, Allergies, Liver problems`,
    qualities: `Sthira (fixed or permanent): emphasis toward permanence and stability and structure: building homes, laying the foundations of communities, plowing the land, planting trees, purchasing agricultural property`,
    activitiesOrEvents: `Marriage, Installing a Deity or Building a Temple, Learning Music or Dance, Medical Treatment`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
  {
    id: 27,
    navatara: 'Atimitra',
    nakshtraName: 'Revati',
    lord: 'Mercury',
    imageFileName: '27.Revati.jpg',
    catchPhrase: 'Abundance',
    keyWords: 'Prosperity, love, gentility, generosity, guidance, spirituality',
    shortDiscription:
      'Revati inspires human beings to attain true prosperity and wealth; through generosity, compassion, devotion and kindness. It blesses loving relationships, inclines us to non-violence, and helps us find our way forward without getting lost. It also grants a good sense of timing and rhythm.',
    color: '#66351C',
    colorName: 'Brown',
    kpBodyParts: 'Feet and toes',
    bodyPart1: 'Abdomen, Groin & Ankles',
    bodyPart2: 'Both Armpits',
    deity:
      'Pushan, The Solar God (aditya) that protects travellers, wild animals through his navigational skills',
    animal: 'Gaja (Elephant) Female',
    diseases: `Abdominal disorders, troubles, deformities of the feet, intestinal ulcers, mostly due to drinks and drugs, gout in the feet, cramps, Nephritis, Lassitude, deafness, pus in the ear`,
    mentalQualities: `Vascillation, indecisive, flexible, and impressionable mind, intuitive, human sympathetic, shrewd, clever, religious and philosophical, ripe judgement, fruitful mind, honest and honourable, studious, mental balance, sober, proficient and competent, honest, caring more for integrity`,
    professions: `Publisher, editor, religious, legal, civil engineering departments, Share broker, advertisement, publicity, propaganda, communications, lawyer, judge, professor, politician, ambassador, representative, telephone, typewriter, radio, tape- recorder, currency notes, international trade, messengers, embassy, accountant, auditor, trustee, governor, university, employment exchange, newspapers, clerk, shipping, import, export, excise, customs, harbour, port trust, religious head- purohits, preachers, industry, finger print expert, successful diplomats, astrologer, mathematician, broker, agent, bank, etc`,
    sunTransitPeriod: '31st Mar - 13th Apr',
    kpPathogenicEffects: `Abdominal disorders, deformities of feet gout in the feet, cramps, nephirties, lassitude, deafness, pus in the ear etc.`,
    asc: `Valiant, rich, proud, Leadership skills, responsible nature, Attractive, strong and clean body, Sociable, many friends, a good host, Good longevity, love of travel`,
    ascPeople: `Mikhail Gorbachev, Dwight Eisenhower, Lee Iacocca, Bette Midler, Pete Rose, F. Lee Bailey`,
    moon: `Independent, ambitious, well-liked. wealthy, Interests in ancient cultures, much wisdom, Interest in religion and mysticism, Love of pets and animals, courageous, Beautiful, magnetic, clean, well-formed body, Good marriage karma, success in foreign lands`,
    moonPeople: `Marlon Brando, James Taylor, Rabindrath Tagore, Joe Pesci, Jim Jones, Rodney King, Whitney Houston`,
    sun: `Artistic nature, sensitive, psychic, Humorous, unusual fame, Interest in political science, law, Philosophical nature, charitable, Love of travel, desires change, Troubles with drugs, unpredictable at times`,
    sunPeople: `Celine Dion, David Letterman, Jerry Brown, Al Gore, Ram Dass, Betty Ford, Hugh Hefner, Eddie Murphy, Robert Downey Jr., Marlon Brando`,
    carrerInterests: `Film actors, comedians, politicians, Humanitarian projects, charitable work, Urban planners, government positions, Psychics, mystical or religious work, Journalists, editors, publishers, Travel agents, flight attendants`,
    healthIssues: `Ankle and feet problems, Childhood illnesses, Insomnia, nightmares, Sensitive nervous system, Stomach problems`,
    qualities: `Mridu (soft, mild or tender): learning music-dance-drama, performing auspicious ceremonies like marriage, buying new clothes, wearing new clothes, Love-making, romance, making new friends, enjoyment of pleasures that are healing, revitalizing`,
    activitiesOrEvents: `Buying a Home, Marriage (First 3 padas), Learning Astrology or Astronomy, Learning Music or Dance, Planting and Sowing, Medical Treatment, Travel or Beginning a Journey (Tuesdays should be avoided if possible)`,
    intro: [``],
    tree: [``],
    symbol: [``],
    birds: [``],
    varna: [``],
    gunas: [``],
    element: [``],
    location: [``],
    gana: [``],
    purpose: [``],
    triMurti: [``],
    alphabets: [``],
  },
]);
