import React from "react";
import useDarkMode from "use-dark-mode";
import styled from "styled-components";

import { decode, deg2dec, nakshatraPada } from "./../engine/core";
import nakshatras from "./../../data/nakshatras";
import grahas from "./../../data/grahas";
import rashis from "./../../data/rashis";
import signStarSubTable from "./../../data/signStarSubTable";
import yoginiDashaTable from "./../../data/yoginiDasha";

const isObject = (val) => {
  return typeof val === "object" && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach((arg) => {
    if (typeof arg === "string") {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach((key) => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        "`classnames` only accepts string or object as arguments"
      );
    }
  });

  return classes.join(" ");
};

export const TagLabelStyle = styled.label`
  float: left;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 1rem;
  margin: 0 7px 7px 0;
  list-style-type: none;
  color: ${({ darkModeValue }) => (darkModeValue ? "#eef4f7" : "#10171d")};
  background-color: ${({ darkModeValue }) =>
    darkModeValue ? "#3a3939" : "#dfdfdf"};
  border: ${({ darkModeValue }) =>
    darkModeValue ? `solid 0.4px #636669` : `solid 0.4px #bbc0ca`};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Tagify = ({ tags }) => {
  const { value: darkModeValue } = useDarkMode();
  if (!tags) return "";

  if (Array.isArray(tags)) {
    return tags?.map((tag) => Tagify({ tags: tag }));
  } else {
    return tags?.split(/[,.]+/).map((tag, idx) => {
      let element = null;
      if (tag !== "") {
        element = (
          <TagLabelStyle content={null} key={idx} darkModeValue={darkModeValue}>
            {tag}
          </TagLabelStyle>
        );
      }
      return element;
    });
  }
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const calculateNakshatra = (signDegrees, signIndex) => {
  const justValue = signDegrees
    .replace(/_/g, "")
    .replace(/°/, "")
    .replace(/'/, "")
    .replace(/"/, "");
  const degreeArray = justValue.split(" ");
  const mapped = degreeArray.filter((deg) => deg === "");

  if (degreeArray.join("") === "" || mapped.length > 0) {
    return;
  }

  if (signDegrees[signDegrees.length - 1] !== '"') {
    signDegrees += '"';
  }

  const totalDegree = `${
    signIndex * 30 + deg2dec(signDegrees.replace(/_/g, ""))
  }`;
  const totalDecimalDegree = parseFloat(totalDegree);
  const nakshatraIndex = (totalDecimalDegree * 60) / 800.0; // get nakshatra
  const nakshatra = nakshatras[Math.floor(nakshatraIndex)]?.nakshtraName;
  const nakshatraLordIndex = Math.floor(nakshatraIndex) % 9;
  const nakshatraLord = grahas[nakshatraLordIndex]?.graha;
  const pada = nakshatraPada(nakshatraIndex);

  return {
    nakshatra: {
      name: nakshatra,
      lord: nakshatraLord,
      pada: pada,
      degreeInDecimal: totalDecimalDegree,
      index: nakshatraIndex,
    },
    sign: {
      index: signIndex,
      name: rashis[signIndex].name,
    },
  };
};

export const signStarSub = (propsForIndex, setFunction, sign, nakshatra) => {
  const returnArray = [];
  if (parseInt(nakshatra.degreeInDecimal) > -1) {
    signStarSubTable.filter((signStarSub) => {
      const startAt = parseFloat(
        sign.index * 30 + deg2dec(signStarSub.startsAt)
      );
      const endAt = parseFloat(sign.index * 30 + deg2dec(signStarSub.endsAt));

      if (
        nakshatra.degreeInDecimal >= startAt &&
        nakshatra.degreeInDecimal < endAt &&
        sign.name === signStarSub.rashi
      ) {
        signStarSub.pada = nakshatra.pada;
        returnArray.push(signStarSub);

        return true;
      } else {
        return false;
      }
    });
  }
  return returnArray;
};

const yoginiDashaMap = {
  1: { lordName: `mars` },
  2: { lordName: `venus` },
  3: { lordName: `mercury` },
  4: { lordName: `moon` },
  5: { lordName: `sun` },
  6: { lordName: `saturn` },
  7: { lordName: `jupiter` },
  8: { lordName: `rahu` },
};

/**
 * Cycle steps generator function
 *  Say I need to iterate over [1, 2, 3], yielding a value
  at a time but once I reach the final value, I want to
  keep receiving the values over and over again, making
  it a repeated sequence: 1, 2, 3, 1, 2, 3, 1 and so on...
  https://gist.github.com/luishendrix92/57075ceb18009ba3f1b2a11783d9ac96
 * @param {array} list Array object
 * @returns obj array element
 */
const circularArraySteps = (list) =>
  (function* () {
    while (true) for (let item of list) yield item;
  })();

const balanceDashaYears = (balanceDashaFactor) => {
  const baseYears = balanceDashaFactor % 365;
  const years = Math.floor(baseYears);

  const baseMonths = ((baseYears - years) * 365) / 30;
  const months = Math.floor(baseMonths);

  const baseDays = (baseMonths - months) * 30;
  const days = Math.floor(baseDays);

  const baseHours = (baseDays - days) * 24;
  const hours = Math.floor(baseHours);

  const baseMinutes = (baseHours - hours) * 60;
  const mins = Math.floor(baseMinutes);

  const baseSeconds = (baseMinutes - mins) * 60;
  const secs = Math.floor(baseSeconds);

  return {
    years,
    months,
    days,
    hours,
    mins,
    secs,
  };
};
const firstYogini = (dashaPeriodInYears, balancedNakshatraDegrees) => {
  const balancedDasha = (balancedNakshatraDegrees / 800) * dashaPeriodInYears;

  return balanceDashaYears(balancedDasha);
};

const anterDashaBalanceYear = (
  currentDashasYears,
  dashaYears,
  nextDashaEnd
) => {
  const { years, months, days, hours, mins, secs } = balanceDashaYears(
    (currentDashasYears * dashaYears) / 36
  );

  return new Date(
    nextDashaEnd.getFullYear() + years,
    nextDashaEnd.getMonth() + months,
    nextDashaEnd.getDate() + days,
    nextDashaEnd.getHours() + hours,
    nextDashaEnd.getMinutes() + mins,
    nextDashaEnd.getSeconds() + secs
  );
};

const arrayPushUnique = (items, item) => {
  if (items.indexOf(item) === -1) {
    items.push(item);
  }
  return items;
};

const yoginiNextDasha = (
  dashaQueue,
  currentDashaName,
  dashaArray,
  birthMahaDashaEnd
) => {
  let nextAnterDasha;
  do {
    nextAnterDasha = dashaQueue.next()?.value;
  } while (nextAnterDasha?.lordName !== currentDashaName);

  // Get the next anter dasha
  let returnArray = [];
  do {
    const { lordName } = nextAnterDasha;
    const { yoginis } = yoginiDashaTable;
    const { dashaPeriodInYears: anterDashaPeriodInYears, name } =
      yoginis[lordName];
    const { dashaPeriodInYears: currentDashaPeriodInYears } =
      yoginis[currentDashaName];
    const dashaEndReference = returnArray.length
      ? returnArray[returnArray.length - 1].endsOn
      : birthMahaDashaEnd;
    returnArray = arrayPushUnique(returnArray, {
      name,
      lordName,
      endsOn: anterDashaBalanceYear(
        currentDashaPeriodInYears,
        anterDashaPeriodInYears,
        dashaEndReference
      ),
    });
    nextAnterDasha = dashaQueue.next()?.value;
  } while (nextAnterDasha?.lordName !== currentDashaName);
  return returnArray;
};

export const yoginiDasha = (
  { degrees, minutes, seconds },
  signIndex,
  birthYear,
  birthMonth,
  birthDate
) => {
  const signDegrees = `${degrees}°${minutes}'${seconds}"`;
  const {
    nakshatra: { degreeInDecimal, index },
  } = calculateNakshatra(signDegrees, signIndex);

  const balancedNakshatraDegrees = 800 - degreeInDecimal;
  const dashaLordIndex = (Math.floor(parseFloat(index)) + 3) % 8;
  const cyclicDasha = circularArraySteps(Object.values(yoginiDashaMap));

  const { yoginis } = yoginiDashaTable;
  const fstYogini = yoginis[yoginiDashaMap[dashaLordIndex + 1]?.lordName];
  const {
    dashaPeriodInYears,
    energyInHouseItsPlacedFrom,
    events,
    name,
    newLord,
    oldLord,
    shortDescription,
  } = fstYogini || {};

  const { years, months, days, hours, mins, secs } = firstYogini(
    dashaPeriodInYears,
    balancedNakshatraDegrees
  );

  const dashaList = [];
  let anterDashaList = [];
  const birthMahaDashaEnd = new Date(
    birthYear + years,
    birthMonth + months,
    birthDate + days,
    hours,
    mins,
    secs
  );
  dashaList.push({
    name,
    lordName: newLord,
    endsOn: birthMahaDashaEnd,
  });

  let anterDashaCyclicDasha = circularArraySteps(Object.values(yoginiDashaMap));
  let dashaEndRef = dashaList.length
    ? dashaList[dashaList.length - 1].endsOn
    : birthMahaDashaEnd;
  anterDashaList = yoginiNextDasha(
    anterDashaCyclicDasha,
    newLord,
    anterDashaList,
    dashaEndRef
  );

  dashaList.push(anterDashaList);
  let nextDasha;
  while (nextDasha?.lordName !== newLord) {
    nextDasha = cyclicDasha.next()?.value;
  }
  // Get the next dasha after birth time dasha
  nextDasha = cyclicDasha.next()?.value;

  let nextMahaDashaEnd = birthMahaDashaEnd;
  for (let indexDasha = 0; indexDasha < 108; ) {
    const {
      dashaPeriodInYears,
      name,
      newLord: nwLord,
    } = yoginis[nextDasha.lordName];

    anterDashaCyclicDasha = circularArraySteps(Object.values(yoginiDashaMap));

    nextDasha = cyclicDasha.next()?.value;
    nextMahaDashaEnd = new Date(
      nextMahaDashaEnd.getFullYear() + dashaPeriodInYears,
      nextMahaDashaEnd.getMonth(),
      nextMahaDashaEnd.getDate(),
      nextMahaDashaEnd.getHours(),
      nextMahaDashaEnd.getMinutes(),
      nextMahaDashaEnd.getSeconds()
    );
    dashaList.push({
      name,
      lordName: nwLord,
      endsOn: nextMahaDashaEnd,
    });

    anterDashaList = yoginiNextDasha(
      anterDashaCyclicDasha,
      nwLord,
      anterDashaList,
      nextMahaDashaEnd
    );

    dashaList.push(anterDashaList);
    indexDasha = indexDasha + dashaPeriodInYears;
  }

  return {
    energyInHouseItsPlacedFrom,
    events,
    lord: newLord,
    oldLord,
    name,
    shortDescription,
    balancedDasha: {
      years,
      months,
      days,
      hours,
      mins,
      secs,
    },
    birthMahaDashaEnd,
    dashaList,
  };
};

export const degree2Angle = (inputDegrees) => {
  let degree;
  let minutes;
  let seconds;
  inputDegrees = Math.abs(inputDegrees);
  degree = Math.floor(inputDegrees);
  minutes = inputDegrees - degree;
  seconds = minutes * 60;
  minutes = Math.floor(seconds);
  seconds -= minutes;
  const rashiIndex = Math.floor(degree / 30);
  degree %= 30;
  seconds = Math.floor(seconds * 60);

  return {
    degree,
    minutes,
    seconds,
    rashiIndex,
  };
};

export const angleSeperator = (angle) => {
  const matches = decode(angle);

  if (!matches) {
    return NaN;
  }

  const degree = parseFloat(matches[1]);
  const minutes = parseFloat(matches[2]);
  const seconds = parseFloat(matches[3]);

  if (isNaN(degree) || isNaN(minutes) || isNaN(seconds)) {
    return NaN;
  }

  return {
    degree,
    minutes,
    seconds,
  };
};
