import React from 'react';
import {
  Card,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  List,
} from 'semantic-ui-react';
import styled from 'styled-components';
import useDarkMode from 'use-dark-mode';
import { capitalize, Tagify } from '../../engine/helper';

import useKeyboardEvent from '../../engine/useKeyboardEvent';

const rashis = require('./../../../data/rashis');
const ghaars = require('./../../../data/houses');
const grahas = require('./../../../data/grahas');

const grahaIndexMapper = {
  ketu: 0,
  venus: 1,
  sun: 2,
  moon: 3,
  mars: 4,
  rahu: 5,
  jupiter: 6,
  saturn: 7,
  mercury: 8,
  uranus: 9,
  neptune: 10,
  fortuna: 11,
  ke: 0,
  ve: 1,
  su: 2,
  mo: 3,
  ma: 4,
  ra: 5,
  ju: 6,
  sa: 7,
  me: 8,
  ur: 9,
  ne: 10,
  fo: 11,
};

const CombinationCardStyle = styled.div`
  .ui.list > .item .header {
    color: ${({ darkModeValue }) => (darkModeValue ? '#e0e1e2' : '#54544c')};
  }
`;

const PlanetDetailsStyle = styled.div`
  .ui.list > .item .header {
    color: ${({ darkModeValue }) => (darkModeValue ? '#e0e1e2' : '#54544c')};
  }
`;

const CombinationCard = ({
  houseIndex,
  rashiIndex,
  selectedPlanets,
  toggler,
}) => {
  const {
    direction,
    kpBodyParts,
    name: houseName,
    romanName,
    relatives,
    lunarAstro: { basic: houseBasic } = {},
    starGuide,
  } = ghaars[houseIndex] || {};

  const {
    name: rashiName,
    nature: rashiNature,
    vedicName,
    gender: rashiGender,
    tenor: rashiTenor,
    lunarAstro: { basic: basicBasic, predictive: rashiBasic } = {},
  } = rashis[rashiIndex];

  useKeyboardEvent('Escape', () => toggler());
  const { value: darkModeValue } = useDarkMode();

  const planetDetails = (planet, planetIndex) => {
    const mappedIndex = grahaIndexMapper[planet.toLowerCase()];
    const graha = grahas[mappedIndex];
    const {
      lunarAstro: {
        basic: planetBasic,
        cabinetPlace,
        predictive: planetPredictive,
      } = {},
      digBalaIn,
      exaltation: {
        inSign: { name: exaltedSignName, number: exaltedSignNumber },
        degree: exaltedDegree,
      } = {},
      debilitation: {
        inSign: { name: debilitedSignName, number: debilitedSignNumber },
        degree: debilitedDegree,
      } = {},
      moolatrikona: {
        inSignName: moolatrikonaInSignName,
        inSignNumber: moolatrikonaInSignNumber,
        startDegree: moolatrikonaStartDegree,
        endDegree: moolatrikonaEndDegree,
      } = {},
    } = graha || {};

    return (
      <PlanetDetailsStyle
        key={`${planet}-${planetIndex}`}
        darkModeValue={darkModeValue}>
        <Divider>
          {graha.graha}
          <Label>{cabinetPlace}</Label>
        </Divider>
        <Divider></Divider>
        <Card.Meta>Planet</Card.Meta>
        <Card.Description>
          {planetPredictive.length < 2
            ? planetPredictive.map((line, idx) => (
                <Tagify key={`${line}-${idx}`} tags={line} />
              ))
            : planetBasic.map((line, idx) => (
                <Tagify key={`${line}-${idx}`} tags={line} />
              ))}
        </Card.Description>
        <Card.Description>
          <List celled>
            {digBalaIn && (
              <List.Item>
                <List.Content>
                  <List.Header>Dig Bala In</List.Header>
                  {`${digBalaIn} House`}
                </List.Content>
              </List.Item>
            )}
            {exaltedSignName && (
              <List.Item>
                <List.Content>
                  <List.Header>Exalted in</List.Header>
                  {`${capitalize(
                    exaltedSignName
                  )} (${exaltedSignNumber}) between 0 - ${exaltedDegree} degrees`}
                </List.Content>
              </List.Item>
            )}
            {debilitedSignName && (
              <List.Item>
                <List.Content>
                  <List.Header>Debilited in</List.Header>
                  {`${capitalize(
                    debilitedSignName
                  )} (${debilitedSignNumber}) between 0 - ${debilitedDegree} degrees`}
                </List.Content>
              </List.Item>
            )}
            {moolatrikonaInSignName && (
              <List.Item>
                <List.Content>
                  <List.Header>Moolatrikona in</List.Header>
                  {`${capitalize(
                    moolatrikonaInSignName
                  )} (${moolatrikonaInSignNumber}) between ${moolatrikonaStartDegree} - ${moolatrikonaEndDegree} degrees`}
                </List.Content>
              </List.Item>
            )}
          </List>
        </Card.Description>
      </PlanetDetailsStyle>
    );
  };

  return (
    <CombinationCardStyle darkModeValue={darkModeValue}>
      <Grid stackable columns={selectedPlanets.length ? 3 : 2}>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Card.Header>
                {`${romanName} - ${houseName} - ${direction}`}
              </Card.Header>
              <Card.Meta>House</Card.Meta>
              <Card.Description>
                <Tagify tags={houseBasic} />
              </Card.Description>
              <Card.Description>
                <Tagify tags={starGuide} />
              </Card.Description>
              <Divider>Relatives</Divider>
              <Card.Description>
                <Tagify tags={relatives} />
              </Card.Description>
              <Divider>Body Parts</Divider>
              <Card.Meta>
                <Tagify tags={kpBodyParts} />
              </Card.Meta>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Card.Header>
                {`${rashiName} - ${vedicName} - ${rashiGender} - ${rashiNature} - ${rashiTenor}`}
              </Card.Header>
              <Card.Meta>Rashi</Card.Meta>
              <Card.Description>
                {rashiBasic.length ? (
                  <Tagify tags={rashiBasic} />
                ) : (
                  <Tagify tags={basicBasic} />
                )}
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        {selectedPlanets.length ? (
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Description>
                  {selectedPlanets.map((planet, idx) =>
                    planetDetails(planet, idx)
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        ) : (
          ''
        )}
      </Grid>
      <Header floated="right">
        <Icon onClick={toggler} name="close" />
      </Header>
    </CombinationCardStyle>
  );
};

export default CombinationCard;
