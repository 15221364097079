import React, {
  // useState,
} from 'react';
import {
  Accordion,
  Message,
} from 'semantic-ui-react'

const rules = [
  {
    key: 'rashi',
    header: `Rashi (Signs) -> Lagna Kundali`,
    content: <Message.List>
      <Message.Item>Always check Rashi of a Planet from Lagna Chart (Kundali).</Message.Item>
      <Message.Item>Represent the different domains of out life.</Message.Item>
    </Message.List>,
  },{
    key: 'house',
    header: `Houses -> KP Cusp Kundali`,
    content: <Message.List>
      <Message.Item>Always check House and Planet placement from KP Cusp Chart (Kundali).</Message.Item>
    </Message.List>,
  },{
    key: 'planet',
    header: `Planet -> KP Cusp Kundali`,
    content: <Message.List>
      <Message.Item>Represent the different domains of out life.</Message.Item>
    </Message.List>,
  },{
    key: 'very-basic',
    header: `Very Basic Rule`,
    content: <Message.List>
      <Message.Item>Planet => Medium (माध्यम)</Message.Item>
      <Message.Item>Nakshatra Lord => Result (परिणाम): Results come through Medium</Message.Item>
      <Message.Item>Sub Lord => Quality (गुणवत्ता) of Result. Promised or Not</Message.Item>
    </Message.List>,
  },{
    key: '12th-house',
    header: `12th House`,
    content: <Message.List>
      <Message.Item>12th House from itself Negates its results</Message.Item>
      <Message.Item>हर घर से १२वा घर उस घर की हानी करता है</Message.Item>
      <Message.Item>
        <Message.List>
          <Message.Item>1st is exception</Message.Item>
          <Message.Item>10th is also exception to 11th</Message.Item>
        </Message.List>
      </Message.Item>
    </Message.List>,
  },{
    key: '2nd-house',
    header: `2nd House`,
    content: <Message.List>
      <Message.Item>2nd House from itself give Strainght to its results</Message.Item>
      <Message.Item>हर घर से २रा घर उस घर को शक्ती प्रदान् करता है</Message.Item>
    </Message.List>,
  },{
    key: 'untenanted-planet',
    header: `Untenanted Planet`,
    content: <Message.List>
      <Message.Item>Planet which is not Nakshatra Lord of any Planet</Message.Item>
      <Message.Item>जो ग्रह किसी ग्रह का नक्शत्र स्वामी नही है उसे Untenanted ग्रह कहते है</Message.Item>
      <Message.Item>Untenanted Planet as Cuspal Sub-lord </Message.Item>
    </Message.List>,
  },{
    key: 'independent-house',
    header: `Independent House`,
    content: <Message.List>
      <Message.Item>Houses owned by Untenanted Planet which are empty (No planet deposited)</Message.Item>
      <Message.Item>Untanented ग्रहो के स्वामित्व के स्थान जो खाली है</Message.Item>
    </Message.List>,
  },{
    key: 'group-of-house',
    header: `Group of Houses`,
    content: <Message.List>
      <Message.Item>Group of Houses comes together to fructify the event</Message.Item>
      <Message.Item>कुछ घरो का Group बनता है एक Event पुरा करने के लिये</Message.Item>
    </Message.List>,
  },{
    key: 'promise',
    header: `Event Promise`,
    content: <Message.List>
      <Message.Item>Cuspal Subload of any house will provide the information regarding the promise of an event related to that house</Message.Item>
      <Message.Item>
        <Message.List>
          <Message.Item>4th CSL - House /  Vehicle related promise</Message.Item>
          <Message.Item>10th CSL - Profession related promise</Message.Item>
        </Message.List>
      </Message.Item>
    </Message.List>,
  },{
    key: 'promise-rule',
    header: `Event Promise`,
    content: <Message.List>
      <Message.Item>Cuspal Subload of any house will provide the information regarding the promise of an event related to that house</Message.Item>
      <Message.Item>किसी भी घर का उप नक्षत्र स्वामी उस घर से संबंधित घटना के वादे के बारे में जानकारी प्रदान करेगा</Message.Item>
      <Message.Item>
        <Message.List>
          <Message.Item>4th CSL - House /  Vehicle related promise</Message.Item>
          <Message.Item>10th CSL - Profession related promise</Message.Item>
        </Message.List>
      </Message.Item>
    </Message.List>,
  },
];

const basicPanel = (activeIndex) => {
  return (
    <Accordion.Content active>
      {rules.map((rule) => {
        return <Message floating key={rule.key}>
          <Message.Header>
            {rule.header}
          </Message.Header>
          <Message.Content>
            {rule.content}
          </Message.Content>
        </Message>
      })}
    </Accordion.Content>
  );
};

export default basicPanel;
