import React, { useEffect, useState } from 'react';
import { Container, Label, TextArea } from 'semantic-ui-react';
// import styled from 'styled-components';

const {
  // jHoraSeq,
  jHoraSeqEng,
  // oldDashaSeq,
  newDashaSeq,
  // yoginis,
} = require('../../../data/yoginiDasha');

const splitString = (array) => {
  let valueFound;
  let keyValue;
  for (const key in array) {
    if (valueFound) break;
    if (Object.hasOwnProperty.call(array, key)) {
      const element = array[key];
      keyValue = key;
      valueFound = element && element.split(':')[0];
    }
  }
  return [valueFound, keyValue];
};

const YoginiDasha = (props) => {
  const [yoginiDashaData, setYoginiDashaData] = useState('');
  const [newDashaText, setNewDashaText] = useState([]);

  const textAreaChanged = (event, { value }) => setYoginiDashaData(value);

  useEffect(() => {
    if (yoginiDashaData) {
      const returnArray = [];
      const rows = yoginiDashaData.split('\n');
      for (const row in rows) {
        const [firstWord, firstWordIndex] = splitString(rows[row].split(' '));
        const indexOfJHoraSeq = jHoraSeqEng.indexOf(firstWord);
        const newDasaName = newDashaSeq[indexOfJHoraSeq]?.substr(0, 3);

        let insertRow = '';
        if (indexOfJHoraSeq === -1) {
          insertRow = rows[row];
        } else {
          insertRow = `${rows[row].substring(
            0,
            firstWordIndex
          )}${newDasaName}${rows[row].substring(
            parseInt(firstWordIndex) + parseInt(firstWord.length)
          )}`;
        }
        returnArray.push(insertRow);
        setNewDashaText(returnArray);
      }
    } else {
      setNewDashaText([]);
    }
  }, [yoginiDashaData]);

  return (
    <>
      <TextArea
        placeholder="Paste Jagannatha Hora Yogini Dasha text"
        style={{
          width: '100%',
          minHeight: 100,
        }}
        onChange={textAreaChanged}
      />

      {newDashaText.map((row, indx) => (
        <Container>
          <Label key={`${indx}-${row.substring(0, 3)}`}>{row}</Label>
        </Container>
      ))}
    </>
  );
};

export default YoginiDasha;
