import React, {
  useEffect,
} from 'react';
import {
  Tab,
} from 'semantic-ui-react'
import Rules from './KP/Rules';
import Numerology from './KP/Numerology';
import Houses from './KP/Houses';
import Planets from './KP/Planets';
import BTR from './KP/BTR';
import SubWiseDiseases from './KP/SubWiseDiseases';
import SubWiseMindset from './KP/SubWiseMindset';

import './table.css';

const panes = [
  {
    menuItem: 'Houses',
    render: () => <Tab.Pane>
      <Houses />
    </Tab.Pane>
  }, {
    menuItem: 'Planets',
    render: () => <Tab.Pane>
      <Planets />
    </Tab.Pane>
  }, {
    menuItem: 'BTR',
    render: () => <Tab.Pane>
      <BTR />
    </Tab.Pane>
  }, {
    menuItem: 'Numerology',
    render: () => <Tab.Pane>
      <Numerology />
    </Tab.Pane>
  }, {
    menuItem: 'Rules',
    render: () => <Tab.Pane>
      <Rules />
    </Tab.Pane>
  }, {
    menuItem: 'Sub Wise Diseases',
    render: () => <Tab.Pane>
      <SubWiseDiseases />
    </Tab.Pane>
  }, {
    menuItem: 'Sub Wise Mindset',
    render: () => <Tab.Pane>
      <SubWiseMindset />
    </Tab.Pane>
  },
];
const KP = () => {

  useEffect(() => {
    document.title = 'KP'
  }, []);

  return (
    <div>
      <h2>Krishnamurti Paddhati</h2>
      <Tab panes={panes} />
    </div>
  )
};

export default KP;
