import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import useDarkMode from 'use-dark-mode';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import AddressBar from './AddressBar';

import { NakshatraContext } from '../contexts/nakshatra.context';

const BirthDetailsFormStyle = styled.div`
  .sub.header {
    // prettier-ignore
    margin: 1em;
    color: ${({ darkModeValue }) => (darkModeValue ? '#e0e1e2' : '#54544c')};
  }
  .react-datepicker-wrapper {
    display: block;
    max-width: 20rem;
  }
  .react-datepicker__triangle {
    color: ${({ darkModeValue }) => (darkModeValue ? '#999' : '#eef4f7')};
  }
  .react-datepicker__header {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? '#999' : '#eef4f7'};
  }
  .react-datepicker__time {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? '#999' : '#eef4f7'};
  }
  .react-datepicker__month-container {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? '#999' : '#eef4f7'};
  }
  .react-datepicker__time-container {
    background-color: ${({ darkModeValue }) =>
      darkModeValue ? '#999' : '#eef4f7'};
  }
`;

const BirthDetailsForm = ({ dateFun, geoFun, timeFun }) => {
  const { value: darkModeValue } = useDarkMode();
  const {
    dispatch,
    state: {
      birthDetails: { birthDate },
    },
  } = useContext(NakshatraContext);

  const [startDate, setStartDate] = useState(new Date(birthDate));

  useEffect(() => {
    dispatch({
      type: 'BIRTH_DETAILS_SET_BIRTH_DATE',
      payload: {
        birthDate: startDate,
      },
    });
  }, [startDate, dispatch]);

  const handleDateTimeChange = (date) => {
    const datedValue = new Date(date);
    dateFun(datedValue);
    timeFun(datedValue.getTime());
    setStartDate(datedValue);
  };

  return (
    <BirthDetailsFormStyle darkModeValue={darkModeValue}>
      <Form>
        <Header>Birth Details</Header>
        <Header.Subheader>Date & Time of Birth</Header.Subheader>
        <DatePicker
          selected={startDate}
          onChange={handleDateTimeChange}
          showTimeSelect
          timeIntervals={1}
          timeFormat="HH:mm aa"
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
        <AddressBar geoFun={geoFun} />
        <Button type="submit" className="btn btn-primary">
          Generate
        </Button>
      </Form>
    </BirthDetailsFormStyle>
  );
};

export default BirthDetailsForm;
