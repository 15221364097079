import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import useDarkMode from 'use-dark-mode';

import 'semantic-ui-css/semantic.min.css';

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const RenderDashasStyle = styled.div`
  .ui.styled.accordion .title {
    color: ${({ darkModeValue }) => (darkModeValue ? '#e0e1e2' : '#54544c')};
  }
`;

export const RenderYoginiDasha = ({ dashas }) => {
  const { value: darkModeValue } = useDarkMode();

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTitleClick = (e, data) => {
    setActiveIndex(data?.index);
  };

  return (
    <RenderDashasStyle darkModeValue={darkModeValue}>
      <Accordion styled>
        {dashas.map(({ name, lordName, endsOn }, inx) =>
          lordName ? (
            <React.Fragment key={inx}>
              <Accordion.Title
                active={activeIndex === inx}
                index={inx}
                onClick={handleTitleClick}>
                <Icon name="dropdown" />
                {lordName?.substring(0, 3)}: ends on{' '}
                {endsOn && endsOn instanceof Date
                  ? endsOn.toDateString('en-UK', dateOptions)
                  : endsOn}{' '}
                {`${name?.charAt(0).toUpperCase()}${name?.slice(1)}`}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === inx}>
                {dashas[inx + 1].map(({ name, lordName, endsOn }) => (
                  <p key={`p-${name}-$(inx)`} size="small">
                    {`${lordName.substring(0, 3)}: ends on ${
                      endsOn && endsOn instanceof Date
                        ? endsOn.toDateString('en-UK', dateOptions)
                        : endsOn
                    } ${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                  </p>
                ))}
              </Accordion.Content>
            </React.Fragment>
          ) : (
            ''
          )
        )}
      </Accordion>
    </RenderDashasStyle>
  );
};
