module.exports = Object.freeze({
  a: {
    number: 1,
    day: 'Sunday',
  },
  b: {
    number: 2,
    day: 'Monday',
  },
  c: {
    number: 3,
    day: 'Thursday',
  },
  d: {
    number: 4,
    day: 'Sunday',
  },
  e: {
    number: 5,
    day: 'Wednessday',
  },
  f: {
    number: 8,
    day: 'Saturday',
  },
  g: {
    number: 3,
    day: 'Thursday',
  },
  h:  {
    number: 5,
    day: 'Wednessday',
  },
  i: {
    number: 1,
    day: 'Sunday',
  },
  j: {
    number: 1,
    day: 'Sunday',
  },
  k: {
    number: 2,
    day: 'Monday',
  },
  l: {
    number: 3,
    day: 'Thursday',
  },
  m: {
    number: 4,
    day: 'Sunday',
  },
  n:  {
    number: 5,
    day: 'Wednessday',
  },
  o: {
    number: 7,
    day: 'Monday',
  },
  p: {
    number: 8,
    day: 'Saturday',
  },
  q: {
    number: 1,
    day: 'Sunday',
  },
  r: {
    number: 2,
    day: 'Monday',
  },
  s: {
    number: 3,
    day: 'Thursday',
  },
  t: {
    number: 4,
    day: 'Sunday',
  },
  u:  {
    number: 6,
    day: 'Friday',
  },
  v: {
    number: 6,
    day: 'Friday',
  },
  w: {
    number: 6,
    day: 'Friday',
  },
  x:  {
    number: 5,
    day: 'Wednessday',
  },
  y: {
    number: 1,
    day: 'Sunday',
  },
  z: {
    number: 7,
    day: 'Monday',
  },
  9: {
    number: 9,
    day: 'Tuesday',
  },
  0: {
    number: 0,
    day: 'Tuesday',
  },
})
