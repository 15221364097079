import React from 'react';
import {
  Divider,
  Grid,
  Label,
  Message,
} from 'semantic-ui-react'
import ReactStars from 'react-rating-stars-component';
import {
  includeNumbers,
} from './calculations';

const numberCharacteristics = require('./../../../data/numerology/numberCharacteristics')
const compatibility = require('./../../../data/numerology/compatibility');
const punchLines = require('./../../../data/numerology/punchLines');
const personalCharacteristics = require('./../../../data/numerology/personal')
const combinations = require('./../../../data/numerology/combinations')
const repeatNumbers = require('./../../../data/numerology/repeatNumbers')
const professions = require('./../../../data/numerology/professions')

const Entity = ({
  name,
  number,
}) => {
  const entityCharacteristics = numberCharacteristics[number];
  const entityCompatibility = compatibility[number];
  const {
    avoid,
    suitable,
  } = professions[number];
  const avoidProfessions = [];
  avoid.split(',').map((item) => {
    const result = parseInt(item);
    if (isNaN(result)) {
      avoidProfessions.push(item);
    } else {
      const { suitable: avoidProfes } = professions[item] || {};
      avoidProfessions.push(avoidProfes);
    }
    return null;
  });

  return(
    <Message
        style={{
          padding: '.1em 1.5em'
        }}
      >
      <Message.Header>
        <p>{`${name} - ${number}`}</p>
        <p>
          {entityCharacteristics && `${entityCharacteristics.tagline} ${entityCharacteristics.numbers.join(' | ')}`}
        </p>
      </Message.Header>
      <Message.List>{entityCharacteristics && entityCharacteristics.owners.join(' | ')} | {entityCharacteristics && entityCharacteristics.dayOfWeek}</Message.List>
      <Message.List>{entityCharacteristics && entityCharacteristics.qualities.join(' | ')}</Message.List>
      <Message.List>{entityCharacteristics && entityCharacteristics.shortcharacters}</Message.List>
      <Message.List>{entityCharacteristics && entityCharacteristics.characters}</Message.List>
      <Message.List>
        <Label>{number && entityCompatibility && `Best Numbers: ${entityCompatibility.best}`}</Label>
        <Label>{number && entityCompatibility && `Neutral Numbers: ${entityCompatibility.neutral}`}</Label>
        <Label>{number && entityCompatibility && `Avoid Numbers: ${entityCompatibility.avoid}`}</Label>
      </Message.List>
      <Message.List>
        <Label>{number && `Representing Colors: ${entityCharacteristics.representingColors}`}</Label>
        <Label>{number && `Avoid Colors: ${entityCharacteristics.avoidColors}`}</Label>
      </Message.List>
      <Message.List>
        {suitable && `Suitable Professions:`}
        {suitable && <Label>{suitable}</Label>}
        {avoidProfessions && `Avoid Professions:`}
        {avoidProfessions && <Label>{avoidProfessions}</Label>}
      </Message.List>
    </Message>
  );
};

const Personal = ({
  name,
  year,
  yearNumber,
}) => {
  const characteristics = personalCharacteristics[yearNumber];

  return(
    <Message
        style={{
          padding: '.1em 1.5em'
        }}
      >
      <Message.Header>
        {`${name} ${year} #${yearNumber}`}
      </Message.Header>
      {characteristics && <Message.List>{characteristics.year}</Message.List>}
    </Message>
  );
};

const Phone = ({
  phoneNumber,
  phoneNumberSingled,
}) => {
  const negativeNumbers = includeNumbers(phoneNumber, [2, 4, 8]);

  return(
    <React.Fragment>
      <Divider horizontal>
          <Label>Phone Number Compatibility</Label>
      </Divider>
      <Message
        style={{
          padding: '.1em 1.5em'
        }}
      >
        <Message.Header>
          <Label>{`Phone Number #${phoneNumber}`}</Label>
        </Message.Header>
        <Message.Header>
          <Label>{`Your phone number sum is: ${phoneNumberSingled}`}</Label>
        </Message.Header>
        {/*<Message.Content>
          <Label>
            <Message.List>
              The best mobile number sum result is 5 then 6 or 1
            </Message.List>
          </Label>
          <Label>
            <Message.List>
              Your mobile number sum result is good
            </Message.List>
          </Label>
          <Label>
            <Message.List>
              Your mobile number sum result is good, but try making it to 5(Best) or 6(Good)
            </Message.List>
          </Label>
          <Label>
            <Message.List>
              Your mobile number sum result is not good, try making it to 5(Best) or 6(Good) or 1(Good)
            </Message.List>
          </Label>
        </Message.Content>*/}
        <Message.Header>
          <Label>{`Negative Number in your phone number`}</Label>
        </Message.Header>
        {(negativeNumbers && negativeNumbers.use === false) ? <Message.Content>
          <Label>
            You have following negative numbers in your phone number
            <Message.List>
              {negativeNumbers.forNumbers.join(', ')}
            </Message.List>
          </Label>
        </Message.Content> : <Message.Content>
          <Label>
            <Message.List>
              Congratulations you have no negative digits in your mobile number
            </Message.List>
          </Label>
        </Message.Content>}
      </Message>
    </React.Fragment>
  )
};

const Analysis = ({
  // loShuPlanesData,
  // nameNumber,
  alphabetNumberSum,
  alphabetsNumbers,
  conductorNumber,
  driverNumber,
  kuaNumber,
  loShuGridData,
  personalBirthYear,
  personalBirthYearNumber,
  personalCustomYear,
  personalCustomYearNumber,
  personalMonths,
  phoneNumber,
  phoneNumberSingled,
}) => {
  const driverConductorNumber = parseInt(`${driverNumber}${conductorNumber}`);
  const punchLine = punchLines[driverConductorNumber];
  const combination = combinations[driverConductorNumber];
  const repeatNumbersLoShu = {};

  if (loShuGridData) {
    Object.keys(loShuGridData).map(element => {
      if (loShuGridData[element].entries.length) {
        repeatNumbersLoShu[element] = loShuGridData[element].entries;
      }
      return null;
    })
  }

  if (driverNumber) {
    if (repeatNumbersLoShu[driverNumber]) {
      repeatNumbersLoShu[driverNumber] = [...repeatNumbersLoShu[driverNumber], driverNumber];
    } else {
      repeatNumbersLoShu[driverNumber] = [driverNumber];
    }
  }

  if (conductorNumber) {
    if (repeatNumbersLoShu[conductorNumber]) {
      repeatNumbersLoShu[conductorNumber] = [...repeatNumbersLoShu[conductorNumber], conductorNumber];
    } else {
      repeatNumbersLoShu[conductorNumber] = [conductorNumber];
    }
  }

  if (kuaNumber) {
    if (repeatNumbersLoShu[kuaNumber]) {
      repeatNumbersLoShu[kuaNumber] = [...repeatNumbersLoShu[kuaNumber], kuaNumber];
    } else {
      repeatNumbersLoShu[kuaNumber] = [kuaNumber];
    }
  }

  let repeatNumbersAnalysis = [];
  repeatNumbersAnalysis = Object.keys(repeatNumbersLoShu).map(repeats => {
    const preRepeatNumber = (typeof(repeatNumbersLoShu[repeats]) !== 'object') ? repeatNumbersLoShu[repeats] : repeatNumbersLoShu[repeats].join('');
    const repeatIndex = parseInt(preRepeatNumber);
    if (!isNaN(repeatIndex)) {
      return {
        [repeatIndex]: repeatNumbers[repeatIndex]
      };
    } else {
      return null;
    }
  }).filter(element => element ? true : false );

  return(<Grid>
    <Grid.Column>
      {combination && <Message
        style={{
          padding: '.1em 1.5em'
        }}
      >
        <Message.Header>
          {`${driverNumber} - ${conductorNumber}`}
          <ReactStars
            activeColor='#ffd700'
            count={5}
            edit={false}
            isHalf={true}
            size={24}
            value={combination.stars}
          />
          {`${combination.result} | ${combination.percent}% | ${combination.combination}`}
        </Message.Header>
      </Message>}
      {punchLine && <Message
        style={{
          padding: '.1em 1.5em'
        }}
      >
        <Message.Header>
          <p>Punch Line</p>
        </Message.Header>
        <Message.List>
          <Message.Item>{punchLine}</Message.Item>
        </Message.List>
      </Message>}
      {driverNumber && <Entity
        name='Driver'
        number={driverNumber}
      />}
      {conductorNumber && <Entity
        name='Conductor'
        number={conductorNumber}
      />}
      {kuaNumber && <Entity
        name='Kua'
        number={kuaNumber}
      />}
      {/*<Divider horizontal>
        <Label>Name or Number</Label>
      </Divider>*/}
      {phoneNumber && <Phone
        phoneNumber={phoneNumber}
        phoneNumberSingled={phoneNumberSingled}
      />}
      <Divider horizontal>
        <Label>Repeating Numbers</Label>
      </Divider>
      {repeatNumbersAnalysis && repeatNumbersAnalysis.map((analysis, index) => <Message
        key={index}
        style={{
          padding: '.1em 1.5em'
        }}
      >
        {Object.keys(analysis).map(key => {
          return <Message.Content
            key={key}
          >
            <Message.Header>
              {key}
            </Message.Header>
            <Message.List>{analysis[key]}</Message.List>
          </Message.Content>
        })}
      </Message>)}
      <Divider horizontal>
        <Label>Year</Label>
      </Divider>
      {personalBirthYear && <Personal
        name='Personal Birth Year'
        year={personalBirthYear}
        yearNumber={personalBirthYearNumber}
      />}
      {personalCustomYear && <Personal
        name='Personal Year for'
        year={personalCustomYear}
        yearNumber={personalCustomYearNumber}
      />}
      {personalMonths && personalMonths.map((month, index) => <Personal
        key={index}
        name={personalCustomYear}
        year={month.name}
        yearNumber={month.number}
      />)}
    </Grid.Column>
  </Grid>)
};

export default Analysis;

