import React, { useContext, useEffect, useState } from "react";
import { Label, Step, Table } from "semantic-ui-react";

import BirthDetailsForm from "./BirthDetailsForm";
import { RenderYoginiDasha } from "./RenderYoginiDasha";
import { NakshatraContext } from "../contexts/nakshatra.context";

import { degree2Angle, yoginiDasha } from "../engine/helper";

import "semantic-ui-css/semantic.min.css";

const { calculate } = require("./../engine/core");

const columns = [
  {
    Header: "Property",
    accessor: "property",
    width: 150,
  },
  {
    Header: "Value",
    accessor: "propertyValue",
  },
];

const toDegreesMinutesAndSeconds = (coordinate) => {
  let absolute = Math.abs(coordinate);
  let degrees = Math.floor(absolute);
  let minutesNotTruncated = (absolute - degrees) * 60;
  let minutes = Math.floor(minutesNotTruncated);
  let seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + " " + minutes + " " + seconds;
};

const convertDMS = (lat, lng) => {
  let latitude = toDegreesMinutesAndSeconds(lat);
  let latitudeCardinal = Math.sign(lat) >= 0 ? "N" : "S";

  let longitude = toDegreesMinutesAndSeconds(lng);
  let longitudeCardinal = Math.sign(lng) >= 0 ? "E" : "W";

  return (
    latitude +
    " " +
    latitudeCardinal +
    "\n" +
    longitude +
    " " +
    longitudeCardinal
  );
};

const ChartDetails = () => {
  const {
    dispatch,
    state: {
      birthDetails: { birthDate: birthDateFromState },
    },
  } = useContext(NakshatraContext);

  const DST = false;
  const [birthDate, setBirthDate] = useState(new Date(birthDateFromState));

  const [birthTime, setBirthTime] = useState(
    new Date(birthDateFromState).getTime()
  );
  const [geoOfBirthPlace, setGeoOfBirthPlace] = useState({
    latitude: "",
    longitude: "",
    address: "",
  });

  const [chartDetailsLoaded, setChartDetailsLoaded] = useState(false);

  let yoginiDashaResult;

  useEffect(() => {
    setChartDetailsLoaded(true);
  }, []);

  useEffect(() => {
    if (chartDetailsLoaded) {
      dispatch({
        type: "BIRTH_DETAILS_SET_BIRTH_DATE",
        payload: {
          birthDate: new Date(birthDate),
        },
      });
    }
  }, [birthDate, chartDetailsLoaded, dispatch]);

  let year = new Date(birthDate).getFullYear();
  if (year < 1800 || year > 2100) {
    year = 1980;
  }
  let month = new Date(birthDate).getMonth() + 1;
  if (month < 1 || month > 12) {
    month = 7;
  }
  let day = new Date(birthDate).getDate();
  if (day < 1 || day > 31) {
    day = 2;
  }

  const timeOfBirth = new Date(birthTime);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const timeAmPmString = new Intl.DateTimeFormat("en-US", options).format(
    timeOfBirth
  );
  console.log(timeAmPmString);

  let hour = timeOfBirth.getHours();
  let minute = timeOfBirth.getMinutes();

  let inputLunarCalc = {
    birthDay: day,
    birthMonth: month,
    birthYear: year,
    birthHour: hour,
    birthMinute: minute,
    birthTimeZone: 5.5,
    DST,
  };

  const astroCoreData = calculate(inputLunarCalc);
  console.log("astroCoreData", astroCoreData);
  const astroDetails = [];

  for (let propertyName in astroCoreData) {
    if (propertyName === "moonAngle") {
      const { degree, minutes, seconds, rashiIndex } = degree2Angle(
        astroCoreData["moonRemainingNakshatra"]
      );

      yoginiDashaResult = yoginiDasha(
        {
          degrees: degree,
          minutes,
          seconds,
        },
        rashiIndex,
        year,
        month,
        day
      );
    }

    astroDetails.push({
      property: propertyName,
      propertyValue: astroCoreData[propertyName].toString(),
    });
  }

  return (
    <div className="ChartDetails">
      <BirthDetailsForm
        dateOfBirth={birthDate}
        dateFun={setBirthDate}
        timeFun={setBirthTime}
        geoFun={setGeoOfBirthPlace}
      />
      <Step.Group size="tiny">
        <Step>
          <Step.Content>
            <Step.Title>{birthDate.toDateString()}</Step.Title>
            <Step.Description>Birth Date</Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Step.Content>
            <Step.Title>{timeAmPmString}</Step.Title>
            <Step.Description>Birth Time</Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Step.Content>
            <Step.Title>{geoOfBirthPlace.latitude}</Step.Title>
            <Step.Title>{geoOfBirthPlace.longitude}</Step.Title>
            <Step.Description>Co-ordinates</Step.Description>
            <Step.Title>
              {convertDMS(geoOfBirthPlace.latitude, geoOfBirthPlace.longitude)}
            </Step.Title>
          </Step.Content>
        </Step>
        <Step>
          <Step.Content>
            <Step.Title>{geoOfBirthPlace.address}</Step.Title>
            <Step.Description>Address</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {columns.map((col) => (
              <Table.HeaderCell key={col.Header}>{col.Header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {astroDetails.map((cel) => (
            <Table.Row key={cel.property}>
              <Table.Cell>{cel.property}</Table.Cell>
              <Table.Cell>{cel.propertyValue}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {yoginiDashaResult?.dashaList?.length ? (
        <>
          <Label size="tiny">Yogini Maha Dasha</Label>
          <RenderYoginiDasha dashas={yoginiDashaResult.dashaList} />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ChartDetails;
