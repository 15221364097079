import { useCallback, useLayoutEffect, useState } from 'react';

const getSize = (element) => {
  if (!element) {
    return {
      width: 0,
      height: 0,
    };
  }
  const clientRect = element.getBoundingClientRect();
  const { innerHeight } = window;

  return {
    width: innerHeight - clientRect.y, //element.offsetWidth,
    height: innerHeight - clientRect.y, //element.offsetHeight,
  };
};

const useComponentSize = (ref) => {
  const [componentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current));
      }
    },
    [ref, setComponentSize]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const currentRef = ref.current;

    handleResize();

    if (typeof ResizeObserver === 'function') {
      var resizeObserver = new ResizeObserver(function () {
        handleResize();
      });
      resizeObserver.observe(currentRef);

      return function () {
        resizeObserver.disconnect(currentRef);
        resizeObserver = null;
      };
    } else {
      window.addEventListener('resize', handleResize);

      return function () {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ref, handleResize]);

  return componentSize;
};

export default useComponentSize;
