import React, { useState } from 'react';
import { Header, Modal, Segment } from 'semantic-ui-react';

const numberCharacteristics = require('./../../../data/numerology/numberCharacteristics');
const personalCharacteristics = require('./../../../data/numerology/personal');
const compatibility = require('./../../../data/numerology/compatibility');
const combinations = require('./../../../data/numerology/combinations');

const PortalElement = ({ number, personal, triggerNode }) => {
  const [isShown, setIsShown] = useState(false);
  const characteristics = number && numberCharacteristics[number];
  const combination = combinations[number];
  const compatibilities = number && compatibility[number];

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  return (
    number && (
      <Modal
        size="fullscreen"
        trigger={triggerNode}
        open={isShown}
        centered={false}
        closeIcon
        onClose={toggler}
        onOpen={toggler}
      >
        {personal ? (
          <Segment>
            <Header>{number}</Header>
            <p>
              {personalCharacteristics[number] &&
                personalCharacteristics[number].year}
            </p>
          </Segment>
        ) : (
          characteristics && (
            <Segment>
              <Header>{`${number} ${
                characteristics.tagline
              } ${characteristics.numbers.join(' | ')}`}</Header>
              <p>
                {characteristics.owners.join(' | ')} |{' '}
                {characteristics.dayOfWeek}
              </p>
              <p>{characteristics.qualities.join(' | ')}</p>
              <p>{characteristics.shortcharacters}</p>
              <p>{characteristics.characters}</p>
              <p>{`Best Numbers: ${compatibilities.best}`}</p>
              <p>{`Neutral Numbers: ${compatibilities.neutral}`}</p>
              <p>{`Avoid Numbers: ${compatibilities.avoid}`}</p>
              <p>{`Representing Colors: ${characteristics.representingColors}`}</p>
              <p>{`Avoid Colors: ${characteristics.avoidColors}`}</p>
            </Segment>
          )
        )}
        {combination && (
          <Segment>
            <Header>{number}</Header>
            <p>{` ≈ ${combination.result} ≈ ${combination.percent}% =`}</p>
            <p>{combination.combination}</p>
            <p>{combination.compountNumbers}</p>
            <p>{combination.compountHindi}</p>
            <p>{combination.compoundMarathi}</p>
          </Segment>
        )}
      </Modal>
    )
  );
};

export default PortalElement;
