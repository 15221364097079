import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
const sublordWiseMindset = require('../../../data/rules/sublordWiseMindset');

const SublordWiseMindset = () => {

  return sublordWiseMindset.default ? (
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Sign</Th>
          <Th>Star</Th>
          <Th>Sub</Th>
          <Th>Mindset</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sublordWiseMindset.default.map((disease, index) => (
          <Tr key={index}>
            <Td>{disease["Sub No."]}</Td>
            <Td>{disease["Sign Lord"]}</Td>
            <Td>{disease["Star lord"]}</Td>
            <Td>{disease["Sub lord"]}</Td>
            <Td>{disease["3rd Mindset"]}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : null;
};

export default SublordWiseMindset;
