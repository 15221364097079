module.exports = Object.freeze({
  14: `rank se raja will reach to heights and success in this life.`,
  18: `health and married life?(relationship with father and spouce????)`,
  19: `param yog, sab kuch hassil hoga is life mein.`,
  24: `shadd1?; (female problems.`,
  26: `shadd1?;(female) relationship(husband/witfe)`,
  28: `niraash zindagi,tension, hurdles in life mehnat jyada result kum. 2*9= shaddi???(male)`,
  36: `36 ka aankada,professionaly very good but marriage disturb(male only).`,
  37: `high education,might become govt official,high post all bcause of good studies.`,
  41: `rank se raja`,
  42: `professional struggle whole life`,
  44: `professional struggle no settlement 4*’7= over all achhevment success in life.`,
  48: `professional struggle, no settlement 4*9= health problems( always hit health)`,
  58: `birth ke baad parents ne and even himself property banane ya yog hai`,
  62: `professional struggle, married life disturbed`,
  63: `Shadi?????? professional life good only for males`,
  66: `additional martial affairs before and after marriage 6*9= controveries in life always`,
  73: `sqme as 3* 7/7 combo T*A= sqme as 4*7 combo`,
  77: `dhokha!!! life ki har stage par dhokha, married life???;health disturbed, no partnership at all in life.`,
  81: `no professional stability, father son relationship disturbed $*2= health and family life disturbed`,
  84: `highly struggle in life`,
  85: `properties jaroor purchase ki honge after birth`,
  88: `super duper thuper struggle and married life disturbed $*9= health and professional struggle.`,
  91: `same as |-9.`,
  92: `struggle whole life, married life??? health problems kitne abhi tak’???`,
  94: `health+surgeries kitne hue aaj tak 9*6= controveries whole life`,
  98: `professional struggle *health problems`,
  99: `jitna prem diya hoga vapisi1 mein kuch nahin mila hoga, shadi late, married life disturbed. Born intelligent, hawa mein baatein nahin karte. Always practical and always use to take firm &practical decisions.`,
});
