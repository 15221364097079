import React from 'react';

const DarkModeSVG = ({ onChange }) =>{
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='0.5'
      className='dark-mode-svg'
      viewBox='0 0 24 24'
      onClick={onChange}
    >
      <path stroke='none' d='M0 0h24v24H0z'></path>
      <path d='M16.2 4a9.03 9.03 0 103.9 12 6.5 6.5 0 11-3.9-12'></path>
    </svg>
  );
};

export default DarkModeSVG;
