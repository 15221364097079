import React, {
  useEffect,
  useState
} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import {
  Modal,
} from 'semantic-ui-react'
import PlanetCapacity from './Rashi/PlanetCapacity';
import RashiCardContent from './Rashi/RashiCardContent';
import TrikonYog from './Rashi/TrikonYog';
import MiscSignifications from './Rashi/MiscSignifications';

import './table.css';

const rashis = require('./../../data/rashis')

const ToggleContent = ({ rashi }) => {
  const [isShown, setIsShown] = useState(false)
  const {
    aspects: {
      aspectNumbers,
    },
    debilitated,
    exaltPlanet,
    gender,
    id,
    nakshatraOwners,
    name,
    nature,
    sunTransits,
    tenor,
    vedicName,
  } = rashi;
  const toggler = (e) => {
    if (isShown) {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  return (
    <Modal
      size='fullscreen'
      key={id}
      trigger={<Tr
        key={id}
        onClick={toggler}
      >
        <Td>{id}</Td>
        <Td>{`${name} - ${vedicName}`}</Td>
        <Td>{sunTransits}</Td>
        <Td>{exaltPlanet}</Td>
        <Td>{debilitated}</Td>
        <Td>{aspectNumbers.join(', ')}</Td>
        <Td>{nakshatraOwners}</Td>
        <Td>{gender}</Td>
        <Td>{nature}</Td>
        <Td>{tenor}</Td>
      </Tr>}
      open={isShown}
      centered={false}>
      <RashiCardContent
        rashi={rashi}
        toggler={toggler}
      />
    </Modal>
  )
}

const Rashi = () => {

  useEffect(() => {
    document.title = 'Rashi'
  }, []);

  return (
    <div>
      <h2>Rashi</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>No.</Th>
            <Th>Name</Th>
            <Th>Sun Transits</Th>
            <Th>Exalt (ऊच्च)</Th>
            <Th>Debilitated (दुर्बळ)</Th>
            <Th>Aspects</Th>
            <Th>Nak Owners</Th>
            <Th>Gender</Th>
            <Th>Nature</Th>
            <Th>Tenor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rashis.map(rashi => <ToggleContent key={rashi.id} rashi={rashi} />)}
        </Tbody>
      </Table>
      <PlanetCapacity />
      <TrikonYog />
      <MiscSignifications />
    </div>
  )
};

export default Rashi
