import React, { useReducer, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";

import { withRouter } from "./withRouter";

import Home from "./lib/components/Home";
import Bhrigu from "./lib/components/Bhrigu";
import Chart from "./lib/components/Chart";
import Ghaars from "./lib/components/Ghaars";
import Graha from "./lib/components/Graha";
import KP from "./lib/components/KP";
import Name from "./lib/components/Name";
import Nakshatra from "./lib/components/Nakshatra";
import Slider from "./lib/components/Slider";
import NakshatraChakra from "./lib/components/NakshatraChakra";
import Nakshatrified from "./lib/components/Nakshatrified";
import Numerology from "./lib/components/Numerology";
import Rashi from "./lib/components/Rashi";
import VimshottariSubs from "./lib/components/VimshottariSubs";

import { initialState, reducer } from "./lib/contexts/reducers";
import { GlobalStyles } from "./lib/components/styles/GlobalStyles";
import { lightTheme, darkTheme } from "./lib/components/styles/Themes";

import { NakshatraProvider } from "./lib/contexts/nakshatra.context";

import DarkModeToggle from "./lib/components/DarkModeToggle";

import { MainMenu } from "./lib/components/Menu";

import "semantic-ui-css/semantic.min.css";

const App = ({ history }) => {
  const [theme] = useState("light");
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const routeItems = [
    { id: 0, component: Home, path: "/" },
    { id: 1, component: Chart, path: "/Chart" },
    { id: 2, component: Nakshatra, path: "/Nakshatra" },
    { id: 3, component: Graha, path: "/Graha" },
    { id: 4, component: Rashi, path: "/Rashi" },
    { id: 5, component: NakshatraChakra, path: "/NakshatraChakra" },
    { id: 6, component: Nakshatrified, path: "/Nakshatrified" },
    { id: 7, component: Slider, path: "/Slider" },
    { id: 8, component: VimshottariSubs, path: "/VimshottariSubs" },
    { id: 9, component: Ghaars, path: "/Houses" },
    { id: 10, component: KP, path: "/KP" },
    { id: 11, component: Bhrigu, path: "/Bhrigu" },
    { id: 12, component: Numerology, path: "/Numerology" },
    { id: 13, component: Name, path: "/Name" },
  ];

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      <NakshatraProvider
        className="App"
        value={{
          dispatch,
          state,
        }}
      >
        <DarkModeToggle themeSelected={theme} />
        <header className="App-header">
          <MainMenu location={location} />
        </header>
        <div>
          <Routes location={location} key={location.pathname}>
            {routeItems.map((routeItem, indx) => {
              return (
                <Route
                  key={routeItem.path}
                  exact
                  path={routeItem.path}
                  element={<routeItem.component onClick={indx + 1} id={indx} />}
                />
              );
            })}
          </Routes>
        </div>
      </NakshatraProvider>
    </ThemeProvider>
  );
};

export default withRouter(App);
