import { useEffect } from 'react'

const useKeyboardEvent = (key, callback) => {
  useEffect(() => {
    const handler = function(event) {
      if (event.key === key) {
        callback()
      }
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])
};

export default useKeyboardEvent;
