import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Label } from "semantic-ui-react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import useDarkMode from "use-dark-mode";

import YearMonth from "./../YearMonth";
import { TagLabelStyle } from '../../engine/helper';

import { houseYears } from "./../../engine/constants/bcp";

const BCP = () => {
  const { value: darkModeValue } = useDarkMode();
  const [selectedBirthYear, setSelectedBirthYear] =
    useState("Select Birth year");
  const [selectedBirthMonth, setSelectedBirthMonth] =
    useState("Select Birth month");

  const eventYear = new Date().getFullYear();
  const eventMonth = new Date().getMonth() + 1;

  const [selectedEventYear, setSelectedEventYear] = useState(eventYear);
  const [selectedEventMonth, setSelectedEventMonth] = useState(eventMonth);

  const [activeHouse, setActiveHouse] = useState(0);
  const [completedAge, setCompletedAge] = useState(0);
  const [runningYear, setRunningYear] = useState(0);

  const [yearsPerHouse, setYearsPerHouse] = useState([]);

  useEffect(() => {
    document.title = "BCP";
  }, []);

  const yearPerHouseCallback = useCallback(() => {
    const yearsPerHouse = {};
    Object.keys(houseYears).forEach((key) => {
      yearsPerHouse[key] = houseYears[key].map(
        (year) => year + selectedBirthYear - 1
      );
    });
    setYearsPerHouse(yearsPerHouse);
  }, [selectedBirthYear, setYearsPerHouse]);

  useMemo(
    () => yearPerHouseCallback(),
    [yearPerHouseCallback]
  );

  useEffect(() => {
    const birthDate = new Date(selectedBirthYear, selectedBirthMonth - 1);
    const eventDate = new Date(selectedEventYear, selectedEventMonth - 1);
    let age = eventDate.getFullYear() - birthDate.getFullYear();
    if (
      eventDate.getMonth() < birthDate.getMonth() ||
      (eventDate.getMonth() === birthDate.getMonth() &&
        eventDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setCompletedAge(age);
    setRunningYear(age + 1);
    setActiveHouse((age + 1) % 12);
  }, [
    selectedBirthYear,
    selectedBirthMonth,
    selectedEventYear,
    selectedEventMonth,
    setCompletedAge,
  ]);

  return (
    <div>
      <h3>Brigu Chakra Paddhati</h3>
      <div>
        <YearMonth
          label="Birth Month & Year"
          onYearChange={setSelectedBirthYear}
          onMonthChange={setSelectedBirthMonth}
        />
      </div>
      <div>
        <YearMonth
          label="Event Month & Year"
          onYearChange={setSelectedEventYear}
          onMonthChange={setSelectedEventMonth}
        />
      </div>
      <div>
        <Label>Completed age: {completedAge} years</Label>
        <Label>Running year: {runningYear}</Label>
        <Label>Active house: {activeHouse}</Label>
      </div>
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th>House</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
              <Th>Years Cycle Lord</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key='0-subheader'>
              <Td>#</Td>
              <Td>Mon</Td>
              <Td>Mer</Td>
              <Td>Ven</Td>
              <Td>Sun</Td>
              <Td>Mar</Td>
              <Td>Jup</Td>
              <Td>Sat</Td>
              <Td>Rah</Td>
              <Td>Ket</Td>
            </Tr>
            {Object.keys(houseYears).map((key) => {
              return (
                <Tr key={key}>
                  <Td>{key}</Td>
                  {yearsPerHouse[key]?.map((year, index) => (
                    <Td key={`${key}-${index}`}>
                      <TagLabelStyle
                        key={`${key}-${index}`}
                        content={null}
                        darkModeValue={
                          selectedEventYear === year ? !darkModeValue : darkModeValue}
                      >
                        {year.toString()}
                      </TagLabelStyle>
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default BCP;
