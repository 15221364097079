export default Object.freeze([
 {
   "Sub No.": "1",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"46'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Fears - Anxiety - Jealousy "
},
 {
   "Sub No.": "2",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "3rd Mindset": "worried about other women"
},
 {
   "Sub No.": "3",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "3rd Mindset": "Effeminate "
},
 {
   "Sub No.": "4",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "03'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "3rd Mindset": "Worries about lands and cultivation, Dispute in the family"
},
 {
   "Sub No.": "5",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "3rd Mindset": "Dispute and Violence"
},
 {
   "Sub No.": "6",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Irritable and quarrel-some - Fear of black magic "
},
 {
   "Sub No.": "7",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Accident Prone - Fearful of Law - Fear of animals - Averse to blood "
},
 {
   "Sub No.": "8",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "3rd Mindset": "Fear of black magic - Argumentative - Adament"
},
 {
   "Sub No.": "9",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Quarrel-some "
},
 {
   "Sub No.": "10",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "3rd Mindset": "Amourous and fond of pleasure and chain- smoker."
},
 {
   "Sub No.": "11",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "3rd Mindset": "Bad habits - Will carry-out his affairs to the end -Magnanimous"
},
 {
   "Sub No.": "12",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"20',00',"
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "3rd Mindset": "Fond of hot - drinks - avaricious and wanting it character - Clever."
},
 {
   "Sub No.": "13",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "3rd Mindset": "Ever active and healthy - Fond of pleasure."
},
 {
   "Sub No.": "14",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "3rd Mindset": "Aspiring and low-born"
},
 {
   "Sub No.": "15",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Escapes of accidents - Indulges in pleasure - Clever - Happy - Higher studies and wisdom "
},
 {
   "Sub No.": "16",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"00',00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "3rd Mindset": "Low-born - Fond of other ladies -Meet with accidents."
},
 {
   "Sub No.": "17",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "3rd Mindset": "Wavering"
},
 {
   "Sub No.": "18",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "3rd Mindset": "Thief - Sharp - Eye for detail"
},
 {
   "Sub No.": "19",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "3rd Mindset": "Bold – Active"
},
 {
   "Sub No.": "20",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "3rd Mindset": "Muscular - Pushy type - and competitive nature "
},
 {
   "Sub No.": "21",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "3rd Mindset": "Robust health - Commanding - Argumentative "
},
 {
   "Sub No.": "22",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Commanding - Eat much - Fond of other's wives"
},
 {
   "Sub No.": "23",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"13',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Speculative – Selfish"
},
 {
   "Sub No.": "24",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Social – Generous - Cheerful - Wins enemies "
},
 {
   "Sub No.": "25",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05'06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "3rd Mindset": "Selfish - dishonest - Loss by bad debts"
},
 {
   "Sub No.": "26",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "3rd Mindset": "Intelligent - Cheerful - Fit for business - Creative ability"
},
 {
   "Sub No.": "27",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "3rd Mindset": "Discourteous - ill-repute - inimical - Jealous "
},
 {
   "Sub No.": "28",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "07:46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "3rd Mindset": "Speculative mind - Popular - Prosperous"
},
 {
   "Sub No.": "29",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"06',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "3rd Mindset": "Pleasing manners - Sweet voice - Lovely appearance"
},
 {
   "Sub No.": "30",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "3rd Mindset": "Fond of other sex – Aggressive"
},
 {
   "Sub No.": "31",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "3rd Mindset": "Unsettled mind - Lack of manners - Lives on other's money"
},
 {
   "Sub No.": "32",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Sympathetic - Steady - Maternal affection - Charm – Higher Education"
},
 {
   "Sub No.": "33",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "15'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17'46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "3rd Mindset": "Selfish - Timid - Mischievous - Stealthy"
},
 {
   "Sub No.": "34",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "3rd Mindset": "Enjoyment nature - Speaker - Agreeable on both sides"
},
 {
   "Sub No.": "35",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"40'O0\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "3rd Mindset": "Wavering - Worried - Fear Complex"
},
 {
   "Sub No.": "36",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "3rd Mindset": "Charm - Sweet talk - Humorous - Imaginative "
},
 {
   "Sub No.": "37",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "22'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "3rd Mindset": "Light reading - Sex with many women"
},
 {
   "Sub No.": "38",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24'06:40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "3rd Mindset": "Excited - One sided sex - Selfish"
},
 {
   "Sub No.": "39",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "3rd Mindset": "Lack of manners"
},
 {
   "Sub No.": "40",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27'53'20'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Good manners - Learned"
},
 {
   "Sub No.": "41",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "3rd Mindset": "Shameless - Naughty"
},
 {
   "Sub No.": "42",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "3rd Mindset": "Alert - Energetic"
},
 {
   "Sub No.": "43",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "3rd Mindset": "Excited - Bad talks"
},
 {
   "Sub No.": "44",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "3rd Mindset": "Quick - Imaginative - Sharp - Wealthy - Sexy"
},
 {
   "Sub No.": "45",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "04'53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33',20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "3rd Mindset": "Excited - Fluent - Eloquent"
},
 {
   "Sub No.": "46",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "3rd Mindset": "Impulsive and mischievous"
},
 {
   "Sub No.": "47",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "06'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Liar - Ungrateful - Sinful"
},
 {
   "Sub No.": "48",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "08'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Ingenious - Scholar - Character-Reader"
},
 {
   "Sub No.": "49",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12'33'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "3rd Mindset": "Cheating nature - Poor Education"
},
 {
   "Sub No.": "50",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20"
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Active – Critical"
},
 {
   "Sub No.": "51",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Cruel - Rogue - Ungrateful - Petition writer – Childless"
},
 {
   "Sub No.": "52",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "3rd Mindset": "Ingenious – Humorous"
},
 {
   "Sub No.": "53",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "3rd Mindset": "Cruel - Perfidious - Art of Boggling"
},
 {
   "Sub No.": "54",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "18'06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"13',20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "3rd Mindset": "Intuition – Thief"
},
 {
   "Sub No.": "55",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "3rd Mindset": "Murderer - Lobber - Rogue – Mischievous"
},
 {
   "Sub No.": "56",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "20'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"46'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Broad outlook - Proper Judgement - True and Rich"
},
 {
   "Sub No.": "57",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "21'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23'53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "3rd Mindset": "Wise – Sincere"
},
 {
   "Sub No.": "58",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "3rd Mindset": "Active - Ingenious - Scholar "
},
 {
   "Sub No.": "59",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26'33'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "3rd Mindset": "Religious - Poor Judgement - Pessimistic - Worried"
},
 {
   "Sub No.": "60",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "3rd Mindset": "Good memory - Proper judgment - Intelligent - easily convinced "
},
 {
   "Sub No.": "61",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "3rd Mindset": "Broad outlook - Clear in thought "
},
 {
   "Sub No.": "62",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Good Memory - Intuition"
},
 {
   "Sub No.": "63",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Imaginative - Honest - Forging nature – Sympathetic"
},
 {
   "Sub No.": "64",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "3rd Mindset": "Lover of beauty - Good argument -good vitality and fertility - Rich "
},
 {
   "Sub No.": "65",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "3rd Mindset": "Reliable - Compassionate "
},
 {
   "Sub No.": "66",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26'40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "3rd Mindset": "Tuberculosis - Lungs and ribs - Cancer - Eczema - Pyorrhoea "
},
 {
   "Sub No.": "67",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "3rd Mindset": "Self reliance - Intelligent – Systematic"
},
 {
   "Sub No.": "68",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"06'40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "3rd Mindset": "Timid and Fear complex "
},
 {
   "Sub No.": "69",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "08'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"20',00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "3rd Mindset": "Prudent and Careful - Liked by all - Wealthy"
},
 {
   "Sub No.": "70",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"00'00'"
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "3rd Mindset": "Learned - Methodical - Attains Positions of Trust "
},
 {
   "Sub No.": "71",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"00',00',"
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40'"
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "3rd Mindset": "Attentive - Contemplative "
},
 {
   "Sub No.": "72",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"06'40',"
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"53'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "3rd Mindset": "Cold - Grounded - Earthy - Loves hair"
},
 {
   "Sub No.": "73",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "3rd Mindset": "Fearing - Irresponsible"
},
 {
   "Sub No.": "74",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"40'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Economical - Conservative - Wealthy - Virtuous "
},
 {
   "Sub No.": "75",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',21\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "3rd Mindset": "Versatile - Quick wits. - Mimicry - clown - Fond of travel – Hypocrite"
},
 {
   "Sub No.": "76",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33',21,\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20',00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "3rd Mindset": "Learns all languages - Ungrateful - Sinful - Failures "
},
 {
   "Sub No.": "77",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "3rd Mindset": "Drunkenness - Imitate others in music, art - insincere - Fond of other's company and Picnic "
},
 {
   "Sub No.": "78",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "3rd Mindset": "Nervous - Timid "
},
 {
   "Sub No.": "79",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00"
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "3rd Mindset": "Versatile - Mimicry - Drunkenness "
},
 {
   "Sub No.": "80",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00"
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "3rd Mindset": "Correspondent - Travel Agents "
},
 {
   "Sub No.": "81",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06',40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "3rd Mindset": "Copious in speech - Fond of Travel "
},
 {
   "Sub No.": "82",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Learns art and literature - fond of Travel - Grateful "
},
 {
   "Sub No.": "83",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "3rd Mindset": "Windiness - Deceitful - Underhand dealings "
},
 {
   "Sub No.": "84",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"46'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Selfish - Fearing - Dreams "
},
 {
   "Sub No.": "85",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'00'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "3rd Mindset": "Pleasing - Good manners "
},
 {
   "Sub No.": "86",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'40'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "3rd Mindset": "Truthful - Reserved"
},
 {
   "Sub No.": "87",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40',"
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "3rd Mindset": "Confused - Brooding over "
},
 {
   "Sub No.": "88",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"46',40',"
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "3rd Mindset": "Like disputes and litigations "
},
 {
   "Sub No.": "89",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Black magic - Fear - depression - Disharmony "
},
 {
   "Sub No.": "90",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Religious - Philosophical - Learned "
},
 {
   "Sub No.": "91",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "3rd Mindset": "Slow - Dull - Dirty"
},
 {
   "Sub No.": "92",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Nervous - Talkative "
},
 {
   "Sub No.": "93",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "3rd Mindset": "Fond of company - Charming - Generous - Courteous"
},
 {
   "Sub No.": "94",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "3rd Mindset": "Generous - Amiable - Affectionate "
},
 {
   "Sub No.": "95",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17'20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "3rd Mindset": "Pleasing - Love Affairs - Social - Charming "
},
 {
   "Sub No.": "96",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "3rd Mindset": "Fond of pleasure Adventure - Amorous - Artistic - Extravagant - Wastes money - Independent "
},
 {
   "Sub No.": "97",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"06'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "3rd Mindset": "Selfish - Speculative "
},
 {
   "Sub No.": "98",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"53'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Generous - Cheerful "
},
 {
   "Sub No.": "99",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"00',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "3rd Mindset": "Selfish - Dishonest "
},
 {
   "Sub No.": "100",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20'"
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "3rd Mindset": "Intelligent - Cheerful - Creative mind "
},
 {
   "Sub No.": "101",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "3rd Mindset": "Discourteous "
},
 {
   "Sub No.": "102",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "3rd Mindset": "Affectionate - Quick recovery of health - Passionate – Commanding"
},
 {
   "Sub No.": "103",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "3rd Mindset": "Fast action - Quick judgment"
},
 {
   "Sub No.": "104",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29'13'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "3rd Mindset": "Good health and appearance - Fair - Energetic - Bold - Violent - Wealthy - Tendency to boss over - Donates land - Higher education - Frequent long journey "
},
 {
   "Sub No.": "105",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13'20'"
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Leading tendency - Over sexual"
},
 {
   "Sub No.": "106",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01'13'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Careless about diet - Nervous "
},
 {
   "Sub No.": "L07",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Doesn't acre about eating"
},
 {
   "Sub No.": "108",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "3rd Mindset": "Careless about diet - Eats anything and everything "
},
 {
   "Sub No.": "109",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "3rd Mindset": "Intelligent - Sentimental. "
},
 {
   "Sub No.": "110",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "3rd Mindset": "Fear Complex "
},
 {
   "Sub No.": "111",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "07'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "3rd Mindset": "Very active "
},
 {
   "Sub No.": "112",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "10'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "3rd Mindset": "Unnecessary brooding "
},
 {
   "Sub No.": "113",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "11'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "3rd Mindset": "Prefers hot drinks and food "
},
 {
   "Sub No.": "114",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "3rd Mindset": "Fond of Travel - Ungrateful "
},
 {
   "Sub No.": "115",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Over eating - Fond of travel - Grateful "
},
 {
   "Sub No.": "116",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "3rd Mindset": "Bad thinking and Bad Advisor "
},
 {
   "Sub No.": "117",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "3rd Mindset": "Uneasy and Restless "
},
 {
   "Sub No.": "118",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"26',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "3rd Mindset": "Ungrateful "
},
 {
   "Sub No.": "119",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "3rd Mindset": "Fond of travel - Insincere "
},
 {
   "Sub No.": "120",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "22'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "3rd Mindset": "Bold and courageous "
},
 {
   "Sub No.": "121",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "23'20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "3rd Mindset": "Saves money - Find fault with others "
},
 {
   "Sub No.": "122",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "24'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26'06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "3rd Mindset": "Fear - Depression - Unhappy "
},
 {
   "Sub No.": "123",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27'53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Very quick and commercial minded "
},
 {
   "Sub No.": "124",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "3rd Mindset": "Hard worker - Miser - Timid - Forgery "
},
 {
   "Sub No.": "125",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "3rd Mindset": "Intuition - Brilliant - Boring the people with meticulous details - Good Lover "
},
 {
   "Sub No.": "126",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "3rd Mindset": "Jealous "
},
 {
   "Sub No.": "127",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "3rd Mindset": "Fond of opposite sex - Helps others - Pleasing - Sincere "
},
 {
   "Sub No.": "128",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "3rd Mindset": "Good Health "
},
 {
   "Sub No.": "129",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "3rd Mindset": "Fertile imagination - Gentle - Lovable "
},
 {
   "Sub No.": "130",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "06\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08'40'00'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Liar - Ungrateful "
},
 {
   "Sub No.": "131",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "08\"40'00'"
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Ingenious - Scholar "
},
 {
   "Sub No.": "132",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "3rd Mindset": "Poor - Wicked - Slow grasping "
},
 {
   "Sub No.": "133",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Critic - Active mind - Intelligent "
},
 {
   "Sub No.": "134",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "14'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Adulterer - Cruel minded - Ungrateful - Petition Writer "
},
 {
   "Sub No.": "135",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "3rd Mindset": "Ingenious - Humorous "
},
 {
   "Sub No.": "136",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "3rd Mindset": "Honest - Courteous - Intelligent "
},
 {
   "Sub No.": "137",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "18'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19'13'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "3rd Mindset": "Sympathetic - Flexible - Sensitive"
},
 {
   "Sub No.": "138",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "3rd Mindset": "Fond of other sex - Loses temper "
},
 {
   "Sub No.": "139",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "20'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "3rd Mindset": "True - Honest - Noble - Charming "
},
 {
   "Sub No.": "140",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "21'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"53'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "3rd Mindset": "Hypocrite - Diplomatic - Selfish - Unreliable "
},
 {
   "Sub No.": "141",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "3rd Mindset": "Twin Birth - Good Master - Pleasing - Tactful - Enjoys life - Lovable - Adviser - Careful in Diet "
},
 {
   "Sub No.": "142",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "3rd Mindset": "Extremist - Influential - Secret enemies "
},
 {
   "Sub No.": "143",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "3rd Mindset": "Pleasing - Sweet manners - Right action - Good-health - Rich "
},
 {
   "Sub No.": "144",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29'26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "3rd Mindset": "Charming - Good built - Generous - Magnanimous "
},
 {
   "Sub No.": "145",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Sweet and pleasing manners - Always active "
},
 {
   "Sub No.": "146",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Worldly wisdom - Quick - Graceful - Optimistic - Strong likes and dislikes - Fatty and Tall "
},
 {
   "Sub No.": "147",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "3rd Mindset": "Energetic - Quick recovery from diseases - Commanding appearance - Sharp and curly hair - Muscular body - Never-minds obstacles - Rash, dash and crash - Over critical - Self assertion - Impulsive action - Gets excited - Makes large money - Fortune favours - Expects appreciation from lover"
},
 {
   "Sub No.": "148",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "3rd Mindset": "Over liberal - Extremist - Conservative Criminal Judge - "
},
 {
   "Sub No.": "149",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "3rd Mindset": "Hard working - Gossip - Funky - Lacks sympathy from lover - Less comforts Miserly - Very stingy - Tall - Old look "
},
 {
   "Sub No.": "150",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "3rd Mindset": "Hides Black Money - Wavering - Forgery - Long nose - thin chin - Confident - Successful bargain - Clever - Fifth Columnist "
},
 {
   "Sub No.": "151",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "3rd Mindset": "Selfish – Cruel"
},
 {
   "Sub No.": "152",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "08\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "3rd Mindset": "Fond of other sex - Secretive - Loose character - Addicted to liquor - Pleasant expenses "
},
 {
   "Sub No.": "153",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "3rd Mindset": "Steady - Never follows old custom - Black-mailing - Interested in Psychic subjects "
},
 {
   "Sub No.": "154",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "11'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "3rd Mindset": "Steady in love affairs - Good personality - Broad face - Meditation - Lazy - Lethargic - Miserly - Reserved - Funky - Interested in mysteries of nature - Proper intuition and action - Muscular body "
},
 {
   "Sub No.": "155",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "12'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "3rd Mindset": "Arrogant - Revengeful - Intelligent - Never-minds obstacles "
},
 {
   "Sub No.": "156",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "3rd Mindset": "Dishonest - Unscrupulous - Selfish - Cannot bear hunger "
},
 {
   "Sub No.": "157",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "14'53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Clever - Well-built - Good imagination - Will not accept any .theory Thoughtful - Honest -Non-interfering - Learned - Manager - Administrator "
},
 {
   "Sub No.": "158",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "3rd Mindset": "Long nose - Clever - Critical - Sharp - Intelligence - Quick grasping - Reverend "
},
 {
   "Sub No.": "159",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "3rd Mindset": "Excited - Short temper - Selfish and Venomous type "
},
 {
   "Sub No.": "160",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "3rd Mindset": "Fair - Muscular - Frank - Plain - Intuitive - Personality - Earns more and spends more - Fortunate - Sympathetic - Expects appreciation from Partner - Comfortable and smooth life "
},
 {
   "Sub No.": "161",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "3rd Mindset": "Healthy - Round face - Intelligent - Steady - Methodical - Rational Practical- Quick recovery - Steady income and expenditure - Steadiness in love affairs "
},
 {
   "Sub No.": "162",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "3rd Mindset": "Healthy - Wavering - Crosses the floor - Talkative "
},
 {
   "Sub No.": "163",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "3rd Mindset": "Well-built - Short Curly hair - Emotional - Self-assertion - Impulsive - Extravagant - Ever borrowing - Plural purchases "
},
 {
   "Sub No.": "164",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "3rd Mindset": "Murderous - Robber - Rogue - Mischievous "
},
 {
   "Sub No.": "165",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Hale and Healthy - Stalwart - Talkative - Versatile - Meditation "
},
 {
   "Sub No.": "166",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "3rd Mindset": "Deepest eyes - Long nose - No cheek-at all No bed comforts and furniture - Interested in mysteries of nature Philosophical -Meditation - Hypnotism - Concentration - Forgery"
},
 {
   "Sub No.": "167",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00'46'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Bold - Greedy - God fearing - Karma Yogi - Spends much Loss in speculation - Cares more for society than for family life - gives freedom to wife "
},
 {
   "Sub No.": "168",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40"
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "3rd Mindset": "Well-built - Fair - Energetic - Sincere - True - Honest - No Black Money cares- equally for society and home - Very rich "
},
 {
   "Sub No.": "169",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"40'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "3rd Mindset": "Well-built - Broad eyes - Aspiring - Ambitious - Honest - good vitality - Good health - Quick recovery - Steady in love affairs "
},
 {
   "Sub No.": "170",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "3rd Mindset": "Charming - Humpy body - Timid - Lack of confidence - Wavering - Lack of decision - Changeable - Not a good lover - Immoral "
},
 {
   "Sub No.": "171",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "04'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05'53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "3rd Mindset": "Well-built - Muscular - Optimistic - Bold - Doubtless - Over confident - Makes plenty of money and spend it - Loss in speculation - Wife questionable character"
},
 {
   "Sub No.": "172",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Unprincipled - Dishonest - Greedy - dirty habits "
},
 {
   "Sub No.": "173",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Well-built - Commanding appearance - Ambitious - Confident - Enthusiastic er gets disappointed - Earn by fair means - False prestige - donates much"
},
 {
   "Sub No.": "174",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "3rd Mindset": "High and bushy eye brows - Lean - Weak - Beautiful - Lazy - Lethargic - Failures-obstacles - Philosopher - Black Money - Loss of speculation - Jealousy - Miserly - benefitted by friends - suspects wife and Business partner "
},
 {
   "Sub No.": "175",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13'20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Graceful - Fair - Versatile - Crosses floor - Earns by all means - Black Money - Gains in speculations - Interested in games , gives freedom to wife "
},
 {
   "Sub No.": "176",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "3rd Mindset": "Handsome - Well-built - Wins enemies - More confident - Sincere - Firm - Gains in speculations - Lottery - True to wife - Profits "
},
 {
   "Sub No.": "177",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "3rd Mindset": "Round face - Broad eyes - Good Complexion - Energetic - Magnanimous -  Journey - Higher studies - Steady income - Gains in speculations - True to wife"
},
 {
   "Sub No.": "178",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "3rd Mindset": "Unreliable - Many love affairs - Make money by all means - Loss in speculation - Failure - False prestige"
},
 {
   "Sub No.": "179",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "3rd Mindset": "Well-built - Commanding appearance - Most enthusiastic - Greedy - Courageous - Stealthy mind - Quick recovery from illness - Loss in speculation - earn by all means - Most extravagant - temper flares up - Free with Partner - Social - No morals – Lacks integrity "
},
 {
   "Sub No.": "180",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "3rd Mindset": "Over liberal - Gets into financial trouble - Spends too much - wavering - Does not respect the society "
},
 {
   "Sub No.": "181",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21'53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Plump body - Charming - Tall - Gigantic figure - Bold - Pushful - Over confident - Law abiding - True & sincere God-fearing - Religious - Earns by fair means - Good in Art and Music - Drawing "
},
 {
   "Sub No.": "182",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "3rd Mindset": "Very fair and lean - Cannot produce children - Cannot satisfy Partner - Stealthy connections - Black-mailing - Writing anonymous letters - Mean in love affairs "
},
 {
   "Sub No.": "183",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "3rd Mindset": "Long nose - Impressive eyes - Charming - Changing ideas - Very bad subordinates "
},
 {
   "Sub No.": "184",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "3rd Mindset": "Timid - Womanish - always fearing something"
},
 {
   "Sub No.": "185",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "26'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "3rd Mindset": "Plump body - Round face - Highly qualified - commanding appearance - Quick -recovery from illness - Good vitality"
},
 {
   "Sub No.": "186",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "3rd Mindset": "Interested in Games and Society - Graceful - Fair - Changeability - Unsteady - Hates parents and relatives - Slowly recovery from illness"
},
 {
   "Sub No.": "187",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26'40"
      }
   },
   "To (DeglMin": {
      "Sec)": "29'13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "3rd Mindset": "Wastes money - Fortune in abroad - Well proportioned body - Commanding appearance - Fair - Over optimistic avaricious - Energetic - success in Examinations- Loss in speculation -Love affairs with divorcee - High class people - Cheat "
},
 {
   "Sub No.": "188",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Optimistic - Bad intentions "
},
 {
   "Sub No.": "189",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "3rd Mindset": "Lazy - Lethargic - False prestige "
},
 {
   "Sub No.": "190",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Magnanimous - Materialistic - True - Honest - Sincere - Fat and bulky body"
},
 {
   "Sub No.": "191",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "3rd Mindset": "Dishonest - Cheat - Meditation "
},
 {
   "Sub No.": "192",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07'00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "3rd Mindset": "Unlawful - Tall - Fake Currency - Gains from Foreigners/Father - Long journey -Partnership Business - Liar - Inherits property from father or stranger"
},
 {
   "Sub No.": "193",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07'46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "3rd Mindset": "Timid - Wavery - Always in trouble "
},
 {
   "Sub No.": "194",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "3rd Mindset": "Thin - Lean - Popular - Deep set eyes - Like loneliness - Unsocial - Secret love affairs - Speculate with others money - Reserved - Talks sweetly "
},
 {
   "Sub No.": "195",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11'06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "3rd Mindset": "Timid - Wavering - Failure in first attempt - Disappointment -Pessimist-Partnership business - Attached to Partner - Bony - Thin and Lean"
},
 {
   "Sub No.": "196",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "3rd Mindset": "Muscular - Popular - Success in Litigation/examination - Partner - a working girl "
},
 {
   "Sub No.": "197",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "11'53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "3rd Mindset": "Delayed ambition - Confused - Disappointed - Pessimist "
},
 {
   "Sub No.": "198",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15'40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Loss to the native - Partner -Gains - Repaying the loans - Sick partner - Frequent tour - Change of house - Loss of prestige "
},
 {
   "Sub No.": "",
   "Rashi": "",
   "From (Deg": {
      "Min": {
         "Sec)": ""
      }
   },
   "To (DeglMin": {
      "Sec)": ""
   },
   "Sign Lord": "",
   "Star lord": "",
   "Sub lord": "",
   "3rd Mindset": "Thin - Lean - Bony structure Secretive and depressed mind - Timid - Partner depressed "
},
 {
   "Sub No.": "199",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "15'40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "3rd Mindset": "Wavering and depressed mind - Tall - Talkative - Shuns crowd - Gains from Foreigner or father or brother-in-law "
},
 {
   "Sub No.": "200",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46',40"
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "3rd Mindset": "Wavering - Timid - Sober - Pessimistic - Contemplating - Funky "
},
 {
   "Sub No.": "201",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "19'40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "3rd Mindset": "Wavering - Timid - Sober - Pessimistic - Contemplating - Funky "
},
 {
   "Sub No.": "202",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "3rd Mindset": "Speculative - Beautiful - Deep set eyes - Profuse hair growth on eyes, head and chest - Immoral - happy - Popular - Musician "
},
 {
   "Sub No.": "203",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "3rd Mindset": "Ever in trouble - Danger to life - slow and steady - Position of trust "
},
 {
   "Sub No.": "204",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "3rd Mindset": "Muscular and thin - Thief - Attacked by Dacoits - Success in examination - Danger to Vehicles "
},
 {
   "Sub No.": "205",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "3rd Mindset": "Selfish - Greedy - Arrogant - Violent - Invite troubles "
},
 {
   "Sub No.": "206",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Acquires landed property - Ever changing of residence - Superiority complex "
},
 {
   "Sub No.": "207",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "3rd Mindset": "Liar - Dacoit - Thief - Quarrelsome - No principles Thin and muscular body -Secretive - Successful in disputes "
},
 {
   "Sub No.": "208",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "3rd Mindset": "Wavering - Funky - Timid - Nervous - Always confused "
},
 {
   "Sub No.": "209",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02\"40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "3rd Mindset": "Quarrelsome - Short temper - Fickle minded - Organiser of Clubs "
},
 {
   "Sub No.": "210",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "3rd Mindset": "Fair - Pleasing manners - Social worker - Loved by women "
},
 {
   "Sub No.": "211",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"53'20'"
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "3rd Mindset": "Bold - Honest - Discipline "
},
 {
   "Sub No.": "212",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06\"40'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "3rd Mindset": "Fair - Pleasing - Loved by women "
},
 {
   "Sub No.": "213",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "06\"40'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "3rd Mindset": "Lazy - Lethargic - Fond of rest - Arrogant "
},
 {
   "Sub No.": "214",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "08'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Sincere - Honest - Patience - Pleasing nature - Higher qualities - Scholar – Dynamic"
},
 {
   "Sub No.": "215",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"33',20',"
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "3rd Mindset": "Lazy and lethargic - Fond of rest "
},
 {
   "Sub No.": "216",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "3rd Mindset": "Intelligent - Strong decision -sometimes act in a foolish way - wrong advisor "
},
 {
   "Sub No.": "217",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "14'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "3rd Mindset": "Ugly appearance - Selfish - Bad dreams - Abortive thinking "
},
 {
   "Sub No.": "218",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "3rd Mindset": "Pleasing - Fair - set manners - Liked by all - Good qualities - Well built "
},
 {
   "Sub No.": "219",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "3rd Mindset": "Honest - True - Sincere - Discipline "
},
 {
   "Sub No.": "220",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "3rd Mindset": "Fair - Pleasing - Liked by all – Sympathetic - Sincere – Dynamic"
},
 {
   "Sub No.": "221",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "3rd Mindset": "Forceful - Independent - Arrogant - Commanding "
},
 {
   "Sub No.": "222",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21'46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Religious minded - Generous - Honest and Sincere "
},
 {
   "Sub No.": "223",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "3rd Mindset": "Lazy - Lethargic - Selfish - Sluggish "
},
 {
   "Sub No.": "224",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "3rd Mindset": "Timid - Funky - Nervous "
},
 {
   "Sub No.": "225",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"33'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "3rd Mindset": "Arrogant - Critic - Bad intentions - Henpecked - Miser "
},
 {
   "Sub No.": "226",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "26'33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "3rd Mindset": "Fair - Loved by all - Pleasing and Sweet manners "
},
 {
   "Sub No.": "227",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "3rd Mindset": "Honest - Sincere - Discipline - Steady income"
},
 {
   "Sub No.": "228",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Pleasing - Liked by all - Soft manners - Fond of travel - Love of Romance - Roaming and changing"
},
 {
   "Sub No.": "229",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "3rd Mindset": "Fair complexion - Courteous - Ever changing habits - Unsteady - Tries to lead a peaceful life - Hospitable - Avoids anxiety - Reliable - Medium built - Fleshy face - Full eyes - Dark hair -Passionate - Mysterious - Loves music and nature - Like change of job - Over work "
},
 {
   "Sub No.": "230",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "3rd Mindset": "Bold - Emotional - Good health - Independent - Short married life - Quick in understanding – Inspirational"
},
 {
   "Sub No.": "231",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "3rd Mindset": "Arrogant - Rough Character - Disregards Society "
},
 {
   "Sub No.": "232",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "3rd Mindset": "Imaginative - Reserved nature - Anxious - Patient - Economical - Discontentment - Rich person "
},
 {
   "Sub No.": "233",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "05'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "3rd Mindset": "Stature above average - Medium complexion - Imaginative - Hospitable - Intelligent -Studious - Learns without a teacher - Traveller - Mathematician - Learns occult Science - Respects others - Unhappy married life - Marries twice - Divorce "
},
 {
   "Sub No.": "234",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'o0\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08'06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "3rd Mindset": "Confusion - Unsteady - Wavering - Timid - Bad dreams - Fraud "
},
 {
   "Sub No.": "235",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "08'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10'20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "3rd Mindset": "Diplomatic - Selfish - Fair - Loved by women "
},
 {
   "Sub No.": "236",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "3rd Mindset": "Liberal - Honest - Proud - Generous - Fond of jewels "
},
 {
   "Sub No.": "237",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "11'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "3rd Mindset": "Pale complexion - Reserved - Calm - Wavering and vacillating - Traditional mind "
},
 {
   "Sub No.": "238",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12'53',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "3rd Mindset": "White complexion with Red shade - Muscular - Bold - Courageous - Not rash - Impulsive - Aggressive - Patient endurance - Locality - Murderous tendency "
},
 {
   "Sub No.": "239",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "3rd Mindset": "Arrogant - Unfair - Rough manners "
},
 {
   "Sub No.": "240",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16'40'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "3rd Mindset": "Healthy - Good manners - Pleasing - Gentle and Honest "
},
 {
   "Sub No.": "241",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "3rd Mindset": "Optimistic - Always in good spirits - Broad minded - Versatile - Slow Judgement - Controversial decision - Brings disputes and litigations "
},
 {
   "Sub No.": "242",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "3rd Mindset": "Vacillation - Flexible - Bad dreams - Confused - Sober "
},
 {
   "Sub No.": "243",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33'20'"
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "3rd Mindset": "Cheerful - Handsome - Two Partners - Two Partners - Two attachments - Ever smiling - Social prestige - Generous - Many friends - Liked by all - Fond of Fine Arts - Gives Surety and Free from troubles "
},
 {
   "Sub No.": "244",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "3rd Mindset": "Generous - Courteous - Loves nature - Social - Easy life - Fond of opposite sex - Fine Arts - Poetry - sickly wife - Unhappy married life - Interested in Astrology and Astronomy - Ambitious - Capable - Inspiring "
},
 {
   "Sub No.": "245",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "3rd Mindset": "Average height - Stout - Long hands - Slender - Dark hair - Expressive eyes - Thin and shrill voice - Looks younger - Intelligent - Imaginative - Emotional - Mental Imbalance - Spreads false rumours - Anxious - Learns many languages - Short journeys - Frequent changes - Pessimistic Wiser as age grows up - Criticised by public - Unhappy dealings with relatives"
},
 {
   "Sub No.": "246",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "3rd Mindset": "Medium stature - Healthy - Round eyes - Narrow waist - Loves. work - duty minded - Quick - Organising ability -Humorous - Ingenious - Constructive - Practical - Business like - Great mental and intellectual powers - Impulsive - Rash - Irritated and Irritates others - Disputes - Litigations - Risky - Ventures - Hasty decision - Public scandal - Criticised by others - Loss in speculation - Fraud - Cheating - Physical fight with wife"
},
 {
   "Sub No.": "247",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40'"
   },
   "Sign Lord": "J upiter",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "3rd Mindset": "Flexible - Cruel - Bad intentions - Wavering - No Principles "
},
 {
   "Sub No.": "248",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "jupiter",
   "3rd Mindset": "Huge body - Tall - Stout - Looks younger - Clear thoughts - good Judgement -god-fearing -Honest - Religious-minded - Liberal "
},
 {
   "Sub No.": "249",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20"
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "3rd Mindset": "Thin built - Pale complexion - Looks older - Deep set eyes - Fear complex - Spreads rumours "
}
]);