// lexilogos.com/english
// https://www.lexilogos.com/keyboard/devanagari.htm
import aksharGrahaConstants from "../constants/aksharGraha.constants";
import { transliterateToHindi } from "./hindi";

const transcrire = (latinText) => {
  let car;

  car = latinText;
  car = car.replace(/a/g, "अ");
  car = car.replace(/[AāĀ]/g, "आ");
  car = car.replace(/i/g, "इ");
  car = car.replace(/[IīĪ]/g, "ई");
  car = car.replace(/u/g, "उ");
  car = car.replace(/[UūŪ]/g, "ऊ");
  car = car.replace(/अअ/g, "आ");
  car = car.replace(/इइ/g, "ई");
  car = car.replace(/उउ/g, "ऊ");
  car = car.replace(/[eē]/g, "ए");
  car = car.replace(/[oō]/g, "ओ");
  car = car.replace(/अइ/g, "ऐ");
  car = car.replace(/अउ/g, "औ");

  // suppression du virama
  car = car.replace(/िइ/g, "ी");
  car = car.replace(/ुउ/g, "ू");
  car = car.replace(/्अ/g, "\u200b");
  car = car.replace(/\u200bअ/g, "ा");
  car = car.replace(/\u200bइ/g, "ै");
  car = car.replace(/\u200bउ/g, "ौ");
  car = car.replace(/्आ/g, "ा");
  car = car.replace(/्इ/g, "ि");
  car = car.replace(/्ई/g, "ी");
  car = car.replace(/्उ/g, "ु");
  car = car.replace(/्ऊ/g, "ू");
  car = car.replace(/्ऋ/g, "ृ");
  car = car.replace(/्ॠ/g, "ॄ");
  car = car.replace(/्ऌ/g, "ॢ");
  car = car.replace(/्ॡ/g, "ॣ");
  car = car.replace(/्ए/g, "े");
  car = car.replace(/्ओ/g, "ो");
  car = car.replace(/्ऐ/g, "ै"); //ajoutai
  car = car.replace(/ेए/g, "ै"); // ee
  car = car.replace(/्औ/g, "ौ"); //ajoutau
  car = car.replace(/ोओ/g, "ौ"); // oo

  car = car.replace(/्᳭/g, "᳭"); // vedique tiryak non spacing

  // retrait ligne hindi  car = car.replace(/् /g, " ");

  //cons
  car = car.replace(/n/g, "न्");
  car = car.replace(/k/g, "क्");
  car = car.replace(/g/g, "ग्");
  car = car.replace(/c/g, "च्");
  car = car.replace(/j/g, "ज्");
  car = car.replace(/[TṭṬ]/g, "ट्");
  car = car.replace(/[DḍḌ]/g, "ड्");
  car = car.replace(/[NṇṆ]/g, "ण्");
  car = car.replace(/t/g, "त्");
  car = car.replace(/d/g, "द्");
  car = car.replace(/p/g, "प्");
  car = car.replace(/b/g, "ब्");
  car = car.replace(/m/g, "म्");
  // car = car.replace(/q/g, "क़्");
  // car = car.replace(/x/g, "ख़्");
  // car = car.replace(/Y/g, "ग़्");
  // car = car.replace(/z/g, "ज़्");
  // car = car.replace(/f/g, "फ़्");
  // car = car.replace(/R/g, "ड़्");
  // car = car.replace(/f/g, "फ़्");
  car = car.replace(/y/g, "य्");
  car = car.replace(/r/g, "र्");
  car = car.replace(/l/g, "ल्");
  car = car.replace(/L/g, "ळ्");
  car = car.replace(/v/g, "व्");
  car = car.replace(/w/g, "व्");
  car = car.replace(/h/g, "ह्");
  car = car.replace(/[SṣṢ]/g, "ष्");
  car = car.replace(/s/g, "स्");
  // cas particuliers
  car = car.replace(/[GṅṄ]/g, "ङ्");
  car = car.replace(/[Jñ]/g, "ञ्");
  //car = car.replace(/न्ग्/g, "ङ्");
  //car = car.replace(/न्ज्/g, "ञ्");

  // aspirées
  car = car.replace(/क्ह्/g, "ख्");
  car = car.replace(/ग्ह्/g, "घ्");
  car = car.replace(/च्ह्/g, "छ्");
  car = car.replace(/ज्ह्/g, "झ्");
  car = car.replace(/ट्ह्/g, "ठ्");
  car = car.replace(/ड्ह्/g, "ढ्");
  car = car.replace(/त्ह्/g, "थ्");
  car = car.replace(/द्ह्/g, "ध्");
  car = car.replace(/त्ह्/g, "थ्");
  car = car.replace(/द्ह्/g, "ध्");
  car = car.replace(/प्ह्/g, "फ्");
  car = car.replace(/ब्ह्/g, "भ्");
  car = car.replace(/ड़्ह्/g, "ढ़्");

  // cas du s barre
  car = car.replace(/स्ह्/g, "श्");
  car = car.replace(/[çzśŚ]/g, "श्");

  // cas du ri li
  car = car.replace(/्-र्/g, "ृ");
  car = car.replace(/-र्/g, "ऋ");
  car = car.replace(/ऋइ/g, "ॠ");
  car = car.replace(/ृइ/g, "ॄ");
  car = car.replace(/[ṛṚ]/g, "ऋ");
  car = car.replace(/[ṝṜ]/g, "ॠ");
  car = car.replace(/[ḷḶ]/g, "ऌ");
  car = car.replace(/[ḹḸ]/g, "ॡ");

  car = car.replace(/्-ल्/g, "ॢ");
  car = car.replace(/-ल्/g, "ऌ");
  car = car.replace(/ऌइ/g, "ॡ");
  car = car.replace(/ॢइ/g, "ॣ");

  //suppression du zero
  car = car.replace(/\u200bक/g, "क");
  car = car.replace(/\u200bख/g, "ख");
  car = car.replace(/\u200bग/g, "ग");
  car = car.replace(/\u200bघ/g, "घ");
  car = car.replace(/\u200bङ/g, "ङ");
  car = car.replace(/\u200bच/g, "च");
  car = car.replace(/\u200bछ/g, "छ");
  car = car.replace(/\u200bज/g, "ज");
  car = car.replace(/\u200bझ/g, "झ");
  car = car.replace(/\u200bञ/g, "ञ");
  car = car.replace(/\u200bट/g, "ट");
  car = car.replace(/\u200bठ/g, "ठ");
  car = car.replace(/\u200bड/g, "ड");
  car = car.replace(/\u200bढ/g, "ढ");
  car = car.replace(/\u200bण/g, "ण");
  car = car.replace(/\u200bत/g, "त");
  car = car.replace(/\u200bथ/g, "थ");
  car = car.replace(/\u200bद/g, "द");
  car = car.replace(/\u200bध/g, "ध");
  car = car.replace(/\u200bन/g, "न");
  car = car.replace(/\u200bप/g, "प");
  car = car.replace(/\u200bफ/g, "फ");
  car = car.replace(/\u200bब/g, "ब");
  car = car.replace(/\u200bभ/g, "भ");
  car = car.replace(/\u200bम/g, "म");
  car = car.replace(/\u200bक़/g, "क़");
  car = car.replace(/\u200bख़/g, "ख़");
  car = car.replace(/\u200bग़/g, "ग़");
  car = car.replace(/\u200bज़/g, "ज़");
  car = car.replace(/\u200bड़/g, "ड़");
  car = car.replace(/\u200bढ़/g, "ढ़");
  car = car.replace(/\u200bफ़/g, "फ़");
  car = car.replace(/\u200bय/g, "य");
  car = car.replace(/\u200bर/g, "र");
  car = car.replace(/\u200bल/g, "ल");
  car = car.replace(/\u200bळ/g, "ळ");
  car = car.replace(/\u200bव/g, "व");
  car = car.replace(/\u200bह/g, "ह");
  car = car.replace(/\u200bश/g, "श");
  car = car.replace(/\u200bष/g, "ष");
  car = car.replace(/\u200bस/g, "स");
  car = car.replace(/\u200b /g, " "); // pb finales
  // accents
  car = car.replace(/\u200b/g, "॓");
  car = car.replace(/\u200b/g, "॔");

  // anusvara
  car = car.replace(/[MṃṂṁ]/g, "ं");
  car = car.replace(/\u200bं/g, "ं");
  car = car.replace(/्ं/g, "ं");
  // + candrabindu
  car = car.replace(/ंं/g, "ँ");
  car = car.replace(/ँं/g, "");
  car = car.replace(/\u200bँ/g, "ँ");
  //OM
  car = car.replace(/O/g, "ॐ");
  car = car.replace(/ॐं/g, "ॐ");
  // visarga
  car = car.replace(/[HḥḤ]/g, "ः");
  car = car.replace(/्ः/g, "ः");
  car = car.replace(/\u200b/g, "ः");
  // + nukta
  car = car.replace(/=/g, "़");
  car = car.replace(/\u200b़/g, "़");
  // anudatta + udatta
  car = car.replace(/_/g, "॒");
  car = car.replace(/Q/g, "॒");
  car = car.replace(/\u200b॒/g, "॒");
  car = car.replace(/॒॒/g, "॑");
  car = car.replace(/\u200b॑/g, "॑");

  car = car.replace(/॒॑/g, "᳚");
  car = car.replace(/\u200b᳚/g, "᳚");
  car = car.replace(/॒᳚/g, "᳛");
  car = car.replace(/\u200b᳛/g, "᳛");
  car = car.replace(/॒᳛/g, "᳜");
  car = car.replace(/᳜॒/g, "");
  car = car.replace(/\u200b᳜/g, "᳜");

  // alternative : car = car.replace(/:/g, "ः");
  // avagraha
  car = car.replace(/'/g, "ऽ");
  car = car.replace(/’/g, "ऽ");

  // ponctuation
  car = car.replace(/\|/g, "।");
  car = car.replace(/\//g, "।");
  car = car.replace(/।।/g, "॥");

  //half conso
  car = car.replace(/x/g, "\u200d");
  car = car.replace(/\u200d\u200d/g, "\u200c");

  car = car.replace(/0/g, "०");
  car = car.replace(/1/g, "१");
  car = car.replace(/2/g, "२");
  car = car.replace(/3/g, "३");
  car = car.replace(/4/g, "४");
  car = car.replace(/5/g, "५");
  car = car.replace(/6/g, "६");
  car = car.replace(/7/g, "७");
  car = car.replace(/8/g, "८");
  car = car.replace(/9/g, "९");

  //uttara 5 + 9
  car = car.replace(/५=/g, "");
  car = car.replace(/=/g, "५");
  car = car.replace(/९=/g, "");
  car = car.replace(/=/g, "९");

  // startPos = document.conversion.saisie.selectionStart;
  // endPos = document.conversion.saisie.selectionEnd;

  // beforeLen = document.conversion.saisie.value.length;
  // afterLen = car.length;
  // adjustment = afterLen - beforeLen;

  // document.conversion.saisie.value = car;

  // document.conversion.saisie.selectionStart = startPos + adjustment;
  // document.conversion.saisie.selectionEnd = endPos + adjustment;

  // var obj = document.conversion.saisie;
  // obj.focus();
  // obj.scrollTop = obj.scrollHeight;
  return car;
};

const copysanskrit = (value) => {
  let car = value;
  car = car.replace(/\u200b/g, ""); // pb finales
  return car;
};

// Note
// Click on the virama, called halant in Sanskrit (diacritic in the center) to delete the inherent vowel a: the virama is needed between two consonants.

// example: for vedānta, type: v + e + d + a + n + virama + t + a

// Instructions
// To type directly with the computer keyboard:

// Use the capital to type the letters subscribed with a dot below: T, Th, D, Dh, N, L, S
// Type G or ng for ṅ & J for ñ
// Type z or ç or sh for ś
// Type aa, ii, uu (or A, I, U) for the long vowels ā, ī, ū
// Type -r and -ri for ri and rī
// Type -l and -li for li and lī
// Type M for ं (anusvara) & MM for ँ (candra bindu)
// Type H for ः (visarga)
// Type = four ़ (nukta): dot below
// Type _ for ॒ (anudatta) and __ for ॑ (udatta)
// Type / or | for । (danda)
// Type ' (apostrophe) for ऽ (avagraha)
// Type O, OM (capitals) for ॐ
// Type x to form a half consonant; example: type sx for स्‍
// Type xx to prevent conjunct forms; example: type kxxS for क्‌ष्
const signs = {
  "'": "ऽ",
  "!": "ँ",
  "/": "́",
  H: "ः",
  M: "ं",
  "-": "-",
  ".": ".",
};

const Vowels = {
  a: "अ",
  A: "आ",
  i: "इ",
  I: "ई",
  u: "उ",
  U: "ऊ",
  e: "ए",
  E: "ऐ",
  o: "ओ",
  O: "औ",
  f: "ऋ",
  F: "ॠ",
  x: "ऌ",
  X: "ॡ",
};

const vowels = {
  a: "",
  A: "ा",
  i: "ि",
  I: "ी",
  u: "ु",
  U: "ू",
  e: "े",
  E: "ै",
  o: "ो",
  O: "ौ",
  f: "ृ",
  F: "ॄ",
  x: "ॢ",
};

const consonants = {
  k: "क", // == is for bolnagri
  K: "ख",
  g: "ग",
  G: "घ",
  c: "च",
  C: "छ",
  j: "ज",
  J: "झ",
  w: "ट", // टठ-tT == टठ-fF // cerebral
  W: "ठ",
  q: "ड", // डढ-dD == डढ-vV
  Q: "ढ",
  t: "त", // तथ-wW == तथ-tT
  T: "थ",
  d: "द", // दध-xX == दध-dD
  D: "ध",
  n: "न",
  R: "ण", // == N
  N: "ङ", // == M
  p: "प",
  P: "फ",
  b: "ब",
  B: "भ",
  m: "म",
  y: "य",
  Y: "ञ",
  r: "र",
  l: "ल",
  v: "व", // वॐ == wW
  S: "श", // श ॅ == zZ
  z: "ष",
  s: "स",
  h: "ह",
  "": "",
};

// ०१२३४५६७८९
const numbers = {
  0: "०",
  1: "१",
  2: "२",
  3: "३",
  4: "४",
  5: "५",
  6: "६",
  7: "७",
  8: "८",
  9: "९",
};

const latinToDevanagari = (str) => {
  if (!str) return "";
  str = str.replace("/", "");
  str = str.replace("-", "");
  const arr = str.split("");
  const sk = [];
  arr.forEach(function (letr, idx) {
    var prev = arr[idx - 1];
    if (idx === 0 && letr in Vowels) sk[0] = Vowels[letr];
    if (prev in consonants) {
      if (letr in vowels) {
        sk[idx - 1] = consonants[prev];
        sk[idx] = vowels[letr];
      } else if (letr in consonants) {
        sk[idx - 1] = consonants[prev] + "्";
      }
    }
    if (idx === arr.length - 1 && letr in consonants)
      sk[idx] = consonants[letr] + "्";
    if (arr[idx] in signs) {
      sk[idx] = signs[letr];
    }
    if (arr[idx] in numbers) {
      sk[idx] = numbers[letr];
    }
  });
  return sk.join("");
};

const transliterateToDevanagari = (input) => {
  const aksharMap = {};
  const lordMap = {};
  const hindiTransliterated = transliterateToHindi(input);
  hindiTransliterated.forEach((char) => {
    const lord = char && aksharGrahaConstants[char];
    if (lordMap[lord]) {
      lordMap[lord].akshar.push(char);
    } else {
      lordMap[lord] = {
        akshar: [char],
      };
    }

    if (aksharMap[char]) {
      const count = aksharMap[char].count + 1;
      let rahuCount = false;
      if (count > 1) {
        // multiple akshar means Rahu
        rahuCount = true;
        lordMap["Ra"] = {
          akshar: [char],
        };
      }
      aksharMap[char] = {
        ...aksharMap[char],
        count,
        rahuCount,
      };
    } else {
      aksharMap[char] = {
        count: 1,
        lord,
      };
    }
  });

  return [hindiTransliterated.join(', '), aksharMap, lordMap];
};

export {
  copysanskrit,
  latinToDevanagari,
  transcrire,
  transliterateToDevanagari,
  transliterateToHindi,
};
