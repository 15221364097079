import { getCookie, setCookie } from "./../components/Auth/session";
const grahasData = require("./../../data/grahas");
const { analyzeHorosoftChartData } = require("./../engine/horosoft");
const { analyzeJHoraChartData } = require("./../engine/jHora");

const lagnadGrahas = () => {
  return [3, 4, 5, 9, 7, 2, 8, 6, 1].map((id) => {
    return grahasData[id - 1];
  });
};

const grahasWLagna = [
  {
    id: 1,
    graha: "Asc (लग्न)",
    uchhaRas: "",
    nicchaRas: "",
    swaGraha: "",
    color: "",
    direction: "",
    aspects: "",
  },
  ...lagnadGrahas(),
];

const storedInitialState = getCookie("state");

const sliderReset = {
  statesEnum: ["NOT_SELECTED", "SELECTED"],
  planets: {
    sun: { id: 1, name: `Sun`, currentState: `NOT_SELECTED` },
    moon: { id: 2, name: `Moon`, currentState: `NOT_SELECTED` },
    mars: { id: 3, name: `Mars`, currentState: `NOT_SELECTED` },
    mercury: { id: 4, name: `Mercury`, currentState: `NOT_SELECTED` },
    venus: { id: 5, name: `Venus`, currentState: `NOT_SELECTED` },
    jupiter: { id: 6, name: `Jupiter`, currentState: `NOT_SELECTED` },
    saturn: { id: 7, name: `Saturn`, currentState: `NOT_SELECTED` },
    rahu: { id: 8, name: `Rahu`, currentState: `NOT_SELECTED` },
    ketu: { id: 9, name: `Ketu`, currentState: `NOT_SELECTED` },
    uranus: { id: 10, name: `Uranus`, currentState: `NOT_SELECTED` },
    neptune: { id: 11, name: `Neptune`, currentState: `NOT_SELECTED` },
  },
  houses: {
    1: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    2: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    3: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    4: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    5: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    6: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    7: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    8: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    9: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    10: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    11: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
    12: {
      planets: [],
      selectedPlanets: [],
      mappedPlanets: [],
    },
  },
  flags: {
    showNakshatraPadas: false,
    showJHoraMappedPlanets: false,
    showAspectsWhenClicked: false,
  },
  chartAnalysis: {
    northChart: {
      currentAscendent: 1,
      currentSignIndex: 0,
      mappedAscendentIndex: 0,
      rotatedAscendentIndex: 0,
    },
  },
};

const initialState = storedInitialState || {
  houses: {
    1: { name: "I" },
    2: { name: "II" },
    3: { name: "III" },
    4: { name: "IV" },
    5: { name: "V" },
    6: { name: "VI" },
    7: { name: "VII" },
    8: { name: "VIII" },
    9: { name: "IX" },
    10: { name: "X" },
    11: { name: "XI" },
    12: { name: "XII" },
  },
  grahas: grahasWLagna.filter((gra) => gra.id),
  isAuthenticated: false,
  isAuthenticating: true,
  user: null,
  slider: sliderReset,
  birthDetails: {
    birthDate: new Date("07/02/1980"),
  },
  jHoraChart: {
    chartDataPresent: false,
    jHoraChartData: {},
    jHoraChartError: null,
    jHoraChartLoading: false,
  },
  horosoftChart: {
    horosoftChartData: {},
    horosoftChartError: null,
    horosoftChartLoading: false,
  },
};

const types = {
  GRAHAS: "GRAHAS",
  HOUSES: "HOUSES",
  USER: "USER",
};

const signs = {
  Ar: 1,
  Ta: 2,
  Ge: 3,
  Cn: 4,
  Le: 5,
  Vi: 6,
  Li: 7,
  Sc: 8,
  Sg: 9,
  Cp: 10,
  Aq: 11,
  Pi: 12,
};

const assignNorthChartPlanets = (
  currentState,
  showJHoraMappedPlanets = false
) => {
  const jHChartData = currentState.jHoraChart?.jHoraChartData?.chartData;

  if (jHChartData && showJHoraMappedPlanets) {
    ["Ke", "Ve", "Su", "Mo", "Ma", "Ra", "Ju", "Sa", "Me"].map((planetName) => {
      const prevMappedPlanets =
        currentState.slider?.houses[signs[jHChartData[planetName].sign]]
          ?.mappedPlanets;
      if (
        currentState.slider?.houses[
          signs[jHChartData[planetName].sign]
        ]?.mappedPlanets?.indexOf(planetName) === -1
      ) {
        currentState.slider.houses[
          signs[jHChartData[planetName].sign]
        ].mappedPlanets = [...new Set([...prevMappedPlanets, planetName])];
      }
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map(
        (houseIndex) => {
          currentState.slider.houses[houseIndex].planets =
            currentState.slider?.houses[houseIndex].mappedPlanets;
          return null;
        }
      );
      return null;
    });
  } else {
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map(
      (houseIndex) => {
        currentState.slider.houses[houseIndex].planets =
          currentState.slider?.houses[houseIndex].selectedPlanets;
        return null;
      }
    );
  }
  return currentState;
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  let storedState = getCookie("state");
  if (!storedState) {
    storedState = state;
  }
  const resultState = {
    ...storedState,
  };

  if (!resultState.slider.houses["1"].planets) {
    resultState.slider = sliderReset;
  }

  switch (type) {
    case "GRAHAS":
      if (payload.key) {
        state.grahas[payload.key] = {
          ...state.grahas[payload.key],
          ...payload.value,
        };
      }
      return state;
    case "HOUSES":
      if (payload.key) {
        state.houses[payload.key] = {
          ...state.houses[payload.key],
          ...payload.value,
        };
      }
      return state;
    case "USER":
      if (Object.keys(payload).length) {
        resultState.user = payload;
        resultState.isAuthenticated = true;
        setCookie("state", resultState);
      }
      return resultState;
    case "LOGOUT":
      resultState.user = undefined;
      resultState.isAuthenticated = false;
      setCookie("state", resultState);
      return resultState;
    case "NAK_ASPECTS_HOUSE_PLANET_ASSIGNMENT":
      if (
        resultState.slider.planets[payload.planet]?.currentState === "SELECTED"
      )
        return state;
      const { slider: { houses } = {} } = resultState || {};

      const arrayPreviousValue = houses[payload.house].selectedPlanets;
      if (payload.planet === "rahu" || payload.planet === "ketu") {
        const forNodes7thHouse =
          payload.house + 6 > 12 ? payload.house - 6 : payload.house + 6;
        const arrayPreviousValue7thHouse =
          houses[forNodes7thHouse].selectedPlanets;
        if (payload.planet === "rahu") {
          resultState.slider.houses[forNodes7thHouse].selectedPlanets = [
            ...new Set([...arrayPreviousValue7thHouse, "ketu"]),
          ];
          resultState.slider.planets["ketu"].currentState = "SELECTED";
        } else {
          resultState.slider.houses[forNodes7thHouse].selectedPlanets = [
            ...new Set([...arrayPreviousValue7thHouse, "rahu"]),
          ];
          resultState.slider.planets["rahu"].currentState = "SELECTED";
        }
      }
      resultState.slider.houses[payload.house].selectedPlanets = [
        ...new Set([...arrayPreviousValue, payload.planet]),
      ];

      resultState.slider.planets[payload.planet].currentState = "SELECTED";
      const newState = assignNorthChartPlanets(
        resultState,
        resultState.slider?.flags?.showJHoraMappedPlanets
      );
      resultState.slider = {
        ...state.slider,
        houses: {
          ...newState.slider.houses,
        },
        planets: {
          ...newState.slider.planets,
        },
      };

      setCookie("state", resultState);
      return resultState;
    case "NAK_ASPECTS_HOUSE_PLANET_RESET":
      resultState.slider = sliderReset;
      setCookie("state", resultState);
      return resultState;
    case "BIRTH_DETAILS_SET_BIRTH_DATE":
      const { birthDate } = payload;
      if (resultState.birthDetails) {
        resultState.birthDetails.birthDate = birthDate;
      } else {
        resultState.birthDetails = {
          birthDate,
        };
      }
      setCookie("state", resultState);
      return resultState;
    case "JHORA_CHART_ANALIZED":
      const { jHoraChartData } = payload;

      resultState.jHoraChart = {
        ...resultState.jHoraChart,
        chartDataPresent: true,
        jHoraChartData: analyzeJHoraChartData(jHoraChartData),
      };
      setCookie("state", resultState);

      return resultState;
    case "JHORA_HOUSES_ANALIZED":
      const { houseDetails } = payload;
      if (houseDetails) {
        resultState.jHoraChart = {
          ...resultState.jHoraChart,
          houseDataPresent: true,
          houseDetails,
        };
      } else {
        resultState.jHoraChart = {
          ...resultState.jHoraChart,
          houseDataPresent: false,
        };
      }
      setCookie("state", resultState);
      return resultState;
    case "ANALYZE_HOROSOFT_CHART":
      const { chartData } = payload;
      resultState.horosoftChart = {
        ...resultState.horosoftChart,
        horosoftChartData: analyzeHorosoftChartData(chartData),
      };
      setCookie("state", resultState);
      return resultState;
    case "TOGGLE_SHOW_JHORA_MAPPED_PLANETS_FLAG":
      if (!resultState.jHoraChart?.jHoraChartData?.chartData)
        return resultState;
      const showJHoraMappedPlanets =
        !state.slider?.flags?.showJHoraMappedPlanets;
      const returnedState = assignNorthChartPlanets(
        resultState,
        showJHoraMappedPlanets
      );
      const mappedAscendentIndex =
        signs[returnedState.jHoraChart?.jHoraChartData?.chartData[1]?.sign] - 1;
      resultState.slider = {
        ...state.slider,
        houses: {
          ...returnedState.slider.houses,
        },
        flags: {
          ...state.slider?.flags,
          showJHoraMappedPlanets,
        },
        chartAnalysis: {
          ...state.slider.chartAnalysis,
          northChart: {
            ...state.slider.chartAnalysis?.northChart,
            currentSignIndex: showJHoraMappedPlanets
              ? mappedAscendentIndex
              : state.slider.chartAnalysis?.northChart.rotatedAscendentIndex,
            mappedAscendentIndex,
          },
        },
      };
      setCookie("state", resultState);
      return resultState;
    case "TOGGLE_SHOW_NAKSHATRA_PADAS_FLAG":
      resultState.slider = {
        ...state.slider,
        flags: {
          ...state.slider?.flags,
          showNakshatraPadas: !state.slider?.flags?.showNakshatraPadas,
        },
      };
      setCookie("state", resultState);
      return resultState;
    case "SLIDER_NORTH_CHART_HOUSE_ROTATION":
      resultState.slider = {
        ...state.slider,
        chartAnalysis: {
          ...state.slider.chartAnalysis,
          northChart: {
            ...state.slider.chartAnalysis?.northChart,
            currentSignIndex: payload,
            rotatedAscendentIndex: payload,
          },
        },
      };
      setCookie("state", resultState);
      return resultState;
    case "TOGGLE_SHOW_ASPECTS_FLAG":
      resultState.slider = {
        ...state.slider,
        flags: {
          ...state.slider?.flags,
          showAspectsWhenClicked: !state.slider?.flags?.showAspectsWhenClicked,
        },
      };
      setCookie("state", resultState);
      return resultState;
    default:
      return state;
  }
};

export { initialState, types, reducer };
