const houseYears = {
  "1": [1, 13, 25, 37, 49, 61, 72, 84, 96],
  "2": [2, 14, 26, 38, 50, 62, 73, 85, 97],
  "3": [3, 15, 27, 39, 51, 63, 74, 86, 98],
  "4": [4, 16, 28, 40, 52, 64, 75, 87, 99],
  "5": [5, 17, 29, 41, 53, 65, 76, 88, 100],
  "6": [6, 18, 30, 42, 54, 66, 77, 89, 101],
  "7": [7, 19, 31, 43, 55, 67, 78, 90, 102],
  "8": [8, 20, 32, 44, 56, 68, 79, 91, 103],
  "9": [9, 21, 33, 45, 57, 69, 80, 92, 104],
  "10": [10, 22, 34, 46, 58, 70, 81, 93, 105],
  "11": [11, 23, 35, 47, 59, 71, 82, 94, 106],
  "12": [12, 24, 36, 48, 60, 72, 83, 95, 107],
}

export {
  houseYears,
}