module.exports = Object.freeze([
  {
    'id': 1,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(0°0)-Ari(3°20)',
    'deity': 'Ashwini-Kumaras',
    'nk-pada': 'Ashwini-1',
    'nakshatra': 'Ashwini (1)',
    'marathiName': 'अश्विनी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '1',
    'rp': '1',
    'nl-o': 'Sco',
    'h': '2',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,10,4',
    'ii-e': ''
  },
  {
    'id': 2,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(3°20)-Ari(6°40)',
    'deity': 'Ashwini-Kumaras',
    'nk-pada': 'Ashwini-2',
    'nakshatra': 'Ashwini (1)',
    'marathiName': 'अश्विनी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Ketu',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '1',
    'rp': '2',
    'nl-o': 'Sco',
    'h': '2',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,10,4',
    'ii-e': ''
  },
  {
    'id': 3,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(6°40)-Ari(10°0)',
    'deity': 'Ashwini-Kumaras',
    'nk-pada': 'Ashwini-3',
    'nakshatra': 'Ashwini (1)',
    'marathiName': 'अश्विनी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '1',
    'rp': '3',
    'nl-o': 'Sco',
    'h': '2',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '5,1,7',
    'ii-e': 'Sun',
    'ii-d': ''
  },
  {
    'id': 4,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(10°0)-Ari(13°20)',
    'deity': 'Ashwini-Kumaras',
    'nk-pada': 'Ashwini-4',
    'nakshatra': 'Ashwini (1)',
    'marathiName': 'अश्विनी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Ketu',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '1',
    'rp': '4',
    'nl-o': 'Sco',
    'h': '2',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,10,4',
    'ii-e': ''
  },
  {
    'id': 5,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(13°20)-Ari(16°40)',
    'deity': 'Yama',
    'nk-pada': 'Bharani-1',
    'nakshatra': 'Bharani (2)',
    'marathiName': 'भरणी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Venus',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '1',
    'rp': '5',
    'nl-o': 'Tau,Lib',
    'h': '6',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,10,4',
    'ii-e': ''
  },
  {
    'id': 6,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(16°40)-Ari(20°0)',
    'deity': 'Yama',
    'nk-pada': 'Bharani-2',
    'nakshatra': 'Bharani (2)',
    'marathiName': 'भरणी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Venus',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '1',
    'rp': '6',
    'nl-o': 'Tau,Lib',
    'h': '6',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': 'Mars',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '11,7,1',
    'ii-e': 'Saturn'
  },
  {
    'id': 7,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(20°0)-Ari(23°20)',
    'deity': 'Yama',
    'nk-pada': 'Bharani-3',
    'nakshatra': 'Bharani (2)',
    'marathiName': 'भरणी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Venus',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '1',
    'rp': '7',
    'nl-o': 'Tau,Lib',
    'h': '6',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 8,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(23°20)-Ari(26°40)',
    'deity': 'Yama',
    'nk-pada': 'Bharani-4',
    'nakshatra': 'Bharani (2)',
    'marathiName': 'भरणी',
    'rasi-lord': 'Mars',
    'nk-lord': 'Venus',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '1',
    'rp': '8',
    'nl-o': 'Tau,Lib',
    'h': '6',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 9,
    'rasi': 'Ari',
    'rashiIndex': 0,
    'degrees': 'Ari(26°40)-Ari(30°0)',
    'deity': 'Agni',
    'nk-pada': 'Krittika-1',
    'nakshatra': 'Krittika (3)',
    'marathiName': 'कृत्तिका',
    'rasi-lord': 'Mars',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '1',
    'rp': '9',
    'nl-o': 'Leo',
    'h': '7',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 10,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(0°0)-Tau(3°20)',
    'deity': 'Agni',
    'nk-pada': 'Krittika-2',
    'nakshatra': 'Krittika (3)',
    'marathiName': 'कृत्तिका',
    'rasi-lord': 'Venus',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '1',
    'rp': '1',
    'nl-o': 'Leo',
    'h': '6',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 11,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(3°20)-Tau(6°40)',
    'deity': 'Agni',
    'nk-pada': 'Krittika-3',
    'nakshatra': 'Krittika (3)',
    'marathiName': 'कृत्तिका',
    'rasi-lord': 'Venus',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '1',
    'rp': '2',
    'nl-o': 'Leo',
    'h': '6',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 12,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(6°40)-Tau(10°0)',
    'deity': 'Agni',
    'nk-pada': 'Krittika-4',
    'nakshatra': 'Krittika (3)',
    'marathiName': 'कृत्तिका',
    'rasi-lord': 'Venus',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '1',
    'rp': '3',
    'nl-o': 'Leo',
    'h': '6',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 13,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(10°0)-Tau(13°20)',
    'deity': 'Prajapati / Bramha',
    'nk-pada': 'Rohini-1',
    'nakshatra': 'Rohini (4)',
    'marathiName': 'रोहिणी',
    'rasi-lord': 'Venus',
    'nk-lord': 'Moon',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '2',
    'rp': '4',
    'nl-o': 'Can',
    'h': '7',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 14,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(13°20)-Tau(16°40)',
    'deity': 'Prajapati / Bramha',
    'nk-pada': 'Rohini-2',
    'nakshatra': 'Rohini (4)',
    'marathiName': 'रोहिणी',
    'rasi-lord': 'Venus',
    'nk-lord': 'Moon',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '2',
    'rp': '5',
    'nl-o': 'Can',
    'h': '7',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 15,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(16°40)-Tau(20°0)',
    'deity': 'Prajapati / Bramha',
    'nk-pada': 'Rohini-3',
    'nakshatra': 'Rohini (4)',
    'marathiName': 'रोहिणी',
    'rasi-lord': 'Venus',
    'nk-lord': 'Moon',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '2',
    'rp': '6',
    'nl-o': 'Can',
    'h': '7',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '9,7,1',
    'ii-e': 'Rahu',
    'ii-d': 'Ketu'
  },
  {
    'id': 16,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(20°0)-Tau(23°20)',
    'deity': 'Prajapati / Bramha',
    'nk-pada': 'Rohini-4',
    'nakshatra': 'Rohini (4)',
    'marathiName': 'रोहिणी',
    'rasi-lord': 'Venus',
    'nk-lord': 'Moon',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '2',
    'rp': '7',
    'nl-o': 'Can',
    'h': '7',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 17,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(23°20)-Tau(26°40)',
    'deity': 'Soma / Chandra',
    'nk-pada': 'Mrigashirsh-1',
    'nakshatra': 'Mrigashirsh (5)',
    'marathiName': 'मृग/म्रृगशीर्ष',
    'rasi-lord': 'Venus',
    'nk-lord': 'Mars',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '2',
    'rp': '8',
    'nl-o': 'Ari,Sco',
    'h': '3',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 18,
    'rasi': 'Tau',
    'rashiIndex': 1,
    'degrees': 'Tau(26°40)-Tau(30°0)',
    'deity': 'Soma / Chandra',
    'nk-pada': 'Mrigashirsh-2',
    'nakshatra': 'Mrigashirsh (5)',
    'marathiName': 'मृग/म्रृगशीर्ष',
    'rasi-lord': 'Venus',
    'nk-lord': 'Mars',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '2',
    'rp': '9',
    'nl-o': 'Ari,Sco',
    'h': '3',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 19,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(0°0)-Gem(3°20)',
    'deity': 'Soma / Chandra',
    'nk-pada': 'Mrigashirsh-3',
    'nakshatra': 'Mrigashirsh (5)',
    'marathiName': 'मृग/म्रृगशीर्ष',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Mars',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '2',
    'rp': '1',
    'nl-o': 'Ari,Sco',
    'h': '2',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 20,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(3°20)-Gem(6°40)',
    'deity': 'Soma / Chandra',
    'nk-pada': 'Mrigashirsh-4',
    'nakshatra': 'Mrigashirsh (5)',
    'marathiName': 'मृग/म्रृगशीर्ष',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Mars',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '2',
    'rp': '2',
    'nl-o': 'Ari,Sco',
    'h': '2',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 21,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(6°40)-Gem(10°0)',
    'deity': 'Rudra',
    'nk-pada': 'Ardra-1',
    'nakshatra': 'Ardra (6)',
    'marathiName': 'आर्द्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '2',
    'rp': '3',
    'nl-o': 'Aqu',
    'h': '6',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 22,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(10°0)-Gem(13°20)',
    'deity': 'Rudra',
    'nk-pada': 'Ardra-2',
    'nakshatra': 'Ardra (6)',
    'marathiName': 'आर्द्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '2',
    'rp': '4',
    'nl-o': 'Aqu',
    'h': '6',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 23,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(13°20)-Gem(16°40)',
    'deity': 'Rudra',
    'nk-pada': 'Ardra-3',
    'nakshatra': 'Ardra (6)',
    'marathiName': 'आर्द्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '2',
    'rp': '5',
    'nl-o': 'Aqu',
    'h': '6',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 24,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(16°40)-Gem(20°0)',
    'deity': 'Rudra',
    'nk-pada': 'Ardra-4',
    'nakshatra': 'Ardra (6)',
    'marathiName': 'आर्द्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '2',
    'rp': '6',
    'nl-o': 'Aqu',
    'h': '6',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 25,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(20°0)-Gem(23°20)',
    'deity': 'Aditi',
    'nk-pada': 'Punarvasu-1',
    'nakshatra': 'Punarvasu (7)',
    'marathiName': 'पुनर्वसु',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '3',
    'rp': '7',
    'nl-o': 'Sag,Pis',
    'h': '8',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 26,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(23°20)-Gem(26°40)',
    'deity': 'Aditi',
    'nk-pada': 'Punarvasu-2',
    'nakshatra': 'Punarvasu (7)',
    'marathiName': 'पुनर्वसु',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '3',
    'rp': '8',
    'nl-o': 'Sag,Pis',
    'h': '8',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 27,
    'rasi': 'Gem',
    'rashiIndex': 2,
    'degrees': 'Gem(26°40)-Gem(30°0)',
    'deity': 'Aditi',
    'nk-pada': 'Punarvasu-3',
    'nakshatra': 'Punarvasu (7)',
    'marathiName': 'पुनर्वसु',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '3',
    'rp': '9',
    'nl-o': 'Sag,Pis',
    'h': '8',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 28,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(0°0)-Can(3°20)',
    'deity': 'Aditi',
    'nk-pada': 'Punarvasu-4',
    'nakshatra': 'Punarvasu (7)',
    'marathiName': 'पुनर्वसु',
    'rasi-lord': 'Moon',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '3',
    'rp': '1',
    'nl-o': 'Sag,Pis',
    'h': '7',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 29,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(3°20)-Can(6°40)',
    'deity': 'Brhaspati',
    'nk-pada': 'Pushya-1',
    'nakshatra': 'Pushya (8)',
    'marathiName': 'पुष्य',
    'rasi-lord': 'Moon',
    'nk-lord': 'Saturn',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '3',
    'rp': '2',
    'nl-o': 'Cap,Aqu',
    'h': '10',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '6,1,7',
    'ii-e': 'Jupiter',
    'ii-d': ''
  },
  {
    'id': 30,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(6°40)-Can(10°0)',
    'deity': 'Brhaspati',
    'nk-pada': 'Pushya-2',
    'nakshatra': 'Pushya (8)',
    'marathiName': 'पुष्य',
    'rasi-lord': 'Moon',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '3',
    'rp': '3',
    'nl-o': 'Cap,Aqu',
    'h': '10',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 31,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(10°0)-Can(13°20)',
    'deity': 'Brhaspati',
    'nk-pada': 'Pushya-3',
    'nakshatra': 'Pushya (8)',
    'marathiName': 'पुष्य',
    'rasi-lord': 'Moon',
    'nk-lord': 'Saturn',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '3',
    'rp': '4',
    'nl-o': 'Cap,Aqu',
    'h': '10',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 32,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(13°20)-Can(16°40)',
    'deity': 'Brhaspati',
    'nk-pada': 'Pushya-4',
    'nakshatra': 'Pushya (8)',
    'marathiName': 'पुष्य',
    'rasi-lord': 'Moon',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '3',
    'rp': '5',
    'nl-o': 'Cap,Aqu',
    'h': '10',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 33,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(16°40)-Can(20°0)',
    'deity': 'Sarpas(Nagas)(Rahu)',
    'nk-pada': 'Ashlesha-1',
    'nakshatra': 'Ashlesha (9)',
    'marathiName': 'आश्लेषा',
    'rasi-lord': 'Moon',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '3',
    'rp': '6',
    'nl-o': 'Gem,Vir',
    'h': '9',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 34,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(20°0)-Can(23°20)',
    'deity': 'Sarpas(Nagas)(Rahu)',
    'nk-pada': 'Ashlesha-2',
    'nakshatra': 'Ashlesha (9)',
    'marathiName': 'आश्लेषा',
    'rasi-lord': 'Moon',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '3',
    'rp': '7',
    'nl-o': 'Gem,Vir',
    'h': '9',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 35,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(23°20)-Can(26°40)',
    'deity': 'Sarpas(Nagas)(Rahu)',
    'nk-pada': 'Ashlesha-3',
    'nakshatra': 'Ashlesha (9)',
    'marathiName': 'आश्लेषा',
    'rasi-lord': 'Moon',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '3',
    'rp': '8',
    'nl-o': 'Gem,Vir',
    'h': '9',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 36,
    'rasi': 'Can',
    'rashiIndex': 3,
    'degrees': 'Can(26°40)-Can(30°0)',
    'deity': 'Sarpas(Nagas)(Rahu)',
    'nk-pada': 'Ashlesha-4',
    'nakshatra': 'Ashlesha (9)',
    'marathiName': 'आश्लेषा',
    'rasi-lord': 'Moon',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '3',
    'rp': '9',
    'nl-o': 'Gem,Vir',
    'h': '9',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Rahu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '10,7,1',
    'ii-e': 'Mars'
  },
  {
    'id': 37,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(0°0)-Leo(3°20)',
    'deity': 'Pitar',
    'nk-pada': 'Magha-1',
    'nakshatra': 'Magha (10)',
    'marathiName': 'मघा',
    'rasi-lord': 'Sun',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '4',
    'rp': '1',
    'nl-o': 'Sco',
    'h': '10',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Sun',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 38,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(3°20)-Leo(6°40)',
    'deity': 'Pitar',
    'nk-pada': 'Magha-2',
    'nakshatra': 'Magha (10)',
    'marathiName': 'मघा',
    'rasi-lord': 'Sun',
    'nk-lord': 'Ketu',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '4',
    'rp': '2',
    'nl-o': 'Sco',
    'h': '10',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Sun',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 39,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(6°40)-Leo(10°0)',
    'deity': 'Pitar',
    'nk-pada': 'Magha-3',
    'nakshatra': 'Magha (10)',
    'marathiName': 'मघा',
    'rasi-lord': 'Sun',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '4',
    'rp': '3',
    'nl-o': 'Sco',
    'h': '10',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Sun',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 40,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(10°0)-Leo(13°20)',
    'deity': 'Pitar',
    'nk-pada': 'Magha-4',
    'nakshatra': 'Magha (10)',
    'marathiName': 'मघा',
    'rasi-lord': 'Sun',
    'nk-lord': 'Ketu',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '4',
    'rp': '4',
    'nl-o': 'Sco',
    'h': '10',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Sun',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 41,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(13°20)-Leo(16°40)',
    'deity': 'Bhag(Shiva)',
    'nk-pada': 'PurvaPhalguni-1',
    'nakshatra': 'PurvaPhalguni (11)',
    'marathiName': 'पूर्वा फाल्गुनी',
    'rasi-lord': 'Sun',
    'nk-lord': 'Venus',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '4',
    'rp': '5',
    'nl-o': 'Tau,Lib',
    'h': '2',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 42,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(16°40)-Leo(20°0)',
    'deity': 'Bhag(Shiva)',
    'nk-pada': 'PurvaPhalguni-2',
    'nakshatra': 'PurvaPhalguni (11)',
    'marathiName': 'पूर्वा फाल्गुनी',
    'rasi-lord': 'Sun',
    'nk-lord': 'Venus',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '4',
    'rp': '6',
    'nl-o': 'Tau,Lib',
    'h': '2',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 43,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(20°0)-Leo(23°20)',
    'deity': 'Bhag(Shiva)',
    'nk-pada': 'PurvaPhalguni-3',
    'nakshatra': 'PurvaPhalguni (11)',
    'marathiName': 'पूर्वा फाल्गुनी',
    'rasi-lord': 'Sun',
    'nk-lord': 'Venus',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '4',
    'rp': '7',
    'nl-o': 'Tau,Lib',
    'h': '2',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 44,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(23°20)-Leo(26°40)',
    'deity': 'Bhag(Shiva)',
    'nk-pada': 'PurvaPhalguni-4',
    'nakshatra': 'PurvaPhalguni (11)',
    'marathiName': 'पूर्वा फाल्गुनी',
    'rasi-lord': 'Sun',
    'nk-lord': 'Venus',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '4',
    'rp': '8',
    'nl-o': 'Tau,Lib',
    'h': '2',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 45,
    'rasi': 'Leo',
    'rashiIndex': 4,
    'degrees': 'Leo(26°40)-Leo(30°0)',
    'deity': 'Aryaman',
    'nk-pada': 'Uttaraphalguni-1',
    'nakshatra': 'Uttaraphalguni (12)',
    'marathiName': 'उत्तरा फाल्गुनी',
    'rasi-lord': 'Sun',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '4',
    'rp': '9',
    'nl-o': 'Leo',
    'h': '3',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 46,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(0°0)-Vir(3°20)',
    'deity': 'Aryaman',
    'nk-pada': 'Uttaraphalguni-2',
    'nakshatra': 'Uttaraphalguni (12)',
    'marathiName': 'उत्तरा फाल्गुनी',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '4',
    'rp': '1',
    'nl-o': 'Leo',
    'h': '2',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 47,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(3°20)-Vir(6°40)',
    'deity': 'Aryaman',
    'nk-pada': 'Uttaraphalguni-3',
    'nakshatra': 'Uttaraphalguni (12)',
    'marathiName': 'उत्तरा फाल्गुनी',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '4',
    'rp': '2',
    'nl-o': 'Leo',
    'h': '2',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 48,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(6°40)-Vir(10°0)',
    'deity': 'Aryaman',
    'nk-pada': 'Uttaraphalguni-4',
    'nakshatra': 'Uttaraphalguni (12)',
    'marathiName': 'उत्तरा फाल्गुनी',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '4',
    'rp': '3',
    'nl-o': 'Leo',
    'h': '2',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 49,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(10°0)-Vir(13°20)',
    'deity': 'Savitr(Sun)',
    'nk-pada': 'Hasta-1',
    'nakshatra': 'Hasta (13)',
    'marathiName': 'हस्त',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Moon',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '5',
    'rp': '4',
    'nl-o': 'Can',
    'h': '3',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 50,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(13°20)-Vir(16°40)',
    'deity': 'Savitr(Sun)',
    'nk-pada': 'Hasta-2',
    'nakshatra': 'Hasta (13)',
    'marathiName': 'हस्त',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Moon',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '5',
    'rp': '5',
    'nl-o': 'Can',
    'h': '3',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Mercury',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': 'Mercury',
    'ii-d': ''
  },
  {
    'id': 51,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(16°40)-Vir(20°0)',
    'deity': 'Savitr(Sun)',
    'nk-pada': 'Hasta-3',
    'nakshatra': 'Hasta (13)',
    'marathiName': 'हस्त',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Moon',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '5',
    'rp': '6',
    'nl-o': 'Can',
    'h': '3',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Mercury',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,1,7',
    'ii-e': ''
  },
  {
    'id': 52,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(20°0)-Vir(23°20)',
    'deity': 'Savitr(Sun)',
    'nk-pada': 'Hasta-4',
    'nakshatra': 'Hasta (13)',
    'marathiName': 'हस्त',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Moon',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '5',
    'rp': '7',
    'nl-o': 'Can',
    'h': '3',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 53,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(23°20)-Vir(26°40)',
    'deity': 'Twasta / Vishwakarma',
    'nk-pada': 'Chitra-1',
    'nakshatra': 'Chitra (14)',
    'marathiName': 'चित्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Mars',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '5',
    'rp': '8',
    'nl-o': 'Ari,Sco',
    'h': '11',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 54,
    'rasi': 'Vir',
    'rashiIndex': 5,
    'degrees': 'Vir(26°40)-Vir(30°0)',
    'deity': 'Twasta / Vishwakarma',
    'nk-pada': 'Chitra-2',
    'nakshatra': 'Chitra (14)',
    'marathiName': 'चित्रा',
    'rasi-lord': 'Mercury',
    'nk-lord': 'Mars',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '5',
    'rp': '9',
    'nl-o': 'Ari,Sco',
    'h': '11',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 55,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(0°0)-Lib(3°20)',
    'deity': 'Twasta / Vishwakarma',
    'nk-pada': 'Chitra-3',
    'nakshatra': 'Chitra (14)',
    'marathiName': 'चित्रा',
    'rasi-lord': 'Venus',
    'nk-lord': 'Mars',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '5',
    'rp': '1',
    'nl-o': 'Ari,Sco',
    'h': '10',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Venus',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,6,12',
    'ii-e': ''
  },
  {
    'id': 56,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(3°20)-Lib(6°40)',
    'deity': 'Twasta / Vishwakarma',
    'nk-pada': 'Chitra-4',
    'nakshatra': 'Chitra (14)',
    'marathiName': 'चित्रा',
    'rasi-lord': 'Venus',
    'nk-lord': 'Mars',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '5',
    'rp': '2',
    'nl-o': 'Ari,Sco',
    'h': '10',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Venus',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,6,12',
    'ii-e': ''
  },
  {
    'id': 57,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(6°40)-Lib(10°0)',
    'deity': 'Vayu',
    'nk-pada': 'Swati-1',
    'nakshatra': 'Swati (15)',
    'marathiName': 'स्वाती',
    'rasi-lord': 'Venus',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '5',
    'rp': '3',
    'nl-o': 'Aqu',
    'h': '2',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Venus',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '11,7,1',
    'ii-e': 'Sun'
  },
  {
    'id': 58,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(10°0)-Lib(13°20)',
    'deity': 'Vayu',
    'nk-pada': 'Swati-2',
    'nakshatra': 'Swati (15)',
    'marathiName': 'स्वाती',
    'rasi-lord': 'Venus',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '5',
    'rp': '4',
    'nl-o': 'Aqu',
    'h': '2',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 59,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(13°20)-Lib(16°40)',
    'deity': 'Vayu',
    'nk-pada': 'Swati-3',
    'nakshatra': 'Swati (15)',
    'marathiName': 'स्वाती',
    'rasi-lord': 'Venus',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '5',
    'rp': '5',
    'nl-o': 'Aqu',
    'h': '2',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 60,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(16°40)-Lib(20°0)',
    'deity': 'Vayu',
    'nk-pada': 'Swati-4',
    'nakshatra': 'Swati (15)',
    'marathiName': 'स्वाती',
    'rasi-lord': 'Venus',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '5',
    'rp': '6',
    'nl-o': 'Aqu',
    'h': '2',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '5,1,7',
    'ii-e': ''
  },
  {
    'id': 61,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(20°0)-Lib(23°20)',
    'deity': 'Indra & Agni',
    'nk-pada': 'Vishakha-1',
    'nakshatra': 'Vishakha (16)',
    'marathiName': 'विशाखा',
    'rasi-lord': 'Venus',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '6',
    'rp': '7',
    'nl-o': 'Sag,Pis',
    'h': '4',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 62,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(23°20)-Lib(26°40)',
    'deity': 'Indra & Agni',
    'nk-pada': 'Vishakha-2',
    'nakshatra': 'Vishakha (16)',
    'marathiName': 'विशाखा',
    'rasi-lord': 'Venus',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '6',
    'rp': '8',
    'nl-o': 'Sag,Pis',
    'h': '4',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 63,
    'rasi': 'Lib',
    'rashiIndex': 6,
    'degrees': 'Lib(26°40)-Lib(30°0)',
    'deity': 'Indra & Agni',
    'nk-pada': 'Vishakha-3',
    'nakshatra': 'Vishakha (16)',
    'marathiName': 'विशाखा',
    'rasi-lord': 'Venus',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '6',
    'rp': '9',
    'nl-o': 'Sag,Pis',
    'h': '4',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 64,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(0°0)-Sco(3°20)',
    'deity': 'Indra & Agni',
    'nk-pada': 'Vishakha-4',
    'nakshatra': 'Vishakha (16)',
    'marathiName': 'विशाखा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '6',
    'rp': '1',
    'nl-o': 'Sag,Pis',
    'h': '3',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': 'Moon',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '7,7,1'
  },
  {
    'id': 65,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(3°20)-Sco(6°40)',
    'deity': 'Mitra(Sun)',
    'nk-pada': 'Anuradha-1',
    'nakshatra': 'Anuradha (17)',
    'marathiName': 'अनुराधा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Saturn',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '6',
    'rp': '2',
    'nl-o': 'Cap,Aqu',
    'h': '6',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 66,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(6°40)-Sco(10°0)',
    'deity': 'Mitra(Sun)',
    'nk-pada': 'Anuradha-2',
    'nakshatra': 'Anuradha (17)',
    'marathiName': 'अनुराधा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '6',
    'rp': '3',
    'nl-o': 'Cap,Aqu',
    'h': '6',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 67,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(10°0)-Sco(13°20)',
    'deity': 'Mitra(Sun)',
    'nk-pada': 'Anuradha-3',
    'nakshatra': 'Anuradha (17)',
    'marathiName': 'अनुराधा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Saturn',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '6',
    'rp': '4',
    'nl-o': 'Cap,Aqu',
    'h': '6',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 68,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(13°20)-Sco(16°40)',
    'deity': 'Mitra(Sun)',
    'nk-pada': 'Anuradha-4',
    'nakshatra': 'Anuradha (17)',
    'marathiName': 'अनुराधा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '6',
    'rp': '5',
    'nl-o': 'Cap,Aqu',
    'h': '6',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 69,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(16°40)-Sco(20°0)',
    'deity': 'Indra',
    'nk-pada': 'Jyeshtha-1',
    'nakshatra': 'Jyeshtha (18)',
    'marathiName': 'ज्येष्ठा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '6',
    'rp': '6',
    'nl-o': 'Gem,Vir',
    'h': '5',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '9,7,1',
    'ii-e': 'Rahu'
  },
  {
    'id': 70,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(20°0)-Sco(23°20)',
    'deity': 'Indra',
    'nk-pada': 'Jyeshtha-2',
    'nakshatra': 'Jyeshtha (18)',
    'marathiName': 'ज्येष्ठा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '6',
    'rp': '7',
    'nl-o': 'Gem,Vir',
    'h': '5',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 71,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(23°20)-Sco(26°40)',
    'deity': 'Indra',
    'nk-pada': 'Jyeshtha-3',
    'nakshatra': 'Jyeshtha (18)',
    'marathiName': 'ज्येष्ठा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '6',
    'rp': '8',
    'nl-o': 'Gem,Vir',
    'h': '5',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 72,
    'rasi': 'Sco',
    'rashiIndex': 7,
    'degrees': 'Sco(26°40)-Sco(30°0)',
    'deity': 'Indra',
    'nk-pada': 'Jyeshtha-4',
    'nakshatra': 'Jyeshtha (18)',
    'marathiName': 'ज्येष्ठा',
    'rasi-lord': 'Mars',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '6',
    'rp': '9',
    'nl-o': 'Gem,Vir',
    'h': '5',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 73,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(0°0)-Sag(3°20)',
    'deity': 'Nirrati',
    'nk-pada': 'Moola-1',
    'nakshatra': 'Mula (19)',
    'marathiName': 'मूळ',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '7',
    'rp': '1',
    'nl-o': 'Sco',
    'h': '6',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Jupiter',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,8,2',
    'ii-e': ''
  },
  {
    'id': 74,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(3°20)-Sag(6°40)',
    'deity': 'Nirrati',
    'nk-pada': 'Moola-2',
    'nakshatra': 'Mula (19)',
    'marathiName': 'मूळ',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Ketu',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '7',
    'rp': '2',
    'nl-o': 'Sco',
    'h': '6',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Jupiter',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,8,2',
    'ii-e': ''
  },
  {
    'id': 75,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(6°40)-Sag(10°0)',
    'deity': 'Nirrati',
    'nk-pada': 'Moola-3',
    'nakshatra': 'Mula (19)',
    'marathiName': 'मूळ',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Ketu',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '7',
    'rp': '3',
    'nl-o': 'Sco',
    'h': '6',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Jupiter',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,8,2',
    'ii-e': ''
  },
  {
    'id': 76,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(10°0)-Sag(13°20)',
    'deity': 'Nirrati',
    'nk-pada': 'Moola-4',
    'nakshatra': 'Mula (19)',
    'marathiName': 'मूळ',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Ketu',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '7',
    'rp': '4',
    'nl-o': 'Sco',
    'h': '6',
    'nl-m': 'Cap',
    'nl-e': 'Sco',
    'nl-d': 'Tau',
    'ii-m': 'Jupiter',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,8,2',
    'ii-e': ''
  },
  {
    'id': 77,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(13°20)-Sag(16°40)',
    'deity': 'Apah(Water)',
    'nk-pada': 'PurvaAshadha-1',
    'nakshatra': 'PurvaAshadha (20)',
    'marathiName': 'पूर्वाषाढा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Venus',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '7',
    'rp': '5',
    'nl-o': 'Tau,Lib',
    'h': '10',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 78,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(16°40)-Sag(20°0)',
    'deity': 'Apah(Water)',
    'nk-pada': 'PurvaAshadha-2',
    'nakshatra': 'PurvaAshadha (20)',
    'marathiName': 'पूर्वाषाढा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Venus',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '7',
    'rp': '6',
    'nl-o': 'Tau,Lib',
    'h': '10',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 79,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(20°0)-Sag(23°20)',
    'deity': 'Apah(Water)',
    'nk-pada': 'PurvaAshadha-3',
    'nakshatra': 'PurvaAshadha (20)',
    'marathiName': 'पूर्वाषाढा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Venus',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '7',
    'rp': '7',
    'nl-o': 'Tau,Lib',
    'h': '10',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 80,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(23°20)-Sag(26°40)',
    'deity': 'Apah(Water)',
    'nk-pada': 'PurvaAshadha-4',
    'nakshatra': 'PurvaAshadha (20)',
    'marathiName': 'पूर्वाषाढा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Venus',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '7',
    'rp': '8',
    'nl-o': 'Tau,Lib',
    'h': '10',
    'nl-m': 'Lib',
    'nl-e': 'Pis',
    'nl-d': 'Vir',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 81,
    'rasi': 'Sag',
    'rashiIndex': 8,
    'degrees': 'Sag(26°40)-Sag(30°0)',
    'deity': 'Vishwedeva',
    'nk-pada': 'UttaraAshadha-1',
    'nakshatra': 'UttaraAshadha (21)',
    'marathiName': 'उत्तराषाढा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '7',
    'rp': '9',
    'nl-o': 'Leo',
    'h': '11',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 82,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(0°0)-Cap(3°20)',
    'deity': 'Vishwedeva',
    'nk-pada': 'UttaraAshadha-2',
    'nakshatra': 'UttaraAshadha (21)',
    'marathiName': 'उत्तराषाढा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '7',
    'rp': '1',
    'nl-o': 'Leo',
    'h': '10',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 83,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(3°20)-Cap(6°40)',
    'deity': 'Vishwedeva',
    'nk-pada': 'UttaraAshadha-3',
    'nakshatra': 'UttaraAshadha (21)',
    'marathiName': 'उत्तराषाढा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Sun',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '7',
    'rp': '2',
    'nl-o': 'Leo',
    'h': '10',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '12,7,1',
    'ii-e': 'Jupiter'
  },
  {
    'id': 84,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(6°40)-Cap(10°0)',
    'deity': 'Vishwedeva',
    'nk-pada': 'UttaraAshadha-4',
    'nakshatra': 'UttaraAshadha (21)',
    'marathiName': 'उत्तराषाढा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Sun',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '7',
    'rp': '3',
    'nl-o': 'Leo',
    'h': '10',
    'nl-m': 'Leo',
    'nl-e': 'Ari',
    'nl-d': 'Lib',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 85,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(10°0)-Cap(13°20)',
    'deity': 'Vishnu',
    'nk-pada': 'Shravana-1',
    'nakshatra': 'Shravana (22)',
    'marathiName': 'श्रवण',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Moon',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '8',
    'rp': '4',
    'nl-o': 'Can',
    'h': '11',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 86,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(13°20)-Cap(16°40)',
    'deity': 'Vishnu',
    'nk-pada': 'Shravana-2',
    'nakshatra': 'Shravana (22)',
    'marathiName': 'श्रवण',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Moon',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '8',
    'rp': '5',
    'nl-o': 'Can',
    'h': '11',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 87,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(16°40)-Cap(20°0)',
    'deity': 'Vishnu',
    'nk-pada': 'Shravana-3',
    'nakshatra': 'Shravana (22)',
    'marathiName': 'श्रवण',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Moon',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '8',
    'rp': '6',
    'nl-o': 'Can',
    'h': '11',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 88,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(20°0)-Cap(23°20)',
    'deity': 'Vishnu',
    'nk-pada': 'Shravana-4',
    'nakshatra': 'Shravana (22)',
    'marathiName': 'श्रवण',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Moon',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '8',
    'rp': '7',
    'nl-o': 'Can',
    'h': '11',
    'nl-m': 'Tau',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 89,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(23°20)-Cap(26°40)',
    'deity': 'Asta Vasu(Indra)',
    'nk-pada': 'Dhanishtha-1',
    'nakshatra': 'Dhanishtha (23)',
    'marathiName': 'धनिष्ठा/श्रविष्ठा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Mars',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '8',
    'rp': '8',
    'nl-o': 'Ari,Sco',
    'h': '7',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 90,
    'rasi': 'Cap',
    'rashiIndex': 9,
    'degrees': 'Cap(26°40)-Cap(30°0)',
    'deity': 'Asta Vasu(Indra)',
    'nk-pada': 'Dhanishtha-2',
    'nakshatra': 'Dhanishtha (23)',
    'marathiName': 'धनिष्ठा/श्रविष्ठा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Mars',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '8',
    'rp': '9',
    'nl-o': 'Ari,Sco',
    'h': '7',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Ketu',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,11,5',
    'ii-e': ''
  },
  {
    'id': 91,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(0°0)-Aqu(3°20)',
    'deity': 'Asta Vasu(Indra)',
    'nk-pada': 'Dhanishtha-3',
    'nakshatra': 'Dhanishtha (23)',
    'marathiName': 'धनिष्ठा/श्रविष्ठा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Mars',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '8',
    'rp': '1',
    'nl-o': 'Ari,Sco',
    'h': '6',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 92,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(3°20)-Aqu(6°40)',
    'deity': 'Asta Vasu(Indra)',
    'nk-pada': 'Dhanishtha-4',
    'nakshatra': 'Dhanishtha (23)',
    'marathiName': 'धनिष्ठा/श्रविष्ठा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Mars',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '8',
    'rp': '2',
    'nl-o': 'Ari,Sco',
    'h': '6',
    'nl-m': 'Ari',
    'nl-e': 'Cap',
    'nl-d': 'Can',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 93,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(6°40)-Aqu(10°0)',
    'deity': 'Varuna',
    'nk-pada': 'Shatabhisha-1',
    'nakshatra': 'Shatabhisha (24)',
    'marathiName': 'शततारका/शतभिषज',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '8',
    'rp': '3',
    'nl-o': 'Aqu',
    'h': '10',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 94,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(10°0)-Aqu(13°20)',
    'deity': 'Varuna',
    'nk-pada': 'Shatabhisha-2',
    'nakshatra': 'Shatabhisha (24)',
    'marathiName': 'शततारका/शतभिषज',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '8',
    'rp': '4',
    'nl-o': 'Aqu',
    'h': '10',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 95,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(13°20)-Aqu(16°40)',
    'deity': 'Varuna',
    'nk-pada': 'Shatabhisha-3',
    'nakshatra': 'Shatabhisha (24)',
    'marathiName': 'शततारका/शतभिषज',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Rahu',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '8',
    'rp': '5',
    'nl-o': 'Aqu',
    'h': '10',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 96,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(16°40)-Aqu(20°0)',
    'deity': 'Varuna',
    'nk-pada': 'Shatabhisha-4',
    'nakshatra': 'Shatabhisha (24)',
    'marathiName': 'शततारका/शतभिषज',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Rahu',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '8',
    'rp': '6',
    'nl-o': 'Aqu',
    'h': '10',
    'nl-m': 'Can',
    'nl-e': 'Tau',
    'nl-d': 'Sco',
    'ii-m': 'Saturn',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '1,9,3',
    'ii-e': ''
  },
  {
    'id': 97,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(20°0)-Aqu(23°20)',
    'deity': 'Aja Ekpada(Bramha)',
    'nk-pada': 'PurvaBhadrapada-1',
    'nakshatra': 'PurvaBhadrapada (25)',
    'marathiName': 'पूर्वाभाद्रपदा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mars',
    'purushartha': 'Dharma',
    'sc': '9',
    'rp': '7',
    'nl-o': 'Sag,Pis',
    'h': '12',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 98,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(23°20)-Aqu(26°40)',
    'deity': 'Aja Ekpada(Bramha)',
    'nk-pada': 'PurvaBhadrapada-2',
    'nakshatra': 'PurvaBhadrapada (25)',
    'marathiName': 'पूर्वाभाद्रपदा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Venus',
    'purushartha': 'Artha',
    'sc': '9',
    'rp': '8',
    'nl-o': 'Sag,Pis',
    'h': '12',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 99,
    'rasi': 'Aqu',
    'rashiIndex': 10,
    'degrees': 'Aqu(26°40)-Aqu(30°0)',
    'deity': 'Aja Ekpada(Bramha)',
    'nk-pada': 'PurvaBhadrapada-3',
    'nakshatra': 'PurvaBhadrapada (25)',
    'marathiName': 'पूर्वाभाद्रपदा',
    'rasi-lord': 'Saturn',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Mercury',
    'purushartha': 'Kama',
    'sc': '9',
    'rp': '9',
    'nl-o': 'Sag,Pis',
    'h': '12',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 100,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(0°0)-Pis(3°20)',
    'deity': 'Aja Ekpada(Bramha)',
    'nk-pada': 'PurvaBhadrapada-4',
    'nakshatra': 'PurvaBhadrapada (25)',
    'marathiName': 'पूर्वाभाद्रपदा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Jupiter',
    'nav-lord': 'Moon',
    'purushartha': 'Moksha',
    'sc': '9',
    'rp': '1',
    'nl-o': 'Sag,Pis',
    'h': '11',
    'nl-m': 'Sag',
    'nl-e': 'Can',
    'nl-d': 'Cap',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 101,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(3°20)-Pis(6°40)',
    'deity': 'Ahir Bundhya',
    'nk-pada': 'UttaraBhadrapada-1',
    'nakshatra': 'UttaraBhadrapada (26)',
    'marathiName': 'उत्तराभाद्रपदा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Saturn',
    'nav-lord': 'Sun',
    'purushartha': 'Dharma',
    'sc': '9',
    'rp': '2',
    'nl-o': 'Cap,Aqu',
    'h': '2',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 102,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(6°40)-Pis(10°0)',
    'deity': 'Ahir Bundhya',
    'nk-pada': 'UttaraBhadrapada-2',
    'nakshatra': 'UttaraBhadrapada (26)',
    'marathiName': 'उत्तराभाद्रपदा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mercury',
    'purushartha': 'Artha',
    'sc': '9',
    'rp': '3',
    'nl-o': 'Cap,Aqu',
    'h': '2',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 103,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(10°0)-Pis(13°20)',
    'deity': 'Ahir Bundhya',
    'nk-pada': 'UttaraBhadrapada-3',
    'nakshatra': 'UttaraBhadrapada (26)',
    'marathiName': 'उत्तराभाद्रपदा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Saturn',
    'nav-lord': 'Venus',
    'purushartha': 'Kama',
    'sc': '9',
    'rp': '4',
    'nl-o': 'Cap,Aqu',
    'h': '2',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 104,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(13°20)-Pis(16°40)',
    'deity': 'Ahir Bundhya',
    'nk-pada': 'UttaraBhadrapada-4',
    'nakshatra': 'UttaraBhadrapada (26)',
    'marathiName': 'उत्तराभाद्रपदा',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Saturn',
    'nav-lord': 'Mars',
    'purushartha': 'Moksha',
    'sc': '9',
    'rp': '5',
    'nl-o': 'Cap,Aqu',
    'h': '2',
    'nl-m': 'Aqu',
    'nl-e': 'Lib',
    'nl-d': 'Ari',
    'ii-m': 'Mercury',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '7,7,1'
  },
  {
    'id': 105,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(16°40)-Pis(20°0)',
    'deity': 'Poosham / Pusha',
    'nk-pada': 'Revati-1',
    'nakshatra': 'Revati (27)',
    'marathiName': 'रेवती',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Dharma',
    'sc': '9',
    'rp': '6',
    'nl-o': 'Gem,Vir',
    'h': '1',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 106,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(20°0)-Pis(23°20)',
    'deity': 'Poosham / Pusha',
    'nk-pada': 'Revati-2',
    'nakshatra': 'Revati (27)',
    'marathiName': 'रेवती',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Artha',
    'sc': '9',
    'rp': '7',
    'nl-o': 'Gem,Vir',
    'h': '1',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 107,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(23°20)-Pis(26°40)',
    'deity': 'Poosham / Pusha',
    'nk-pada': 'Revati-3',
    'nakshatra': 'Revati (27)',
    'marathiName': 'रेवती',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Mercury',
    'nav-lord': 'Saturn',
    'purushartha': 'Kama',
    'sc': '9',
    'rp': '8',
    'nl-o': 'Gem,Vir',
    'h': '1',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': '',
    'title': '',
    'smallDescriptions': ''
  },
  {
    'id': 108,
    'rasi': 'Pis',
    'rashiIndex': 11,
    'degrees': 'Pis(26°40)-Pis(30°0)',
    'deity': 'Poosham / Pusha',
    'nk-pada': 'Revati-4',
    'nakshatra': 'Revati (27)',
    'marathiName': 'रेवती',
    'rasi-lord': 'Jupiter',
    'nk-lord': 'Mercury',
    'nav-lord': 'Jupiter',
    'purushartha': 'Moksha',
    'sc': '9',
    'rp': '9',
    'nl-o': 'Gem,Vir',
    'h': '1',
    'nl-m': 'Vir',
    'nl-e': 'Vir',
    'nl-d': 'Pis',
    'ii-m': 'Venus',
    'title': '',
    'smallDescriptions': '',
    'm,e,d(h)': '8,1,7',
    'ii-e': ''
  }
])
