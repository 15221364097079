const house1202Y = 21;
const house0206X = 24;
const house0608Y = 83;
const house0305X = 19;
const house0311Y = 26;

module.exports = Object.freeze({
  houses: [
    {
      houseIndex: 0,
      label: `1`,
      key: `ghaar-1`,
      name: `house-1`,
      houseName: `firstHouse`,
      nakshatraCoordinates: {
        x: 45,
        y: 10,
      },
      labelCoordinates: {
        x: 48.5,
        y: 46,
      },
    },
    {
      houseIndex: 1,
      label: `2`,
      key: `ghaar-2`,
      name: `house-2`,
      houseName: `secondHouse`,
      nakshatraCoordinates: {
        x: 24,
        y: 23,
      },
      labelCoordinates: {
        x: house0206X,
        y: house1202Y,
      },
    },
    {
      houseIndex: 2,
      label: `3`,
      key: `ghaar-3`,
      name: `house-3`,
      houseName: `thirdHouse`,
      nakshatraCoordinates: {
        x: 21,
        y: 26,
      },
      labelCoordinates: {
        x: house0305X,
        y: house0311Y,
      },
    },
    {
      houseIndex: 3,
      label: `4`,
      key: `ghaar-4`,
      name: `house-4`,
      houseName: `fourthHouse`,
      nakshatraCoordinates: {
        x: 46,
        y: 51,
      },
      labelCoordinates: {
        x: 46,
        y: 51,
      },
    },
    {
      houseIndex: 4,
      label: `5`,
      key: `ghaar-5`,
      name: `house-5`,
      houseName: `fifthHouse`,
      nakshatraCoordinates: {
        x: 21,
        y: 76,
      },
      labelCoordinates: {
        x: house0305X,
        y: 76,
      },
    },
    {
      houseIndex: 5,
      label: `6`,
      key: `ghaar-6`,
      name: `house-6`,
      houseName: `sixthHouse`,
      nakshatraCoordinates: {
        x: 24,
        y: 78,
      },
      labelCoordinates: {
        x: house0206X,
        y: house0608Y,
      },
    },
    {
      houseIndex: 6,
      label: `7`,
      key: `ghaar-7`,
      name: `house-7`,
      houseName: `seventhHouse`,
      nakshatraCoordinates: {
        x: 49,
        y: 54,
      },
      labelCoordinates: {
        x: 49,
        y: 54,
      },
    },
    {
      houseIndex: 7,
      label: `8`,
      key: `ghaar-8`,
      name: `house-8`,
      houseName: `eighthHouse`,
      nakshatraCoordinates: {
        x: 74,
        y: 79,
      },
      labelCoordinates: {
        x: 74,
        y: house0608Y,
      },
    },
    {
      houseIndex: 8,
      label: `9`,
      key: `ghaar-9`,
      name: `house-9`,
      houseName: `ninethHouse`,
      nakshatraCoordinates: {
        x: 77,
        y: 76,
      },
      labelCoordinates: {
        x: 77,
        y: 76,
      },
    },
    {
      houseIndex: 9,
      label: `10`,
      key: `ghaar-10`,
      name: `house-10`,
      houseName: `tenthHouse`,
      nakshatraCoordinates: {
        x: 51,
        y: 51,
      },
      labelCoordinates: {
        x: 52,
        y: 51,
      },
    },
    {
      houseIndex: 10,
      label: `11`,
      key: `ghaar-11`,
      name: `house-11`,
      houseName: `eleventhHouse`,
      nakshatraCoordinates: {
        x: 77,
        y: 26,
      },
      labelCoordinates: {
        x: 77,
        y: house0311Y,
      },
    },
    {
      houseIndex: 11,
      label: `12`,
      key: `ghaar-12`,
      name: `house-12`,
      houseName: `twelvethHouse`,
      nakshatraCoordinates: {
        x: 73,
        y: 23,
      },
      labelCoordinates: {
        x: 74,
        y: house1202Y,
      },
    },
  ],
});
