import React, { useState } from 'react';
import { Card, Modal } from 'semantic-ui-react';
import { Tr, Td } from 'react-super-responsive-table';
import GrahaCardContent from './GrahaCardContent';

const GrahaToggleRowContent = ({ graha }) => {
  const [isShown, setIsShown] = useState(false);

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  return (
    <Modal
      key={graha.id}
      size="fullscreen"
      trigger={
        <Tr key={graha.id} onClick={toggler}>
          <Td>{graha.graha}</Td>
          <Td>{graha.uchhaRas}</Td>
          <Td>{graha.nicchaRas}</Td>
          <Td>{graha.swaGraha}</Td>
          <Td>{graha.color}</Td>
          <Td>{graha.direction}</Td>
          <Td>{graha.aspects}</Td>
          <Td>{graha.friends}</Td>
          <Td>{graha.enemies}</Td>
          <Td>{graha.neutral}</Td>
          <Td>{graha.ages.join(', ')}</Td>
          <Td>{graha.linga}</Td>
          <Td>{graha.alphabetsSanskrit?.join(', ')}</Td>
        </Tr>
      }
      open={isShown}
      centered={false}>
      <Card fluid key={graha.id} className="toggle">
        <GrahaCardContent graha={graha} toggler={toggler} />
      </Card>
    </Modal>
  );
};

export default GrahaToggleRowContent;
