import React, {
  useEffect,
} from 'react';
import {
  Tab,
} from 'semantic-ui-react'
import PlanetBasic from './PlanetBasic';
import PlanetBusiness from './PlanetBusiness';

import 'semantic-ui-css/semantic.min.css'

const panes = [
  {
    menuItem: 'Basic',
    render: () => <Tab.Pane>
      <PlanetBasic />
    </Tab.Pane>
  }, {
    menuItem: 'Business',
    render: () => <Tab.Pane>
      <PlanetBusiness />
    </Tab.Pane>
  },
];
const Planets = () => {

  useEffect(() => {
    document.title = 'KP Planets'
  }, []);

  return (
    <div>
      <h2>Planets</h2>
      <Tab panes={panes} />
    </div>
  )
};

export default Planets;
