import React, { useEffect } from 'react';
import { BasicNakshatras, RememberNakshatras } from './Nakshatras';

const Nakshatra = () => {
  useEffect(() => {
    document.title = 'Nakshatras'
  }, []);


  return (
    <>
      <BasicNakshatras />
      <RememberNakshatras />
    </>
  )
};

export default Nakshatra
