import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Accordion,
  Checkbox,
  Divider,
  Dropdown,
  Icon,
  Label,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import styled from "styled-components";

import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import { loShuGrid, singledDigits } from "./Numerology/calculations";
import Alphabets from "./Numerology/Alphabets";
import Analysis from "./Numerology/Analysis";
import DateDetails from "./Numerology/DateDetails";
import LoShuGrid from "./Numerology/LoShuGrid";
import Numbers from "./Numerology/Numbers";
import PortalElement from "./Numerology/PortalElement";
import { NakshatraContext, useAuth } from "./../contexts/nakshatra.context";
import { RestrictedArea } from "./Auth";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import "./table.css";

const SemanticDatepickerStyle = styled.div`
  height: 24px;
  border: none;
`;

const numerologyPanel = (activeIndex) => {
  return <Accordion.Content active={activeIndex === 0}></Accordion.Content>;
};

const Numerology = () => {
  const location = useLocation();
  const { state } = useContext(NakshatraContext);
  const { user } = state;
  const { loginWithRedirect } = useAuth();

  const initialize = useCallback(async () => {
    const currentPath = window.location.origin + location.pathname;
    await loginWithRedirect({
      redirectUri: currentPath,
    });
  }, [loginWithRedirect, location]);

  useEffect(() => {
    if (!user) {
      initialize();
    }
  }, [user, initialize]);

  const [activeIndex, setActiveIndex] = useState(false);
  const [driverNumber, setDriverNumber] = useState(null);
  // Conductor / Destiny / भाग्यांक
  const [conductorNumber, setConductorNumber] = useState(null);
  const [conductorNumberCombined, setConductorNumberCombined] = useState(null);
  const [gender, setGender] = useState("male");
  const [kuaNumber, setKuaNumber] = useState(null);
  const [number, setNumber] = useState(null);
  const [singledNumber, setSingledNumber] = useState(null);
  const [loShuGridData, setLoShuGridData] = useState(null);
  const [loShuPlanesData, setLoShuPlanesData] = useState(null);
  const [personalBirthYear, setPersonalBirthYear] = useState(null);
  const [personalBirthYearNumber, setPersonalBirthYearNumber] = useState(null);
  const [personalCustomYear, setPersonalCustomYear] = useState(
    new Date().getFullYear()
  );
  const [personalCustomYearNumber, setPersonalCustomYearNumber] =
    useState(null);
  const [personalMonths, setPersonalMonths] = useState(null);
  const [alphabetsNumbers, setAlphabetsNumbers] = useState(null);
  const [alphabetNumberSum, setAlphabetsNumbersSum] = useState(null);
  const [nameNumber, setNameNumber] = useState(null);
  const years = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const yearOptions = years(
    personalCustomYear - 100,
    personalCustomYear + 100,
    1
  ).map((year) => ({
    key: year,
    value: year,
    text: year,
  }));

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);

  useEffect(() => {
    if (currentDate) {
      setLoShuGridData(null);
      setLoShuPlanesData(null);
      setPersonalMonths(null);
      setPersonalBirthYearNumber(null);

      const theYear = currentDate.getFullYear();
      setPersonalBirthYear(theYear);
      const theMonth = currentDate.getMonth() + 1;
      const theDate = currentDate.getDate();

      const driver = singledDigits(theDate);
      const kuaDriver = singledDigits(theYear);

      const singledMonth = singledDigits(theMonth);
      const combinedTotal = kuaDriver + singledMonth + driver;
      setConductorNumberCombined(combinedTotal);
      const conductor = singledDigits(combinedTotal);

      setPersonalBirthYearNumber(conductor);

      const customYearConductor = singledDigits(
        singledDigits(personalCustomYear) + singledMonth + driver
      );

      setPersonalCustomYearNumber(customYearConductor);
      const monthsNumbers = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 1,
        November: 2,
        December: 3,
      };

      const months = Object.keys(monthsNumbers).map((month) => {
        return {
          name: month,
          number: singledDigits(
            parseInt(monthsNumbers[month]) + customYearConductor
          ),
        };
      });

      setPersonalMonths(months);
      let kua;

      setDriverNumber(driver);
      setConductorNumber(conductor);
      if (gender === "male") {
        kua = singledDigits(11 - kuaDriver);
      } else {
        kua = singledDigits(4 + kuaDriver);
      }

      setKuaNumber(kua);

      const [theGrid, planes] = loShuGrid([
        theYear,
        theMonth,
        theDate,
        driver,
        conductor,
        kua,
      ]);

      setLoShuGridData(theGrid);

      setLoShuPlanesData(planes);
    }
  }, [currentDate, gender, personalCustomYear]);

  const handleGenderChange = (e, genderProps) => {
    const { value } = genderProps;

    setGender(value);
  };

  return user?.email ? (
    <Accordion
      panels={[
        {
          key: "Numerology",
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <SemanticDatepickerStyle>
                <Alphabets
                  alphabetsNumbers={alphabetsNumbers}
                  alphabetNumberSum={alphabetNumberSum}
                  nameNumber={nameNumber}
                  setAlphabetsNumbers={setAlphabetsNumbers}
                  setAlphabetsNumbersSum={setAlphabetsNumbersSum}
                  setNameNumber={setNameNumber}
                />
                <Numbers
                  number={number}
                  setNumber={setNumber}
                  setSingledNumber={setSingledNumber}
                />
                {
                  <Divider horizontal>
                    <Icon name="clock" />
                    <Label>Date of Birth</Label>
                    <SemanticDatepicker
                      icon={"time"}
                      showToday={false}
                      onChange={onChange}
                      clearIcon={"x"}
                      format={"DD-MM-YYYY"}
                    />
                    <Checkbox
                      radio
                      label="Male"
                      value="male"
                      name="checkboxRadioGroup"
                      checked={gender === "male"}
                      onChange={handleGenderChange}
                    />
                    <Checkbox
                      radio
                      label="Female"
                      name="checkboxRadioGroup"
                      value="female"
                      checked={gender === "female"}
                      onChange={handleGenderChange}
                    />
                  </Divider>
                }
                {currentDate && (
                  <Divider horizontal>
                    {`${currentDate.toDateString()} - Personal Birth ${personalBirthYear} Number`}
                    {
                      <PortalElement
                        personal={true}
                        number={personalBirthYearNumber}
                        triggerNode={
                          <Label>{`#${personalBirthYearNumber}`}</Label>
                        }
                      />
                    }
                  </Divider>
                )}
                {currentDate && (
                  <Divider horizontal>
                    Personal Birth{" "}
                    {
                      <Dropdown
                        search
                        placeholder="Select year"
                        options={yearOptions}
                        defaultValue={personalCustomYear}
                        onChange={(event, data) =>
                          setPersonalCustomYear(data.value)
                        }
                      />
                    }{" "}
                    Number
                    {
                      <PortalElement
                        personal={true}
                        number={personalCustomYearNumber}
                        triggerNode={
                          <Label>{`#${personalCustomYearNumber}`}</Label>
                        }
                      />
                    }
                  </Divider>
                )}
                {currentDate && (
                  <Divider horizontal>
                    {`Personal ${personalCustomYear} Months`}
                    {personalMonths && (
                      <Table>
                        <Thead>
                          <Tr>
                            {personalMonths.map((month, index) => (
                              <Th key={index}>{month.name.slice(0, 3)}</Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            {personalMonths.map((month, index) => (
                              <Td key={index}>
                                <PortalElement
                                  personal={true}
                                  key={index}
                                  number={month.number}
                                  triggerNode={
                                    <Label>{`#${month.number}`}</Label>
                                  }
                                />
                              </Td>
                            ))}
                          </Tr>
                        </Tbody>
                      </Table>
                    )}
                  </Divider>
                )}
                {currentDate && (
                  <DateDetails
                    conductorNumber={conductorNumber}
                    conductorNumberCombined={conductorNumberCombined}
                    driverNumber={driverNumber}
                    kuaNumber={kuaNumber}
                  />
                )}
                {currentDate && (
                  <LoShuGrid
                    conductorNumber={conductorNumber}
                    driverNumber={driverNumber}
                    kuaNumber={kuaNumber}
                    loShuGridData={loShuGridData}
                    loShuPlanesData={loShuPlanesData}
                  />
                )}
                {currentDate && (
                  <Analysis
                    alphabetNumberSum={alphabetNumberSum}
                    alphabetsNumbers={alphabetsNumbers}
                    conductorNumber={conductorNumber}
                    driverNumber={driverNumber}
                    kuaNumber={kuaNumber}
                    loShuGridData={loShuGridData}
                    loShuPlanesData={loShuPlanesData}
                    nameNumber={nameNumber}
                    phoneNumber={number}
                    phoneNumberSingled={singledNumber}
                    personalBirthYear={personalBirthYear}
                    personalBirthYearNumber={personalBirthYearNumber}
                    personalCustomYear={personalCustomYear}
                    personalCustomYearNumber={personalCustomYearNumber}
                    personalMonths={personalMonths}
                  />
                )}
              </SemanticDatepickerStyle>
            </Accordion.Title>
          ),
          content: numerologyPanel(activeIndex),
          active: true,
        },
      ]}
    />
  ) : (
    <RestrictedArea />
  );
};

export default Numerology;
