import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
const subWiseDiseasesArray = require('../../../data/rules/subWiseDiseasesArray');

const SubWiseDiseases = () => {

  return subWiseDiseasesArray.default ? (
    <Table>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Sign</Th>
          <Th>Star</Th>
          <Th>Sub</Th>
          <Th>Diseases</Th>
        </Tr>
      </Thead>
      <Tbody>
        {subWiseDiseasesArray.default.map((disease, index) => (
          <Tr key={index}>
            <Td>{disease["Sub No."]}</Td>
            <Td>{disease["Sign Lord"]}</Td>
            <Td>{disease["Star lord"]}</Td>
            <Td>{disease["Sub lord"]}</Td>
            <Td>{disease["6th Diseases"]}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : null;
};

export default SubWiseDiseases;
