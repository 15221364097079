import React, { createRef, useEffect, useState } from 'react';
import { Input, Label } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const YoginiRemain = (props) => {
  const [result, setResult] = useState('');
  const [dashaPlanetFactor, setDashaPlanetFactor] = useState('');
  const [planetDegree, setPlanetDegree] = useState('');
  const [dashaFormFactor, setDashaFormFactor] = useState('');
  const [dashaStartDate, setDashaStartDate] = useState(null);

  const inputRef = createRef();

  useEffect(() => {
    inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (planetDegree && dashaFormFactor) {
      setDashaPlanetFactor(
        parseFloat(planetDegree) * parseFloat(dashaFormFactor)
      );
    }
  }, [setDashaPlanetFactor, planetDegree, dashaFormFactor]);

  useEffect(() => {
    if (dashaPlanetFactor) {
      let monthsFloat = dashaPlanetFactor * 12;

      let years = 0;
      if (monthsFloat > 12) {
        const yearFloat = monthsFloat / 12;
        years = Math.floor(yearFloat);
        monthsFloat = (yearFloat - years) * 30;
      }

      const months = Math.floor(monthsFloat);
      const daysFloat = (monthsFloat - months) * 30;
      const days = Math.floor(daysFloat);
      const hoursFloat = (daysFloat - days) * 24;
      const hours = Math.floor(hoursFloat);
      const minutesFloat = (hoursFloat - hours) * 60;
      const mins = Math.floor(minutesFloat);
      if (dashaStartDate) {
        const dashaDate = new Date(dashaStartDate);
        const finalDate = new Date(
          dashaDate.getFullYear() + years,
          dashaDate.getMonth() + months,
          dashaDate.getDay() + days,
          dashaDate.getHours() + hours,
          dashaDate.getMinutes() + mins
        );
        setResult(finalDate.toLocaleString());
      } else {
        setResult(`${years}y, ${months}m, ${days}d, ${hours}h, ${mins}m`);
      }
    }
  }, [setResult, dashaPlanetFactor, dashaStartDate]);

  return (
    <div>
      <Label.Group>
        <Label.Detail>Planet Degree</Label.Detail>
        <Input
          type="text"
          placeholder="Enter Planet Degree"
          ref={inputRef}
          value={planetDegree}
          onChange={(e, { value }) => setPlanetDegree(value)}
        />
      </Label.Group>
      <Label.Group>
        <Label.Detail>Dasha Form Factor</Label.Detail>
        <Input
          type="text"
          placeholder="Enter Planet Degree"
          value={dashaFormFactor}
          onChange={(e, { value }) => setDashaFormFactor(value)}
        />
      </Label.Group>
      <Label.Group>
        <Label.Detail>Dasha Start Date (DD-MM-YYYY)</Label.Detail>
        <SemanticDatepicker
          icon={'time'}
          showToday={false}
          onChange={(e, { value }) => setDashaStartDate(value)}
          clearIcon={'x'}
          format={'DD-MM-YYYY'}
          value={new Date(dashaStartDate)}
        />
      </Label.Group>
      <Label.Group>
        <Label.Detail>{result}</Label.Detail>
      </Label.Group>
    </div>
  );
};

export default YoginiRemain;
