import React, {
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Divider,
  Header,
  Icon,
  Input,
  Label,
  Modal,
  Segment,
} from 'semantic-ui-react'
import InputMask from 'react-input-mask';
import {
  singledDigits,
} from './../Numerology/calculations';
import PortalElement from './PortalElement';
import styled from 'styled-components';

const alphabetValues = require('./../../../data/numerology/alphabetValues')
const combinations = require('./../../../data/numerology/combinations')

const AlphabetsStyle = styled.div`
  .label {
    font-size: 10px !important;
  }
`;

const Compound = ({
  alphabetNumberSum,
  combination,
  triggerNode,
}) => {
  const [isShown, setIsShown] = useState(false);
  const {
    compountNumbers,
  } = combination || {};

  const toggler = (e) => {
    if (isShown) {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  };

  return(
    alphabetNumberSum && <Modal
      size='fullscreen'
      trigger={triggerNode}
      open={isShown}
      centered={false}
      closeIcon
      onClose={toggler}
      onOpen={toggler}
    >
      <Segment>
        <Header>{alphabetNumberSum}</Header>
        <p>{compountNumbers}</p>
      </Segment>
    </Modal>
  );
}

const Alphabets = ({
  alphabetsNumbers,
  alphabetNumberSum,
  nameNumber,
  setAlphabetsNumbers,
  setAlphabetsNumbersSum,
  setNameNumber,
}) => {

  const [combination, setCombination] = useState(null);

  const handleTextChange = (event) => {
    const {
      target: {
        value
      } = {},
    } = event;

    if (typeof(value) === 'string') {
      const trimmedValue = value.trim();
      setAlphabetsNumbers(trimmedValue);
    }
  };

  useEffect(() => {
    if (alphabetsNumbers) {
      const stringArray = alphabetsNumbers.toLowerCase().split('');

      const stringsNumberArray = stringArray.map(char => {
        const convertedNumber = (+char);
        if (isNaN(char)) {
          const alphabet = alphabetValues[char];
          return  alphabet && alphabet.number;
        } else {
          return convertedNumber;
        }
      });

      const sum = stringsNumberArray.map(x => parseInt(x, 10)).reduce((a, b) => a+b)
      setAlphabetsNumbersSum(sum);
      setCombination(combinations[sum]);
      setNameNumber(singledDigits(sum));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [alphabetsNumbers])


  return(
    <Accordion>
      <Divider horizontal><Icon name='write' />
        <Label>Alphabets and or Numbers</Label>
        <InputMask
          mask={'************************************************************************************'}
          maskChar={null}
          value={alphabetsNumbers}
          placeholder='Alphabets or Number'
          onChange={handleTextChange}
        >
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      </Divider>
      <Divider horizontal />
      {alphabetsNumbers && <AlphabetsStyle>
        <Divider horizontal>
          <Label>1 - A I J Q Y</Label>
          <Label>2 - B K R</Label>
          <Label>3 - C G L S</Label>
          <Label>4 - D M T</Label>
          <Label>5 - E H N X</Label>
          <Label>6 - U V W</Label>
          <Label>7 - O Z</Label>
          <Label>8 - F P</Label>
        </Divider>
      </AlphabetsStyle>}
      {alphabetsNumbers && <Divider horizontal>
        <Label>Name Number ∑{nameNumber && <Compound
          alphabetNumberSum={alphabetNumberSum}
          combination={combination}
          triggerNode={<Label>{`${alphabetNumberSum}`}</Label>}
        />}
        {` ≈ ${combination && combination.result} ≈ ${combination && combination.percent}% =`}</Label>
        {nameNumber && <PortalElement
          number={nameNumber}
          triggerNode={<Label>{`${nameNumber}`}</Label>}
        />}
      </Divider>}
    </Accordion>
  );

};

export default Alphabets;
