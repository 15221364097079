import React from 'react';
import { Grid, Label, Segment } from 'semantic-ui-react';

import PortalElement from './PortalElement';

const compatibility = require('./../../../data/numerology/compatibility');

const DateDetails = ({
  conductorNumber,
  conductorNumberCombined,
  driverNumber,
  kuaNumber,
}) => {
  return (
    <Grid>
      <Grid.Column mobile={16} tablet={8} computer={4}>
        <Segment padded>
          <PortalElement
            number={driverNumber}
            triggerNode={<Label attached="top left">{driverNumber}</Label>}
          />
          <div className={'lo-shu-label'}>Driver / Psychic / Mulank</div>
          <Label attached="top right">
            {driverNumber && `Best: ${compatibility[driverNumber].best}`}
          </Label>
          <Label attached="bottom left">
            {driverNumber && `Neutral: ${compatibility[driverNumber].neutral}`}
          </Label>
          <Label attached="bottom right">
            {driverNumber && `Avoid: ${compatibility[driverNumber].avoid}`}
          </Label>
        </Segment>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={4}>
        <Segment padded>
          {conductorNumber && (
            <Label attached="top left">
              <PortalElement
                number={conductorNumber}
                triggerNode={<label>{conductorNumber} </label>}
              />
              {conductorNumberCombined && (
                <PortalElement
                  number={conductorNumberCombined}
                  triggerNode={
                    <label
                      style={{ fontSize: '70%' }}
                    >{`(${conductorNumberCombined})`}</label>
                  }
                />
              )}
            </Label>
          )}
          <div className={'lo-shu-label'}>
            Conductor / Destiny / Life Path / भाग्यांक
          </div>
          <Label attached="top right">
            {conductorNumber && `Best: ${compatibility[conductorNumber].best}`}
          </Label>
          <Label attached="bottom left">
            {conductorNumber &&
              `Neutral: ${compatibility[conductorNumber].neutral}`}
          </Label>
          <Label attached="bottom right">
            {conductorNumber &&
              `Avoid: ${compatibility[conductorNumber].avoid}`}
          </Label>
        </Segment>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={4}>
        {kuaNumber && (
          <Segment padded>
            <PortalElement
              number={kuaNumber}
              triggerNode={<Label attached="top left">{kuaNumber}</Label>}
            />
            <div className={'lo-shu-label'}>KUA Number</div>
            <Label attached="top right">{`Best: ${compatibility[kuaNumber].best}`}</Label>
            <Label attached="bottom left">{`Neutral: ${compatibility[kuaNumber].neutral}`}</Label>
            <Label attached="bottom right">{`Avoid: ${compatibility[kuaNumber].avoid}`}</Label>
          </Segment>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default DateDetails;
