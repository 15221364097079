import React, {
  useEffect,
} from 'react';
import {
  Accordion,
  Message,
} from 'semantic-ui-react'

const aries = require('./../../../data/rashis/aries');

const renderKeys = (json, keey) => {
  const renderedArray = [];
  for (const k in json){
    renderedArray.push(
      <Message.Content
        key={k}
      >
        <Message.Header>
          {k}
        </Message.Header>
        {renderContent(k, json[k]['description'])}
      </Message.Content>
    );
  }
  return renderedArray;
};

const renderContent = (key, value) => {
  return <Message.Item
    key={key}
  >
    {value}
  </Message.Item>;
};

const AriesMars = (activeIndex) => {
  useEffect(() => {
    document.title = 'Aries & Mars'
  }, []);

  return (
    <div>
      {[
        'stories',
        'qualities',
        'techniques',
        'combinations',
        'crossReferences',
        'remedies',
        'books'
      ].map((keey) => {
        return <Accordion key={`aries-${keey}`}>
        <Accordion.Title
          active={true}
        >
          {keey}
        </Accordion.Title>
        <Accordion.Content
          active
          key={`aries-${keey}`}
        >
          <Message
            floating
            key={`aries-${keey}`}
          >
            <Message.Content>
              <Message.List>
                {renderKeys(aries.bhrigu[keey], keey)}
              </Message.List>
            </Message.Content>
          </Message>
        </Accordion.Content>
        </Accordion>
      })}
    </div>
  );
};

export default AriesMars;
