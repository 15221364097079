import React from 'react';

import { Message } from 'semantic-ui-react';

import { capitalize } from '../../engine/helper';

const PlanePercentages = ({ planes }) => {
  const keys = Object.keys(planes);

  const node = keys.map((plane, idx) => {
    const element = planes[plane];

    return (
      <Message
        key={idx}
        style={{
          padding: '.1em 1.5em',
        }}>
        <Message.Header>{`${capitalize(plane)} ${
          element.percentage
        }%`}</Message.Header>
        <Message.List>
          <Message.Item>{`Numbers ${element.numbers.join(', ')}`}</Message.Item>
        </Message.List>
      </Message>
    );
  });
  return node;
};

export default PlanePercentages;
