import React, {
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  // Divider,
  // Icon,
  Label,
  Message,
} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import styled from 'styled-components';

const SemanticDatepickerStyle = styled.div`
  height: 24px;
  border: none;
`;

const numerologyPanel = (activeIndex) => {
  return (
    <Accordion.Content active={activeIndex === 0}>
    </Accordion.Content>
  );
};

const digitized = (number) => {
  const digits = number.toString().split('');
  return digits.map(Number);
};

const singledDigits = (number) => {
  let digitizedNumber = digitized(number);
  let reducedNumber = digitizedNumber.reduce((a, b) => a + b, 0);

  while (digitizedNumber.length > 1) {
    digitizedNumber = digitized(reducedNumber);
    reducedNumber = digitizedNumber.reduce((a, b) => a + b, 0);
  }
  return reducedNumber;
}

const Numerology = () => {
  const [activeIndex, setActiveIndex] = useState(false)
  // const [date, setDate] = useState(null)
  // const [month, setMonth] = useState(null)
  // const [year, setYear] = useState(null)
  // Driver Number / Psychic Number / Mulank
  const [driverNumber, setDriverNumber] = useState(null)
  // Conductor Number / Destiny Number / Bhagyank
  const [conductorNumber, setConductorNumber] = useState(null)

  useEffect(() => {
    document.title = 'Numerology'
  }, []);


  const handleClick = (e, titleProps) => {
    const { index } = titleProps

    setActiveIndex(activeIndex === index ? -1 : index)
  }

  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);

  useEffect(() => {
    if (currentDate) {
      const theYear = currentDate.getFullYear();
      const theMonth = currentDate.getMonth() + 1;
      const theDate = currentDate.getDate();

      const driver = singledDigits(theDate);

      const combinedTotal = singledDigits(theYear) + singledDigits(theMonth) + driver;

      // setYear(theYear);
      // setMonth(theMonth);
      // setDate(theDate);

      setDriverNumber(driver);
      setConductorNumber(singledDigits(combinedTotal));
    }
  }, [currentDate]);

  return (
    <Accordion panels={[
        {
          key: 'Numerology',
          title: (
            <Accordion.Title
              index={0}
              onClick={handleClick}
              active={activeIndex === 0}
            >
              <SemanticDatepickerStyle>
                <Label>Date of Birth</Label>
                {/*<Divider horizontal><Icon name='dropdown' />Planet Capacity (ग्रह क्षमता)</Divider>*/}
                <SemanticDatepicker
                  icon={'time'}
                  showToday={false}
                  onChange={onChange}
                  clearIcon={'x'}
                  format={'MM-DD-YYYY'}
                  />
                {currentDate && <Message floating>
                  <Message.Content>
                    Date
                  </Message.Content>
                  <Message.Header>
                    {currentDate.toDateString()}
                  </Message.Header>
                  <Message.Content>
                    Driver Number / Psychic Number / Mulank
                  </Message.Content>
                  <Message.Header>
                    {driverNumber}
                  </Message.Header>
                  <Message.Content>
                    Conductor Number / Destiny Number / Bhagyank
                  </Message.Content>
                  <Message.Header>
                    {conductorNumber}
                  </Message.Header>
                </Message>}
              </SemanticDatepickerStyle>
            </Accordion.Title>
          ),
          content: numerologyPanel(activeIndex),
          active: true
        },
      ]}
    />
  )
};

export default Numerology;
