module.exports = Object.freeze([
  {
    id: 1,
    name: "Aries",
    vedicName: "Mesh (मेष)",
    ownerGraha: "Mars (मंगळ)",
    ownerShortName: "Ma",
    gender: "Male (पुरुष)",
    nature: "Movable (चर)",
    tenor: "Firy (अग्नि)",
    handle: "Ari",
    characteristics: `The persons born under Aries sign have a bit dark complexioned face, eyes somewhat brown, dwarf or medium height, sturdy and strong body, long neck and they talk cleverly. This rashi includes Ashwini, Bharani and first Charan of Krittika Nakshatras. The persons of this sign as a rule are solitary by nature; they have smiling faces and are less spoken. They are brisk walkers. They have thin teeth in the lower jaw, upper jaw may sometimes have long teeth protruding out of the mouth. Anger reflects from their faces. They have ample travels and frequent transfers from place to place in life. Service or business they acquire has touring feature. Usually they may have bleeding teeth. They possess limited intelligence. They prefer to work under directions and guidance from others. Their eyes acquire red or pale colour. They have scars on forehead or face. They are temperamental in nature and are prone to fevers and blood diseases. They may have some skin diseases or are prone to them. They have large eyes and rough hair. They are usually short tempered and impatient in nature. They are ambitious, courageous and vigilant for self-respect. They shape their own destiny by dint of struggle and hard work. They are straight forward, generous and lovers of freedom. They are practical at work and work for welfare in many areas of the social fabric. They may have weak knees and are allergic to suffocation of closed chambers. Many of them are water phobic. They like to stick to the profession or job they do. They may sometimes be acknowledged as friend offenders in the society because they don't open up with their likes and dislikes. They usually have fewer children. They are gluttonous`,
    kpBodyParty: `Governs Head and Face, Brain and Bones of head and Face`,
    kpTenorProperties: `fiery - controls the vitality of a person`,
    kpPathogenicEffects: `Reacts upon the head producing head aches, Neuralgia, Coma, Trance, Disease of the Brain and Cerebral Insomnia, Tendency to inflammatory diseases, Pains etc`,
    kpHPlaces: `Sandy or pasture ground, small hill, there where thieves stay, stables`,
    kpHCountriesNCities: `Britain, Galatia, Germany, Lithuania, Lower Poland, Palestine, Denmark, Syria, Judia (Lebanon), Burgandy, Damascus, Verona, Naples, Florence, Capua, Morseilles, Burnswick, Padur, Saragossa, Leicester, Cracow, Utrecht, Madras`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Northern, Equinoctial`,
    kpHElement: `Fiery signs: Assertive, independent, courageous and enthusiastic. Activity and ambition, vigour and vitality, energy and push`,
    kpHQualities: [
      `Movable signs: Enterprise, enthusiasm, love of ambition, forcefulness, and go-a-head spirit; fame, recognition and popu­ larity : loves changes and reform`,
      `Barren signs: Attempts not successful; No desired results`,
      `Violent signs: "no fears".They undertake all enterprises with courage and confidence`,
      `Bestial signs: 4 legged`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Middle stature; lean, muscular; neither stout nor thick; ruddy complexion; long neck and face; broad head, bushy eyebrows; wiry hair`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Active, ambitious; bold, impulsive, rash, aggres­ sive; argumentative; confidence, courage; high aim; ever enterprising; dislikes subordination; quick, action; unbounded confidence; executive ability; uncompromising spirit, mind pregnant with ideas; no self-sacrifice; fretful and boisterous; over optimistic; over-trade; fanatic; bold, foolhardiness; demonstrative; passionate; proud; hasty, quarrelsome`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Good health, enough immunity, fond of sports, energetic, Headaches, burns, inflammatory diseases, affection of the brain, neuralgia, coma, high fever, colic pains,paralysis, apo­ plexy, pimples, insomnia, ringworms, migraine, smallpox, malaria, affection of the brain, thrombosis`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Can earn much, spend much, impulsive purchases, rash investments, think of today, never of tomorrow, start in a flash, end in a crash, act first, speak next, think last`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Frank; enthusiastic; charming; practical; freedom, adventure in love, ever-lasting affection, but dominant, nature, independent, or proud`,
    nkAmenableSigns: `Leo, Scorpio`,
    nkCaste: `Kshatriya`,
    nkQualities: `Pioneering in new directions, adventurous spirit, need for travel, Masculine, love exercise and movement (Cardinal or movable sign), Proud, heroic, courageous nature—spiritual warrior, Passionate, strong, intense drive—quick to anger, Assertive, aggressive nature-can coerce others to join them, Good leadership abilities, ambitious, motivated, Independent thinkers, scientific nature, Pitta nature`,
    nkShadowIssues: `Trouble in marriage, due to narcissism, Difficulties finding a home and settling down, challenges with mother, Few children (Sun rules fifth house), Scar on the body, head injuries, accident prone nature, Issues with anger, aggression, impatience, need to slow down`,
    nkFriends: `Mars, Sun, Moon, Jupiter`,
    nkEnemies: `Venus, Mercury, Saturn`,
    direction: ``,
    nakshatraOwners: `Ke, Ve, Su`,
    exaltPlanet: `Sun`,
    debilitated: `Saturn`,
    bhrigu: `Security`,
    sunTransits: `April 15 - May 15`,
    aspects: {
      aspectNumbers: [5, 8, 11],
      aspectNames: ["Le", "Sc", "Aq"],
    },
    lunarAstro: {
      basic: [
        `Aries is positive sign of Mars, cardinal fiery in nature and most importantly head of zodiac`,
        `Sign is represented by a Ram as most powerful body part of ram is head and being a first sign Aries also represents head`,
        `Aries sign has lot of energy and gives lot of independence, self reliant, force, energetic in life, getting shy or being hesitant is not in part of there nature`,
        `Aries people are the one who takes initiative and are very dynamic in approach, They hardly think through the process or result of getting job done`,
        `They are self centred and do not care much about feelings of others`,
        `If Ascendant rises in sign Aries then most the times these people have some mole, scar or some birth mark on face as Aries is first sign and represents face as well`,
        `They are more logical than emotional and hence sometimes tend to force there opinions on others`,
        `If this sign or lord of this sign Mars gets afflicted they are prone to short temper`,
        `Aries people are good for sports only which requires power such as boxing or weight lifting but playing chess or cricket which requires patience will not be there cup of tea`,
        `As Aries has lot of energy they are prone to adventure and sometimes take decisions in life which they regret later on such as marriage`,
        `So better take advice from someone who has lot of patience and knowledge`,
        `They are very clear in expressing themselves and sometimes their family and friends also cannot handle such straight truth. They are not diplomatic in handling any argument. They will stood by on side which they think is right without taking others prospective in account`,
        `These people always need reason to struggle for some goal or any hobby to keep themselves busy otherwise they will become frustrated`,
      ],
      business: [],
      predictive: [
        "New Beginnings",
        `Re-birth`,
        `Quick / Fast`,
        `Your Ego`,
        `Lean Body`,
        `Army Commander`,
        `No Emotions`,
        `High Heat`,
        `Red Color`,
        `High Energy`,
        `Any planet placed in Aries is bound to work quickly`,
        `Saturn gets debilitated in Aries for not being able to move quickly`,
        `Focus is on house where Aries is placed`,
        `People get offended about Aries (ego). For example, for Gemini Lagna, if enquired on their money making, they get offended`,
        `Quick in Action / Decision Making`,
        `Sun gets exalted in Aries because of the heat and energy Aries has`,
        `New Beginnings in its Dasas`,
        `Average motion of Mars is less than Mercury / Venus. Suddenly goes ahead due to energy bursts and slows down`,
        `But Aries gets sudden burst of energy quickly and then slows down`,
        `Venus placed here can push a person make and break relationships quickly. This can be business partners or sex partners depending on ascendant`,
        `sudden gain in energy in this house and then drops to normal`,
        `You do something innovative / never tried before in the house wherever your Aries is placed`,
        `Wherever Aries is placed, lot of your energy is spent on maintaining the house. It’s a priority house for you and you are mostly focused there`,
      ],
      inHouse: {
        1: [
          `Digestion is excellent as fifth house is fire sign`,
          `Decision making is quick and can go wrong`,
          `Starts anything quickly without any planning`,
        ],
        2: [
          `Ups and Downs in Earning ability`,
          `Takes responsibility of family`,
          `Spend a lot on personality. Face Cream etc`,
        ],
        3: [
          `Hurried in communicating`,
          `Can call up over phone (communication) anybody anytime`,
        ],
        4: [
          `House / Mother / Vehicle. Strange House in a strange place`,
          `Very different vehicle`,
        ],
        5: [
          `Relationships are sudden and won’t last for long`,
          `Quick in learning anything`,
        ],
        6: [
          `Strange Disease`,
          `Strange Debt`,
          `Strange Enemies`,
          `Also your job/service can be new / challenging`,
          `Revolutionary people as they are focused to fight`,
          `Keep in mind your capabilities before accepting challenges`,
        ],
        7: [
          `Some Strangeness in Marriage / Business`,
          `Quick in making relations / leaving them`,
          `Can be deceived in business by partners`,
        ],
        8: [`Focused on Insurance`, `Inheritance (fights for inheritance etc)`],
        9: [
          `Learning new religion / vidya at home`,
          `Focused on learning new things every day quickly`,
        ],
        10: [
          `First in such a Job/Work (in Father’s Family (Khandaan))`,
          `Over focused on career, spoiling everything else`,
          `They get huge responsibility in career`,
        ],
        11: [
          `Focused on Money making / Gains`,
          `Does not matter anything, always thinks about his own money`,
        ],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [`Sun occupies Throne in Aries`],
        moon: [
          `Quick in changing opinions`,
          `Tries doing many things at the same time`,
          `Unstable thoughts`,
        ],
        mars: [
          `Takes quickest decisions and can stay focused in that house`,
          `Spends most energy in that house`,
          `+saturn - Revolutionary and cannot be oppressed. Usually become Union leaders to revolt against management in an organization`,
          `+rahu - Gives high energy and can cause canceric tumors`,
        ],
        mercury: [
          `Satirical(व्यंगपूर्ण / उपहासात्मक) / Diplomatic`,
          `Gets his job done with comedy`,
        ],
        venus: [
          `Brings difficulties in maintaining relationships`,
          `Could be business partners or bed partners`,
          `Quick in making partners and also quick in breaking them`,
        ],
        jupiter: [
          `Will push people for a job and curse them on failing to do the given job`,
          `Mugs up everything very quickly since Jupiter is god of wisdom`,
        ],
        saturn: [
          `Oldest, Slowest planet, gets debilitated and forced to work fast`,
          `Takes up challenges`,
          `Does everything hurriedly`,
          `(Tip: Painters, Musicians need skill of Saturn)`,
          `+mars - Revolutionary and cannot be oppressed. Usually become Union leaders to revolt against management in an organization`,
        ],
        rahu: [
          `Rahu is a mirror / amplifies the house many times`,
          `Also Energy increases and focus is high here`,
          `Gives big head`,
          `+mars - Gives high energy and can cause canceric tumors`,
        ],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [
        `For Cancer Ascendants, there is a big check list of things for accepting a house to buy`,
        `If 4th lord relates to Aries / Mars, there are high chances that you renovate your house. Also there will be dents on your car / bike`,
        `Should not buy / wear new clothes on a Tuesday. Mars signifies burnt clothes`,
        `For Mars remedy, offer cold-sweet-drink to friends during Grishma Ritu`,
        `If there is blood deficiency, Mars is afflicted. If Ketu is in trines to Mars blood deficiency is indicated`,
        `Aries represents Mesh (Goat), which climbs a mountain (ambitious). It is possible to achieve a thing connected to Aries. For example, Cancer ascendant should think they have to achieve high in career (10th house) and such thing becomes possible. For a goat, you have to show the mountain`,
        `Always, there will be 2-3 small events before a major event`,
        `Capricorn is a workaholic sign (Aries 10th sign of zodiac). Always want to work, brings home work`,
        `Pisces Ascendant, especially women think that they are doing everything for the family and other ladies are doing nothing – because Aries is in 2nd`,
      ],
      remedies: [],
    },
    badhak: {
      badhakHouse: `11`,
      badhakSign: `Aquarius`,
      secondaryBadhakHouse: `10`,
      secondaryBadhakSign: `Capricorn`,
      badhakPlanet: `Saturn`,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 2,
    name: "Taurus",
    vedicName: "Vrishabh (वृषभ)",
    ownerGraha: "Venus (शुक्र)",
    ownerShortName: "Ve",
    gender: "Female (स्त्री)",
    nature: "Stable (स्थिर)",
    tenor: "Earthy (पृथ्वी)",
    handle: "Tau",
    characteristics: `The person of this sign possesses charming physique with a medium height, have sparkling teeth, fair complexion, attractive face and beautiful eyes. This rashi comprises of the later three charans of Krittika, complete Rohini and the first two charans of Mrigshira Nakshatras. The people under this sign have sound physique, thick neck and round face. They have curly hair; they may have distinctive image scar on their face. They are labourious and steady but slow workers. They are nurtures of old traditions and taboos. They seldom lose temper, but when they do so they don't care for the repercussions. They seldom seek the advice of others. They are pleasure lovers and possess serious nature. They make friends easily and are behaviour efficient. Whatever they do, they do efficiently and with total self-confidence. They love music and adore jewellery and fine clothes. They have regulated habits and possess remarkable tolerance power. They are devotees of respectable people and are obedient to their parents. They acquire riches and develop qualities. They may suffer a lot in the early age of their lives but gradually in the middle age and later in life, they acquire luxuries. They believe in religion and run their family with noble principles. They love travel and have aptitude for purchase and selection of things. They have good power of digestion. They are prone to cold, cough and diseases. They seldom fall sick. They have worry free nature. They feel happy in every situation to which they adapt themselves easily`,
    kpBodyParty: `Neck and Throat, Gullet Larym and Cerebellum, Right eye, Bone of the Neck and Cheeks`,
    kpTenorProperties: `earthy - control bones and flesh`,
    kpPathogenicEffects: `Diptheria, Croup, Apoplexy, Goitre. Disease of Cerebellum ., Cervical Vertebrae, Planets deposited in this sign will also oppose the sign Scorpio, Hence they may also cause veneral disease, Piles, fistula and constipation, Irregular menses and troubles thereby`,
    kpHPlaces: `Cow-shed, cattle-shed, furniture, 'pasture ground away from the house, agricultural land`,
    kpHCountriesNCities: `Persia, Modia, Mozendaram, Azebijan, Caucusus, Georgia, Cyprus, Asia Minor, Poland, the Archipelago, Holland White Russia and Ireland, Rhodes, Mantua, Dublin, Darma, Leipzig, Pranconia, Palermo, Bhadra, Marwar, Ghosha, the Yamuna, Matshya, Mathura, Surasena, Panclnla, Ayodhya, Kuru, Pariyatra Moun­ tain, Kapisthala and Hastinapura`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Northern`,
    kpHElement: `Earthy signs: Stability, material welfare, wealth, power, position, health, cautious, premeditative, prudent and prac­ tical; secretive and auspicious; stubborn and self-protective; slow and steady; reserved and restrained; methodical and economical; plodding and perservering`,
    kpHQualities: [
      `Fixed signs; Stamina and persistence in action. Very strong: and determined will. Keep on working, patience and persistence; practical; firm`,
      `Semi-fruitful`,
      `Voice signs (Western Authors)`,
      `Bestial signs: 4 legged`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Middle stature; plumpy body broad forehead? thick neck bright eyes; dark hair good complexion; well built, big shoulders, well-developed muscles`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Endurance; Patience; never forget, nor forgive; unrelenting; slow, steady; perseverance; patience; persis­ tence; conservative; lazy: self-confidence; deepset in tuition; not verbose; stubborn; loyal`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Robust health, never deficiency, slow recovery, tonsils, diptheria, pyorrhoea, throat, palate, neck, cold, apo­ plexy, constipation, plethora, pimples and eyesore`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Hoards money, accumulates wealth, cautious spending, always keeps reserve, never risks in money matters, never squander, care for material status, invest on estates, agri­ cultural lands, practically builds fortune steadily`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Loyal, true, faces an opposition, self-control needed, lucky, steady`,
    nkAmenableSigns: `Cancer, Libra`,
    nkCaste: `Shudra`,
    nkQualities: `Creative intelligence, good speaking and writing skills, Attractive and charismatic, soft pleasing nature, Great endurance, determination, and stamina, Self-reliant, proud, ambitious; don’t try to boss the bull, Produces high quality work, successful career, Can be very amorous and affectionate, forgiving nature, Love of pleasure and beautiful art objects, enjoy good food Kapha nature`,
    nkShadowIssues: `Troubles in early marriage, few children, Stubborn (fixed sign) and can become violent if pushed to hard, Can be easily seduced, over-sexed, Hedonistic tendencies, need to watch diet, Materialistic nature, Arrogance and pride issues`,
    nkFriends: `Venus, Mercury, Saturn (Yoga—Karaka)`,
    nkEnemies: `Sun, Moon, Mars, Jupiter`,
    direction: ``,
    nakshatraOwners: `Su, Mo, Ma`,
    exaltPlanet: `Moon`,
    debilitated: `-`,
    bhrigu: `Resources, responsibilities`,
    sunTransits: `May 15 - Jun 15`,
    aspects: {
      aspectNumbers: [4, 7, 10],
      aspectNames: ["Cn", "Li", "Cp"],
    },
    lunarAstro: {
      basic: [
        `Taurus is an earthy sign, ruled by Venus, fixed in nature and represents eyes and neck in human body`,
        `Taurus is represented by Bull-Imagine bull who walks on street in his own world without disturbing others and not bothered by anyone else, not in any hurry and walks calmly, similar is the nature if Taurus`,
        `They do interfere in anyone’s life and keep their emotions to themselves only, very calm and composed but if anyone try to harm them or if they sense danger these people become aggressive and forever get these type of people out of life`,
        `Since ruled by Venus, Planet of luxury and wealth, People with Taurus ascendant never gets satisfied with amount of money they have, Money is very important factor in their life`,
        `These people use wealth to decorate their home to very detail, fond of buying new dresses and ornaments`,
        `These people love to be host of party so they can show off their house decoration, new clothes, crockery`,
        `These people never express their emotions to anyone and do not like changes in life due to fix nature of sign`,
      ],
      business: [],
      predictive: [
        `Pomp / Show`,
        `Krishna Leelas look like they are random, psychic and devoid logic and faddish (sunky)`,
        `Emotional logic unlike Aries`,
        `Good as friend / teammate`,
        `Resources`,
        `One of your girl child’s marriage can be a failure in case of Sukra Vanshi`,
        `If Jupiter aspects Venus in Sukra Vanshi, the malefic effect is reduced`,
        `Wherever Taurus is placed from your Ascendant, there are resources (can be hidden) for your help`,
        `Every person talks about both his 2nd house and also Taurus (whichever house it is)`,
      ],
      inHouse: {
        1: [
          `Psychic`,
          `Most of their actions are unexplainable and not very logically correct, but morally right`,
          `Cancer ascendants cannot explain the logic behind their action. They do actions emotionally`,
          `Cancer Ascendant or Moon in Lagna or Lagna Lord closely conjunct moon, these people show lunatic nature`,
          `Invites people to home or office etc only to show off/ pomp. Fortune (Bhagyodaya) will be late in life. Their definition of Dharma is different`,
          `If they get into fight with anyone, they will not look back to them again`,
        ],
        2: [
          `If 2nd house has Satvik Planets, takes good responsibility`,
          `Foodie. Expects appreciation for things at home. Shows off at home, Jewelry etc. Talks about investments, how resources can be utilized etc.`,
          `Dont eat food from fridge`,
          `Start  appreciating and find something good in everyone`,
        ],
        3: [
          `Talks about siblings, his valor/courage, short travels, incidents during travel`,
        ],
        4: [
          `Whenever he spends on body grooming / haircuts etc., he will be more peaceful – 4th house Taurus`,
          `Talks on Property like Home/ vehicles, Peace, mother and expenses.`,
        ],
        5: [`Talks about Share Markets, Kids, education`],
        6: [
          `If Taurus is in 6,8 or 12 from Lagna, do not spend high on cosmetics/grooming. Such actions activate these houses`,
          `Health issues, enemies, office related (2nd house is Capricon), money related to profession (6th)`,
        ],
        7: [
          `Talks about wife/society / wife’s father etc. Talks about society transformation etc`,
        ],
        8: [
          `If Taurus is in 6,8 or 12 from Lagna, do not spend high on cosmetics/grooming. Such actions activate these houses`,
          `Inheritance, Transformation and Money from Govt policies, insurance. Talks about govt policies – a complete transformation is needed in them`,
        ],
        9: [
          `Talks more about religion, father, higher education, law and legality. Also about spouse (2nd house Libra)`,
        ],
        10: [
          `Talks more about his profession. Father’s family and father’s income`,
        ],
        11: [
          `Openly claims that he has earned this much out of something`,
          `Cancer ascendants talk about government(2nd-Leo), fame(2nd-Leo) and also profits they made (11th -Taurus ). Additionally, if Taurus has a planet Mars, he will talk more about his profession (10th lord) and how he made money out of it`,
        ],
        12: [
          `If Taurus is in 6,8 or 12 from Lagna, do not spend high on cosmetics/grooming. Such actions activate these houses`,
          `Hides money Under the Bed / In foreign banks etc. Often quotes that expenditures are high – spent a lot etc. If their 4th lord (Mercury) gets into 12th house, they talk about loss due to home/vehicles`,
        ],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: `9`,
      badhakSign: `Capricorn`,
      secondaryBadhakHouse: `10`,
      secondaryBadhakSign: `Aquarius`,
      badhakPlanet: `Saturn`,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 3,
    name: "Gemini",
    vedicName: "Mithun (मिथुन)",
    ownerGraha: "Mercury (बुध)",
    ownerShortName: "Me",
    gender: "Male (पुरुष)",
    nature: "Dual (द्विस्वभावी)",
    tenor: "Airy (वायू)",
    handle: "Gem",
    characteristics: `The persons with Gemini sign have small face, pointing towards chin. They may possess dual nature. They have effective personality, depicting their intelligence, judicious and humane nature. Their nature is versatile, imaginative, pleasant, thoughtful and adaptable. This rashi sign covers the last two charans of Mrigshira; complete Ardra and first three charans of Punarvasu Nakshatras. The people of this sign usually have thin physique, are tall in height, medium complexion, dark eyes, thin hair. They are generally soft-spoken, with good power of oration. They are learned people with good command of language. They use phrases frequently while talking. They lean while walking. They have pointed nose and long organs of the body. They are fast at work. They advance their logic in talk, conversations. They love change and possess foresightedness. As a rule, they are short tempered but cool down quite easily. They can consistently work without feeling fatigue, which adversely affects their health. They get help from friends and relatives. They are more passionate towards sex. They learn quickly the traits of their service or occupation and are usually successful. They usually have big families. They are prone to addictions. They are comedians by nature. Professions of Engineering, architecture or apparel are of their taste. They are religious. They rise quite late in their lives. They are prone to cold and eye diseases. They have poor digestive power. `,
    kpBodyParty: `Shoulder and Hands, Lungs, Blood and Breath, Shoulder blades and Bones of Arms`,
    kpTenorProperties: `airy - governs the breath`,
    kpPathogenicEffects: `Pulmonary disease, EosonophiIlia, Dry Cough, Pneumonia, Branchiatics, Pleurisy, Asthma, Inflammation of Pericardium, Affection of the hands and Tuberculosis`,
    kpHPlaces: `Boxes, trunks, hills, mountains, playing groundi, schools, nurseries, libraries`,
    kpHCountriesNCities: `The West of England, the United States of America, Africa (north-east of Tripoli). Lower Egypt, Slanders, Lambardy, Belgium, Wales, Canada, Armenia, Lower Egypt, Flanders, Sardinia, Brabant, London, Versailles, Nuretpburg, Plymouth, Melborne,  San Francisco, Mentz, Loraine, Bruges, Cordove and Nuremt burg`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Northern`,
    kpHElement: `Airy signs: Cheerful, gentle, amiable, courteous, sympa­ thetic, refined, humane. Good intellect, fertile imagination, idealistic and artistic, well-informed, fertile. Musical, level­ headed and tactful`,
    kpHQualities: [
      `Common (dual, mutable) signs: Dual, fluctuating, flexi­ bility, plasticity, intellectual, sympathetical sensitive, subtle, versatile, changeful, inconstant, indecisive, vacillating and restless`,
      `Barren signs: Attempts not successful; No desired results`,
      `Human signs: Humane`,
      `Voice signs`,
      `Dual signs`,
      `Bicorporeal or double-bodied sign `,
      `Short ascension (वाढ): inclusive : 90° on both sides of Vernal (वसंत ऋतू) equinox`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Tall, upright; straight body; long hands; thin legs; moderate complexion; sharp eyes; long nose`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Carefree; joyous: reluctant; versatile; restless; changeful; adaptability; intelligent; adviser; fond of travel; indecisive; ever contemplating; insight waywardness, fickleness; generous; or niggardly, no rule, no habit; quick grasp; much retentive power; good reproductive ability`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Delicate health, straio, overtaxing, thereby ill-health, lungs, cold, running of the nose, influenza, Eosonophilia, pleurisy, Bronchitis, T.B. shoulders and arms affected`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Either fortune or misfortune, family disputes, self-destruction and of changes, secret love affairs, detrimental`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Quick friendship, critics, finding fault with, no satisfaction, prefers variety, baffles others, controls, mixes with intellectuals, more romance-more happy, difficulty to understand`,
    nkAmenableSigns: `Virgo`,
    nkCaste: `Vaisya`,
    nkQualities: `Very mental, intellectual people, philosophical, Mutable, chameleon nature, Can see things from different points of view, Love of science, literature, arts and crafts, Need variety, get bored easily, Alluring and refined quality, very talkative, sweet speech, Like to work indoors, dual careers, Good for writers, teachers, scientists, counselors, astrologers, May also have clerical skills, Vata Nature`,
    nkShadowIssues: `Sensitive nervous system, health issues (Mars rules sixth and eleventh houses), Do not like physical labor, Few children, expenditures through them (Venus rules fifth and twelfth houses), Do not want burdensome family responsibilities, free spirits, May talk too much and put their foot in their mouth, Too much in their heads, walking cerebrums`,
    nkFriends: `Mercury, Saturn, Venus (Yoga—Karaka)`,
    nkEnemies: `Sun, Moon, Jupiter, and Mars (highly malefic — 6 and 11 Lords)`,
    direction: ``,
    nakshatraOwners: `Ma, Ra, Ju`,
    exaltPlanet: `-`,
    debilitated: `-`,
    bhrigu: ``,
    sunTransits: `Jun 15 - Jul 14`,
    aspects: {
      aspectNumbers: [6, 9, 12],
      aspectNames: ["Vi", "Sg", "Pi"],
    },
    lunarAstro: {
      basic: [
        `It’s a Dual sign ruled by Mercury and airy in nature. Due to airy sign they can easily visualise things and make pictures in their head. `,
        `Represented by man and woman together shows duality in their nature and it shows in their personality as well. `,
        `If you ever comes across a person who has opinion on everything and can discuss everything from stock market, government to his new shoes with in 30 minutes of chat, then for sure he is Gemini`,
        `Being a dual sign they never take a stand against anyone and goes with flow`,
        `You will find Gemini ascendant mostly involved in paperwork, press, communications, post-office as it is ruled by Mercury- Messenger of Gods`,
        `Talkative in nature and can express themselves in beautiful form`,
        `Good in mimicry as they tend to observe very minute details and pay very close attention to everything happening around them`,
        `Multitasking is their quality and you will find they are capable of running multiple business at same time`,
      ],
      business: [],
      predictive: [`Paperwork`, `Stationary`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 4,
    name: "Cancer",
    vedicName: "Karka (कर्क)",
    ownerGraha: "Moon (चंद्र)",
    ownerShortName: "Mo",
    gender: "Female (स्त्री)",
    nature: "Movable (चर)",
    tenor: "Watery (जल)",
    handle: "Can",
    characteristics: `The persons under the influence of cancer sign are sensitive, conservative. They are introverts. They love occult science. They are sympathetic, energetic and imaginative. They usually live outside their native place. The sign includes last charans of Punarvasu, full Pushya and full Aashlesha Nakshatras. They are water lovers. They like natural scenery. They love traveling and roaming. Their upper jaws are usually big. They possess round face and long organs of body. They are brisk in walking; they are fearless and somewhat rough in talks but are straightforward. They love the opposite sex. Sometimes are bigamous. They are inventive and intuitive and are hurt easily. They are obstinate, non-emotional and show less hospitality and quite often are sentimental. They are slow workers. They have protruding hips and have slanted vision sight. They obtain quick gains of their company. They also acquire inherited property. They love luxuries in life and are usually pious people. They are prone to gastric aliments. They love fine arts like drama, music, acting etc. They are showy people. They often catch diseases. They have soft bones and are good critics. They are highly prone to chest and lung infections. They have moles, scars, warts etc., on the left side of their body`,
    kpBodyParty: `Breast and Chest, Stomach and digestive Organs Bones of the Chest and Ribs`,
    kpTenorProperties: `watery - governs human blood and blood circulations`,
    kpPathogenicEffects: `Pain in the Stomach, Gas troubles, Indigestion, Cough, Hi cough, Dropsy, Melancholia(deep sadness or gloom), Hypchondnia, Hysteria, Jaundice, Gallstons`,
    kpHPlaces: `Rivers, lakes, sea, springs, marshy area`,
    kpHCountriesNCities: `Holland. Scotland, New Zealand, North and Wes, Africa, Mauritius, A natolia near Constantinople, Paraguay, Stockholm and Mendenburg, St. Andrews, Deptford, Manchester, Rochdale, York, New York, Algiers, Amsterdam, Berne, Cadiz, Constantinople, Genoa, Lubeck, Magdeburg, Milan, Tunis, Venice, Vencentia, Medenburg`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Northern, Tropical`,
    kpHElement: `Watery signs : Fertile imagination; constitution—weak; timid, inert and unwilling to act. Receptive, contemplative, sensitive, sentimental and psychic`,
    kpHQualities: [
      `Movable signs: Enterprise, enthusiasm, love of ambition, forcefulness, and go-a-head spirit; fame, recognition and popu­ larity : loves changes and reform`,
      `Fruitful signs show success: reilisation of ambition`,
      `Mute sign`,
      `Long ascension (वाढ): inclusive : 90° on both sides of Autumnal (शरद ऋतू) equinox`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Clumsy body; upper body targe; In; age big abdomen; wide nose; large mouth; brown hair; pale comple­xion`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Changeability, timid; fertile imagination, emo­tional, sympathetic; over-sensitive; talkitivo; nervous irritability; brave in mental attitude; short temper; tenacious memory; diplomatic, discreet, conventional; loyal; impatient; very selfish`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Fragile lungs, flu, cough, asthma, bronchitis, pleurisy, T.B. stomach upset, dyspepsia, flatulence, dropsy, beri­ beri, nervous debility, fear complex, hysteria, jaundice, obstruc­ tion of the bile, gall stone, boils on the chest`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Commercial career, trade, caterer, shops, stalls, inurses, careful expenses, prudent, saves by hard labour, accumu­lates wealth`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) No romance means no life to them, will sacrifice comforts, loyal and affectionate, sincere and true, yet fickle minded`,
    nkAmenableSigns: `Scorpio, Sagittarius`,
    nkCaste: `Brahmin`,
    nkQualities: `Very emotional nature, care deeply for others, Attached to their home, but love travel, Like to work around their home, Many friends, but introverted at times, Intelligent, good imagination, psychic, Religious, charitable nature, maternal, Proud, virtuous nature, spiritual warrior, Fights fora cause (Mars), humanitarian concern, Need to live near water, Kapha nature`,
    nkShadowIssues: `Difficult marital karma, later marriage better (Saturn rules 7th & 8th houses), Few children or a loss of child (Mars rules the fifth house), Issues with anger, fussiness, overly sensitive, jealous, Mental confusion (Mercury rules 3rd & 12th houses), Timid and shy if the moon is afflicted, unfixed in pursuits, Kapha or Pitta nature`,
    nkFriends: `Sun, Moon, Jupiter and Mars (Yoga—Karaka)`,
    nkEnemies: `Mercury, Venus and Saturn (highly malefic—rules 7th and 8th houses)`,
    direction: ``,
    nakshatraOwners: `Ju, Sa, Me`,
    exaltPlanet: `Jupiter`,
    debilitated: `Mars`,
    bhrigu: ``,
    sunTransits: `Jul 15 - Aug 15`,
    aspects: {
      aspectNumbers: [2, 8, 11],
      aspectNames: ["Ta", "Sc", "Aq"],
    },
    lunarAstro: {
      basic: [
        `Cancer is a Watery sign and as even a small stone thrown in water can create ripples in silent lake similarly these people gets mood swings very easily`,
        `Highly emotional in nature and fickle minded`,
        `Being owned by Moon this is a Royal sign and hence people born in Cancer ascendant get rich much easily`,
        `Cancer sign is represented by crab-If you ever get chance observe crab that if crab ever holds on to anything it is very difficult for crab to let it go. Similarly if cancer ascendant hold on to a goal in life, person or any habit it is very difficult for them to let it go`,
        `Due to their highly complex thought procedure these people develop tend to live in isolation and mostly in areas which are quiet, outside city, nearby lake or water bodies, mostly in cold weather`,
        `Cancer is 4th sign in natural zodiac and it represents home, for cancer ascendants home, family and mother meant everything in life`,
        `These people have most number of divorces as it is very much advisable for them get a astrological consultation to get a right partner`,
      ],
      business: [],
      predictive: [
        `Emotions`,
        `Krishna Leelas look like they are random, psychic and devoid logic and faddish (sunky)`,
        `Emotional logic unlike Aries`,
        `Good as friend / teammate`,
        `Planet sitting in Cancer #1 objective is create peace / behieve like mother`,
        `Dasha of cancel might take extreme steps to achieve peace`,
      ],
      inHouse: {
        1: [
          `Psychic`,
          `Most of their actions are unexplainable and not very logically correct, but morally right`,
          `Cancer ascendants cannot explain the logic behind their action. They do actions emotionally`,
          `Cancer Ascendant or Moon in Lagna or Lagna Lord closely conjunct moon, these people show lunatic nature`,
        ],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [
          `Adopt strange ways to achieve peace`,
          `Person will go away from the house just to achieve peace`,
          `Travel long distances just to achieve peace`,
        ],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
  {
    id: 5,
    name: "Leo",
    vedicName: "Simha (सिंह)",
    ownerGraha: "Sun (रवी)",
    ownerShortName: "Su",
    gender: "Male (पुरुष)",
    nature: "Stable (स्थिर)",
    tenor: "Firy (अग्नि)",
    handle: "Leo",
    characteristics: `This rashi includes complete Magha, Poorva Phalguni and first charan of Uttara Phalguni. The people of this sign are courageous, tall in height and have powerful physique and broad chest. They possess impressive and commanding personality. They are born with leadership qualities and have magnetic power of attraction. They have reddish complexion. They have strong bones, broad forehead, beautiful eyes and big nose. They are ambitious and have dominating nature. They seek such occupations like military, police services or administrative services. They are proud, pious and clean hearted. They get fewer results for their deeds but are never disappointed. They love travels of hills and are adventurous. They like fast driving and fast moving vehicles. They achieve rise in destiny very late sometimes above 30 yrs of age. They have small families. Their pitch of voice is high. They are ambitious, autocratic, independent, strong-willed, suspicious, violent, ill tempered and jealous. They are strong and rough towards their subordinates`,
    kpBodyParty: `Heart, Spinal Column, Nerves and Fibre, Bones of the back`,
    kpTenorProperties: `fiery - controls the vitality of a person`,
    kpPathogenicEffects: `Regurgitation of Blood, Palpitation , Aneurism , Feeling giddy, faintings, Spinal meningitis, Curvature of the spine, Arteri Sclerosis , Angina Pecturis , Hoper Aemia, Anaemia and Hydraemia etc`,
    kpHPlaces: `Forest, desert, rock, fort, castles, palaces, furnaces, ovens, kitchens`,
    kpHCountriesNCities: `France, Puglia, Italy, Sicily, Bohemia, the Alps, Chaldea, the coast of Sidon and Tyre, Capadocia. Apulia, Lancashire, California, the North of Rumania, Rome, Bristol, Bath, Prague, Taunton, Damasond, Ravenna, Dalton-le-Moo,' Portsmouth, Philadelphia (Chicago), East of Narmande, Orissa. Bengal, Ganjam district, Kama. rup in Assam, Puhinda Hill. Eastern half of Deccan, Eastern bank of Jumna, the Bindha Hill, Golangul, Burdwan, Sree mountains, the Mikal hill. South Bihar, Kirata, Vitaka, Champs, Udumbara, Chedi, river ikshmatee, Vanga, Kalinga, etc`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Northern`,
    kpHElement: `Fiery signs: Assertive, independent, courageous and enthusiastic. Activity and ambition, vigour and vitality, energy and push`,
    kpHQualities: [
      `Fixed signs; Stamina and persistence in action. Very strong: and determined will. Keep on working, patience and persistence; practical; firm`,
      `Barren signs: Attempts not successful; No desired results`,
      `Bestial signs: 4 legged`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Well-developed appearance; broad shoulders; broad forehead: Tall, well-built, muscular; full stature; majes­tic appearance`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Noble, large hearted, magnanimous, generous, furious, humanitarian, no evil thought, dignity, faith, less of talk, observes silence, likes flattery, enthusiastic, sports, speculation`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Good constitution, quick recovery, Heart disease, palgitation, spinal meningitis, syncope, locomotor ataxia, inflammations, sunstroke, giddiness, pestilence. Epilepsy, rheumatic fever`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) High position, deals big issues, spend-thrift, faces hard times, in youth lucky, gambles, speculates, cverliberal`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Romantic, deep love, keeps partner cheerful, ideal lover, very passionate, attracts others, proud of family`,
    nkAmenableSigns: `Libra`,
    nkCaste: `Kshatriya`,
    nkQualities: `Fame, power, wealth and success, Leadership ability, creative nature, Majestic appearance, honorable and respected, Sincere in their affections, faithful (fixed sign), Strong issues with loyalty and trust, Love of sports and entertainment, Gifts in music, dance and drama, Need to be totally involved in their work, set high standards, Self-employed or in management positions, Political interests, government work, Interested in God-realization, mystical nature, May live in mountain regions, gains thru property, Pitta nature`,
    nkShadowIssues: `Arrogance and vanity, Trouble in early marriage (Saturn rules 6th and 7th houses), Limits the number of children, challenges (Jupiter rules 5th and 8th houses), Issues with anger and impatience, Set their goals too high, experience disappointment, Conflict with employers, need to be their own boss, Strong sexual desires, need to learn to tame the inner beast`,
    nkFriends: `Sun, Jupiter, Mars (Yoga—Karaka) Moon—Neutral`,
    nkEnemies: `Venus, Mercury, Saturn`,
    direction: ``,
    nakshatraOwners: `Ke, Ve, Su`,
    exaltPlanet: `-`,
    debilitated: `-`,
    bhrigu: ``,
    sunTransits: `Aug 15 - Sep 15`,
    aspects: {
      aspectNumbers: [1, 7, 10],
      aspectNames: ["Ar", "Li", "Cp"],
    },
    lunarAstro: {
      basic: [
        `Leo sign is a fiery sign and movable as well, It is represented by Lion as it is difficult to ignore lion anywhere in world, similarly anyone born on Leo ascendant is difficult to ignore due to his aura and personality`,
        `Leo is another royal sign but unlike moon it is not a ground of emotional turbulence rather people born in Leo ascendant are confident and natural leaders`,
        `Leo sign has self respect to a level that these people never ask for help or favour even in most difficult times of life as by showing weakness to others or asking for help is a matter of shame for them`,
        `Mostly you will find these people working independently without supervision or doing their own business rather than working for anyone else. If in service mostly managerial positions where they can continuously take decisions`,
        `Since governed by Sun these people are disciplined like to follow a routine in life`,
        `Their thought process is on different level, These people gets more happy when they do business in which they are also helping for social cause and giving something back to society`,
      ],
      business: [],
      predictive: [],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
  {
    id: 6,
    name: "Virgo",
    vedicName: "Kanya (कन्या)",
    ownerGraha: "Mercury (बुध)",
    ownerShortName: "Me",
    gender: "Female (स्त्री)",
    nature: "Dual (द्विस्वभावी)",
    tenor: "Earthy (पृथ्वी)",
    handle: "Vir",
    characteristics: `Virgoans are shy and sensitive. They usually walk with a curved neck. Smiling is a peculiar sign of their nature. They are practical, intelligent and love refinement in life. This sign is made up of the last three charans of uttara phalguni, Hasta, and the first two charans of Chitra Nakshatras. The persons with the influence have lively smiling faces, and carefree gait. They are lazy and lethargic by nature. They are slow workers also. They have shrill voice. They sometimes lose luster of body. They are intelligent, middle-heighted. They are artisans by nature. They are observant, patient, sensible, studious, soft spoken, and logical. They do not reveal their secrets. They are shrewd but discrete. They fall victims to stomach ailments like diarrhoea, loose motions, stomach aches. Allergy of skin is also their ailment. Travel is their favourite passion. They seldom lose temper. Lottery, speculation is their taste .Usually they shift from their place of birth .They acquire estate or property at the fag end of life`,
    kpBodyParty: `Abdominal and umbilical regions, Bowels, Intestines and Liver, Nervous system`,
    kpTenorProperties: `earthy - control bones and flesh`,
    kpPathogenicEffects: `Tape worm, Round worm, Hook worm, Nutrition , Peritonitis, Interference with the absorption of the Chyle, Typhoid, Cholera, Appendicities, Loose motion, Vitamin B Deficiency`,
    kpHPlaces: `Libraries, book-shelves, books, closets, corns fields`,
    kpHCountriesNCities: `Turkey, Croatia, Mesopotamia, Bobylon, Assyria, the locality between the Tibet and the Eurphates, Greece, Livadia Thessaly, Corinth, Mores, Condia, Switzerland, Lower Silesia, Brazil, Virginia and West Indies, Jerusalem, Navarre, Paris, Brazil, Padua, Lyons, Tolluouse, Reading, Bagdad, Cheltenham—Maidstonte, Norwich, Todmordan, Boston, Brindisi, Corinth and Los Angels`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Northern`,
    kpHElement: `Earthy signs: Stability, material welfare, wealth, power, position, health, cautious, premeditative, prudent and prac­ tical; secretive and auspicious; stubborn and self-protective; slow and steady; reserved and restrained; methodical and economical; plodding and perservering`,
    kpHQualities: [
      `Common (dual, mutable) signs: Dual, fluctuating, flexi­ bility, plasticity, intellectual, sympathetical sensitive, subtle, versatile, changeful, inconstant, indecisive, vacillating and restless`,
      `Barren signs: Attempts not successful; No desired results`,
      `Human signs: Humane`,
      `Voice signs (Western Authors)`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Slender body; tall, dark hair; and eyes; curved eyebrows, profuse growth of hair; thin voice; quick walk, pot-belly; long nose`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Ever changeful, commercial instinct, very brisk, very quick; thorough, methodical sensible, rational practical, discriminative, critical, self-pity, saves money, prudent, over-careful, proper, orderly, fond of horticulture, gardening, medicine, food, hygiene`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Good health, very active, careful about diet, abdomen. Hypochondria, chylifactive, typhoid, bowels, malnutrition, tapeworm, intestinal disease`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Commercial instinct, careful about money, knows value of money, self-made, works hard, money accumulates, investment nor profitable`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Own ideals, so difficult, long delay to choose one, cares work, perfection, values the intellectuals, shy, touchy, finds fault with others`,
    nkAmenableSigns: `Cancer, Pisces`,
    nkCaste: `Shudra`,
    nkQualities: `Intelligent, discriminating nature, detail oriented, Analytical nature, good with mathematics or computers, Attractive, well-dressed, clean (cleanliness is next to godliness), Eat good quality food, interest in self-healing, May work in food service of healing professions, Need a lot of variety in their work, Success in business and communications work, Interest in arts and music, good education (Venus rules 2nd and 9th), Gentle nature, shy and naive, feminine sign, Love of nature and greenery, enjoy hiking, Deep sensuality behind closed doors, Peaceful end of life, spiritual home, religious nature, Vata nature`,
    nkShadowIssues: `Critical, judgmental nature, Trouble with health, sensitive nervous system and digestion, Marital difficulties, need a spiritual mate, Few children if any (challenges due to Saturn ruling 5th and 6th houses), May have anxiety about the world due to perfectionistic nature, May lack self-confidence, dislike conflict`,
    nkFriends: `Mercury, Saturn, Venus (Yoga—Karaka)`,
    nkEnemies: `Sun, Moon, Jupiter and Mars (very malefic—rules 3rd and 8th houses)`,
    direction: ``,
    nakshatraOwners: `Su, Mo, Ma`,
    exaltPlanet: `Mercury`,
    debilitated: `Venus`,
    bhrigu: ``,
    sunTransits: `Sep 15 - Oct 15`,
    aspects: {
      aspectNumbers: [3, 9, 12],
      aspectNames: ["Le", "Sg", "Pi"],
    },
    lunarAstro: {
      basic: [
        `Virgo is second sign owned by Mercury, while Gemini was airy sign Virgo is earthy sign and is more practical than Gemini`,
        `Virgo is represented by girl-A Virgin`,
        `Virgo natives tend to plan everything before starting their work, If they have to go on vacation they will plan each and every detail of the trip`,
        `Virgo natives are best story tellers as when these people will tell story, they will paint the picture perfectly by telling you each and every detail such as colour of curtains and what someone was wearing`,
        `If you see someone writing down house expense every month and going through it 3 times, then that person is your Virgo. `,
        `These people are tailored for serving others and do very good in being nurse, clerk and more of a routine jobs as they care of each small detail patient might need`,
        `These people do not express themselves much and keep their feeling to themselves only for which sometime they have to suffer`,
      ],
      business: [],
      predictive: [`Disputes`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
  {
    id: 7,
    name: "Libra",
    vedicName: "Tula (तुला)",
    ownerGraha: "Venus (शुक्र)",
    ownerShortName: "Ve",
    gender: "Male (पुरुष)",
    nature: "Movable (चर)",
    tenor: "Airy (वायू)",
    handle: "Lib",
    characteristics: `The Librans are usually thin and tall. They have conspicuous veins. This sign includes the last two charans of Chitra, Swati and three charans of Vishakha Nakshatras. They are logical with their nature and are dominated by balance and harmony. They are sensitive, curious, will - principled, gentle, critical, good in perception, but are intolerant. Charming and easily mixing with people around are their sterling qualities. They are pious and spiritual. They are logical and as such balance all their deeds and relations with logic. They speak less but believe more in action. They are learned, intelligent, and helpful to others and do social work. They may have a thick nose but are attractive. They believe in religion. They are repellent to opposite sex. The early stages of their lives are usually marked with hardships, but destiny shines to them between 32-35 of their age. Great people help them. They are likely to have skin disorders like boils etc. They impress people effortlessly. They are prone to kidney diseases. They marry usually rich spouse. They come up in life in their native place itself`,
    kpBodyParty: `Lumbar Region and skin, Kidneys and bones of the Lumbar regions. Uterus of the Lady`,
    kpTenorProperties: `airy - governs the breath`,
    kpPathogenicEffects: `Polyria, Suppression of the Urine, Inflammation of the Uterus, brights disease, Lumbgao (pain in the muscles and joints of the lower back), Rheumatic Pains, Eczema, Skin disease, Affection of Vasomotor system, Kidney appendicities, Hernia`,
    kpHPlaces: `Out-house, bed-room, inner-chamber, wind-mill top of the mountain, side of the hill, well ventilated place`,
    kpHCountriesNCities: `Northern Provinces of India, Burma, Indo-China, China, Argentina, Republic parts of Tibet, Upper Egypt, Libya, Uzbeck, Savoy, Austria, Bactrianna and Livonia, Antwerp, Nottingam Leeds, Frankfort, Lisbon, Vienna Charles Town, Petrograd, Copenhagen, Spiries, Playonya Fribourg, Suessa, Johannesburg and Gaeta`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Southern, Equinoctial`,
    kpHElement: `Airy signs: Cheerful, gentle, amiable, courteous, sympa­ thetic, refined, humane. Good intellect, fertile imagination, idealistic and artistic, well-informed, fertile. Musical, level­ headed and tactful`,
    kpHQualities: [
      `Movable signs: Enterprise, enthusiasm, love of ambition, forcefulness, and go-a-head spirit; fame, recognition and popu­ larity : loves changes and reform`,
      `Semi-fruitful`,
      `Human signs: Humane`,
      `Voice signs`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Tall, well-proportioned body; slender limbs; strong, graceful appearance; sweet smile; attractive counte­ nance; beautiful eyebrows; parrotlike nose`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Level headed, mental equilibrium, dispassionate, .compares and contracts, constructive critics foregoes, personal comforts, painstaking, helps others, popular, politicians, fertile imagination, correct intuition brilliant, intellect, admirable refinement, pleasant nature, gentle and affectionate, easy-going, shrewd, persuasive, fond of opposite sex`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Normal health, susceptible for infectious disease, prophylactic measures-needed, Kidneys, loins, spines? affected,, uterus removal, appendi­ citis, Lumbago, Polyria, Hernia`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Business with partners, advisers, critics, story writers, able salesman, spend-thrift`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Most lovable, experts in love affairs, sincere, present conversationalists, enjoys in full, satisfies`,
    nkAmenableSigns: `Virgo, Capricorn`,
    nkCaste: `Vaisya`,
    nkQualities: `Considered the strongest ascendant, political power and leadership, Skillful in business and trade, career success, Searches for beauty, truth and fairness, Very frank and forceful about their opinions, militant nature, Good social psychologists, contribute to humanity (Moon rules the 10th), Love of social functions, groups and many friends, Government jobs, organizational development skills, Good at mediation, can see both sides of an issue, interest in law, Sensual nature, like to live in comfort, owns property, Active nature, love of travel to foreign lands (Mercury rules 9th and 12th houses), Cardinal or moveable sign, Vata nature`,
    nkShadowIssues: `Difficult for early marriage (Mars rules 7th house), Few children if any (Saturn rules the fifth), Overly zealous about their ideals, may become dictatorial, Need to be discriminating about their friends and associates, Can be indulgent if Venus is afflicted`,
    nkFriends: `Venus, Mercury, and Saturn (Yoga—Karaka)`,
    nkEnemies: `Sun, Jupiter, Mars (double maraka), Moon—neutral`,
    direction: ``,
    nakshatraOwners: `Ma, Ra, Ju`,
    exaltPlanet: `Saturn`,
    debilitated: `Sun`,
    bhrigu: ``,
    sunTransits: `Oct 15 - Nov 15`,
    aspects: {
      aspectNumbers: [2, 5, 11],
      aspectNames: ["Ta", "Le", "Aq"],
    },
    lunarAstro: {
      basic: [
        `Libra is an airy sign and second sign owned by Venus, while Taurus was earthy sign running towards money, Libra is an airy sign who does lot on mental plane`,
        `Libra is represented by sign balance, Libra natives tries to balance pros and cons in every situation`,
        `If you ever ask any of you family or friends opinion regarding travelling from New-Delhi to Mumbai by bus or train ? Person who will give you advice in a form in which he will advocate both means by telling you pros and cons of both will be the person of Libra`,
        `Even if a enemy comes to Libra natives for advice these people will give the best advice they can as it is in their nature to guide people to right path`,
        `Libra natives are diplomatic, open minded and social in nature and tend to mix up with everyone`,
        `Sometimes due to their nature of balancing pros and cons they tend to delay decisions in life for which they regret also`,
        `You will find libra natives open to admit their mistakes and ready to improve based on new information provided`,
        `Libra natives do not shy away to learn anything new even from 5 year old kid as these people embrace knowledge and believe in healthy discussion but never the violence and try to avoid conflict with anyone`,
      ],
      business: [],
      predictive: [`Cloths`],
      inHouse: {
        1: [],
        2: [],
        3: [
          `Donate Kids(3rd House Mercury) Cloths(Libra) to Neighbors (Mercury) Give more than they expect`,
        ],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [
        `Donate Kids(3rd House Mercury) Cloths(Libra) to Neighbors (Mercury) Give more than they expect`,
      ],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 8,
    name: "Scorpio",
    vedicName: "Vrischik (वृश्चिक)",
    ownerGraha: "Mars (मंगळ)",
    ownerShortName: "Ma",
    gender: "Female (स्त्री)",
    nature: "Stable (स्थिर)",
    tenor: "Watery (जल)",
    handle: "Sco",
    characteristics: `The scorpions are quite serious in nature mixed with a blend of jealousy. The sign comprises of the last charan of Vishakha, full Anuradha and Jyeshta Nakshatras. The people with this sign are trustworthy, honest, truthful and sincere. They have big teeth, big jaws, fair complexion, medium height and fat body. They are of medium height and possess fat body appearance. They are short tempered, self-confident and courageous people. They normally seek jobs associated with chemicals, army and technology. They are also successful in business. They are violent and do not care for repercussions in scuffles and trifles. They sometimes harm their health by addictions. They use rough language and are straightforward in their speech. They do and finish their work quite fast but irritate others by their nature. They speak less but believe more in action. They are learned, intelligent, and helpful to others and do social work. They may have a thick nose but are attractive. They believe in religion. They are repellent to opposite sex. The early stages of their lives are usually marked with hardships, but destiny shines to them between 32-35 of their age. They are clever in earning. They don't have good relations with their brothers and sisters. They may have scars on hands and legs. They are impatient and love rituals more than truth. Though they are outspoken, a soft heart within them worries about children usually surrounding them`,
    kpBodyParty: `Generative Organs and Anus, Bladder and Bones of Pelvic Area`,
    kpTenorProperties: `watery - governs human blood and blood circulations`,
    kpPathogenicEffects: `Affection of the bladder, coliform infection troubles in the urethra and generative organs, Rectum, colon, the prostate glands, nasal catarrh, adehoids troubles in the womb, ovaries, venerial diseases, irregularities of the menses, leucorrhoea(a whitish or yellowish discharge of mucus from the vagina), rupture, renal stone`,
    kpHPlaces: `Drains, cesspools, ruins, marshy area, washbasin, laundry and places where rubbish is thrown`,
    kpHCountriesNCities: `Sweden, Brazil, Morocco, Transval, Norway, Syria, Bhvaria, Algeirs, Barbay, Syria, Catalonia, Judea, Fez, Jutland, Cappadocia, Queensland,  Liverpool, Delhi, Frankfurt, Baltimore, Stockport, Washington, New Castle, Messina, Dover, Oregon, Ghent, Glossop, Halifax, Hull, Valentia, Portland`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Southern`,
    kpHElement: `Watery signs : Fertile imagination; constitution—weak; timid, inert and unwilling to act. Receptive, contemplative, sensitive, sentimental and psychic`,
    kpHQualities: [
      `Fixed signs; Stamina and persistence in action. Very strong: and determined will. Keep on working, patience and persistence; practical; firm`,
      `Fruitful signs show success: reilisation of ambition`,
      `Mute sign`,
      `Violent signs: "no fears".They undertake all enterprises with courage and confidence`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Well-proportioned body; long nose; average stature; broad face; commanding appearance; short and curly hair, muscular body; good personality`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Very clever, hard working, overcritical, determined, never ideal, never mindful of obstacles, good imagination, sharp intelligence, intense feeling, emotion, good critic, resourceful, proper intuition, prompt action, successful bargains, self-assertion, impulsive action, independ­ ence, excitement, likes and dislikes, gossip, non-interfering, frank, plain, blunt, revengeful`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Good; health, quick recovery, generative organs, the bladder, womb, ovaries, prostate gland, venereal distemper, renal stone, gravel Adenoids, Polypi, Piles`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Impulsive purchases, extravagant expenses, makes large money, and spends much of it, no mind to save maximum, but fortune favours`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Complex mood, energetic, dynamic, lacking sympathy, unsteady, expects appreciation from partner, gives comforts, and keeps in good cheer`,
    nkAmenableSigns: `Cancer`,
    nkCaste: `Brahmin`,
    nkQualities: ` Youthful appearance, strong, courageous, Intense, forceful nature, need for excitement, Self-reliant, competitive, determined people, Secretive, love of the occult or hidden things, Work in medicine, pharmacology, hospice settings, Good for detective, police work and research Interest in the creative arts, mate may be an artist, Deep sensual nature, passionate, Good teaching abilities especially one on one, Take pride in being unconventional, Pitta nature`,
    nkShadowIssues: `Difficult marital karma, loss of mate (Venus rules 7th and 12th houses), Temperamental differences with mate, may try to dominate or control partner, Revengeful if betrayed, issues with anger, Brooding nature, pessimistic, Loss of father or trouble with teachers/gurus, Rebellious, question authority figures`,
    nkFriends: `Sun, Moon, Mars, Jupiter`,
    nkEnemies: `Venus, Mercury, Saturn`,
    direction: `South-South-West`,
    nakshatraOwners: `Ju, Sa, Me`,
    exaltPlanet: `-`,
    debilitated: `Moon`,
    bhrigu: ``,
    sunTransits: `Nov 15 - Dec 15`,
    aspects: {
      aspectNumbers: [1, 4, 10],
      aspectNames: ["Ar", "Cn", "Cp"],
    },
    lunarAstro: {
      basic: [
        `Scorpio sign is owned by Mars but unlike Aries which is fiery sign, Scorpio is watery sign and more stable than cancer`,
        `Scorpio natives as under influence of Mars are very energetic but focused if they decide to get something surely these people will achieve it`,
        `Scorpio natives are brave and face every difficulty in life with courage. Imagine someone who lost everything in flood, these people will start from next day to build house`,
        `Scorpio natives are the ones you can trust, If Scorpio native promised you that they will stood by you the these people will keep the promise`,
        `Scorpio natives are best in keeping secrets and most suited in profile of a spy, or at a position where confidentiality is a priority`,
        `Being the 8th sign of natural Zodiac these people are inventors, researchers also something unusual hidden in sex life`,
        `Scorpio being the negative sign of Mars as carrying the temperament of Mars as well. Most number of terrorist, revolutionaries in world are born under this sign as this why this sign is not common`,
      ],
      business: [],
      predictive: [
        `Transformations`,
        `Planet sitting in Scorpio #1 objective is transformations (die and born again / 180º change)`,
      ],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 9,
    name: "Sagittarius",
    vedicName: "Dhanu (धनु)",
    ownerGraha: "Jupiter (गुरू/बृहस्पति)",
    ownerShortName: "Ju",
    gender: "Male (पुरुष)",
    nature: "Dual (द्विस्वभावी)",
    tenor: "Firy (अग्नि)",
    handle: "Sag",
    characteristics: `This Rashi sign is made up of full Nakshatras of Mool and Poorvashada and the first charan of Uttarashadha. The people under this sign are men of principle, following their religion. They are the nucleus of their families, earning for all. They have great potentials, happy and lucky attitude towards life. They are enterprising, logical, leaders, have healthy attitude towards life. They gain popularity through their actions and intelligence. They are optimistic, truthful and generous. They are loyal, independent, modest, and virtuous. They attain their goals in life. They are usually born in rich families and do not acquire riches by their efforts. They have long faces and necks and other parts of body attractive but conspicuous. They are soft-spoken and quite popular. They stick to their friendship. They are courageous but tolerant. They lose their hair of their head early. They work without thinking of fruits. Even if they are traders they are honest. They are quick to anger; are more idealistic and dominating. They attain rise of destiny at the age of 22-23 years`,
    kpBodyParty: `Hips and Thighs, Veins and Arteries, Buftock, Bones of Thighs`,
    kpTenorProperties: `fiery - controls the vitality of a person`,
    kpPathogenicEffects: `Affection of the hips, thighs, temur, Illiumn (The upper part of the pelvic bone, which forms the receptacle of the hip), locomotar ataxia(inability to precisely control one's own bodily movements), Rheumatism disease of in the hip, also pulmonary troubles, Fracture of collor Bones, Varicose Veins`,
    kpHPlaces: `Stable, hill, high land, pooja-room, temple cash-box, money-purse`,
    kpHCountriesNCities: `Australia, Arabia, Felix, part of France, Madagascar, Hungary. Sclaronia, Tuscany, Spain, Istria, Dalmatla, Italy, tfarbonne, Stutland, Avignon, Sheffield, Rottenberg Cologne Buda, Bradford, West Bromwich, Torouts, Toledo Portand, Nottingham`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Southern`,
    kpHElement: `Fiery signs: Assertive, independent, courageous and enthusiastic. Activity and ambition, vigour and vitality, energy and push`,
    kpHQualities: [
      `Common (dual, mutable) signs: Dual, fluctuating, flexi­ bility, plasticity, intellectual, sympathetical sensitive, subtle, versatile, changeful, inconstant, indecisive, vacillating and restless`,
      `Semi-fruitful`,
      `Human signs: Humane (1st half - Sagittarius)`,
      `Voice signs (Western Authors)`,
      `Bestial signs: 4 legged (2nd Half - Sagittarius)`,
      `Dual signs`,
      `Bicorporeal or double-bodied sign (1st half - Sagittarius)`,
      `Long ascension (वाढ): inclusive : 90° on both sides of Autumnal (शरद ऋतू) equinox`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Well-proportioned, well-developed body; eyebrows high and bushy; long nose; broad eyes, charming appearance; graceful look; fair complexion; beautiful`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Bold, courageous; pushful, ambitious, greedy, aspiring, optimistic, never timid, dauntless, self-confidence, energy, enthusiasm, vigour, vitality, true, honest, sincere, God-fearing`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Good health, quick recovery, Sciatica, rheumatic pain, fracture in the hip, lumbago, gout, eosonophilia, lung trouble, fracture of the bones, diabetes, affection; of lungs, hiccough, asthma`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Earns by fair means, no black money, satisfactory earnings, speculation not useful, false prestige, spends much`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Temper flares up, interest in games, society, family life not so important, person of integrity, and morals, allows freedom to wife`,
    nkAmenableSigns: `Pisces`,
    nkCaste: `Kshatriya`,
    nkQualities: `Noble, active, travel a lot, need for adventure, Athletic, healthy, cheerful and optimistic nature, Leadership skills, independent profession, Philosophical, excellent for writing and teaching, May have two careers, communications work, Humanitarian, need to fight for a good cause, valiant, Great achievement if they can get behind an ideal or movement, Success in foreign lands or away from birth place, Good parenting skills, excellent counselors, Spiritually oriented, virtuous nature, Fond of hiking and vision quests, Get nice homes, gains thru property, Honored by noble people, sacrifice themselves for others, Need intelligent and successful mate that helps their career, Open-hearted and generous, fair and just, Kapha and pitta nature`,
    nkShadowIssues: `Opinionated, arrogant, need to watch speech, Need to budget money, fear of poverty, Trouble with siblings, Loss of child, Need to leam discrimination in sexual matters, promiscuous`,
    nkFriends: `Sun, Mars, Jupiter, Moon—Neutral`,
    nkEnemies: `Venus, Mercury, Saturn`,
    direction: ``,
    nakshatraOwners: `Ke, Ve, Su`,
    exaltPlanet: `-`,
    debilitated: `-`,
    bhrigu: ``,
    sunTransits: `Dec 15 - Jan 15`,
    aspects: {
      aspectNumbers: [3, 6, 12],
      aspectNames: ["Ge", "Vi", "Pi"],
    },
    lunarAstro: {
      basic: [
        `Sagittarius is 9th sign of zodiac and first sign owned by Jupiter, This is a fiery sign and since owned by natural benefic Jupiter a very optimistic and positive sign`,
        `Sagittarius is represented by –Human archer on top and lower body is of Horse`,
        `Sagittarius natives are very fond of travelling and mostly you will find these people in jobs where travel is required as these people enjoy travelling around`,
        `Sagittarius natives don’t think twice before giving advice to others as they do not think how rude or illogical their advice may be. Usually the ones with big mouth`,
        `Sagittarius natives are open minded and tend to work in a team and easy way to spot them in team is their way of approach to complete targets is by wonderful planning but always happy go lucky attitude considered as happiest sign of Zodiac`,
        `Negative traits of this sign is sometimes these people promise more than they can actually do and unable to deliver`,
        `These people love to teach and tend to do good in a role of a mentor, coach, city planners but these people never pay attention to details and easily gets irritated when someone points out small details while panning.(Jupiter effect)`,
      ],
      business: [],
      predictive: [`Higher learning`],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Ketu`,
    },
  },
  {
    id: 10,
    name: "Capricorn",
    vedicName: "Makar (मकर)",
    ownerGraha: "Saturn (शनी)",
    ownerShortName: "Sa",
    gender: "Female (स्त्री)",
    nature: "Movable (चर)",
    tenor: "Earthy (पृथ्वी)",
    handle: "Cap",
    characteristics: `This Rashi sign comprises of the first three charans of Uttarashad, Shravan complete and two charans of Dhanishta Nakshatras. The people under this sign are thin in body, long-necked and have thin teeth. They have hard hair on their scalp. They have strong bones. Sharpened chin is usually noticeable. They are stable and serious in nature. They set high standards for themselves. Sometimes their private and social lives are different. They are critical in nature and even do not spare themselves in criticism. They are slow workers but work confidently only. They are usually seen having large families. They are usually not sensitive about means but aim at the end only. They are introverts and are reserved in nature. They are highly ambitious and try utmost to acquire higher status in life. Their will power is strong. They are disappointed when they fail to achieve the goal they aspire set goal in their life. The seers and sages are also found under this sign. They may have to face rivalry and strong enmity in life. They are showy sometimes. Rise in destiny is marked at 32-33 years of age. They develop ailments associated with allergies, hypertension and weak-back`,
    kpBodyParty: `Knees and Hands, Bones of Knee caps and joints, Skin`,
    kpTenorProperties: `earthy - control bones and flesh`,
    kpPathogenicEffects: `Affects the skin, the patella, knee cap and the knee Stomach, Eczema, Cry sipelas, Leprosy, Leucoderma, Digestive disturbances, Rhematism, Chills gout, Neuralgia, Melancholia, Blood disorders`,
    kpHPlaces: `Cow-shed, lumber-room, store-room, thorny places, low lying area`,
    kpHCountriesNCities: `India, Punjab, Circram Macedonia, Macian, Illyria, Thrace, Bosnia, Morea, Bulgaria, Romandiola, Albania, Stiun, Syria, parts of Persia, Afganistan, Mecklenburg, Hesse, Mexico, Orkney islands, Keighley, Salisbury, Bruesels. Oxford, Bradenburg, Prato, Fayence, Tortano, Mayence`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Southern, Tropical`,
    kpHElement: `Earthy signs: Stability, material welfare, wealth, power, position, health, cautious, premeditative, prudent and prac­ tical; secretive and auspicious; stubborn and self-protective; slow and steady; reserved and restrained; methodical and economical; plodding and perservering`,
    kpHQualities: [
      `Movable signs: Enterprise, enthusiasm, love of ambition, forcefulness, and go-a-head spirit; fame, recognition and popu­ larity : loves changes and reform`,
      `Semi-fruitful`,
      `Bestial signs: 4 legged`,
      `Short ascension (वाढ): inclusive : 90° on both sides of Vernal (वसंत ऋतू) equinox`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Emaciated, weak; slow growth; slender, lean, wiry; long nose; deep set eyes; coarse hair. In old age, hunch backed`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Economical, prudent, reasonable; thoughtful, practical minded, methodical, plodding, persevering, patiently working, quick execution, careful decision, confidence, toler­ ance, steady, reserved, fear of redicule, chooses friends, honest, sincere, reliable or conceited; dishonest, selfish, greedy, miserly, criminal, underhand dealings`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Leprosy, Erysipelas, Rheumatism, gout, indigestion, cold, melancholia, neuralgia, knees and petalla bones fracture, urticaria, skin disease, eczema, dislocation`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Conservative, gains, fame and money resourceful, concentration, no speculation, invest on permanent position, industrious, saves money, no waste, no rest`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Affectionate, but never gives expression, emotional, slow and cautious in selection, never bold and rash, vfait for the consent of the other, true and faithful`,
    nkAmenableSigns: `Aries, Aquarius`,
    nkCaste: `Shudra`,
    nkQualities: `Career oriented, success in the business world, ambitious, Sure-footed goat climbing the mountain step by step, Patience and perseverance brings wealth, Good organizational ability, good reputation, Hard workers, perform difficult tasks, Love of travel particularly for business or educational endeavors, Conservative nature, like ancient ways and tradition, Love of children, successful, artistic children, Success more in the second half of life, Interest in the fine arts, creative business ideas, Dry sense of humor, Vata Nature`,
    nkShadowIssues: `Pessimistic, feel unappreciated, difficult early life, Critical nature, picky, focus on the flaws too much, Cold-hearted and calculating in business, Dissatisfaction with the home or family life, Few children (Jupiter rules 3rd and 12th houses), Miserly nature, Troubles with arthritis or fatigue if Saturn is afflicted`,
    nkFriends: `Mercury, Saturn, Venus (Yoga—Karaka)`,
    nkEnemies: `Sun, Moon, Jupiter, Mars`,
    direction: ``,
    nakshatraOwners: `Su, Mo, Ma`,
    exaltPlanet: `Mars`,
    debilitated: `Jupiter`,
    bhrigu: ``,
    sunTransits: `Jan 15 - Feb 13`,
    aspects: {
      aspectNumbers: [2, 5, 8],
      aspectNames: ["Ta", "Le", "Sc"],
    },
    lunarAstro: {
      basic: [
        `First sign owned by Saturn, Earthy in nature, very practical in nature and effect of Saturn is completely in this sign`,
        `Capricorn is represented by fish goat hybrid`,
        `Capricorn natives are most serious than any other signs as due to effect of Saturn most of the time there concentration is on getting goal achieved`,
        `Very responsible to get the job done and also hard working`,
        `Even in young age these people take responsibility of family and sacrifice themselves for the happiness of others`,
        `Capricorn natives hardly spend money on themselves as they believe in saving money for bad times`,
        `Since ruled by Saturn these natives have lot of patience in getting job done and it does not matter how much time job will take but ultimately slowly and steady these people will achieve the target. For example-Dashrath Manji`,
      ],
      business: [],
      predictive: [],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
  {
    id: 11,
    name: "Aquarius",
    vedicName: "Kumbha (कुंभ)",
    ownerGraha: "Saturn (शनी)",
    ownerShortName: "Sa",
    gender: "Male (पुरुष)",
    nature: "Stable (स्थिर)",
    tenor: "Airy (वायू)",
    handle: "Aqu",
    characteristics: `This Rashi sign consists of last two charans of Dhanishta ,Shatbhisha complete and the first three charans of Poorvabhadrapada Nakshatras. Aquarians are lovers of personal freedom. The height of the body is dwarf or medium. The forehead is small and face less attractive. They are imaginative, serious and spiritual in nature. Their back is wide and sound. They are behaviourists, kind and helpful. They tend to hide their character and are attracted to the opposite sex. They do not make friends easily but like the friends they have. They are afraid of sins. They acquire friends of higher status and caliber. They are of fair complexion and have fatty fore and hind limbs. They are emotional and cannot see the adversities of others. They are affected with headaches, indigestion and stomach irregularities. They are theists and believe in religious values. They are attached to their spouses and are called gentlemen. They have to travel for their livelihood and usually have more than one source of income. They are unprejudiced, frank, tolerant, quick-witted, practical, intuitive and imaginative. They inherit property but face litigation, sometimes of disputes in the process of inheritance`,
    kpBodyParty: `Ankles and Feet, Circulatory system and Bones of Ankles and Shanks`,
    kpTenorProperties: `airy - governs the breath`,
    kpPathogenicEffects: `Affects the ankles, the limbs, Varicose veins, Sprain in the ankles, dropsy, irregularities of the functioning of the heart, skin, and eye disease, Filarias`,
    kpHPlaces: `Mines, metals, quarries, conduits, tunnels, lecture room, or research laboratories`,
    kpHCountriesNCities: `Arabia, Abyssinia, Prussia, Red Russia, Circassia, Lithuvania, Poland, Tartary, Swedan, West Phalia, Ursbeck. Wallachia, Piedmont, Azania, mainly Russia, Salisbury`,
    kpHGender: `Positive/Masculine signs: Assertive, bold, expressive, active, offensive, determined, more self-expressive`,
    kpHOrientations: `Southern`,
    kpHElement: `Airy signs: Cheerful, gentle, amiable, courteous, sympa­ thetic, refined, humane. Good intellect, fertile imagination, idealistic and artistic, well-informed, fertile. Musical, level­ headed and tactful`,
    kpHQualities: [
      `Fixed signs; Stamina and persistence in action. Very strong: and determined will. Keep on working, patience and persistence; practical; firm`,
      `Human signs: Humane`,
      `Voice signs`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Tall, full stature; oval face>; fair complexion; handsome appearance; defective teeth; brown shady hair`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Intelligent, careful and cautious, watchful and thoughtful, intelligent, slow in grasp, strong memory, broad out-look human understanding, outspoken, unselfish, humane, impersonal, social/silent worker, new thoughts, fresh Ideas, research mind, steady and stubborn, inclination to science`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Below normal health, tooth trouble, tonsils, heart trouble, rheumatic pains, skin disease, elephantiasis, eczema, eye-trouble, blood-pressure, insomnia, unrated gum`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Makes money, inventions, discoveries, patiently saving, neither economical, nor extravagant`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Intelligent, prefers educated partner, strong attachment, permanent one, unassuming, free, and social, sympa­thetic, and accommodative`,
    nkAmenableSigns: `Aries`,
    nkCaste: `Vaisya`,
    nkQualities: `Scientific, philosophical nature, great intelligence, Gifts in teaching, writing, Service oriented, humanitarian concerns, Work well with groups and organizations, May appear aloof or secretive, stoic nature, Success in the second half of life, Tend to not get full credit for the work they do, Humble, may hide their light, hesitant to take on leadership Vata nature`,
    nkShadowIssues: `Health problems in early life, difficult childhood, Trouble in early marriage unless Sun is well-placed, Melancholy nature, Shyness, self-esteem issues`,
    nkFriends: `Mercury, Venus, Saturn`,
    nkEnemies: `Sun, Moon, Mars, Jupiter (reflects money karma—rules 2nd and 11th)`,
    direction: ``,
    nakshatraOwners: `Ma, Ra, Ju`,
    exaltPlanet: `-`,
    debilitated: `-`,
    bhrigu: ``,
    sunTransits: `Feb 13 - Mar 15`,
    aspects: {
      aspectNumbers: [1, 4, 7],
      aspectNames: ["Ar", "Cn", "Li"],
    },
    lunarAstro: {
      basic: [
        `Aquarius sign is an Airy sign and here better part of Saturn comes in to play`,
        `Being an airy sign Aquarius natives are very intellectual and capable of solving complex problems by deep thinking process`,
        `Aquarius is represented by a women with pot in her hand as nobody knows what is in the pot similarly nobody knows what is inside the head of Aquarius natives`,
        `These people keep their emotions to themselves only`,
        `These people are the one who will enjoy with friends in evening and fight for social cause in morning`,
        `These people have strong sense of judgement and will standby on right side regardless of consequences`,
        `Due to patience of Saturn these people are good listeners and like intellectual conversations`,
        `Only negative trait in them are these people do not want to be confronted on emotional plane and secondly these people do not compromise to there rules`,
        `These people believe in drawing lines weather it is accepted by anyone or not does not matter to them`,
      ],
      business: [],
      predictive: [],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [],
        rahu: [`Generate money`],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
  {
    id: 12,
    name: "Pisces",
    vedicName: "Meen (मीन)",
    ownerGraha: "Jupiter (गुरू/बृहस्पति)",
    ownerShortName: "Ju",
    gender: "Female (स्त्री)",
    nature: "Dual (द्विस्वभावी)",
    tenor: "Watery (जल)",
    handle: "Pis",
    characteristics: `This Rashi comprises of the last charan of Poorvabhadrapada, complete Uttara-bhadrapada and Revti Nakshatras. The persons under this sign have good qualities like truthfulness, innocence, logical thinking and love for mankind. They are dwarf-heighted,have small parts of body and beautiful nose,are lazy by nature but versatile, ardent lovers of pleasure, good writers. They easily trust people. They have simple life. They face dangers in early life and are sometimes courageous and at times timid. They develop friendship with leading and popular people. They do work after meticulously pondering over matters and issues. They drink more water. They commonly become popular because of their easygoing nature. They tend to be more emotional rather than rational. They have limited concentration and will power. They are gifted with commanding and impressive personality. They sensibly divide their time between work and rest. They are idle and careless, sometimes dishonest in their dealings. They are afraid of sins. They have good speculative power. They earn large sums all of a sudden in life. They are calm and cool by nature and earn by dint of their own efforts in life. They travel a lot and have many children. They are unprejudiced, frank, tolerant, quick-witted, practical, intuitive and imaginative. They inherit property but face litigation, sometimes of disputes in the process of inheritance. They are patients of arthritis, stomach ailments, heart and eye diseases. They start earning at 20-21 years of their age`,
    kpBodyParty: `Feet and Toes, Lymphatic system , left eyes, Bones of the feet`,
    kpTenorProperties: `watery - governs human blood and blood circulations`,
    kpPathogenicEffects: `Disease of the feet and toes, it also affects intestina. Deformities of the feet, dropsy, delirium, tremens due to drugs, addicted to drinks and thereby complications`,
    kpHPlaces: `Wells, rivers, ponds, fish, water-mill, pumps, cisterns (पाण्याची टाकी)`,
    kpHCountriesNCities: `South of Asia Minor, Calpria, Portugal, Normandy, Fezzen, Egypt, Gallacia in Spain, Zara desert—Nubia, Ceylon, Bournemouth, Grimsby, Christchurch. Farnham, King’s Iyan, Lancester, Alexandria Preston, Southport, Compostella, Regensbury, Ratibon, Seville, Chicago`,
    kpHGender: `Negative/Feminine signs: Passive, receptive, defensive, more self-repressive`,
    kpHOrientations: `Southern`,
    kpHElement: `Watery signs : Fertile imagination; constitution—weak; timid, inert and unwilling to act. Receptive, contemplative, sensitive, sentimental and psychic`,
    kpHQualities: [
      `Common (dual, mutable) signs: Dual, fluctuating, flexi­ bility, plasticity, intellectual, sympathetical sensitive, subtle, versatile, changeful, inconstant, indecisive, vacillating and restless`,
      `Fruitful signs show success: reilisation of ambition`,
      `Mute sign`,
      `Dual signs`,
      `Bicorporeal or double-bodied sign`,
    ],
    kpHPhysicalAppearance: `(Nlord of sub-lord of asc is here) Short; plumpy, stout, muscular, spherical big eyes protruding, soft hair, silky`,
    kpHCharacteristics: `(Nlord of sub-lord of asc is here) Philosophical results, dreamy, contemplating, imagining, honest, outspoken, helpful, humane, forego, forgive and forget, unassuming, over-liberal, unbounded generosity, con­tradiction, in nature, sweet temper, social inclined`,
    kpHHeathNDiseases: `(Nlord of sub-lord of 6th is here) Moderate healths addicted to drinks, affection of the ankle, feet, dropsy, varicose veins, intestinal trouble, perspiration of the feet, typhoid, over-dose of medicine, corns, piles, deformities, T.B., deficiency of enzymes, hernia, deafness`,
    kpHFinanceNFortune: `(Nlord of sub-lord of 2nd is here) Very happy, helpful to others, interested in music, films, spends thereby, good business ability, keeps money safe, permanent service, successful politician`,
    kpHRomance: `(Nlord of sub-lord of 5nd is here) Prefers beautiful partner, also intellectual, fond of fine arts, suspicious nature, dreams much, gets delayed, slow development`,
    nkAmenableSigns: `Capricorn`,
    nkCaste: `Brahmin`,
    nkQualities: `Spiritual, psychic nature, Very sensitive, emotional nature, Romantic, dream of faraway places, love to travel, Need to live near water, Imaginative mind, good for hypnosis and imagery, Need an intelligent mate, may marry twice, Independent, active, influential, Charitable nature, may be too generous at times, Make good priests or counselors, enjoy teaching Good connections with children, Kapha Nature`,
    nkShadowIssues: `May be overly sensitive to criticism, Jealousy issues, Indecisive due to lack of confidence, Contradictory goals or ideals, two fish swimming in opposite directions, Troubles with alcohol or drugs`,
    nkFriends: `Moon, Mars, Jupiter, Sun—Neutral`,
    nkEnemies: `Venus, Mercury, Saturn`,
    direction: ``,
    nakshatraOwners: `Ju, Sa, Me`,
    exaltPlanet: `Venus`,
    debilitated: `Mercury`,
    bhrigu: ``,
    sunTransits: `Mar 15 - Apr 15`,
    aspects: {
      aspectNumbers: [3, 6, 9],
      aspectNames: ["Ge", "Vi", "Sg"],
    },
    lunarAstro: {
      basic: [
        `Last sign of zodiac, a watery sign owned by Jupiter and being watery it is emotional but not as much as Cancer and not as turbulent as Scorpio`,
        `Being a last sign it represents maturity and creates a balance by being a dual in nature`,
        `Last sign also represents final salvation of soul and also shows detachment from this world and knowledge of higher world`,
        `Represented by two fishes joined back to front as shown in picture`,
        `Pisces natives enjoy their own company and likes the isolation from world so they can get lost in their dreamy world`,
        `By being isolated sometime results in depression as they hardly have any friend circle or interest in family`,
        `These people enjoy swimming and water sports and being a watery sign it rejuvenates them from new energy`,
      ],
      business: [],
      predictive: [
        `Isolation`,
        `Higher learning (Spiritual)`,
        `True knowledge of life (Moksh)`,
        `Watery`,
        `Free actions`,
        `Independent decision making`,
        `Planet sitting in Pisces #1 objective is above benific (easy) malific (harsh manar)`,
      ],
      inHouse: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      },
      whenOccupyPlanet: {
        sun: [],
        moon: [],
        mars: [],
        mercury: [],
        venus: [],
        jupiter: [],
        saturn: [`Betrayal for karakatva to fullfil`],
        rahu: [],
        ketu: [],
        uranus: [],
        neptune: [],
        pluto: [],
        fortuna: [],
      },
      snapshots: [],
      remedies: [],
    },
    badhak: {
      badhakHouse: ``,
      badhakSign: ``,
      secondaryBadhakHouse: ``,
      secondaryBadhakSign: ``,
      badhakPlanet: ``,
      secondaryBadhakPlanet: `Rahu`,
    },
  },
]);
