export default Object.freeze([
 {
   "Sub No.": "1",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"46'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "6th Diseases": "Injury in the head - Scars - Brain Congestion - Epilepsy - Coma - Abortion"
},
 {
   "Sub No.": "2",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "6th Diseases": "Headache - trance - eye-trouble - eruptive - fever - venereal diseases "
},
 {
   "Sub No.": "3",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "6th Diseases": "Fainting - Cerebral Anaemia - Reddish and sore eyes - One-side Headache -Thrombosis - Barren"
},
 {
   "Sub No.": "4",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "03'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "6th Diseases": "Brain congestion - Boils - Neuralgia - Cerebral - Haemorrhage - Insomnia - Hysteria - Neuralgia "
},
 {
   "Sub No.": "5",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "6th Diseases": "Haemorrhage - Epilepsy - Scars in head "
},
 {
   "Sub No.": "6",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "6th Diseases": "Diseased - Dog-bite - Rabies"
},
 {
   "Sub No.": "7",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Brain congestion - Malaria"
},
 {
   "Sub No.": "8",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "6th Diseases": "Scar in head - Small-pox - Boils - Injury - Operation in head - Coma - Insomnia - Filaria - Severe head-ache - Barren - Abortion - False-pains - Irregular monthly periods in women."
},
 {
   "Sub No.": "9",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "6th Diseases": "Epilepsy - Violent spurnn - Paralytic Sun-stroke - Barren"
},
 {
   "Sub No.": "10",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "6th Diseases": "Injury around eyes "
},
 {
   "Sub No.": "11",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "6th Diseases": "Cataract- Injury around eyes "
},
 {
   "Sub No.": "12",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"20',00',"
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "6th Diseases": "Facial blood vessels affected "
},
 {
   "Sub No.": "13",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "6th Diseases": "Venereal Diseases "
},
 {
   "Sub No.": "14",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "6th Diseases": "Leukoderma - Leprosy"
},
 {
   "Sub No.": "15",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "6th Diseases": "Disease of metabolism and fats obesity. "
},
 {
   "Sub No.": "16",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"00',00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "6th Diseases": "Venereal Diseases - Catarrh "
},
 {
   "Sub No.": "17",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "6th Diseases": "Skin troubles "
},
 {
   "Sub No.": "18",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "6th Diseases": "Diseased - Leukoderma -. Syphilis - Poor vision - Catarrh "
},
 {
   "Sub No.": "19",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "6th Diseases": "Plague - Brain fever"
},
 {
   "Sub No.": "20",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "6th Diseases": "Carbuncle - Poxes "
},
 {
   "Sub No.": "21",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "6th Diseases": "Sharp fever - Eruptive - fever - Malaria - Cerebral meningitis - Injury - Cuts Accidents"
},
 {
   "Sub No.": "22",
   "Rashi": "Aries",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Plague - Filaria - Fire - Accident - Burns"
},
 {
   "Sub No.": "23",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"13',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Reddish Eyes - Scar eyes - Leprosy "
},
 {
   "Sub No.": "24",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "6th Diseases": "Swelling above the neck"
},
 {
   "Sub No.": "25",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05'06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "6th Diseases": "Pimples - Throat troubles"
},
 {
   "Sub No.": "26",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "6th Diseases": "Tumour in knee - Polypans of the nose"
},
 {
   "Sub No.": "27",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "6th Diseases": "Cataract - Nose trouble"
},
 {
   "Sub No.": "28",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "07:46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "6th Diseases": "Polypous of the nose - Injury around eyes � Defective vision - Bad taste - Deformed lips"
},
 {
   "Sub No.": "29",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"06',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "6th Diseases": "Cold - Cough � Catarrh"
},
 {
   "Sub No.": "30",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "6th Diseases": "Facial Nervous troubles - Burning sensation on the nose and eye brows "
},
 {
   "Sub No.": "31",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "6th Diseases": "Ailments of a sudden nature"
},
 {
   "Sub No.": "32",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "6th Diseases": "Apoplexy - Swelling"
},
 {
   "Sub No.": "33",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "15'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17'46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "6th Diseases": "Cough - Cold - Irregular - Discharge - Pain in legs"
},
 {
   "Sub No.": "34",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "6th Diseases": "Tonsils -Cerebellum - Tongue - Goitre - Sore throat"
},
 {
   "Sub No.": "35",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"40'O0\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "6th Diseases": "Pimples - Tumour � Stammering "
},
 {
   "Sub No.": "36",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "6th Diseases": "Chest pain - Swelling - Cold"
},
 {
   "Sub No.": "37",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "22'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "6th Diseases": "Sore throat - Irregular discharge - Scantly Bleeding"
},
 {
   "Sub No.": "38",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24'06:40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "6th Diseases": "Inflammation of Pericardium - Inflammed Tonsils"
},
 {
   "Sub No.": "39",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "6th Diseases": "Marks on cheek - Jugular Veins - Dirty pimple"
},
 {
   "Sub No.": "40",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27'53'20'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "6th Diseases": "Short of hair - Carotid artery - Adenoids - Goitre"
},
 {
   "Sub No.": "41",
   "Rashi": "Taurus",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "6th Diseases": "Face injury - Throat pain - Weak loins - Constipation -Venereal Disease - Polypous"
},
 {
   "Sub No.": "42",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "6th Diseases": "Shoulder injury - Defective vocal chord"
},
 {
   "Sub No.": "43",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "6th Diseases": "Thymus gland - Bad blood - Fever - Collar Bone troubles"
},
 {
   "Sub No.": "44",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "6th Diseases": "Throat trouble - Ear trouble - Itches - Disorders in sexual parts - Inflammation of Pericardium - Femur - Sciatica"
},
 {
   "Sub No.": "45",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "04'53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33',20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "6th Diseases": "Trouble in Arms and Shoulders"
},
 {
   "Sub No.": "46",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "6th Diseases": "Troubles in Secret Parts - Throat - Thymus Glands � Inflammation - Boils"
},
 {
   "Sub No.": "47",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "06'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "6th Diseases": "Septic Throat - Easonophilia"
},
 {
   "Sub No.": "48",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "08'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Ear trouble - Mumps "
},
 {
   "Sub No.": "49",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12'33'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "6th Diseases": "Asthma - Pus in the ear"
},
 {
   "Sub No.": "50",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20"
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "6th Diseases": "Hiccough - Ear trouble"
},
 {
   "Sub No.": "51",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "6th Diseases": "Throat trouble � Diphtheria"
},
 {
   "Sub No.": "52",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "6th Diseases": "Throat trouble - Ear trouble - Dry Cough"
},
 {
   "Sub No.": "53",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "6th Diseases": "Eosinophilia - Shoulder pains"
},
 {
   "Sub No.": "54",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "18'06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"13',20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "6th Diseases": "Ear trouble - Asthma"
},
 {
   "Sub No.": "55",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "6th Diseases": "Septic throat - Shoulder injury"
},
 {
   "Sub No.": "56",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "20'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"46'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "6th Diseases": "Swelling in the ear - Pulmonary apoplexy"
},
 {
   "Sub No.": "57",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "21'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23'53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "6th Diseases": "Injury in Shoulder Blade - Inflammation - Pain in the ear -  Iodine deficient - Trouble in upper lobe of lungs "
},
 {
   "Sub No.": "58",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "6th Diseases": "Hiccough - Asthma"
},
 {
   "Sub No.": "59",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26'33'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "6th Diseases": "Pleurisy - Pneumonia - Pulmonary Apoplexy"
},
 {
   "Sub No.": "60",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "6th Diseases": "Bronchitis - Urine disorder"
},
 {
   "Sub No.": "61",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"26'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "6th Diseases": "Throat trouble - Pus in the ear - Bronchitis - Eosinophilia "
},
 {
   "Sub No.": "62",
   "Rashi": "Gemini",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Pleurisy - Pneumonia "
},
 {
   "Sub No.": "63",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Tuberculosis - Lungs and Chest trouble - Dropsy - Beriberi - Bad blood - Jaundice"
},
 {
   "Sub No.": "64",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "6th Diseases": "Pancreas - Diaphragm - Beriberi - Loss of appetite - Bad blood - Liver Troubles"
},
 {
   "Sub No.": "65",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "6th Diseases": "Oesophagus - Thoracic and Stomach troubles - Tuberculosis - Dyspepsia "
},
 {
   "Sub No.": "66",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26'40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "6th Diseases": "Tuberculosis - Lungs and ribs - Cancer - Eczema - Pyorrhoea "
},
 {
   "Sub No.": "67",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "6th Diseases": "Both lungs - Nausea - Phthisis � Dyspepsia"
},
 {
   "Sub No.": "68",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"06'40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "6th Diseases": "Galloping T.B. - Bleaching - Scurvy - Jaundice - Pyorrhoea "
},
 {
   "Sub No.": "69",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "08'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"20',00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "6th Diseases": "Chest wounds - Cough - Eczema "
},
 {
   "Sub No.": "70",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"00'00'"
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "6th Diseases": "Ulcer in Respiratory Organs - Galstone "
},
 {
   "Sub No.": "71",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"00',00',"
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40'"
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "6th Diseases": "Tuberculosis - Nausea - Obstructions - Cancer "
},
 {
   "Sub No.": "72",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"06'40',"
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"53'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "6th Diseases": "Ulcer in Respiratory organs - Injury in ribs - Gastric ulcer - Chest wounds "
},
 {
   "Sub No.": "73",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "6th Diseases": "Galstone - Tuberculosis - Cancer � Hiccough"
},
 {
   "Sub No.": "74",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"40'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "6th Diseases": "Gastric trouble - Ulcer in chest � Jaundice"
},
 {
   "Sub No.": "75",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',21\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "6th Diseases": "Defective stomach and lungs - Gas trouble - Difficult breathing - Hysteria - Vit B deficiency "
},
 {
   "Sub No.": "76",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33',21,\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20',00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "6th Diseases": "Hysteria - Liver troubles "
},
 {
   "Sub No.": "77",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "6th Diseases": "Hypochondria - Dropsy � Windiness"
},
 {
   "Sub No.": "78",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13',20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "6th Diseases": "Indigestion - Liver troubles - Constipation - Jaundice"
},
 {
   "Sub No.": "79",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00"
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "6th Diseases": "Cold - Hysteria - Oedema"
},
 {
   "Sub No.": "80",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00"
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "6th Diseases": "Flatulence - Nephritis - Pain in Stomach - Ulcer "
},
 {
   "Sub No.": "81",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06',40\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "6th Diseases": "Typhoid - Jaundice - Vitamin B Deficiency "
},
 {
   "Sub No.": "82",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Jupiter",
   "6th Diseases": "Breathing trouble - Pancreas - Liver trouble - Dropsy - Beriberi "
},
 {
   "Sub No.": "83",
   "Rashi": "Cancer",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Moon",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "6th Diseases": "Trouble in Stomach - Trouble in lungs - Pains in knees and legs - Phlegm - Vitamin B Deficiency "
},
 {
   "Sub No.": "84",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"46'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "6th Diseases": "Heart diseases - Palpitation - Giddiness - Congestion "
},
 {
   "Sub No.": "85",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'00'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "6th Diseases": "Spinal Meningitis - Epilepsy - Swelling - Leukoderma - Leprosy "
},
 {
   "Sub No.": "86",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03'40'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "6th Diseases": "Thrombosis - Sun-stroke - Unconscious - Eye trouble "
},
 {
   "Sub No.": "87",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40',"
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "6th Diseases": "Boils - Thrombosis - Headache - Trouble in Aorta - Neuralgia - Regurgitation in heart "
},
 {
   "Sub No.": "88",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"46',40',"
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "6th Diseases": "Epilepsy - Heart disease - Locomotor Ataxia - Inter-spinal muscles - Spinal marrow"
},
 {
   "Sub No.": "89",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "6th Diseases": "Blood poisoning - Meningitis - Dorsal vertebrae "
},
 {
   "Sub No.": "90",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer - Congestion - Regurgitation - Enlargement of Heart"
},
 {
   "Sub No.": "91",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "6th Diseases": "Rheumatic fever - Rheumatic Heart - Affliction of bony system - Chronic ailment - High fever - Smallpox - Irregular menses - defect in generative system - Abortion - Childless "
},
 {
   "Sub No.": "92",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "6th Diseases": "Nervous trouble - Palpitation of heart - Meningities - Giddiness � Pestilence - Barren - Childless "
},
 {
   "Sub No.": "93",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "6th Diseases": "Eye defect"
},
 {
   "Sub No.": "94",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "6th Diseases": "Eye defect - Palpitation of heart - Bright�s Disease"
},
 {
   "Sub No.": "95",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17'20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "6th Diseases": "Diseases of Ovaries - Enlarged Tonsils - Mucous Disease "
},
 {
   "Sub No.": "96",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "6th Diseases": "Venereal Disease - Cataract"
},
 {
   "Sub No.": "97",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"06'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "6th Diseases": "Eye soar - Reddish eyes "
},
 {
   "Sub No.": "98",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"53'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "6th Diseases": "Bad blood - Blood obstructions - Fever - Boils - Tumours - Inflammations "
},
 {
   "Sub No.": "99",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"00',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "6th Diseases": "Weakness - Poisoning - Pain in the ducts "
},
 {
   "Sub No.": "100",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20'"
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "6th Diseases": "Palpitation of Heart - Pains in the back - Nervous debility "
},
 {
   "Sub No.": "101",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "6th Diseases": "Cataract - Nose trouble "
},
 {
   "Sub No.": "102",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "6th Diseases": "Brain fever - Heart trouble - Palpitation of heart - spinal trouble - spinal meningitis - Sub-stroke - Eye trouble - Typhoid "
},
 {
   "Sub No.": "103",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26'40\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "6th Diseases": "Blood Pressure - Dropsy - Facial eruption - Poxes - Dysentery - Boils � Pustulation"
},
 {
   "Sub No.": "104",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29'13'20\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "6th Diseases": "Pain in back - Venereal Distemper - Cardinal thrombosis - Pain in head - Epilepsy - Meningitis - Fits - Injury in head and chest "
},
 {
   "Sub No.": "105",
   "Rashi": "Leo",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13'20'"
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Sun",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Heart disease - Giddiness - Higher Fever - Palpitation - Regurgitation � Plague"
},
 {
   "Sub No.": "106",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01'13'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Intestinal and Abdominal troubles - Typhoid - Cough due to Gas - Nervous debility"
},
 {
   "Sub No.": "L07",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "6th Diseases": "Flatulence - Over eating - Poor assimilation of food"
},
 {
   "Sub No.": "108",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "6th Diseases": "Worms - Tapeworm - Anaemic "
},
 {
   "Sub No.": "109",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "6th Diseases": "Nervous breakdown - Ulcer in stomach and mouth - Irregular food. "
},
 {
   "Sub No.": "110",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "6th Diseases": "Food poisoning - Diarrhoea - Sprue "
},
 {
   "Sub No.": "111",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "07'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "6th Diseases": "Nervous debility - Vitamin B deficiency - Gastric trouble "
},
 {
   "Sub No.": "112",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "10'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "6th Diseases": "Intestine and Chest troubles - Hypochondria - Chyle defective"
},
 {
   "Sub No.": "113",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "11'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "6th Diseases": "Bleeding Piles - Irrigation - Ulcer in intestines "
},
 {
   "Sub No.": "114",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "6th Diseases": "Typhoid - Jaundice "
},
 {
   "Sub No.": "115",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15'40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "6th Diseases": "Diarrhoea - Cancer in intestines - Poor assimilation "
},
 {
   "Sub No.": "116",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"46',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "6th Diseases": "Constipation - Flatulence - Pain - Chronic Disease - Gas trouble "
},
 {
   "Sub No.": "117",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "6th Diseases": "Poor digestion - Irregular food - Bowels disorder"
},
 {
   "Sub No.": "118",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"26',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "6th Diseases": "Dull Liver - Hysteria "
},
 {
   "Sub No.": "119",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "6th Diseases": "Round worms - Worms - Bowels disorder "
},
 {
   "Sub No.": "120",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "22'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "6th Diseases": "Dull Liver - Poor digestion - Jaundice "
},
 {
   "Sub No.": "121",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "23'20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "6th Diseases": "Ulcer in intestine - Duodenal Ulcer - Careful in diet "
},
 {
   "Sub No.": "122",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "24'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26'06'40\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "6th Diseases": "Dirty pimples - Trouble in veins "
},
 {
   "Sub No.": "123",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27'53'20\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer - Fattiness "
},
 {
   "Sub No.": "124",
   "Rashi": "Virgo",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Mercury",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "6th Diseases": "Hook worms - Constipation - Bad Health - Chronic Disease "
},
 {
   "Sub No.": "125",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53',20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "6th Diseases": "Hernia - Removal of Uterus "
},
 {
   "Sub No.": "126",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "6th Diseases": "Spinal affection - Ulcer "
},
 {
   "Sub No.": "127",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "6th Diseases": "Appendicitis "
},
 {
   "Sub No.": "128",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "6th Diseases": "Ulcer in Urinary tract - Sexual urge "
},
 {
   "Sub No.": "129",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06\"40'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "6th Diseases": "Periods trouble in Women - Susceptible to all diseases "
},
 {
   "Sub No.": "130",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "06\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08'40'00'"
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "6th Diseases": "Urinary trouble - Appendicitis "
},
 {
   "Sub No.": "131",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "08\"40'00'"
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Moles - Skin trouble - Ear trouble � Mumps"
},
 {
   "Sub No.": "132",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "6th Diseases": "Appendicitis - Pus in the ear "
},
 {
   "Sub No.": "133",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "6th Diseases": "Hiccough - Urinary trouble "
},
 {
   "Sub No.": "134",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "14'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "6th Diseases": "Appendicitis - Eczema - Leprosy "
},
 {
   "Sub No.": "135",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "6th Diseases": "Ear trouble - Hernia - Urinary trouble"
},
 {
   "Sub No.": "136",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "6th Diseases": "Pain in Hips - Skin trouble - Hernia "
},
 {
   "Sub No.": "137",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "18'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19'13'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "6th Diseases": "Ulcer in Uterus - Eczema "
},
 {
   "Sub No.": "138",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "6th Diseases": "Skin and Kidney trouble - Leprosy "
},
 {
   "Sub No.": "139",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "20'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer - Hernia - Enlargement of glands in lower abdominal region "
},
 {
   "Sub No.": "140",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "21'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"53'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "6th Diseases": "Chronic diseases - Kidney - Loins trouble - Removal of Uterus "
},
 {
   "Sub No.": "141",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46',40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "6th Diseases": "Skin eruptions - Diabetes - Kidney troubles "
},
 {
   "Sub No.": "142",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"33'20\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "6th Diseases": "Abortion - Bladder and Rectum troubles "
},
 {
   "Sub No.": "143",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "6th Diseases": "Diabetes - Renal abscess - Kidney trouble "
},
 {
   "Sub No.": "144",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29'26'40\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "6th Diseases": "Renal Abscess - Giddiness -Nervousness - Liver complaints - Weak chest and stomach "
},
 {
   "Sub No.": "145",
   "Rashi": "Libra",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Venus",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Troubles in Kidney and Bladder - Pus in urine "
},
 {
   "Sub No.": "146",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Disease in bladder - Profuse bleeding "
},
 {
   "Sub No.": "147",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "6th Diseases": "Prostrate glands - Venereal Distemper "
},
 {
   "Sub No.": "148",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "6th Diseases": "Urinary infection - Excess of bleeding - Urethral Abscess "
},
 {
   "Sub No.": "149",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "6th Diseases": "Piles - Ovary diseases - Scanty monthly flow to ladies "
},
 {
   "Sub No.": "150",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "6th Diseases": "Gravel stone in bladder - Adenoids "
},
 {
   "Sub No.": "151",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "6th Diseases": "Pain in hips - Naval bones - Cuts and wounds in Rectum - Boils around sexual organs "
},
 {
   "Sub No.": "152",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "08\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "6th Diseases": "Venereal distemper - Boils - Constipation - Piles - Phlegm fracture of naval bone "
},
 {
   "Sub No.": "153",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "6th Diseases": "Diseases of generative organs - Boils - Itches around rectum "
},
 {
   "Sub No.": "154",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "11'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "6th Diseases": "Pus in wounds and cuts - gonorrhoea - Chronic ailment - Renal stone "
},
 {
   "Sub No.": "155",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "12'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "6th Diseases": "Bleeding piles - Boils - Chronic ailments - Stone in bladder - Bone fracture "
},
 {
   "Sub No.": "156",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "6th Diseases": "Diseases of Rectum and Genitals - Syphilis - Pus in Genitals "
},
 {
   "Sub No.": "157",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "14'53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"40'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer - Incurable diseases in sex organs "
},
 {
   "Sub No.": "158",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "6th Diseases": "Nervous break down of the genitals - Hernia - Piles "
},
 {
   "Sub No.": "159",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "6th Diseases": "Itches - Boils around Sex organs and Rectum "
},
 {
   "Sub No.": "160",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "6th Diseases": "Fistula - Venereal Distemper - Complicated diseases around navel and sex organs "
},
 {
   "Sub No.": "161",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13',20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "6th Diseases": "Violent Hysteria - Sperm around genitals "
},
 {
   "Sub No.": "162",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "6th Diseases": "Fistula - Bleeding piles - Fisher in rectum "
},
 {
   "Sub No.": "163",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06'40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "6th Diseases": "Stone in Bladder - Kidney - surgery in womb or ovaries "
},
 {
   "Sub No.": "164",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06',40\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "6th Diseases": "Typhoid - Boils around rectum - Pus in genitals - Injury in hip bones "
},
 {
   "Sub No.": "165",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Jupiter",
   "6th Diseases": "Incurable and complicated disease - Cancer in genitals "
},
 {
   "Sub No.": "166",
   "Rashi": "Scorpio",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Mars",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "6th Diseases": "Injury in Hip bones - Chronic Piles - Stone in Bladder "
},
 {
   "Sub No.": "167",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00'46'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Ketu",
   "6th Diseases": "Delayed menses - Barren - Abortion - Lung troubles - Asthma - Hiccough "
},
 {
   "Sub No.": "168",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"46'40"
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Venus",
   "6th Diseases": "Diabetes - Lumbago - Pulmonary troubles "
},
 {
   "Sub No.": "169",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"40'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Sun",
   "6th Diseases": "Lumbago - Hip troubles - Pulmonary diseases "
},
 {
   "Sub No.": "170",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"46'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Moon",
   "6th Diseases": "TB - Pulmonary trouble - Locomotor Locomotor Ataxia "
},
 {
   "Sub No.": "171",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "04'46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05'53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Mars",
   "6th Diseases": "Invalid - Eosinophilia - Blood poison - Abortion "
},
 {
   "Sub No.": "172",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"33',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Rahu",
   "6th Diseases": "TB - Pulmonary trouble - Lumbago "
},
 {
   "Sub No.": "173",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "09\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Lumbago - trouble in lungs - cancer "
},
 {
   "Sub No.": "174",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "09\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Saturn",
   "6th Diseases": "Fracture in Hip - Rheumatic Pain - Asthma "
},
 {
   "Sub No.": "175",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13'20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Ketu",
   "Sub lord": "Mercury",
   "6th Diseases": "Trouble in lungs - Asthma - Locomotor Ataxia"
},
 {
   "Sub No.": "176",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"33'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Venus",
   "6th Diseases": "Diabetes"
},
 {
   "Sub No.": "177",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16\"13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Sun",
   "6th Diseases": "Hiccough - Gout - Swelling on the knee "
},
 {
   "Sub No.": "178",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Moon",
   "6th Diseases": "Ear trouble - Pus in the ear - Diabetes - TB - Slow recovery"
},
 {
   "Sub No.": "179",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Mars",
   "6th Diseases": "Vomiting of Blood - Swelling above the knee "
},
 {
   "Sub No.": "180",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20'06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Rahu",
   "6th Diseases": "Sacral and Spinal troubles - Diseases in veins - Cancer in lungs"
},
 {
   "Sub No.": "181",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21'53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer in lungs - diabetes - Trouble in knee and thigh bones"
},
 {
   "Sub No.": "182",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Saturn",
   "6th Diseases": "Asthma - Fracture of hips and bones - Weak sperms - Abortions "
},
 {
   "Sub No.": "183",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Mercury",
   "6th Diseases": "Nervous break down of thighs and hips - Diabetes "
},
 {
   "Sub No.": "184",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"40',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Venus",
   "Sub lord": "Ketu",
   "6th Diseases": "Sacral and Spinal troubles - Pain in thighs & hips"
},
 {
   "Sub No.": "185",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "26'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"20',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Sun",
   "6th Diseases": "Trouble in thighs - Eye diseases "
},
 {
   "Sub No.": "186",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Moon",
   "6th Diseases": "Sudden death - Bleeding Piles - trouble in bowels "
},
 {
   "Sub No.": "187",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"26'40"
      }
   },
   "To (DeglMin": {
      "Sec)": "29'13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Mars",
   "6th Diseases": "Injuries - Venereal Distemper - High fever - Fracture in arms "
},
 {
   "Sub No.": "188",
   "Rashi": "Sagittarius",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Complicated troubles in thighs and arteries - Paralysis "
},
 {
   "Sub No.": "189",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Rahu",
   "6th Diseases": "Trouble in knee caps - Injuries - Fracture of thigh bones "
},
 {
   "Sub No.": "190",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Jupiter",
   "6th Diseases": "Trouble in knee cap - Chronic Gout - Cancer in Chest "
},
 {
   "Sub No.": "191",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "03'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Saturn",
   "6th Diseases": "Ever in danger - Bone diseases - Chronic ailments - Bushy eye brows and profuse hair growth - Eosinophilia - Pneumonia - Heart failure � Thin � Lean"
},
 {
   "Sub No.": "192",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07'00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Mercury",
   "6th Diseases": "Chronic sickness - Nervous debility - Affected speech - Asthma - Heart attack "
},
 {
   "Sub No.": "193",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07'46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Ketu",
   "6th Diseases": "Heart attack - Congestion in heart - Infantile death - Uncertain heart beat "
},
 {
   "Sub No.": "194",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Sun",
   "Sub lord": "Venus",
   "6th Diseases": "Fair - Chronic Skin Diseases - Trouble in knee cap "
},
 {
   "Sub No.": "195",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11'06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Moon",
   "6th Diseases": "Short life - Pus in knee cap - Gout - Dislocation of knee cap - Water in lower part of lungs -Pus in chest - Injury in nibs"
},
 {
   "Sub No.": "196",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "11\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Mars",
   "6th Diseases": "Accidents - Injuries - Trouble in knee caps - Blood poison - Blood clotting in heart - Deficiency of blood Corpuscles "
},
 {
   "Sub No.": "197",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "11'53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "13\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Rahu",
   "6th Diseases": "Eczema - Leprosy - TB - Digestive troubles "
},
 {
   "Sub No.": "198",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "13\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15'40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Jupiter",
   "6th Diseases": "Cancer in lung - Lymphatic trouble - Skin diseases\nShort life - Pus in lungs - Injury to knee & lungs - Profuse hair growth on head and breast "
},
 {
   "Sub No.": "199",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "15'40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Saturn",
   "6th Diseases": "Nervous weakness - Chronic mental patient - Stomach pain disorder "
},
 {
   "Sub No.": "200",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"46',40"
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"40',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Mercury",
   "6th Diseases": "Infantile death - Knee trouble - Skin diseases - Eczema - Boils - Ugly skin "
},
 {
   "Sub No.": "201",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "19'40',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Ketu",
   "6th Diseases": "Infantile death - Knee trouble - Skin diseases - Eczema - Boils - Ugly skin "
},
 {
   "Sub No.": "202",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"40',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Venus",
   "6th Diseases": "Diabetes - Pus in bones - TB - Poor digestion "
},
 {
   "Sub No.": "203",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Moon",
   "Sub lord": "Sun",
   "6th Diseases": "Short life - Heart attack - Eosinophilia - Chronic headache - Injury to knees - Pus in knee cap"
},
 {
   "Sub No.": "204",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Mars",
   "6th Diseases": "Injury to knee - Deficiency of Red Corpuscles - Anaemia "
},
 {
   "Sub No.": "205",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Rahu",
   "6th Diseases": "Pain in knee cap - Bone troubles - Injury to legs - Dry cough - gout - Amputation "
},
 {
   "Sub No.": "206",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Jupiter",
   "6th Diseases": "Hiccough - Gout - Pain in bones - Cancer in bones "
},
 {
   "Sub No.": "207",
   "Rashi": "Capricorn",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Saturn",
   "6th Diseases": "Bones and Knees trouble - Boils - Gout - chronic bone ailments "
},
 {
   "Sub No.": "208",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "00'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Mercury",
   "6th Diseases": "Arthritis - Running pain in body - Rheumatism - Bowels disorder "
},
 {
   "Sub No.": "209",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "02\"40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Ketu",
   "6th Diseases": "Leg Fracture - Varicose Veins - Cardiac troubles "
},
 {
   "Sub No.": "210",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "02\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "04\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Venus",
   "6th Diseases": "Pain and swelling in legs and knees - Heart troubles"
},
 {
   "Sub No.": "211",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "04\"53'20'"
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"33'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Sun",
   "6th Diseases": "Blood inflammation - Renal Disorder - Gravel - Stones - Strangury "
},
 {
   "Sub No.": "212",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "05\"33',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "06\"40'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Mars",
   "Sub lord": "Moon",
   "6th Diseases": "Hysteria - Boils - Swellings - Pain in legs and Secret parts "
},
 {
   "Sub No.": "213",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "06\"40'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08\"40'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Rahu",
   "6th Diseases": "Pain in knees - Rheumatism - Leprosy"
},
 {
   "Sub No.": "214",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "08'40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Jupiter",
   "6th Diseases": "Eczema - Cancer in leg - High blood-pressure "
},
 {
   "Sub No.": "215",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"33',20',"
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Saturn",
   "6th Diseases": "Asthma - Pus in bones - Palpitation of heart "
},
 {
   "Sub No.": "216",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Mercury",
   "6th Diseases": "Nervous troubles in the leg - Eczema - Injury to calf-muscles "
},
 {
   "Sub No.": "217",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "14'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "15\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Ketu",
   "6th Diseases": "Septic wounds in legs - Swelling in calf muscles "
},
 {
   "Sub No.": "218",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "15\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "17\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Venus",
   "6th Diseases": "Pain in Ankles - calf muscles - Oozing Eczema - Fracture - Disfiguration of calf muscles "
},
 {
   "Sub No.": "219",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "17\"26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"06',40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Sun",
   "6th Diseases": "Pain in knees and legs - Boils on calf muscles "
},
 {
   "Sub No.": "220",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"13'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Moon",
   "6th Diseases": "Eczema - Blood Pressure - Palpitation of heart "
},
 {
   "Sub No.": "221",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"13',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "20\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Rahu",
   "Sub lord": "Mars",
   "6th Diseases": "Fracture in legs - Waste of blood - Blood Pressure "
},
 {
   "Sub No.": "222",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "20\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21'46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Jupiter",
   "6th Diseases": "Lumbago - Hip pains - Cancer in legs "
},
 {
   "Sub No.": "223",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"46',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"53'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Saturn",
   "6th Diseases": "Head the Teeth disorder - Lumbago - Hip pains - Ear troubles - Swelling in in legs - Bruises - Sore throat "
},
 {
   "Sub No.": "224",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "25\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Mercury",
   "6th Diseases": "Windy pain in the body - Changing from one place to the other - Imaginative - Digestive troubles "
},
 {
   "Sub No.": "225",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "25\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"33'20\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Ketu",
   "6th Diseases": "Eczema in ankles - heart trouble - Ugly itches and boils "
},
 {
   "Sub No.": "226",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "26'33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "28\"46'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Venus",
   "6th Diseases": "Indigestion - Pain in Stomach - Flatulence - Appendicitis - Heart afflicted "
},
 {
   "Sub No.": "227",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "28\"46'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "29\"26'40\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Sun",
   "6th Diseases": "Enlarged Heart - Palpitation - Low Blood Pressure - Graves"
},
 {
   "Sub No.": "228",
   "Rashi": "Aquarius",
   "From (Deg": {
      "Min": {
         "Sec)": "29\"26',40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "30\"00'00\""
   },
   "Sign Lord": "Saturn",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Epilepsy - Hysteria - Fits - Lumbago - Pain in Lower abdomen - Irregular Menses "
},
 {
   "Sub No.": "229",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "00\"33'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Moon",
   "6th Diseases": "Liver trouble - Tumour in abdomen - Trouble in intestine "
},
 {
   "Sub No.": "230",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "00\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "01\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Mars",
   "6th Diseases": "Enlarged liver - Hernia - Jaundice - Corns in hands and feet "
},
 {
   "Sub No.": "231",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "01\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "03\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Jupiter",
   "Sub lord": "Rahu",
   "6th Diseases": "Sudden pain in feet and toes - Gout - Swelling - Hernia - Sprue - Abdominal Tumour"
},
 {
   "Sub No.": "232",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "03\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "05\"26'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Saturn",
   "6th Diseases": "Chronic ailments in feet - Constipation - Flatulence - dropsy "
},
 {
   "Sub No.": "233",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "05'26'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "07\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Mercury",
   "6th Diseases": "Malaria - Round Worms - Hook Worms "
},
 {
   "Sub No.": "234",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "07\"20'o0\""
      }
   },
   "To (DeglMin": {
      "Sec)": "08'06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Ketu",
   "6th Diseases": "Dropsy - Rheumatic pains in feet - Flatulence - Ugly itches in foot "
},
 {
   "Sub No.": "235",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "08'06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "10'20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Venus",
   "6th Diseases": "Chronic ailment - Internal parts damaged - Pus in feet - Ailments "
},
 {
   "Sub No.": "236",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "10\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "11'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Sun",
   "6th Diseases": "Sunstroke - Boils in feet "
},
 {
   "Sub No.": "237",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "11'00'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12\"06'40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Moon",
   "6th Diseases": "Lymphatic troubles - Intestinal disorders - Varicose veins - constipation complaints of liquor"
},
 {
   "Sub No.": "238",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "12'53',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Mars",
   "6th Diseases": "Small-pox - Ulcer in intestine - Hernia - Appendicitis "
},
 {
   "Sub No.": "239",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "12\"53',20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "14\"53',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Rahu",
   "6th Diseases": "Rheumatic pains - Hernia - Dropsy - TB "
},
 {
   "Sub No.": "240",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "14\"53'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "16'40'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Saturn",
   "Sub lord": "Jupiter",
   "6th Diseases": "Impotency - Constipation - Nausea - Cancer "
},
 {
   "Sub No.": "241",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "16\"40'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "18\"33',20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Mercury",
   "6th Diseases": "Vitamin 'B� deficiency - Lack of enzymes - Dyspepsia "
},
 {
   "Sub No.": "242",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "18\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "19\"20'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Ketu",
   "6th Diseases": "Eczema in feet and toes - Deformity of feet - Cramps - Gout - Intestinal disorders "
},
 {
   "Sub No.": "243",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "19\"20'00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "21\"33'20'"
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Venus",
   "6th Diseases": "Eye diseases - Nervous disorders - Hysteria - Injuries "
},
 {
   "Sub No.": "244",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "21\"33'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "22\"13'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Sun",
   "6th Diseases": "Eye diseases - Heart afflicted - Hypertension - Low Blood Pressure - Cardiac, thrombosis - Fever - Typhoid - Epilepsy - Sunstroke "
},
 {
   "Sub No.": "245",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "22\"13'20\""
      }
   },
   "To (DeglMin": {
      "Sec)": "23\"20',00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Moon",
   "6th Diseases": "Nervous and Digestive troubles - Eye troubles - Asthma - Cold - Cough - Colic pain - Worms - Typhoid - Gastric troubles "
},
 {
   "Sub No.": "246",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "23\"20',00\""
      }
   },
   "To (DeglMin": {
      "Sec)": "24\"06',40\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Mars",
   "6th Diseases": "Fits - Epilepsy - Brain disorders - Piles � Tissues"
},
 {
   "Sub No.": "247",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "24\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "26\"06'40'"
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Rahu",
   "6th Diseases": "Abdominal disorders - Sudden pain in feet and toes - Ulcer in stomach - Jaundice "
},
 {
   "Sub No.": "248",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "26\"06'40\""
      }
   },
   "To (DeglMin": {
      "Sec)": "27\"53'20\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "jupiter",
   "6th Diseases": "Dyspepsia - Heart trouble - Liver complaints - Jaundice - Abscess - Dermatitis - Catarrh - Carbuncles "
},
 {
   "Sub No.": "249",
   "Rashi": "Pisces",
   "From (Deg": {
      "Min": {
         "Sec)": "27\"53'20"
      }
   },
   "To (DeglMin": {
      "Sec)": "30'00'00\""
   },
   "Sign Lord": "Jupiter",
   "Star lord": "Mercury",
   "Sub lord": "Saturn",
   "6th Diseases": "Ear trouble - Deafness - Pus in the ear - Defective speech - Paralysis "
}
]);